import { Stack, Typography } from '@mui/material'
import Modal from '../Modal'
import { UseHelpModalProps } from './types'

function VisitHistoryModal(props: UseHelpModalProps) {
    return (
        <Modal
            {...props}
            title='Historie návštěv pacienta'
        >
            <Stack className='gap-3'>
                <Stack className='gap-1'>
                    <Typography className='text-sm'>
                        Přehled návštěv daného pacienta za zvolené období.
                    </Typography>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default VisitHistoryModal
