import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    AddPatientsAnamnesisFormStep11Hook,
    CareAnamnesisStep11,
    CareAnamnesisStep11Keys,
    CareAnamnesisStepQuery,
    IAddPatientAnamnesisFormStep11,
    PointDTO,
    PointDTOForm,
} from '../types'
import { useQuery } from '@tanstack/react-query'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../providers'
import {
    getPatientCareAnamnesis,
    PATIENT_CARE_ANAMNESIS_QUERY,
} from './services'
import { useSavePatientCareAnamnesisMutation } from './hooks'
import { convertToDate, formatDateIfValid } from '../../../utils/functionsUtils'

const serverImgWidth = 400
const serverImgHeight = 335

const OFFSET_X = 4
const OFFSET_Y = 6

export const useAddPatientAnamnesisFormStep11 = (
    props: AddPatientsAnamnesisFormStep11Hook,
): IAddPatientAnamnesisFormStep11 => {
    const { setCurrentStep, anamnesisId, currentStep, canEditCareAnamnesis } =
        props
    const { token } = useContext(AuthContext)
    const [isFormLoading, setIsFormLoading] = useState(true)

    const [invalidDateInputs, setInvalidDateInputs] = useState<
        CareAnamnesisStep11Keys[]
    >([])

    const { mutatePatientCareAnamnesisStepPut } =
        useSavePatientCareAnamnesisMutation()

    const defaultValues: CareAnamnesisStep11 = {
        Points: [],
        IsVascularInputs: false,
        IsVascularInputsPeripheral: false,
        IsVascularInputsCentral: false,
        IsVascularInputsOther: false,
        VascularInputsOther: '',
        LocatedAt: '',
        DayOfIntroduction: '',
        LastChange: '',
        IsAroundEntranceCalm: false,
        IsAroundEntranceReddened: false,
        IsAroundEntranceIsland: false,
        IsAroundEntranceSecretion: false,
    }
    const validationSchema = yup.object().shape({})

    const formMethods = useForm<CareAnamnesisStep11>({
        criteriaMode: 'all',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues,
    })

    const { data, isLoading, error } = useQuery<CareAnamnesisStepQuery, Error>(
        [PATIENT_CARE_ANAMNESIS_QUERY, anamnesisId, currentStep],
        () => getPatientCareAnamnesis(anamnesisId, currentStep, token),
        {
            enabled: !!anamnesisId && !!currentStep && !!token,
        },
    )
    useEffect(() => {
        if (data && !isLoading) {
            if (data?.content) {
                const deserializedData = JSON.parse(data.content)

                let mappedPoints: PointDTOForm[] = []

                if (
                    deserializedData?.Points &&
                    deserializedData.Points.length > 0
                ) {
                    mappedPoints = deserializedData.Points.map(
                        (point: PointDTO) => ({
                            Id: point.Id,
                            Coordinates: {
                                X: point.X + OFFSET_X,
                                Y: point.Y + OFFSET_Y,
                                ImageWidth: serverImgWidth,
                                ImageHeight: serverImgHeight,
                            },
                            Order: point.Order,
                            Name: point.Name,
                            Description: point.Description,
                            Version: point.Version,
                        }),
                    )
                }

                const newData = {
                    ...deserializedData,
                    Points: mappedPoints,
                }

                formMethods.reset(newData)

                const invalidDateInputs: CareAnamnesisStep11Keys[] = []

                const dayOfIntroductionDate = convertToDate(
                    deserializedData?.DayOfIntroduction,
                )

                if (dayOfIntroductionDate === null) {
                    invalidDateInputs.push('DayOfIntroduction')
                } else {
                    formMethods.setValue(
                        'DayOfIntroduction',
                        dayOfIntroductionDate,
                    )
                }

                const lastChangeDate = convertToDate(
                    deserializedData?.LastChange,
                )

                if (lastChangeDate === null) {
                    invalidDateInputs.push('LastChange')
                } else {
                    formMethods.setValue('LastChange', lastChangeDate)
                }
            }

            setIsFormLoading(false)
        }
    }, [data, isLoading, formMethods])

    const submitForm = async (
        formData: CareAnamnesisStep11,
        nextStep: boolean,
    ) => {
        try {
            const transformedData = {
                ...formData,
                DayOfIntroduction: formatDateIfValid(
                    formData.DayOfIntroduction,
                ),
                LastChange: formatDateIfValid(formData.LastChange),
                Points: formData.Points.map((point: PointDTOForm) => {
                    // Get the current image dimensions from the Coordinates object
                    const currentImgWidth = point.Coordinates.ImageWidth
                    const currentImgHeight = point.Coordinates.ImageHeight

                    // Calculate proportional X and Y back to the original values
                    const proportionalX =
                        (point.Coordinates.X * serverImgWidth) / currentImgWidth
                    const proportionalY =
                        (point.Coordinates.Y * serverImgHeight) /
                        currentImgHeight

                    const finalX = Math.round(proportionalX - OFFSET_X)
                    const finalY = Math.round(proportionalY - OFFSET_Y)

                    return {
                        Id: point.Id,
                        X: finalX,
                        Y: finalY,
                        Order: point.Order,
                        Name: point.Name,
                        Description: point.Description,
                        Version: point.Version,
                    }
                }),
            }

            if (canEditCareAnamnesis) {
                await mutatePatientCareAnamnesisStepPut({
                    anamnesisID: anamnesisId,
                    data: {
                        currentStep: currentStep,
                        content: JSON.stringify(transformedData),
                    },
                })
            }

            if (nextStep) setCurrentStep((prev) => prev + 1)
            else {
                setCurrentStep((prev) => prev - 1)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        formMethods,
        invalidDateInputs,
        isLoading: isFormLoading || isLoading,
        error: error ? !!error : false,
        submitForm,
        ...props,
    }
}
