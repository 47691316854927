import * as yup from 'yup'

export const anamnesisStep0Schema = yup.object().shape({
    clientName: yup.string(),
    insuranceNumber: yup.string(),
    insuranceCompanyID: yup.number(),

    pernamentAddress: yup.string(),
    phone: yup.string(),

    addressNote: yup.string(),
    familyContact: yup.string(),
    familyContactNote: yup.string(),

    practicalDoctor: yup.string().nullable(),
    doctor: yup.string().nullable(),
    intakeDate: yup
        .string()
        .nullable()
        .test('is-valid-date', 'Zadejte platné datum', (value) => {
            // If value is null or empty, return true (not required)
            if (!value) return true // Allow null or empty values

            const date = new Date(value)
            return !isNaN(date.getTime())
        }),

    transferFrom: yup.string(),
    medication: yup.string(),
    careDiagnoses: yup.string(),
    medicalDiagnoses: yup.string(),
})

export const anamnesisStep1Schema = yup.object().shape({})

export const anamnesisStep2Schema = yup.object().shape({})
