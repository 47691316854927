import { Grid, InputLabel, OutlinedInput } from '@mui/material'
import Aside from './Aside'
import { SplitContentProps } from '../../types'
import { twMerge } from 'tailwind-merge'

export const SplitContent = (props: SplitContentProps) => {
    const { patientNotesQuery, isLoading, activePatient, showTabs } = props

    const getFormattedAddress = (address?: string | null) =>
        address?.replace(', CZ', '')

    return (
        <Grid
            container
            className='pb-6'
        >
            <Grid
                item
                xs={3}
                className={twMerge(
                    'sticky top-48 h-max text-center',
                    !showTabs && 'top-24',
                )}
            >
                <Aside
                    patientNotesQuery={patientNotesQuery}
                    patientLoading={isLoading}
                    patientSex={activePatient?.sex}
                />
            </Grid>
            <Grid
                item
                container
                xs={9}
                spacing={2}
            >
                <Grid
                    item
                    xs={8}
                    md={6}
                >
                    <InputLabel className='pb-2 text-sm font-medium'>
                        Jméno pacienta
                    </InputLabel>
                    <OutlinedInput
                        readOnly
                        size='small'
                        value={activePatient?.fullName}
                        className='w-full rounded bg-orange-100 text-sm font-medium'
                        inputProps={{
                            className: 'px-2 py-1',
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                    md={6}
                >
                    <InputLabel className='pb-2 text-sm font-medium'>
                        Věk
                    </InputLabel>
                    <OutlinedInput
                        readOnly
                        size='small'
                        value={activePatient?.age}
                        className='w-full rounded bg-orange-100 text-sm font-medium'
                        inputProps={{
                            className: 'px-2 py-1',
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={8}
                    md={6}
                >
                    <InputLabel className='pb-2 text-sm font-medium'>
                        Číslo pojištěnce
                    </InputLabel>
                    <OutlinedInput
                        readOnly
                        size='small'
                        value={activePatient?.insuranceNumberFormatted}
                        className='w-full rounded bg-orange-100 text-sm font-medium'
                        inputProps={{
                            className: 'px-2 py-1',
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                    md={6}
                >
                    <InputLabel className='pb-2 text-sm font-medium'>
                        Pojišťovna
                    </InputLabel>
                    <OutlinedInput
                        readOnly
                        size='small'
                        value={activePatient?.insuranceCompanyID}
                        className='w-full rounded bg-orange-100 text-sm font-medium'
                        inputProps={{
                            className: 'px-2 py-1',
                        }}
                    />
                </Grid>
                {!!activePatient?.permanentAddress && (
                    <Grid
                        item
                        xs={12}
                    >
                        <InputLabel className='pb-2 text-sm font-medium'>
                            Trvalá adresa
                        </InputLabel>
                        <OutlinedInput
                            fullWidth
                            readOnly
                            size='small'
                            value={getFormattedAddress(
                                activePatient?.permanentAddress?.fullText,
                            )}
                            multiline
                            className='w-full rounded bg-orange-100 px-2 py-1 text-sm font-medium'
                        />
                    </Grid>
                )}
                {!!activePatient?.careAddress && (
                    <Grid
                        item
                        xs={12}
                    >
                        <InputLabel className='pb-2 text-sm font-medium'>
                            Adresa poskytované péče
                        </InputLabel>
                        <OutlinedInput
                            fullWidth
                            readOnly
                            size='small'
                            value={getFormattedAddress(
                                activePatient?.careAddress?.fullText,
                            )}
                            multiline
                            className='rounded bg-orange-100 px-2 py-1 text-sm font-medium'
                        />
                    </Grid>
                )}
                {activePatient?.mobility && (
                    <Grid
                        item
                        xs={12}
                    >
                        <InputLabel className='pb-2 text-sm font-medium'>
                            Mobilita
                        </InputLabel>
                        <OutlinedInput
                            fullWidth
                            readOnly
                            size='small'
                            value={activePatient?.mobility}
                            multiline
                            className='rounded bg-orange-100 px-2 py-1 text-sm font-medium'
                        />
                    </Grid>
                )}
                {activePatient?.continence && (
                    <Grid
                        item
                        xs={12}
                    >
                        <InputLabel className='pb-2 text-sm font-medium'>
                            Kontinence
                        </InputLabel>
                        <OutlinedInput
                            fullWidth
                            readOnly
                            size='small'
                            value={activePatient?.continence}
                            multiline
                            className='rounded bg-orange-100 px-2 py-1 text-sm font-medium'
                        />
                    </Grid>
                )}
                <Grid
                    item
                    xs={12}
                >
                    <InputLabel className='pb-2 text-sm font-medium'>
                        Alergie
                    </InputLabel>
                    <OutlinedInput
                        fullWidth
                        readOnly
                        size='small'
                        value={activePatient?.alergy || 'Žádné'}
                        multiline
                        className='rounded bg-orange-100 px-2 py-1 text-sm font-medium'
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <InputLabel className='pb-2 text-sm font-medium'>
                        Sebeobsluha
                    </InputLabel>
                    <OutlinedInput
                        fullWidth
                        readOnly
                        size='small'
                        value={
                            activePatient?.selfService ||
                            `Nezávisl${
                                activePatient?.sex === 'Male' ? 'ý' : 'á'
                            }`
                        }
                        multiline
                        className='rounded bg-orange-100 px-2 py-1 text-sm font-medium'
                    />
                </Grid>

                <Grid
                    item
                    xs={12}
                >
                    <InputLabel className='pb-2 text-sm font-medium'>
                        Ošetřujicí sestra
                    </InputLabel>
                    <OutlinedInput
                        fullWidth
                        readOnly
                        size='small'
                        value={activePatient?.nurseName || ''}
                        multiline
                        className='rounded bg-orange-100 px-2 py-1 text-sm font-medium'
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <InputLabel className='pb-2 text-sm font-medium'>
                        Kmenová sestra
                    </InputLabel>
                    <OutlinedInput
                        fullWidth
                        readOnly
                        size='small'
                        value={activePatient?.baseNurseName || ''}
                        multiline
                        className='rounded bg-orange-100 px-2 py-1 text-sm font-medium'
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}
