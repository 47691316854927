import {
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
} from '@mui/material'
import { SelectIdDropdownProps } from '../types'
import { useUIDSeed } from 'react-uid'

export const SelectIdDropdown = (props: SelectIdDropdownProps) => {
    const {
        disabled,
        handleIdChange,
        setIdFunction,
        selectItems,
        labelTitle,
        isError,
        isLoading,
        ...elementProps
    } = props

    const uid = useUIDSeed()

    return (
        <Stack spacing={1}>
            <InputLabel
                sx={{
                    fontSize: '0.875rem',
                }}
            >
                {labelTitle}
            </InputLabel>
            <Select
                variant='outlined'
                size='small'
                disabled={disabled || isLoading || isError}
                onChange={(e: SelectChangeEvent) =>
                    handleIdChange(e, setIdFunction)
                }
                defaultValue={undefined}
                MenuProps={{
                    MenuListProps: {
                        sx: {
                            maxHeight: '300px',
                        },
                    },
                }}
                inputProps={{
                    sx: {
                        fontSize: '13px',
                        backgroundColor: '#f8f8f8',
                    },
                }}
                {...elementProps}
            >
                {selectItems?.map((selectItem) => (
                    <MenuItem
                        key={uid(selectItem.id)}
                        sx={{
                            fontSize: '13px',
                        }}
                        value={selectItem.id}
                    >
                        {selectItem.name}
                    </MenuItem>
                ))}
            </Select>
        </Stack>
    )
}
