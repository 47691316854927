import {
    useRef,
    useState,
    useEffect,
    useCallback,
    useContext,
    useMemo,
} from 'react'
import { FileProps, MessageAttachmentsProps } from '../BulletinUpload'
import { ControllerRenderProps, UseFormSetValue } from 'react-hook-form'
import { BulletinForm } from '../../../types/components'
import { useQueries, useQuery } from '@tanstack/react-query'
import { DocumentQueryProps } from '../../../api/types'
import { AuthContext } from '../../../providers'
import { getBulletinDocumentFile } from '../services'
import { useHelpCamera } from '../../../hooks/useHelpCamera'
import { useCustomSnackbar } from '../../../hooks'

type BulletinPropsHooks = {
    setValue: UseFormSetValue<BulletinForm>
}
type Props = {
    inputRef: React.RefObject<HTMLInputElement>
    selectedFiles: FileProps[]
    handleButtonClick: () => void
    setSelectedFiles: React.Dispatch<React.SetStateAction<FileProps[]>>
    handleUploadDiscard: (imageSrc: string) => void
    handleUploadFile: (
        e: React.ChangeEvent<HTMLInputElement>,
        field: ControllerRenderProps<BulletinForm, 'attachments'>,
    ) => void
}

export const useBulletinUpload = ({ setValue }: BulletinPropsHooks): Props => {
    const inputRef = useRef<HTMLInputElement>(null)
    const [selectedFiles, setSelectedFiles] = useState<FileProps[]>([])
    const [checkCameraPermission, setCheckCameraPermission] =
        useState<boolean>(false)
    const customSnackbar = useCustomSnackbar()

    const { cameraPermission } = useHelpCamera()

    useEffect(() => {
        setValue('attachments', selectedFiles)
    }, [selectedFiles, setValue])

    const handleUploadDiscard = useCallback((imageSrc: string) => {
        setSelectedFiles((prev) => {
            return prev.filter(
                (documentPrev) => imageSrc !== documentPrev.imageSrc,
            )
        })
    }, [])

    useEffect(() => {
        if (cameraPermission === 'denied' && checkCameraPermission) {
            customSnackbar(
                'Nebyl udělen přístup k Vaší kameře. V případě, že budete chtít vložit obrázek, vyberte jej z galerie nebo v nastavení Vašeho telefonu udělte prohlížeči náležité oprávnění.',
                'warning',
                true,
            )
            setCheckCameraPermission(false)
        }
    }, [checkCameraPermission, cameraPermission, customSnackbar])

    const handleButtonClick = async () => {
        setCheckCameraPermission(true)

        inputRef.current?.click()
    }

    const handleUploadFile = useCallback(
        (
            e: React.ChangeEvent<HTMLInputElement>,
            field: ControllerRenderProps<BulletinForm, 'attachments'>,
        ) => {
            const files = e.target.files
            if (files && files.length > 0) {
                const fileList = Array.from(files)
                field.onChange([...(field.value || []), ...fileList])

                const reader = new FileReader()
                reader.readAsDataURL(files[0])
                reader.onload = () => {
                    setSelectedFiles((prev: FileProps[]) => [
                        ...prev,
                        {
                            imageSrc: reader.result as string,
                            name: files[0].name,
                            type: files[0].type,
                        },
                    ])
                }
            }
        },
        [],
    )

    return {
        inputRef,
        selectedFiles,
        handleUploadFile,
        setSelectedFiles,
        handleUploadDiscard,
        handleButtonClick,
    }
}

export const useBulletinDocumentForm = ({
    attachments,
    setSelectedFiles,
}: {
    attachments: MessageAttachmentsProps[]
    setSelectedFiles: React.Dispatch<React.SetStateAction<FileProps[]>>
}) => {
    const { token } = useContext(AuthContext)

    const queries = useMemo(() => {
        if (!attachments || attachments?.length === 0) {
            return []
        }

        return attachments.map((attachment) => ({
            queryKey: ['bulletin', 'documentFile', attachment.id],
            queryFn: () =>
                getBulletinDocumentFile(
                    attachment.id.toString(),
                    token,
                    200,
                    200,
                ),
            enabled: !!attachment.id && !!token,
        }))
    }, [attachments, token])

    const documentQueries = useQueries({ queries })

    const allQueriesSuccessful = useMemo(
        () => documentQueries.every((query) => query.isSuccess),
        [documentQueries],
    )

    useEffect(() => {
        if (allQueriesSuccessful) {
            const files: FileProps[] = documentQueries.map((query, index) => {
                const imageSrc = query.data as string
                const attachment = attachments[index]

                return {
                    id: attachment.id,
                    name: attachment.name,
                    imageSrc: imageSrc,
                    description: attachment.description,
                    type: attachment.type,
                }
            })
            setSelectedFiles(files)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allQueriesSuccessful])
}

export const useQueryBulletinDocumentFile = ({
    documentId,
    width,
    height,
    disable = false,
    normalSize = false,
}: DocumentQueryProps) => {
    const { token } = useContext(AuthContext)
    const documentFileQuery = useQuery(
        ['bulletin', 'documentFile', documentId, normalSize],
        () => getBulletinDocumentFile(documentId, token, width, height),
        {
            enabled:
                documentId !== undefined && token !== '' && disable === false,
        },
    )

    return documentFileQuery
}
