import type { FC } from 'react'
import { wrap } from '../../utils'
import { VisitDetailView } from './VisitDetailView'
import { usePage } from './hooks'
import type { UseVisitDetailHookProps } from './types'

export const VisitDetailPage: FC<UseVisitDetailHookProps> = wrap(
    VisitDetailView,
    usePage,
)
