import { type FC } from 'react'
import { BulletinPageProps } from './types'
import { Box, IconButton, SpeedDialAction, Typography } from '@mui/material'
import { HelpDial, Navigation } from '../../components'
import { BulletinFiltersProvider } from '../../providers'
import { BulletinPageContent } from './components/BulletinPageContent'
import { bulletinActions } from '../../components/HelpDial/HelpDialActions'
import { HelpSpeedDialActions } from '../../components/HelpDial/types'
import { useUIDSeed } from 'react-uid'
import HomeIcon from '@mui/icons-material/Home'
import { useNavigate } from 'react-router-dom'
import { BulletinDial } from '../../components/Bulletin/BulletinDial'
import { useFnol } from '../../providers/AuthProvider/hooks'

export const BulletinPageView: FC<BulletinPageProps> = ({
    isMultiEditMode,
    setIsMultiEditMode,
}) => {
    const uid = useUIDSeed()
    const navigate = useNavigate()

    const { isFnol } = useFnol()

    return (
        <Box
            component='main'
            className='relative bg-orange-50 pt-6'
            sx={{
                minHeight: isMultiEditMode
                    ? 'calc(100vh - 112px)'
                    : 'calc(100vh - 64px)',
                paddingBottom: isMultiEditMode ? '7rem' : '4rem',
            }}
        >
            <Navigation>
                <Box className='flex items-center'>
                    <IconButton
                        onClick={() => navigate('/home')}
                        className='mr-1'
                    >
                        <HomeIcon style={{ color: '#faa26b' }} />
                    </IconButton>
                    <Typography
                        variant='h1'
                        className='text-xl font-medium sm:text-2xl'
                    >
                        Nástěnka
                    </Typography>
                </Box>
            </Navigation>

            <Box className='mx-auto  pt-2 lg:max-w-container'>
                <BulletinFiltersProvider>
                    <BulletinPageContent
                        isMultiEditMode={isMultiEditMode}
                        setIsMultiEditMode={setIsMultiEditMode}
                    />
                </BulletinFiltersProvider>
                <HelpDial isBulletinEditModeEnabled={isMultiEditMode}>
                    {({ handleClose, handleActionClick }) => {
                        let bulletinActionRender = bulletinActions
                        if (!isFnol) {
                            bulletinActionRender = bulletinActionRender.filter(
                                (action) => action.modalName !== 'fnolContacts',
                            )
                        }
                        return bulletinActionRender.map(
                            ({
                                name,
                                icon,
                                modalName,
                            }: HelpSpeedDialActions) => (
                                <SpeedDialAction
                                    key={uid(modalName)}
                                    icon={icon}
                                    tooltipTitle={name}
                                    tooltipPlacement='right'
                                    tooltipOpen
                                    sx={{
                                        '& .MuiSpeedDialAction-staticTooltipLabel':
                                            {
                                                width: 'max-content',
                                            },
                                    }}
                                    onClick={handleActionClick(modalName)}
                                />
                            ),
                        )
                    }}
                </HelpDial>
                <BulletinDial
                    isBulletinEditModeEnabled={isMultiEditMode}
                    setIsMultiEditMode={setIsMultiEditMode}
                />
            </Box>
        </Box>
    )
}
