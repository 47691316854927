import {
    createContext,
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    useState,
} from 'react'
import { Patient } from '../types'

interface PatientContextProps {
    activePatient?: Patient
    setActivePatient: Dispatch<SetStateAction<Patient | undefined>>
}

export const PatientContext = createContext<PatientContextProps>({
    activePatient: undefined,
    setActivePatient: () => {},
})

export const PatientProvider = ({ children }: PropsWithChildren) => {
    const [activePatient, setActivePatient] = useState<Patient | undefined>()

    return (
        <PatientContext.Provider value={{ activePatient, setActivePatient }}>
            {children}
        </PatientContext.Provider>
    )
}
