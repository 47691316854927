import moment from 'moment'
import type { ListNurseVisit } from '../api/visit/types'
import type { VisitCollectionHookProps, VisitCollectionProps } from '../types'
import { useNearestElement } from './useNearestElement'

export const useVisitMonthCollection = ({
    collectionData,
    isVisitLoading,
    nearestElementAvailable,
    orderAscending,
}: VisitCollectionHookProps): VisitCollectionProps => {
    const dateVisitCollections = collectionData?.reduce<{
        [key: string]: ListNurseVisit[]
    }>((acc, object) => {
        const date = object?.date
            ? moment(object.date, 'DD.MM.YYYY').locale('cs').format('L')
            : ''
        if (date && !acc[date]) {
            acc[date] = []
        }
        if (date) {
            acc[date]?.push(object as ListNurseVisit)
        }
        return acc
    }, {})

    const sortedDateVisitCollections = Object.fromEntries(
        Object.entries(dateVisitCollections || {})
            .sort(([dateA], [dateB]) =>
                !!orderAscending
                    ? moment(dateA, 'L').diff(moment(dateB, 'L'))
                    : moment(dateB, 'L').diff(moment(dateA, 'L')),
            )
            .map(([date, visits]) => [
                date,
                visits.sort((a, b) =>
                    moment(b.time, 'kk:mm').diff(moment(a.time, 'kk:mm')),
                ),
            ]),
    )

    const nearestElement = useNearestElement({
        sortedDateVisitCollections,
        isAvailable: nearestElementAvailable,
    })

    const hasEntries =
        dateVisitCollections &&
        Object.entries(dateVisitCollections).length !== 0 &&
        !isVisitLoading

    return {
        sortedDateVisitCollections,
        hasEntries,
        ...nearestElement,
    }
}
