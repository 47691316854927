import { useNavigate } from 'react-router-dom'
import {
    VisitFormData,
    VisitFormDataProps,
} from './../../pages/VisitDetailPage/types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { useContext } from 'react'
import { AuthContext } from '../../providers'
import type { VisitNurseDetails } from '../../types'
import {
    ListType,
    getListOfVisits,
    getVisitDetails,
    putVisitOrder,
    searchVisits,
    sendVisit,
    stornoVisit,
    updateVisitDetails,
} from './services'
import { ListOfVisitsQuery, VisitOrderItem } from './types'
import { useSnackbar } from 'notistack'
import { AxiosError } from 'axios'
import { ErrorResponse } from '../user/types'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'

export const useVisitDetailsQuery = (visitId?: string) => {
    const { token } = useContext(AuthContext)
    const navigate = useNavigate()
    const {
        data: visitData,
        isLoading: visitDetailsLoading,
        isError: isVisitDetailsError,
    } = useQuery<VisitNurseDetails>(
        ['visitDetails', visitId],
        () => getVisitDetails(Number(visitId), token),
        {
            enabled: visitId !== undefined || visitId !== 0,
            onError: () => {
                navigate('/404')
            },
        },
    )

    return {
        visitData,
        visitDetailsLoading,
        isVisitDetailsError,
    }
}

export const useVisitQuery = (
    query?: ListOfVisitsQuery,
    token?: string,
    isDisabled = false,
    listType: keyof typeof ListType = 'normal',
) => {
    const { data, isError, isLoading } = useQuery(
        ['visit', { ...query }],
        () => getListOfVisits(query, token, listType),
        {
            enabled: !!token && !isDisabled,
        },
    )

    return { data, isError, isLoading }
}

export const useOrderMutation = (dateAt: Date | null) => {
    const { token } = useContext(AuthContext)
    const queryClient = useQueryClient()
    const mutateDate = moment(dateAt).format('YYYY-MM-DD')

    const { mutateAsync, isLoading, isError } = useMutation(
        async (data: VisitOrderItem[]) =>
            await putVisitOrder(data, mutateDate, token),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['visit'])
            },
        },
    )

    return { mutateAsync, isLoading, isError }
}

export const useUpdateVisit = (visitId?: number) => {
    const { token } = useContext(AuthContext)
    const queryClient = useQueryClient()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const { mutateAsync, isLoading, isError } = useMutation(
        (data: VisitFormDataProps) => updateVisitDetails(data, visitId, token),
        {
            onSuccess: async (data, variables) => {
                await queryClient.invalidateQueries([
                    'visitDetails',
                    String(visitId),
                ])
                if (variables?.showSavedSnackbar) {
                    enqueueSnackbar('návštěva uložena.', {
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        action: (key) => (
                            <IconButton onClick={() => closeSnackbar(key)}>
                                <Close color='secondary' />
                            </IconButton>
                        ),
                    })
                }
            },
            onError: (res) => {
                const error = (res as AxiosError).response
                    ?.data as ErrorResponse
                enqueueSnackbar(
                    `Návštěvu se nepodařilo uložit: ${error.title}.`,
                    {
                        variant: 'error',
                        anchorOrigin: { vertical: 'top', horizontal: 'center' },
                        action: (key) => (
                            <IconButton onClick={() => closeSnackbar(key)}>
                                <Close color='secondary' />
                            </IconButton>
                        ),
                    },
                )
            },
        },
    )

    return { mutateAsync, isLoading, isError }
}

export const useStornoVisit = (visitId?: number) => {
    const { token } = useContext(AuthContext)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const { mutateAsync, isLoading, isError } = useMutation(
        (reasonNonDone: string) => stornoVisit(reasonNonDone, visitId, token),
        {
            onSuccess: () => {
                enqueueSnackbar('Návštěna nastavena na neprovedenou.', {
                    variant: 'success',
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    action: (key) => (
                        <IconButton onClick={() => closeSnackbar(key)}>
                            <Close color='secondary' />
                        </IconButton>
                    ),
                })
            },
            onError: (res) => {
                const error = (res as AxiosError).response
                    ?.data as ErrorResponse
                enqueueSnackbar(
                    `Návštěvu se nepodařilo stornovat: ${error.title}.`,
                    {
                        variant: 'error',
                        anchorOrigin: { vertical: 'top', horizontal: 'center' },
                        action: (key) => (
                            <IconButton onClick={() => closeSnackbar(key)}>
                                <Close color='secondary' />
                            </IconButton>
                        ),
                    },
                )
            },
        },
    )

    return { mutateAsync, isLoading, isError }
}

export const useSendVisit = async (visitId?: number) => {
    const { token } = useContext(AuthContext)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const { mutateAsync, isLoading, isError } = useMutation(
        async (data: VisitFormData) => await sendVisit(data, visitId, token),
        {
            onSuccess: () => {
                enqueueSnackbar('návštěva odeslána.', {
                    variant: 'success',
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    action: (key) => (
                        <IconButton onClick={() => closeSnackbar(key)}>
                            <Close color='secondary' />
                        </IconButton>
                    ),
                })

                queryClient.refetchQueries(['visitDetails'])
                navigate(-1)
            },
            onError: (res) => {
                const error = (res as AxiosError).response
                    ?.data as ErrorResponse
                const { errors } = error

                enqueueSnackbar(
                    `Návštěvu se nepodařilo odeslat: ${error.title}.`,
                    {
                        variant: 'error',
                        anchorOrigin: { vertical: 'top', horizontal: 'center' },
                        autoHideDuration: 10000,
                        action: (key) => (
                            <IconButton onClick={() => closeSnackbar(key)}>
                                <Close color='secondary' />
                            </IconButton>
                        ),
                    },
                )

                Object.keys(errors).forEach((key) => {
                    enqueueSnackbar(errors[key], {
                        variant: 'error',
                        anchorOrigin: { vertical: 'top', horizontal: 'center' },
                        autoHideDuration: 10000,
                        action: (key) => (
                            <IconButton onClick={() => closeSnackbar(key)}>
                                <Close color='secondary' />
                            </IconButton>
                        ),
                    })
                })
            },
        },
    )

    return { mutateAsync, isLoading, isError }
}

export const useSearchVisit = (query: string) => {
    const { token } = useContext(AuthContext)
    const { data, isError, isLoading } = useQuery(
        ['visit', 'search', { query }],
        async () => await searchVisits(query, token),
        {
            enabled: !!token && query.length > 2,
        },
    )

    return { data, isError, isLoading }
}
