import { useQuery } from '@tanstack/react-query'
import {
    getOrganizationUsers,
    getOrganizations,
    getReadonlyUserResponse,
} from './services'
import { SimpleEnum } from '../../types'
import { ErrorResponse, LoginResponse } from '../user/types'

export const useOrganizationsQuery = (token?: string) => {
    const {
        data: organizations,
        isLoading: organizationsLoading,
        isError: organizationsError,
    } = useQuery<SimpleEnum[], ErrorResponse>(
        ['organizations'],
        () => getOrganizations(token),
        {
            enabled: !!token && token !== '',
            retry: false,
        },
    )

    return { organizations, organizationsLoading, organizationsError }
}

export const useOrganizationUsersQuery = (
    organizationId?: number,
    token?: string,
) => {
    const {
        data: users,
        isLoading: usersLoading,
        isError: usersError,
    } = useQuery<SimpleEnum[], ErrorResponse>(
        ['organizations', 'users', organizationId],
        () => getOrganizationUsers(organizationId, token),
        {
            enabled: !!token && token !== '' && !!organizationId,
            retry: false,
        },
    )

    return { users, usersLoading, usersError }
}

export const useReadonlyResponseQuery = (userId?: number, token?: string) => {
    const {
        data: readonlyResponse,
        isLoading: readonlyResponseLoading,
        isError: readonlyResponseError,
    } = useQuery<LoginResponse, ErrorResponse>(
        ['organizations', 'readOnlyToken', userId],
        () => getReadonlyUserResponse(userId, token),
        {
            enabled: !!token && token !== '' && !!userId,
            retry: false,
        },
    )

    return { readonlyResponse, readonlyResponseLoading, readonlyResponseError }
}
