import { Slide, Box, Paper, Modal } from '@mui/material'
import { ModalComponentProps } from '../types/components'

export const SlideModal = (props: ModalComponentProps) => {
    const { title, children, handleClose, ...elementProps } = props

    return (
        <Modal
            onClick={handleClose}
            {...elementProps}
        >
            <Slide
                direction='up'
                in={true}
            >
                <Box
                    sx={{
                        position: 'absolute' as 'absolute',
                        bottom: '0',
                        width: '100%',
                        minHeight: '20vh',
                        overflowY: 'auto',
                        borderRadius: '6px',
                        maxWidth: '768px',
                        '@media screen and (min-width: 768px)': {
                            left: 'calc(50% - 384px)',
                            bottom: 'auto',
                            top: '50%',
                            transform: 'translateY(-50%) !important',
                        },
                    }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Paper
                        sx={{
                            background: '#feefe6',
                        }}
                    >
                        {children}
                    </Paper>
                </Box>
            </Slide>
        </Modal>
    )
}
