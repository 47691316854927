import { useContext } from 'react'
import { AuthContext } from '../../../providers'
import { useCustomSnackbar } from '../../../hooks'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AddPatientFormFormatted, PutSaveCareAnamnesisStep } from '../types'
import {
    createCareAnamnesis,
    PATIENT_ANAMNESES_QUERY,
    PATIENT_CARE_ANAMNESIS_QUERY,
    postNewPatient,
    putSaveCareAnamnesisStep,
} from './services'
import { PATIENT_CARD_LIST_QUERY } from './usePatientsQuery'

export const useAddPatientMutation = () => {
    const { token } = useContext(AuthContext)
    const snackbar = useCustomSnackbar()
    const queryClient = useQueryClient()

    const addPatientMutation = useMutation(
        async (data: AddPatientFormFormatted) =>
            await postNewPatient({ data, token }),
        {
            onSuccess: () => {
                queryClient.refetchQueries([PATIENT_CARD_LIST_QUERY])
                snackbar('Pacient byl úspěšně přidán do kartotéky.', 'success')
            },
            onError: (error: any) => {
                snackbar(
                    error.response?.data?.detail ||
                        'Nastala chyba při vytváření pacienta.',
                    'error',
                )
            },
        },
    )

    return addPatientMutation
}

export const useSavePatientCareAnamnesisMutation = () => {
    const { token } = useContext(AuthContext)
    const snackbar = useCustomSnackbar()
    const queryClient = useQueryClient()

    const { mutateAsync: mutatePatientCareAnamnesisStepPut, isLoading } =
        useMutation(
            async ({ anamnesisID, data }: PutSaveCareAnamnesisStep) =>
                await putSaveCareAnamnesisStep({
                    anamnesisID: anamnesisID,
                    data,
                    token,
                }),
            {
                onSuccess: (data) => {
                    const { currentStep, anamnesisID, patientID } = data

                    queryClient.refetchQueries([
                        PATIENT_CARE_ANAMNESIS_QUERY,
                        anamnesisID,
                        currentStep,
                    ])
                    queryClient.refetchQueries([
                        PATIENT_ANAMNESES_QUERY,
                        patientID,
                    ])
                    // snackbar('Step byl úspešně uložen', 'success')
                },
                onError: (error: any) => {
                    snackbar(
                        error.response?.data?.detail ||
                            'Nastala chyba při ukládání kroku.',
                        'error',
                    )
                },
            },
        )

    return { mutatePatientCareAnamnesisStepPut, isLoading }
}

export const useCreateAnamnesisMutation = () => {
    const { token } = useContext(AuthContext)
    const snackbar = useCustomSnackbar()
    const queryClient = useQueryClient()

    const { mutateAsync: mutateCreateAnamnesis, isLoading } = useMutation(
        async ({ patientId }: { patientId: number }) =>
            await createCareAnamnesis({
                patientId,
                token,
            }),
        {
            onSuccess: (data) => {
                queryClient.refetchQueries([
                    PATIENT_ANAMNESES_QUERY,
                    data.patientID,
                ])
                snackbar('Anamnéza byla úspěšně vytvořena.', 'success')
            },
            onError: (error: any) => {
                snackbar(
                    error.response?.data?.detail ||
                        'Nastala chyba při vytváření anamnézy.',
                    'error',
                )
            },
        },
    )

    return { mutateCreateAnamnesis, isLoading }
}
