import { Button } from '@mui/material'
import React from 'react'
import DownloadIcon from '@mui/icons-material/Download'

const getFileType = (base64String: string): string | null => {
    const matches = base64String.match(/^data:(.*);base64,/)
    return matches ? matches[1] : null
}

const base64ToBlob = (base64String: string, contentType: string): Blob => {
    const byteCharacters = atob(base64String.split(',')[1])
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512)
        const byteNumbers = new Array(slice.length)

        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: contentType })
}

const downloadBlobFile = (blob: Blob, filename: string): void => {
    const element = document.createElement('a')
    const url = URL.createObjectURL(blob)
    element.setAttribute('href', url)
    element.setAttribute('download', filename)
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
    URL.revokeObjectURL(url)
}

type Props = {
    base64String: string
    filename: string
}

const DownloadFileButton: React.FC<Props> = ({ base64String, filename }) => {
    const handleDownload = (): void => {
        const fileType = getFileType(base64String)
        if (!fileType) {
            console.log('Invalid base64 string')
            return
        }

        const extension = fileType.split('/')[1] // Extract the extension
        const downloadFileName = `${filename}.${extension}`
        const blob = base64ToBlob(base64String, fileType)

        downloadBlobFile(blob, downloadFileName)
    }

    return (
        <Button
            type='button'
            size='small'
            className='p-0 text-base capitalize'
            onClick={handleDownload}
        >
            Stáhnout <DownloadIcon className='ml-2 text-2xl' />
        </Button>
    )
}

export default DownloadFileButton
