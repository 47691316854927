import { usePageTitle } from './../../../hooks/usePageTitle'
import moment from 'moment'
import { useContext } from 'react'
import { useVisitQuery } from '../../../api/visit/hooks'
import { AuthContext } from '../../../providers'
import type { UseVisitOrderHookProps, VisitOrderPageProps } from '../types'
import { useReorder } from './useReorder'
import { useDateHandle } from './useDateHandle'

export const usePage = (props: UseVisitOrderHookProps): VisitOrderPageProps => {
    const { token } = useContext(AuthContext)

    const dateHandle = useDateHandle()

    const {
        data: visitData,
        isLoading: isVisitLoading,
        isError: isVisitError,
    } = useVisitQuery(
        {
            dateAt: dateHandle.activeDate
                ? moment(dateHandle.activeDate).format('YYYY-MM-DD')
                : moment().format('YYYY-MM-DD'),
            stateIn: ['New', 'Scheduled', 'Draft'],
            includeNurseOrdering: true,
            resultLimit: false,
        },
        token,
    )

    usePageTitle('Pořadí návštěv')

    const { reorderData, onDragEnd } = useReorder({
        visitData,
        isVisitError,
        isVisitLoading,
        reorderDate: dateHandle.activeDate,
    })

    return {
        visitData,
        isVisitLoading,
        isVisitError,
        reorderData,
        onDragEnd,
        ...dateHandle,
    }
}
