import {
    createContext,
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    useState,
} from 'react'

export interface MainVisitType {
    id: number
    date?: string
}

interface PatientContextProps {
    mainVisit?: MainVisitType
    setMainVisit: Dispatch<SetStateAction<MainVisitType | undefined>>
}

export const MainVisitContext = createContext<PatientContextProps>({
    mainVisit: undefined,
    setMainVisit: () => {},
})

export const MainVisitProvider = ({ children }: PropsWithChildren) => {
    const [mainVisit, setMainVisit] = useState<MainVisitType | undefined>()

    return (
        <MainVisitContext.Provider value={{ mainVisit, setMainVisit }}>
            {children}
        </MainVisitContext.Provider>
    )
}
