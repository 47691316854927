import { useCallback, useState } from 'react'

export const useLocation = () => {
    const [locationErrorMsg, setLocationErrorMsg] = useState('')
    const [latLng, setLatLng] = useState('')
    const [isFindingLocation, setIsFindingLocation] = useState(false)

    const success = (position: GeolocationPosition) => {
        const latitude = position.coords.latitude
        const longitude = position.coords.longitude

        setLatLng(`${latitude},${longitude}`)
        setIsFindingLocation(false)
    }

    const error = () => {
        setLocationErrorMsg('Unable to retrieve your location')
        setIsFindingLocation(false)
    }

    const handleTrackLocation = useCallback(() => {
        setIsFindingLocation(true)
        if (!navigator.geolocation) {
            setLocationErrorMsg('Geolocation is not supported by your browser')
            setIsFindingLocation(false)
        } else {
            navigator.geolocation.getCurrentPosition(success, error)
            setLocationErrorMsg('')
        }
    }, [])

    return {
        latLng,
        handleTrackLocation,
        locationErrorMsg,
        isFindingLocation,
    }
}
