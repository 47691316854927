import {
    CareAnamneses,
    ECareAnamnesisState,
    EditPatientAnamnesisHook,
} from '../types'
import { useQuery } from '@tanstack/react-query'
import { getPatientCareAnamneses, PATIENT_ANAMNESES_QUERY } from './services'
import { useCreateAnamnesisMutation } from './hooks'
import { useOpenState } from '../../../hooks'

export const useEditPatientAnamnesis = ({
    patientId,
}: {
    patientId: number
}): EditPatientAnamnesisHook => {
    const { isOpen, handleClose, handleOpen } = useOpenState()
    const { data, isLoading, error } = useQuery<CareAnamneses[], Error>(
        [PATIENT_ANAMNESES_QUERY, patientId],
        () => getPatientCareAnamneses(patientId),
        {
            enabled: !!patientId,
        },
    )

    const { mutateCreateAnamnesis } = useCreateAnamnesisMutation()

    const handleCreateConfirmation = async () => {
        await mutateCreateAnamnesis({
            patientId,
        })
    }

    const handleCreateAnamnesis = async () => {
        try {
            const hasActiveAnamnesis = data?.some((anamnesis) => {
                return anamnesis.state !== ECareAnamnesisState.Closed
            })

            if (hasActiveAnamnesis) {
                handleOpen()
            } else {
                handleCreateConfirmation()
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        isLoading,
        handleCreateAnamnesis,
        handleCreateConfirmation,
        isOpenConfirmationDialog: isOpen,
        handleCloseConfirmationDialog: handleClose,
        CareAnamnesesList: data ?? [],
        error: error ? !!error : false,
    }
}
