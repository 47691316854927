import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
    EDIT_PATIENTS_ENUM,
    PatientFilterQueries,
    PatientsCard,
    PatientsContentHook,
    PatientsPageContentProps,
} from '../types'
import { usePatientsListQuery } from './usePatientsQuery'
import { PatientsFiltersContext } from '../../../providers/PatientFiltersProvider'
import { useCustomSnackbar, useLocation } from '../../../hooks'
import { AuthContext } from '../../../providers'
import { useSearchParams } from 'react-router-dom'

export const FILTERS_PARAMS = 'filters'
export const getFiltersParams = (paramsArg: URLSearchParams) => {
    return paramsArg.getAll(FILTERS_PARAMS) || []
}

export const usePatientsContent = (
    props: PatientsContentHook,
): PatientsPageContentProps => {
    const { patientsQueryFilters, setPatientsQueryFilters } = useContext(
        PatientsFiltersContext,
    )

    const snackbar = useCustomSnackbar()

    const { user } = useContext(AuthContext)
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()

    const [selectedPatientCards, setSelectedPatientCards] = useState<number[]>(
        [],
    )

    const params = useMemo(
        () => new URLSearchParams(searchParams),
        [searchParams],
    )

    const pageParam = params.get('page') ? Number(params.get('page')) : 1

    useEffect(() => {
        const pageFilters: PatientFilterQueries = {}

        params.forEach((value, key) => {
            if (key in pageFilters) {
                pageFilters[key] = [...pageFilters[key], value]
            } else {
                pageFilters[key] = [value]
            }
        })

        setPatientsQueryFilters(pageFilters)
    }, [params, setPatientsQueryFilters])

    const [currentPage, setCurrentPage] = useState<number>(pageParam || 1)

    const patientsQuery = usePatientsListQuery({
        skip: (currentPage - 1) * 10,
        take: 10,
        queries: patientsQueryFilters,
    })

    useEffect(() => {
        if (location.latLng === '') {
            location.handleTrackLocation()
        }
    }, [location])

    const handleSetNewPage = useCallback(
        (newPage: number) => {
            const newParams: PatientFilterQueries = {}

            params.forEach((value, key) => {
                if (key in newParams) {
                    newParams[key] = [...newParams[key], value]
                } else {
                    newParams[key] = [value]
                }
            })
            newParams['page'] = newPage.toString()

            setSearchParams(newParams)
        },
        [params, setSearchParams],
    )

    useEffect(() => {
        const newPage = params.get('page') ? Number(params.get('page')) : 1
        if (newPage !== currentPage) {
            setCurrentPage(newPage)
        }
    }, [searchParams, params, currentPage])

    const handlePatientCardSelect = useCallback((id: number) => {
        setSelectedPatientCards((prevSelected) =>
            prevSelected.includes(id)
                ? prevSelected.filter((cardId) => cardId !== id)
                : [...prevSelected, id],
        )
    }, [])

    const handleSelectAll = useCallback(() => {
        const selectedPatientsIds = patientsQuery?.patientsCardList?.data?.map(
            (patientCard: PatientsCard) => patientCard.id,
        )

        if (!selectedPatientsIds || !patientsQuery?.patientsCardList) return

        if (
            selectedPatientCards.length ===
            patientsQuery?.patientsCardList?.data?.length
        ) {
            setSelectedPatientCards([])
        } else {
            setSelectedPatientCards(selectedPatientsIds)
        }
    }, [patientsQuery.patientsCardList, selectedPatientCards])

    const handleRenameSelectedPatients = useCallback(
        async (selectedPatientsCard: PatientsCard[]) => {
            if (!(user?.isAdmin || user?.isHeadNurse)) {
                snackbar(
                    'Nelze přejmenovat pacienty bez oprávnění vrchní sestry nebo administrátora.',
                    'error',
                )
                return
            }
        },
        [user?.isAdmin, user?.isHeadNurse, snackbar],
    )

    const handleMultiplePatientsAction = useCallback(
        (action: EDIT_PATIENTS_ENUM) => {
            const selectedPatientsCard =
                patientsQuery?.patientsCardList?.data?.filter((patientCard) =>
                    selectedPatientCards.some((id) => id === patientCard.id),
                )

            if (!selectedPatientsCard || selectedPatientsCard.length === 0) {
                snackbar('Je nutné vybrat alespoň jednoho pacienta.', 'error')
                return
            }

            switch (action) {
                case EDIT_PATIENTS_ENUM.RenamePatients:
                    handleRenameSelectedPatients(selectedPatientsCard)
                    break
            }
        },
        [
            patientsQuery?.patientsCardList,
            selectedPatientCards,
            snackbar,
            handleRenameSelectedPatients,
        ],
    )

    return {
        patientsQuery,
        patientsQueryFilters,
        selectedPatientCards,
        location,
        handlePatientCardSelect,
        handleSelectAll,
        handleMultiplePatientsAction,
        handleSetNewPage,
        currentPage,
    }
}
