import { useContext } from 'react'
import { PatientFilterQueries, PatientsQueryResponse } from '../types'
import { AuthContext } from '../../../providers'
import { useQuery } from '@tanstack/react-query'
import { getPatientsCard } from '../services'

export const PATIENT_CARD_LIST_QUERY = 'PATIENT_CARD_LIST_QUERY'

export const usePatientsListQuery = ({
    skip,
    take,
    queries,
}: {
    skip: number
    take: number
    queries?: PatientFilterQueries
}): PatientsQueryResponse => {
    const { token } = useContext(AuthContext)

    delete queries?.page

    const { data, isLoading, isError } = useQuery(
        [PATIENT_CARD_LIST_QUERY, skip, take, queries || {}],
        async () => await getPatientsCard({ skip, take, token, queries }),
    )

    return { patientsCardList: data, isLoading, isError }
}
