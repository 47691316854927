import { VisitFormData } from '../types'

export const defaultValues: VisitFormData = {
    bloodPressureHigh: null,
    bloodPressureLow: null,
    fixVisitTime: null,
    pulse: null,
    breathingFreq: null,
    saturation: null,
    temperature: null,
    glycemia: null,
    pain: null,
    subjective: null,
    objective: null,
    reasonNonDone: null,
    carePlans: [],
    time: null,
    requiredFields: false,
}
