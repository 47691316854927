import { Backdrop, SpeedDial, SpeedDialAction } from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import { usePatientsDial } from '../hooks/usePatientsDial'
import { PatientsDialProps } from '../types'
import { useOpenState } from '../../../hooks'
import PatientsActionWrapper from './PatientsActionWrapper'

export const PatientsDial = ({
    setIsMultiEditMode,
    isPatientsEditModeEnabled,
}: PatientsDialProps) => {
    const { isOpen, handleClose, handleOpen } = useOpenState()
    const { selectedAction, handleActionSelect, closeAction } =
        usePatientsDial()

    return (
        <div>
            <Backdrop
                open={isOpen}
                className='z-50'
            />
            <SpeedDial
                ariaLabel='Zobrazení akcí'
                className='fixed right-4'
                sx={{
                    bottom: isPatientsEditModeEnabled ? '4rem' : '1rem',
                }}
                icon={
                    isOpen ? (
                        <CloseIcon />
                    ) : (
                        <AddIcon className='text-white-reduced' />
                    )
                }
                onClose={handleClose}
                onOpen={handleOpen}
                open={isOpen}
                FabProps={{
                    size: 'small',
                }}
            >
                <SpeedDialAction
                    icon={<AddIcon />}
                    tooltipTitle='Přidat pacienta'
                    tooltipPlacement='left'
                    tooltipOpen
                    sx={{
                        '& .MuiSpeedDialAction-staticTooltipLabel': {
                            width: 'max-content',
                        },
                    }}
                    onClick={handleActionSelect('ADD_PATIENT')}
                />

                {/*
                
                     <SpeedDialAction
                    icon={<EditIcon />}
                    tooltipTitle='Hromadné změny'
                    tooltipPlacement='left'
                    tooltipOpen
                    sx={{
                        '& .MuiSpeedDialAction-staticTooltipLabel': {
                            width: 'max-content',
                        },
                    }}
                    onClick={() => setIsMultiEditMode(true)}
                />
                */}
            </SpeedDial>
            <PatientsActionWrapper
                selectedAction={selectedAction}
                disableRestoreFocus
                open={!!selectedAction}
                handleClose={closeAction}
                onClick={(e: React.MouseEvent) => {
                    e.stopPropagation()
                    closeAction()
                }}
            />
        </div>
    )
}
