import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    AddPatientsAnamnesisFormStep12Hook,
    CareAnamnesisStep12,
    CareAnamnesisStep12Keys,
    CareAnamnesisStepQuery,
    IAddPatientAnamnesisFormStep12,
} from '../types'
import { useQuery } from '@tanstack/react-query'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../providers'
import {
    getPatientCareAnamnesis,
    PATIENT_CARE_ANAMNESIS_QUERY,
} from './services'
import { useCustomSnackbar } from '../../../hooks'
import { useSavePatientCareAnamnesisMutation } from './hooks'

export const useAddPatientAnamnesisFormStep12 = (
    props: AddPatientsAnamnesisFormStep12Hook,
): IAddPatientAnamnesisFormStep12 => {
    const {
        handleClose,
        anamnesisId,
        currentStep,
        canEditCareAnamnesis,
        setCurrentStep,
    } = props
    const { token } = useContext(AuthContext)
    const snackbar = useCustomSnackbar()
    const [isFormLoading, setIsFormLoading] = useState(true)

    const [invalidDateInputs, setInvalidDateInputs] = useState<
        CareAnamnesisStep12Keys[]
    >([])

    const { mutatePatientCareAnamnesisStepPut } =
        useSavePatientCareAnamnesisMutation()

    const defaultValues: CareAnamnesisStep12 = {
        IsPain: false,
        PainType: undefined,
        IsIntensityOfPainDisturbsSleep: false,
        IsIntensityOfPainPreventsRest: false,
        IsIntensityOfPainOnlyWhenMoving: false,
        IsIntensityOfPainNotRestrict: false,
        IntensityOfPainValue: '',
        IsCharacteristicsOfPainDull: false,
        IsCharacteristicsOfPainPrickly: false,
        IsCharacteristicsOfPainCrampy: false,
        IsCharacteristicsOfPainFiring: false,
        IsCharacteristicsOfPainClutching: false,
        IsCharacteristicsOfPainColicky: false,
        IsCharacteristicsOfPainNeuralgic: false,
        IsCharacteristicsOfPainIndefinite: false,
        PainLocalization: '',
        IsPainIntensityTypeSteady: false,
        IsPainIntensityTypeUnsteady: false,
        IsPainIntensityTypeBearable: false,
        IsPainIntensityTypeUnbearable: false,
        IsPainReliefAnalgesics: false,
        IsPainReliefReliefPosition: false,
        IsPainReliefReliefRelaxationTechniques: false,
        IsPainReliefReliefPressureMassage: false,
        IsPainReliefReliefWarmCompress: false,
        IsPainReliefReliefColdCompress: false,
        IsPainReliefReliefOther: false,
        Analgesics: '',
        PainReliefOther: '',
    }

    const validationSchema = yup.object().shape({})

    const formMethods = useForm<CareAnamnesisStep12>({
        criteriaMode: 'all',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues,
    })

    const { data, isLoading, error } = useQuery<CareAnamnesisStepQuery, Error>(
        [PATIENT_CARE_ANAMNESIS_QUERY, anamnesisId, currentStep],
        () => getPatientCareAnamnesis(anamnesisId, currentStep, token),
        {
            enabled: !!anamnesisId && !!currentStep && !!token,
        },
    )
    useEffect(() => {
        if (data && !isLoading) {
            if (data?.content) {
                const deserializedData = JSON.parse(data.content)
                formMethods.reset(deserializedData)
            }

            setIsFormLoading(false)
        }
    }, [data, isLoading, formMethods])

    const submitForm = async (
        formData: CareAnamnesisStep12,
        nextStep: boolean,
    ) => {
        try {
            if (canEditCareAnamnesis) {
                await mutatePatientCareAnamnesisStepPut({
                    anamnesisID: anamnesisId,
                    data: {
                        currentStep: currentStep,
                        content: JSON.stringify(formData),
                    },
                })
            }
            if (nextStep) {
                setCurrentStep((prev) => prev + 1)
                snackbar('Ošetřovatelská anamnéza uložena.', 'success')
                handleClose()
            } else {
                setCurrentStep((prev) => prev - 1)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        formMethods,
        invalidDateInputs,
        isLoading: isFormLoading || isLoading,
        error: error ? !!error : false,
        submitForm,
        ...props,
    }
}
