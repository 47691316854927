import { useNavigate } from 'react-router-dom'
import { Box, IconButton, SpeedDialAction, Typography } from '@mui/material'
import moment from 'moment'
import type { FC } from 'react'
import type { HomePageProps } from './types'
import {
    OfficeDashboard,
    UnreadNotifications,
    VisitCarousel,
    VisitOrderButton,
} from './components'
import { HelpDial, Navigation } from '../../components'

import { homeActions } from '../../components/HelpDial/HelpDialActions'
import { HelpSpeedDialActions } from '../../components/HelpDial/types'
import { useUIDSeed } from 'react-uid'
import HomeIcon from '@mui/icons-material/Home'

export const HomeView: FC<HomePageProps> = (props) => {
    const {
        visitLoading,
        dashboard,
        location,
        carouselState,
        unreadNotifications,
    } = props

    const uid = useUIDSeed()
    const navigate = useNavigate()
    return (
        <Box
            component='main'
            className='relative min-h-[calc(100vh-64px)] bg-orange-50 py-2'
        >
            <Navigation>
                <Box className='flex items-center'>
                    <IconButton
                        onClick={() => navigate('/home')}
                        className='mr-1'
                    >
                        <HomeIcon style={{ color: '#faa26b' }} />
                    </IconButton>
                    <Typography
                        variant='h1'
                        className='text-xl font-medium sm:text-2xl'
                    >
                        Denní přehled
                    </Typography>
                </Box>
            </Navigation>
            <Box className='container mx-auto lg:max-w-container'>
                <Box
                    component='section'
                    className='bg-orange-50  pt-2'
                >
                    <Box className='py-3'>
                        <Typography className='font-medium'>
                            {moment().format('DD.MM.')}
                        </Typography>
                    </Box>
                    <Box
                        component='div'
                        className='pb-px '
                    >
                        {!carouselState.slides.length && !visitLoading ? (
                            <Box className='flex h-48 items-center justify-center'>
                                <Typography className='text-sm'>
                                    Zdá se, že dnes nemáte žádné návštěvy.
                                </Typography>
                            </Box>
                        ) : (
                            <VisitCarousel
                                carouselState={carouselState}
                                isLoading={visitLoading}
                                location={location}
                                visitList={carouselState?.slides}
                            />
                        )}
                        <VisitOrderButton isLoading={visitLoading} />
                    </Box>
                </Box>
                <UnreadNotifications
                    unreadNotifications={unreadNotifications}
                />
                <OfficeDashboard {...dashboard} />

                <HelpDial>
                    {({ handleActionClick }) => {
                        return homeActions.map(
                            ({
                                name,
                                icon,
                                modalName,
                            }: HelpSpeedDialActions) => (
                                <SpeedDialAction
                                    key={uid(modalName)}
                                    icon={icon}
                                    tooltipTitle={name}
                                    tooltipPlacement='right'
                                    tooltipOpen
                                    sx={{
                                        '& .MuiSpeedDialAction-staticTooltipLabel':
                                            {
                                                width: 'max-content',
                                            },
                                    }}
                                    onClick={handleActionClick(modalName)}
                                />
                            ),
                        )
                    }}
                </HelpDial>
            </Box>
        </Box>
    )
}
