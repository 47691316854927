import { Stack, Chip, Typography } from '@mui/material'
import { MedicationCzech, type MedicationData } from '../../types'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { MedicationModal } from './MedicationModal'
import { useOpenState } from '../../../../hooks'

export const MedicationCard = (props: MedicationData) => {
    const { type, dateEndFor, drugTitle, note, dosage, drugNotice } = props

    const openState = useOpenState()

    return (
        <Stack
            gap='0.75rem'
            className='rounded bg-white-reduced py-[0.5rem] px-[0.75rem]'
            onClick={() => openState.handleOpen()}
        >
            <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
            >
                <Chip
                    className='rounded-[0.375rem] text-sm text-white-reduced'
                    label={MedicationCzech[type].toLocaleLowerCase()}
                    color='primary'
                    size='small'
                />
                {dateEndFor && (
                    <Stack
                        className='text-sm text-gray-500'
                        direction='row'
                        alignItems='center'
                        gap='0.38rem'
                    >
                        <AccessTimeIcon />
                        {dateEndFor}
                    </Stack>
                )}
            </Stack>
            <Typography className='text-[1rem] font-medium'>
                {drugTitle}
            </Typography>
            <Typography
                className='max-w-full overflow-hidden text-sm font-light'
                sx={{
                    display: '-webkit-box',
                    '-webkit-line-clamp': '3',
                    '-webkit-box-orient': 'vertical',
                }}
            >
                {note}
            </Typography>
            <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                gap={1}
            >
                <Typography className='inline-block max-h-[1.2rem] w-full truncate text-sm font-normal'>
                    {dosage}
                </Typography>
                <Typography className='inline-block max-h-[1.2rem] w-full truncate text-right text-sm font-normal'>
                    {drugNotice}
                </Typography>
            </Stack>
            <MedicationModal
                {...openState}
                {...props}
            />
        </Stack>
    )
}
