import { RefObject, useEffect, useState } from 'react'
import { useUpdateNoteMutation } from '../../../api'
import { useIntersectionObserver } from '../../../hooks'

interface UseNoteVisibilityProps {
    noteId: number
    isReaded: boolean
    noteRef: RefObject<HTMLDivElement>
}

export const useNoteVisibility = (props: UseNoteVisibilityProps) => {
    const { noteId, isReaded, noteRef } = props
    const [isOnceIntersected, setIsOnceIntersectected] = useState(false)

    const updateNoteMutation = useUpdateNoteMutation()
    const isIntersecting = useIntersectionObserver({
        ref: noteRef,
        options: {
            rootMargin: '20px',
        },
    })

    useEffect(() => {
        const updateNote = async () => {
            if (!isOnceIntersected && isIntersecting && !isReaded) {
                try {
                    const data = {
                        noteId,
                        readedByLoginUser: true,
                    }
                    await updateNoteMutation.mutateAsync(data)
                    setIsOnceIntersectected(true)
                } catch (error) {
                    console.error('Failed to update note:', error)
                }
            }
        }

        updateNote()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isIntersecting, isOnceIntersected, noteId, isReaded])
}
