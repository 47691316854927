import { Box } from '@mui/material'
import { TabPanelProps } from '../types'

export const TabPanel = (props: TabPanelProps) => {
    const { children, currentTab, tabName, ...elementProps } = props

    return (
        <Box
            role='tabpanel'
            hidden={currentTab !== tabName}
            id={`vertical-tabpanel-${tabName}`}
            aria-labelledby={`vertical-tab-${tabName}`}
            {...elementProps}
        >
            {currentTab === tabName && <Box>{children}</Box>}
        </Box>
    )
}
