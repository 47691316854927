import {
    Stack,
    Avatar,
    Typography,
    Button,
    Box,
    Chip,
    IconButton,
} from '@mui/material'
import {
    BulletinDetailHook,
    urlLink,
    UseBulletinDetail,
} from '../../types/components'
import { FC, useContext, useMemo } from 'react'
import { calculateTime } from '../../helpers/date'
import { wrap } from '../../utils'
import { useBulletinDetailModal } from './hooks'
import { BulletinReadability } from './BulletinReadability'
import { BulletinFormModal } from './BulletinFormModal'
import { BulletinTagItem } from './BulletinTagItem'
import { BulletinFiltersContext } from '../../providers'
import { useQueryClient } from '@tanstack/react-query'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { useUIDSeed } from 'react-uid'
import { SlideModal } from '../SlideModal'
import CloseIcon from '@mui/icons-material/Close'
import BulletinAttachment from './BulletinAttachment'
import { getAbsoluteUrl } from './hooks/hooks'

export const BulletinDetailModalView: FC<UseBulletinDetail> = (props) => {
    const {
        user,
        handleClose,
        open,
        dateCreated,
        tag,
        badgeColor,
        title,
        message,
        handleRead,
        bulletinMessageQuery,
        bulletinFormModalState,
        isAdmin,
        hideReadButtonDetail = false,
    } = props

    const {
        bulletin: bulletinData,
        isLoading: bulletinDataLoading,
        isError: bulletinDataHasError,
    } = bulletinMessageQuery

    const {
        isOpen: isFormModalOpen,
        handleOpen: handleFormModalOpen,
        handleClose: handleFormModalClose,
    } = bulletinFormModalState

    const creationTime = useMemo(() => {
        return calculateTime(dateCreated)
    }, [dateCreated])

    const readedButtonText = useMemo(
        () => (bulletinData?.isReaded ? 'nepřečteno' : 'přečteno'),
        [bulletinData?.isReaded],
    )

    const readButtonShowConditions = useMemo(() => {
        return !bulletinData?.isOwner && !hideReadButtonDetail
    }, [bulletinData?.isOwner, hideReadButtonDetail])

    const { bulletinQueryFilters } = useContext(BulletinFiltersContext)
    const queryClient = useQueryClient()
    const uid = useUIDSeed()

    return (
        <SlideModal
            handleClose={() => {
                queryClient.invalidateQueries(
                    ['notifications', bulletinQueryFilters || {}],
                    { exact: true },
                )
                handleClose()
            }}
            open={open}
        >
            <Box>
                <Box className='flex items-center  bg-[#feefe6] px-5 py-4'>
                    <Typography
                        className='w-full text-center font-bold'
                        color={'primary'}
                    >
                        Detail zprávy
                    </Typography>
                    <IconButton
                        className=' bg-orange-400 p-1 text-white-reduced'
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Stack
                    spacing={2}
                    style={{
                        maxHeight: 'calc(90vh - 120px)',
                    }}
                    className='min-h-[500px] overflow-y-auto px-5 py-2 md:min-h-max'
                >
                    <Stack
                        direction='row'
                        justifyContent='space-between'
                    >
                        <Stack
                            direction='row'
                            spacing={1}
                        >
                            <Avatar
                                className='bg-orange-300 text-base'
                                src={user?.icon || ''}
                                alt={user?.fullName || ''}
                            >
                                {!user?.icon?.trim() &&
                                    user?.fullName &&
                                    (user?.fullName.split(' ').length === 2
                                        ? `${
                                              user?.fullName.split(' ')[0]?.[0]
                                          }${user?.fullName.split(' ')[1]?.[0]}`
                                        : user?.fullName[0])}
                            </Avatar>
                            <Stack>
                                <Typography className='text-sm font-semibold leading-[auto]'>
                                    {user?.fullName}
                                </Typography>
                                <Typography className='text-xs font-light text-gray-500'>
                                    <span className='pr-1'>{user?.role}</span>·
                                    <span className='pl-1'>{creationTime}</span>
                                </Typography>
                            </Stack>
                        </Stack>
                        <BulletinTagItem
                            tag={tag}
                            badgeColor={badgeColor}
                            isAdmin={isAdmin}
                        />
                    </Stack>
                    <Stack>
                        {bulletinDataLoading ? (
                            'Načítání...'
                        ) : bulletinDataHasError ? (
                            'Error'
                        ) : isAdmin ? null : (
                            <BulletinReadability
                                readerUsers={bulletinData?.readerUsers}
                                countOfUsersToRead={
                                    bulletinData?.countOfUsersToRead
                                }
                            />
                        )}
                    </Stack>
                    <Stack className='gap-[0.625rem]'>
                        <Typography
                            component='h3'
                            className='text-sm font-semibold'
                        >
                            {title}
                        </Typography>
                        <Typography
                            className='overflow-x-auto whitespace-pre-line text-sm'
                            component='p'
                        >
                            {message}
                        </Typography>
                    </Stack>
                    {bulletinData?.urlLinks &&
                        bulletinData.urlLinks.length > 0 && (
                            <Stack>
                                <Typography
                                    component='h3'
                                    className='mb-[0.625rem] text-sm font-semibold'
                                >
                                    Odkazy
                                </Typography>
                                <Stack className='flex flex-col gap-2'>
                                    {bulletinData.urlLinks.map(
                                        (link: urlLink, index: number) => (
                                            <Stack
                                                key={uid(
                                                    `${link.urlAddress}-${index}`,
                                                )}
                                                className='flex flex-col gap-1'
                                            >
                                                <Box className='flex'>
                                                    <a
                                                        className='flex items-center text-sm text-[#757575]'
                                                        href={getAbsoluteUrl(
                                                            link.urlAddress,
                                                        )}
                                                        target='_blank'
                                                        rel='noreferrer'
                                                    >
                                                        <OpenInNewIcon className='mr-[5px] text-[1.25rem]' />
                                                        {link?.name
                                                            ? link.name
                                                            : link.urlAddress}
                                                    </a>
                                                </Box>
                                            </Stack>
                                        ),
                                    )}
                                </Stack>
                            </Stack>
                        )}

                    {bulletinData?.selectedPatients &&
                        bulletinData?.selectedPatients.length > 0 && (
                            <Stack>
                                <Typography
                                    component='h3'
                                    className='mb-[0.625rem] text-sm font-semibold'
                                >
                                    Vztahuje se k pacientům
                                </Typography>
                                <Stack className='flex flex-col gap-[0.625rem]'>
                                    {(bulletinData?.selectedPatients.filter(
                                        (patient) => patient.id !== 0,
                                    )).length > 0 && (
                                        <Stack>
                                            <Stack className='mb-[0.625rem] flex flex-row items-center justify-between text-sm'>
                                                <Typography className='text-[0.8125rem]'>
                                                    v kartotéce
                                                </Typography>
                                                <div className='h-[1px] w-[20%] bg-[#D9D9D9]'></div>
                                            </Stack>
                                            <Stack className='flex flex-row flex-wrap gap-1'>
                                                {bulletinData?.selectedPatients
                                                    .filter(
                                                        (patient) =>
                                                            patient.id !== 0,
                                                    )
                                                    .map((patient) => (
                                                        <Chip
                                                            key={uid(
                                                                `${patient.fullName}${patient.id}`,
                                                            )}
                                                            size='small'
                                                            label={
                                                                patient.fullName
                                                            }
                                                            color='primary'
                                                            className={
                                                                'text-white h-8 bg-[#33333314] text-[#333333de] '
                                                            }
                                                            avatar={
                                                                patient.fullName?.split(
                                                                    ' ',
                                                                ).length > 1 ? (
                                                                    <Avatar
                                                                        style={{
                                                                            width: '24px',
                                                                            height: '24px',
                                                                            backgroundColor:
                                                                                '#BDBDBD',
                                                                        }}
                                                                        className='text-base'
                                                                        src={''}
                                                                        alt={
                                                                            patient.fullName ||
                                                                            ''
                                                                        }
                                                                    >
                                                                        {
                                                                            patient.fullName?.split(
                                                                                ' ',
                                                                            )[0][0]
                                                                        }
                                                                        {
                                                                            patient.fullName?.split(
                                                                                ' ',
                                                                            )[1][0]
                                                                        }
                                                                    </Avatar>
                                                                ) : undefined
                                                            }
                                                        />
                                                    ))}
                                            </Stack>
                                        </Stack>
                                    )}
                                    {(bulletinData?.selectedPatients.filter(
                                        (patient) => patient.id === 0,
                                    )).length > 0 && (
                                        <Stack>
                                            <Stack className='items-space-between mb-[0.625rem] flex flex-row justify-between'>
                                                <Typography className='text-[0.8125rem]'>
                                                    mimo kartotéku
                                                </Typography>
                                                <div className='h-[1px] w-[20%] bg-[#D9D9D9]'></div>
                                            </Stack>
                                            <Stack className='flex flex-row flex-wrap gap-1'>
                                                {bulletinData?.selectedPatients
                                                    .filter(
                                                        (patient) =>
                                                            patient.id === 0,
                                                    )
                                                    .map((patient, index) => (
                                                        <Chip
                                                            key={uid(
                                                                `${patient.fullName}${index}`,
                                                            )}
                                                            label={
                                                                patient.fullName
                                                            }
                                                            color='primary'
                                                            className={
                                                                'text-white bg-[#33333314] text-[#333333de]'
                                                            }
                                                        />
                                                    ))}
                                            </Stack>
                                        </Stack>
                                    )}
                                </Stack>
                            </Stack>
                        )}

                    {bulletinData?.attachments &&
                        bulletinData?.attachments.length > 0 && (
                            <Stack>
                                <Typography
                                    component='h3'
                                    className='mb-[10px] text-sm font-semibold'
                                >
                                    Přílohy
                                </Typography>
                                <Stack className='flex flex-row gap-8'>
                                    {bulletinData?.attachments.map(
                                        (attachment) => {
                                            return (
                                                <BulletinAttachment
                                                    key={uid(attachment.id)}
                                                    {...attachment}
                                                />
                                            )
                                        },
                                    )}
                                </Stack>
                            </Stack>
                        )}

                    <Stack></Stack>
                </Stack>
                <Stack className='flex-row justify-end px-5 py-4'>
                    {!bulletinDataLoading && bulletinData?.isOwner && (
                        <>
                            {!!bulletinData?.id && isFormModalOpen && (
                                <BulletinFormModal
                                    title='Úprava zprávy'
                                    messageData={bulletinData}
                                    open={isFormModalOpen}
                                    isEdit
                                    isFnol={bulletinData.tag === 'Fnol'}
                                    handleClose={handleFormModalClose}
                                />
                            )}
                            <Button
                                variant='outlined'
                                size='small'
                                className='bg-white-reduced normal-case shadow-none'
                                onClick={() => handleFormModalOpen()}
                            >
                                Upravit
                            </Button>
                        </>
                    )}
                    {readButtonShowConditions && (
                        <Button
                            variant='contained'
                            size='small'
                            className='normal-case shadow-none'
                            onClick={() => {
                                if (bulletinData) {
                                    handleRead(bulletinData.isReaded)
                                }
                            }}
                        >
                            Označit jako {readedButtonText}
                        </Button>
                    )}
                </Stack>
            </Box>
        </SlideModal>
    )
}

export const BulletinDetailModal: FC<BulletinDetailHook> = wrap(
    BulletinDetailModalView,
    useBulletinDetailModal,
)
