import { Female, Male } from '@mui/icons-material'

import { Avatar, Skeleton } from '@mui/material'

import type { FC } from 'react'
import { wrap } from '../../../../utils'
import { useAside } from '../../hooks'
import type { AsideHookProps, AsideProps } from '../../types'

const AsideView: FC<AsideProps> = (props) => {
    const { patientLoading, patientSex } = props

    return (
        <>
            <Avatar className='mx-auto mb-4 h-14 w-14 bg-orange-300'>
                {patientLoading ? (
                    <Skeleton
                        animation='wave'
                        variant='circular'
                        width={40}
                        height={40}
                    />
                ) : patientSex === 'Male' ? (
                    <Male
                        className='text-white-reduced'
                        fontSize='large'
                    />
                ) : (
                    <Female
                        className='text-white-reduced'
                        fontSize='large'
                    />
                )}
            </Avatar>
        </>
    )
}

const Aside: FC<AsideHookProps> = wrap(AsideView, useAside)

export default Aside
