import { useBulletinUpdateMutation } from '../../../api'
import {
    BulletinDetailModalProps,
    UseBulletinDetail,
} from '../../../types/components'
import { useBulletinMessageQuery } from '../../../api/notifications/hooks'
import { useOpenState } from '../../../hooks'

export const useBulletinDetailModal = (
    props: BulletinDetailModalProps,
): UseBulletinDetail => {
    const { id, isReaded, stopListInvalidate, ...rest } = props
    const { mutateBulletinUpdate: mutateBulletinRead } =
        useBulletinUpdateMutation(stopListInvalidate)

    const bulletinMessageQuery = useBulletinMessageQuery(id)

    const bulletinFormModalState = useOpenState()

    const handleRead = async (isReadedParam?: boolean) => {
        await mutateBulletinRead({ ...rest, id, isReaded: !isReadedParam })
    }

    return {
        handleRead,
        id,
        isReaded,
        bulletinMessageQuery,
        bulletinFormModalState,
        ...rest,
    }
}
