import { useRef, useState } from 'react'
import { useCustomSnackbar } from '../../../hooks'
import { useHelpCamera } from '../../../hooks/useHelpCamera'

export const useHelpUpload = () => {
    const uploadInputRef = useRef<HTMLInputElement>(null)
    const [selectedFiles, setSelectedFiles] = useState<string[]>([])

    const customSnackbar = useCustomSnackbar()
    const { cameraPermission } = useHelpCamera()

    const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0]
        if (!file) return

        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            setSelectedFiles((prev: string[]) => [
                ...prev,
                reader.result as string,
            ])
        }
    }

    const handleButtonClick = async () => {
        if (cameraPermission === 'denied') {
            customSnackbar(
                'Nebyl udělen přístup k Vaší kameře. V případě, že budete chtít vložit obrázek, vyberte jej z galerie nebo v nastavení Vašeho telefonu udělte prohlížeči náležité oprávnění.',
                'warning',
                true,
            )
        }

        uploadInputRef.current?.click()
    }

    return {
        uploadInputRef,
        selectedFiles,
        handleFileUpload,
        handleButtonClick,
    }
}
