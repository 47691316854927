import { FC, useCallback, useEffect } from 'react'
import { wrap } from '../../../utils'
import { useAddPatientAnamnesisFormStep7 } from '../hooks/useAddPatientAnamnesisFormStep7'
import {
    AddPatientsAnamnesisFormStep7Hook,
    IAddPatientAnamnesisFormStep7,
} from '../types'
import { Stack, InputLabel, CircularProgress, Box } from '@mui/material'
import { AnamnesisFormFooter } from './AnamnesisFormFooter'
import YesNoRadioGroup from '../../../components/YesNoRadioGroup'
import { eHousingInOptions } from '../../../components/options'
import MultipleChoiceRadioGroup from '../../../components/MultipleChoicesRadioGroup'
import CheckboxStyled from '../../../components/CheckboxStyled'
import InputStyled from '../../../components/InputStyled'

const AddPatientAnamnesisFormStep7View = (
    props: IAddPatientAnamnesisFormStep7,
) => {
    const {
        submitForm,
        formMethods,
        setCurrentStep,
        setSaveCurrentStepOnClose,
        currentStep,
        isLoading,
        canEditCareAnamnesis,
    } = props

    const {
        control,
        handleSubmit,
        watch,
        getValues,
        formState: { errors, isSubmitting },
    } = formMethods

    const isHousingWithOther = watch('IsHousingWithOther')
    const isReleaseHospitalProblem = watch('IsReleaseHospitalProblem')
    const isRequestToSocialContactWith = watch('IsRequestToSocialContactWith')
    const isFamilyCooperation = watch('IsFamilyCooperation')
    const handleSubmitWrapper = useCallback(
        async (isNext?: boolean) => {
            const formData = getValues()
            await submitForm(formData, isNext ? isNext : false)
        },
        [getValues, submitForm],
    )
    useEffect(() => {
        setSaveCurrentStepOnClose(() => handleSubmitWrapper)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSaveCurrentStepOnClose])
    if (isLoading) {
        return (
            <Box className='!mt-12 flex justify-center'>
                <CircularProgress />
            </Box>
        )
    }
    return (
        <form
            onSubmit={() => handleSubmitWrapper(true)}
            className='grid gap-3'
        >
            <MultipleChoiceRadioGroup
                control={control}
                label='Bydlení v'
                readOnly={!canEditCareAnamnesis}
                name='HousingIn'
                options={eHousingInOptions}
                isSubmitting={isSubmitting}
            />

            <Stack spacing={1}>
                <InputLabel className='text-sm font-semibold'>
                    Bydlení s
                </InputLabel>
                <CheckboxStyled
                    readOnly={!canEditCareAnamnesis}
                    control={control}
                    disabled={isSubmitting}
                    name='IsHousingWithAlone'
                    label='sám'
                />
                <CheckboxStyled
                    readOnly={!canEditCareAnamnesis}
                    control={control}
                    disabled={isSubmitting}
                    name='IsHousingWithFamily'
                    label='rodinou'
                />
                <CheckboxStyled
                    readOnly={!canEditCareAnamnesis}
                    control={control}
                    disabled={isSubmitting}
                    name='IsHousingWithPartner'
                    label='partnerem'
                />
                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsHousingWithChildren'
                    label='u dětí'
                />
                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsHousingWithOther'
                    label='jiné'
                />
            </Stack>

            {isHousingWithOther && (
                <InputStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='HousingWithOther'
                    errors={errors}
                    label='Jiné:'
                    placeholder='jiné'
                />
            )}

            <YesNoRadioGroup
                control={control}
                readOnly={!canEditCareAnamnesis}
                name='IsReleaseHospitalProblem'
                label='Problémy související s propuštěním z nemocnice'
                disabled={isSubmitting}
            />

            {isReleaseHospitalProblem && (
                <>
                    <InputStyled
                        readOnly={!canEditCareAnamnesis}
                        control={control}
                        disabled={isSubmitting}
                        name='ReleaseHospitalProblem'
                        errors={errors}
                        label='V čem:'
                        placeholder='v čem'
                    />

                    <CheckboxStyled
                        control={control}
                        readOnly={!canEditCareAnamnesis}
                        disabled={isSubmitting}
                        name='IsRequestToSocialRequestSend'
                        label='Podána žádost do sociálního zařízení'
                    />
                    <CheckboxStyled
                        control={control}
                        readOnly={!canEditCareAnamnesis}
                        disabled={isSubmitting}
                        name='IsRequestToSocialContactWith'
                        label='Nutný kontakt se sociální pracovnicí'
                    />
                    {isRequestToSocialContactWith && (
                        <InputStyled
                            control={control}
                            readOnly={!canEditCareAnamnesis}
                            disabled={isSubmitting}
                            name='RequestToSocialName'
                            errors={errors}
                            label='Jméno sociální pracovnice:'
                            placeholder='jméno sociální pracovnice'
                        />
                    )}
                </>
            )}

            <YesNoRadioGroup
                control={control}
                name='IsFamilyCooperation'
                readOnly={!canEditCareAnamnesis}
                label='Spolupráce rodiny'
                disabled={isSubmitting}
            />

            {!isFamilyCooperation && (
                <InputStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='FamilyCooperation'
                    errors={errors}
                    label='Důvod:'
                    placeholder='důvod'
                />
            )}

            <Stack spacing={2}>
                <AnamnesisFormFooter
                    currentStep={currentStep}
                    canEditCareAnamnesis={canEditCareAnamnesis}
                    handleSubmitWrapper={handleSubmitWrapper}
                    disabled={isLoading || isSubmitting}
                    setCurrentStep={setCurrentStep}
                />
            </Stack>
        </form>
    )
}

export const AddPatientAnamnesisFormStep7: FC<AddPatientsAnamnesisFormStep7Hook> =
    wrap(AddPatientAnamnesisFormStep7View, useAddPatientAnamnesisFormStep7)
