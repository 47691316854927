import { useCallback, useState } from 'react'

export const useOpenState = (initialValue?: boolean) => {
    const [isOpen, setIsOpen] = useState(initialValue || false)

    const stateSwitch = () => setIsOpen((prevState) => !prevState)
    const handleOpen = useCallback(() => setIsOpen(true), [])
    const handleClose = useCallback(() => setIsOpen(false), [])

    return { isOpen, stateSwitch, handleOpen, handleClose }
}
