import { Box, Container, TextField } from '@mui/material'
import type { FC } from 'react'
import { Controller } from 'react-hook-form'
import Logo from '../../assets/logo-big.svg'
import LogoTest from '../../assets/logo-big-test.svg'
import type { LoginPageProps } from './types'
import { LoadingButton } from '@mui/lab'

export const LoginView: FC<LoginPageProps> = ({ form }) => {
    const {
        formMethods: {
            control,
            handleSubmit,
            formState: { errors },
        },
        submitLogin,
        smsCodeNeeded,
        isLoading,
    } = form

    const environment = process.env.REACT_APP_ENVIRONMENT

    return (
        <Container className='h-screen max-w-none bg-orange-100'>
            <Box
                component='div'
                className='mx-auto grid max-w-[650px] py-[77px]'
            >
                {environment === 'development' ? (
                    <img
                        className='mx-auto mb-8'
                        src={LogoTest}
                        alt='Logo e-sestřičky testovací'
                    />
                ) : (
                    <img
                        className='mx-auto mb-8'
                        src={Logo}
                        alt='Logo e-sestřičky'
                    />
                )}
                <form
                    onSubmit={handleSubmit(submitLogin)}
                    className='grid gap-3'
                >
                    <Controller
                        control={control}
                        name='username'
                        render={({ field }) => (
                            <TextField
                                {...field}
                                placeholder='email@adresa.cz'
                                variant='outlined'
                                type='email'
                                size='small'
                                className='w-full rounded border-none bg-white-reduced'
                                error={errors.username !== undefined}
                                helperText={errors.username?.message}
                                sx={{
                                    placeholder: { color: '#e0e0e0' },
                                }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='password'
                        render={({ field }) => (
                            <TextField
                                {...field}
                                placeholder='heslo'
                                variant='outlined'
                                type='password'
                                size='small'
                                className={`${
                                    !smsCodeNeeded ? 'mb-3' : ''
                                } w-full rounded border-none bg-white-reduced`}
                                error={errors.password !== undefined}
                                helperText={errors.password?.message}
                                sx={{
                                    placeholder: { color: '#e0e0e0' },
                                }}
                            />
                        )}
                    />
                    {smsCodeNeeded && (
                        <Controller
                            control={control}
                            name='smsCode'
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    placeholder='sms kód'
                                    variant='outlined'
                                    type='text'
                                    size='small'
                                    className='mb-3 w-full rounded border-none bg-white-reduced'
                                    error={errors.smsCode !== undefined}
                                    helperText={errors.smsCode?.message}
                                    sx={{
                                        placeholder: { color: '#e0e0e0' },
                                    }}
                                />
                            )}
                        />
                    )}
                    <LoadingButton
                        variant='contained'
                        fullWidth
                        className='bg-orange-400 py-[0.625rem] text-lg text-white-reduced shadow-none'
                        sx={{ textTransform: 'none' }}
                        type='submit'
                        loading={isLoading}
                        loadingPosition='end'
                    >
                        Přihlásit se
                    </LoadingButton>
                </form>
            </Box>
        </Container>
    )
}
