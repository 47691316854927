import { useCallback, useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../providers'
import { getBulletinPatientsSelect, getBulletinUsersSelect } from '../services'
import { useQuery } from '@tanstack/react-query'
import { debounce } from 'lodash'
import {
    BulletinPatientsSearchProps,
    BulletinUsersProps,
    UseBulletinPatientsSelectProps,
    UseBulletinUsersSelectProps,
} from '../types'
import { useCustomSnackbar } from '../../../hooks'

export const useBulletinUsersSelect = ({
    isOpen,
    preSelectedUsers,
    handleClose,
    setValue,
    triggerValidation,
}: UseBulletinUsersSelectProps) => {
    const { token } = useContext(AuthContext)
    const [selectedUsers, setSelectedUsers] = useState<BulletinUsersProps[]>([])

    useEffect(() => {
        if (isOpen) {
            setSelectedUsers(preSelectedUsers === null ? [] : preSelectedUsers)
        }
    }, [preSelectedUsers, isOpen])

    const [name, setName] = useState<string>('')

    const bulletinUsersSelect = useQuery(
        ['bulletinUsersSelect', name],
        () => getBulletinUsersSelect(name, token),
        {
            enabled: !!name,
        },
    )

    const filterOptions = useCallback(
        (options: BulletinUsersProps[]) => {
            return options.filter(
                (option) =>
                    !selectedUsers.find((user) => user.id === option.id),
            )
        },
        [selectedUsers],
    )

    const handleInputChangeDebounced = debounce((e) => {
        if (!e) return
        setName(e.target.value)
    }, 500)

    const handleUpdateActualUsers = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        event.stopPropagation()
        setValue('selectedUsers', selectedUsers)
        triggerValidation('selectedUsers')
        handleClose()
    }

    return {
        selectedUsers,
        setSelectedUsers,
        filterOptions,
        handleUpdateActualUsers,
        handleInputChangeDebounced,
        users: bulletinUsersSelect.data || [],
    }
}

export const useBulletinPatientSelect = ({
    isOpen,
    preselectedPatients,
    handleClose,
    setValue,
    triggerValidation,
}: UseBulletinPatientsSelectProps) => {
    const { token } = useContext(AuthContext)
    const [selectedPatients, setSelectedPatients] = useState<
        BulletinPatientsSearchProps[]
    >([])

    const [debouncedName, setDebouncedName] = useState('')
    const [debouncedPersonalNumber, setDebouncedPersonalNumber] = useState('')

    useEffect(() => {
        if (isOpen) {
            setSelectedPatients(
                preselectedPatients === null ? [] : preselectedPatients,
            )
        }
    }, [preselectedPatients, isOpen])

    const [name, setName] = useState<string>('')
    const [personalNumber, setPersonalNumber] = useState<string>('')
    const customSnackbar = useCustomSnackbar()

    const bulletinPatientsSelect = useQuery(
        ['bulletinPatientsSelect', debouncedName, debouncedPersonalNumber],
        () =>
            getBulletinPatientsSelect(
                debouncedName,
                debouncedPersonalNumber,
                token,
            ),
        {
            enabled: !!debouncedName || !!debouncedPersonalNumber,
        },
    )

    const filterOptions = useCallback(
        (options: BulletinPatientsSearchProps[]) => {
            return options.filter(
                (option) =>
                    !selectedPatients.find((user) => user.id === option.id),
            )
        },
        [selectedPatients],
    )

    const handleUpdateActualPatients = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        event.stopPropagation()
        setValue('selectedPatients', selectedPatients)
        triggerValidation('selectedPatients')
        handleClose()
    }

    const handleChangePersonalNumber = (e: any) => {
        let value = e.target.value.replace(/\D/g, '') // Remove non-digit characters
        if (value.length > 6) {
            if (value.length > 10) {
                value = value.slice(0, 10) // Max length 10 digits
            }
            value = value.slice(0, 6) + '/' + value.slice(6)
        }
        setPersonalNumber(value)
    }

    const handleAddNewPatient = () => {
        if (name && personalNumber) {
            const patient = {
                id: 0,
                fullName: name,
                personalNumber: personalNumber,
            }
            setSelectedPatients((prev) => [...prev, patient])
            setName('')
            setPersonalNumber('')
        } else {
            customSnackbar(
                'Je nutné vyplnit jméno pacienta a rodné číslo.',
                'warning',
            )
        }
    }

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedName(name)
        }, 500)
        return () => clearTimeout(timeoutId)
    }, [name])

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedPersonalNumber(personalNumber)
        }, 500)
        return () => clearTimeout(timeoutId)
    }, [personalNumber])

    return {
        selectedPatients,
        setSelectedPatients,
        filterOptions,
        handleUpdateActualPatients,
        personalNumber,
        setPersonalNumber,
        name,
        setName,
        handleChangePersonalNumber,
        handleAddNewPatient,
        isLoadingData: bulletinPatientsSelect.isLoading,
        patients: bulletinPatientsSelect.data || [],
    }
}

export const getAbsoluteUrl = (url: string) => {
    // Přidáme https:// pokud URL nezačíná http:// nebo https://
    if (!/^https?:\/\//i.test(url)) {
        return `https://${url}`
    }
    return url
}
