import { FC, useCallback, useEffect } from 'react'
import { wrap } from '../../../utils'
import { useAddPatientAnamnesisFormStep1 } from '../hooks/useAddPatientAnamnesisFormStep1'
import {
    AddPatientsAnamnesisFormStep1Hook,
    IAddPatientAnamnesisFormStep1,
} from '../types'
import { Box, CircularProgress, Stack } from '@mui/material'
import { AnamnesisFormFooter } from './AnamnesisFormFooter'
import YesNoRadioGroup from '../../../components/YesNoRadioGroup'
import InputStyled from '../../../components/InputStyled'

const AddPatientAnamnesisFormStep1View = (
    props: IAddPatientAnamnesisFormStep1,
) => {
    const {
        submitForm,
        formMethods,
        setCurrentStep,
        currentStep,
        setSaveCurrentStepOnClose,
        canEditCareAnamnesis,
        isLoading,
    } = props

    const {
        control,
        handleSubmit,
        getValues,
        watch,
        formState: { errors, isSubmitting },
    } = formMethods

    const IsHospitalization = watch('IsHospitalization')
    const IsSurgery = watch('IsSurgery')
    const IsInfectiousDiseases = watch('IsInfectiousDiseases')
    const IsBreathing = watch('IsBreathing')
    const IsSmoking = watch('IsSmoking')
    const IsAlergy = watch('IsAlergy')
    const IsOtherServices = watch('IsOtherServices')

    const handleSubmitWrapper = useCallback(
        async (isNext?: boolean) => {
            const formData = getValues()
            await submitForm(formData, isNext ? isNext : false)
        },
        [getValues, submitForm],
    )
    useEffect(() => {
        setSaveCurrentStepOnClose(() => handleSubmitWrapper)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSaveCurrentStepOnClose])

    if (isLoading) {
        return (
            <Box className='!mt-12 flex justify-center'>
                <CircularProgress />
            </Box>
        )
    }

    return (
        <form
            onSubmit={() => handleSubmitWrapper(true)}
            className='grid gap-3'
        >
            <YesNoRadioGroup
                control={control}
                name='IsHospitalization'
                label='Hospitalizace'
                disabled={isSubmitting}
                readOnly={!canEditCareAnamnesis}
            />

            {IsHospitalization && (
                <InputStyled
                    control={control}
                    disabled={isSubmitting}
                    name='Hospitalization'
                    errors={errors}
                    label='Důvod:'
                    readOnly={!canEditCareAnamnesis}
                    placeholder='důvod'
                />
            )}

            <YesNoRadioGroup
                control={control}
                name='IsSurgery'
                label='Operace'
                readOnly={!canEditCareAnamnesis}
                disabled={isSubmitting}
            />

            {IsSurgery && (
                <InputStyled
                    control={control}
                    disabled={isSubmitting}
                    name='Surgery'
                    errors={errors}
                    label='Jaké:'
                    readOnly={!canEditCareAnamnesis}
                    placeholder='jaké operace'
                />
            )}

            <YesNoRadioGroup
                control={control}
                name='IsInfectiousDiseases'
                label='Infekční choroby'
                readOnly={!canEditCareAnamnesis}
                disabled={isSubmitting}
            />

            {IsInfectiousDiseases && (
                <InputStyled
                    control={control}
                    disabled={isSubmitting}
                    name='InfectiousDiseases'
                    errors={errors}
                    readOnly={!canEditCareAnamnesis}
                    label='Jaké:'
                    placeholder='jaké infekce'
                />
            )}

            <InputStyled
                control={control}
                disabled={isSubmitting}
                name='PhysiologicalFunctions'
                errors={errors}
                readOnly={!canEditCareAnamnesis}
                label='Fyziologické funkce – TK + P'
                placeholder='fyziologické funkce'
            />

            <YesNoRadioGroup
                control={control}
                name='IsBreathing'
                falseLabel='bez potíží'
                readOnly={!canEditCareAnamnesis}
                trueLabel='s obtížemi'
                label='Dýchání'
                disabled={isSubmitting}
            />

            {IsBreathing && (
                <InputStyled
                    control={control}
                    disabled={isSubmitting}
                    name='Breathing'
                    errors={errors}
                    readOnly={!canEditCareAnamnesis}
                    label='Jakými:'
                    placeholder='dušnost – bolest – rýma – kašel'
                />
            )}

            <YesNoRadioGroup
                control={control}
                name='IsSmoking'
                readOnly={!canEditCareAnamnesis}
                label='Kouření'
                disabled={isSubmitting}
            />

            {IsSmoking && (
                <InputStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='Smoking'
                    errors={errors}
                    label='Kolik cigaret denně?'
                    placeholder='počet cigaret'
                />
            )}

            <YesNoRadioGroup
                control={control}
                name='IsAlcohol'
                readOnly={!canEditCareAnamnesis}
                label='Alkohol'
                disabled={isSubmitting}
            />

            <YesNoRadioGroup
                control={control}
                name='IsDrugs'
                readOnly={!canEditCareAnamnesis}
                label='Drogy'
                disabled={isSubmitting}
            />

            <YesNoRadioGroup
                control={control}
                readOnly={!canEditCareAnamnesis}
                name='IsAlergy'
                label='Alergie'
                disabled={isSubmitting}
            />

            {IsAlergy && (
                <InputStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='Alergy'
                    errors={errors}
                    label='Jaké:'
                    placeholder='alergie'
                />
            )}

            <YesNoRadioGroup
                control={control}
                name='IsOtherServices'
                readOnly={!canEditCareAnamnesis}
                label='Ostatní služby'
                disabled={isSubmitting}
            />

            {IsOtherServices && (
                <InputStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='OtherServices'
                    errors={errors}
                    label='Jakých:'
                    placeholder='zdravotní/sociální'
                />
            )}
            <Stack spacing={2}>
                <AnamnesisFormFooter
                    canEditCareAnamnesis={canEditCareAnamnesis}
                    currentStep={currentStep}
                    handleSubmitWrapper={handleSubmitWrapper}
                    disabled={isLoading || isSubmitting}
                    setCurrentStep={setCurrentStep}
                />
            </Stack>
        </form>
    )
}

export const AddPatientAnamnesisFormStep1: FC<AddPatientsAnamnesisFormStep1Hook> =
    wrap(AddPatientAnamnesisFormStep1View, useAddPatientAnamnesisFormStep1)
