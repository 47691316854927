import { useCallback } from 'react'
import { VisitState } from '../../../types'
import {
    OfficeStateCzech,
    OfficeStateHookProps,
    OfficeStateProps,
} from '../types'

export const useOfficeState = ({
    stateIn,
}: OfficeStateHookProps): OfficeStateProps => {
    const getStateText = useCallback(
        (stateIn?: VisitState[]): OfficeStateCzech => {
            if (!stateIn) return ''

            switch (stateIn?.[0]) {
                case 'Draft':
                    return 'rozpracovaných'
                case 'Returned':
                    return 'vrácených'
                default:
                    return ''
            }
        },
        [],
    )

    return {
        officeStateCzech: getStateText(stateIn),
    }
}
