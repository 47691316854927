import { Backdrop, SpeedDial, SpeedDialAction } from '@mui/material'
import { useOpenState } from '../../hooks'
import { useBulletinDial } from '../../pages/BulletinPage/hooks/useBulletinDial'
import BulletinActionWrapper from '../../pages/BulletinPage/components/BulletinActionWrapper'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import ApartmentIcon from '@mui/icons-material/Apartment'
import { useAuth } from '../../providers/AuthProvider/hooks'
import EditIcon from '@mui/icons-material/Edit'
import { BulletinDialProps } from '../../pages/BulletinPage/types'
import { OLOMOUC_FNOL_ID } from '../../constants'

export const BulletinDial = ({
    setIsMultiEditMode,
    isBulletinEditModeEnabled,
}: BulletinDialProps) => {
    const { isOpen, handleClose, handleOpen } = useOpenState()
    const { selectedAction, handleActionSelect, closeAction } =
        useBulletinDial()

    const { user } = useAuth()

    return (
        <div>
            <Backdrop
                open={isOpen}
                className='z-50'
            />
            <SpeedDial
                ariaLabel='Zobrazení akcí'
                className='fixed right-4'
                sx={{
                    bottom: isBulletinEditModeEnabled ? '4rem' : '1rem',
                }}
                icon={
                    isOpen ? (
                        <CloseIcon />
                    ) : (
                        <AddIcon className='text-white-reduced' />
                    )
                }
                onClose={handleClose}
                onOpen={handleOpen}
                open={isOpen}
                FabProps={{
                    size: 'small',
                }}
            >
                <SpeedDialAction
                    icon={<AddIcon />}
                    tooltipTitle='Přidat zprávu'
                    tooltipPlacement='left'
                    tooltipOpen
                    sx={{
                        '& .MuiSpeedDialAction-staticTooltipLabel': {
                            width: 'max-content',
                        },
                    }}
                    onClick={handleActionSelect('NEW_MESSAGE')}
                />
                {user?.organizationID === OLOMOUC_FNOL_ID && (
                    <SpeedDialAction
                        icon={<ApartmentIcon />}
                        tooltipTitle='Přidat FNOL'
                        tooltipPlacement='left'
                        tooltipOpen
                        sx={{
                            '& .MuiSpeedDialAction-staticTooltipLabel': {
                                width: 'max-content',
                            },
                        }}
                        onClick={handleActionSelect('FNOL')}
                    />
                )}
                <SpeedDialAction
                    icon={<EditIcon />}
                    tooltipTitle='Hromadné akce'
                    tooltipPlacement='left'
                    tooltipOpen
                    sx={{
                        '& .MuiSpeedDialAction-staticTooltipLabel': {
                            width: 'max-content',
                        },
                    }}
                    onClick={() => setIsMultiEditMode(true)}
                />
            </SpeedDial>
            <BulletinActionWrapper
                selectedAction={selectedAction}
                disableRestoreFocus
                open={!!selectedAction}
                handleClose={closeAction}
                onClick={(e: React.MouseEvent) => {
                    e.stopPropagation()
                    closeAction()
                }}
            />
        </div>
    )
}
