import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    AddPatientsAnamnesisFormStep6Hook,
    CareAnamnesisStep6,
    CareAnamnesisStep6Keys,
    CareAnamnesisStepQuery,
    IAddPatientAnamnesisFormStep6,
} from '../types'
import { useQuery } from '@tanstack/react-query'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../providers'
import {
    getPatientCareAnamnesis,
    PATIENT_CARE_ANAMNESIS_QUERY,
} from './services'
import { useSavePatientCareAnamnesisMutation } from './hooks'

export const useAddPatientAnamnesisFormStep6 = (
    props: AddPatientsAnamnesisFormStep6Hook,
): IAddPatientAnamnesisFormStep6 => {
    const { setCurrentStep, anamnesisId, currentStep, canEditCareAnamnesis } =
        props
    const { token } = useContext(AuthContext)
    const [isFormLoading, setIsFormLoading] = useState(true)

    const [invalidDateInputs, setInvalidDateInputs] = useState<
        CareAnamnesisStep6Keys[]
    >([])

    const { mutatePatientCareAnamnesisStepPut } =
        useSavePatientCareAnamnesisMutation()

    const defaultValues: CareAnamnesisStep6 = {
        IsEmotionalStateCalm: false,
        IsEmotionalStateScared: false,
        IsEmotionalStateAngry: false,
        IsEmotionalStateStubborn: false,
        IsEmotionalStateSelfPitying: false,
        IsEmotionalStateOther: false,
        EmotionalStateOther: '',
        HygienicCare: undefined,
        Clothing: undefined,
        Nutrition: undefined,
        UsingToilet: undefined,
        KeepingOrder: undefined,
    }

    const validationSchema = yup.object().shape({})

    const formMethods = useForm<CareAnamnesisStep6>({
        criteriaMode: 'all',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues,
    })

    const { data, isLoading, error } = useQuery<CareAnamnesisStepQuery, Error>(
        [PATIENT_CARE_ANAMNESIS_QUERY, anamnesisId, currentStep],
        () => getPatientCareAnamnesis(anamnesisId, currentStep, token),
        {
            enabled: !!anamnesisId && !!currentStep && !!token,
        },
    )

    useEffect(() => {
        if (data && !isLoading) {
            if (data?.content) {
                const deserializedData = JSON.parse(data.content)
                formMethods.reset(deserializedData)
            }

            setIsFormLoading(false)
        }
    }, [data, isLoading, formMethods])

    const submitForm = async (
        formData: CareAnamnesisStep6,
        nextStep: boolean,
    ) => {
        try {
            if (canEditCareAnamnesis) {
                await mutatePatientCareAnamnesisStepPut({
                    anamnesisID: anamnesisId,
                    data: {
                        currentStep: currentStep,
                        content: JSON.stringify(formData),
                    },
                })
            }
            if (nextStep) setCurrentStep((prev) => prev + 1)
            else {
                setCurrentStep((prev) => prev - 1)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        formMethods,
        isLoading: isFormLoading || isLoading,
        error: error ? !!error : false,
        submitForm,
        invalidDateInputs,
        ...props,
    }
}
