import { ChangeEvent, useContext } from 'react'
import { AuthContext } from '../../../providers'
import { useAuth } from '../../../providers/AuthProvider/hooks'
import { useNavigate } from 'react-router-dom'
import { usePhotoMutation } from './usePhotoMutation'
import type { ProfileHookProps, ProfilePageProps } from '../types'
import { useUserPhotoQuery } from '../../../api'
import { useDropdownAnchor } from './useDropdownAnchor'
import { PAGE_LOGIN_REDIRECT } from '../../LoginPage/hooks/useLogin'

export const usePage = (props: ProfileHookProps): ProfilePageProps => {
    const { user } = useContext(AuthContext)
    const { logOut } = useAuth()
    const navigate = useNavigate()
    const profilePhotoHandles = usePhotoMutation()
    const userPhotoQuery = useUserPhotoQuery()

    const menuDropdown = useDropdownAnchor()

    const { handlePhotoUpload } = profilePhotoHandles
    const { handleCloseMenu } = menuDropdown

    const profilePhotoChange = async (e: ChangeEvent<HTMLInputElement>) => {
        try {
            await handlePhotoUpload(e)
            handleCloseMenu()
        } catch (error) {
            console.error(error)
        }
    }

    const handleLogOut = () => {
        sessionStorage.removeItem(PAGE_LOGIN_REDIRECT)
        logOut()
        navigate('/')
    }

    return {
        user,
        handleLogOut,
        profilePhotoHandles,
        userPhotoQuery,
        menuDropdown,
        profilePhotoChange,
    }
}
