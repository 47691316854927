import {
    useCallback,
    useMemo,
    useLayoutEffect,
    Dispatch,
    SetStateAction,
} from 'react'

type FilterProp<T> = {
    type: any
    title?: string
    items: {
        active: boolean
        value: T
        name: string
    }[]
}

type FiltersHookProps<T> = {
    filterNameType: string
    filters: FilterProp<T>[]
    setFilters: Dispatch<SetStateAction<FilterProp<T>[]>>
    singleSelectGroup: string[]
}

export const useFilterReset = <T>({
    filterNameType,
    filters,
    setFilters,
    singleSelectGroup,
}: FiltersHookProps<T>) => {
    const groupWithEveryActive = useMemo(
        () =>
            filters.find(({ type, items }) =>
                items.every(({ active }) => active),
            ),
        [filters],
    )

    const resetFilterAllActiveGroup = useCallback(
        (resetEverything = false) => {
            setFilters((currentData) =>
                currentData.map((group) => {
                    if (
                        resetEverything ||
                        group.type === groupWithEveryActive?.type
                    ) {
                        const isSingleSelectGroup = singleSelectGroup.includes(
                            group.type,
                        )

                        return {
                            ...group,
                            items: group.items.map((_item) => ({
                                ..._item,
                                active:
                                    resetEverything || isSingleSelectGroup
                                        ? false
                                        : _item.active,
                            })),
                        }
                    }
                    return group
                }),
            )
        },
        [groupWithEveryActive?.type, setFilters, singleSelectGroup],
    )

    const resetFilterGroup = useCallback(
        (groupType: string) => {
            setFilters((currentData) =>
                currentData.map((group) => {
                    if (group.type === groupType) {
                        return {
                            ...group,
                            items: group.items.map((_item) => ({
                                ..._item,
                                active: false,
                            })),
                        }
                    }
                    return group
                }),
            )
        },
        [setFilters],
    )

    useLayoutEffect(() => {
        resetFilterAllActiveGroup()
    }, [resetFilterAllActiveGroup])

    return { resetFilterGroup, resetFilterAllActiveGroup }
}
