import { FC } from 'react'
import { wrap } from '../../../../utils'
import { FinalInfoHookProps } from '../../types'
import { FinalInfoView } from './FinalInfoView'
import { useFinalInfo } from '../../hooks/useFinalInfo'

export const FinalInfo: FC<FinalInfoHookProps> = wrap(
    FinalInfoView,
    useFinalInfo,
)
