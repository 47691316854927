import { FC, useCallback, useEffect } from 'react'
import { wrap } from '../../../utils'
import {
    AddPatientsAnamnesisFormStep0Hook,
    IAddPatientAnamnesisFormStep0,
} from '../types'
import { Box, CircularProgress, Stack } from '@mui/material'
import { useAddPatientAnamnesisFormStep0 } from '../hooks/useAddPatientAnamnesisFormStep0'
import { AnamnesisFormFooter } from './AnamnesisFormFooter'
import InputStyled from '../../../components/InputStyled'
import DateInputStyled from '../../../components/DateInput'
import SelectInputStyled from '../../../components/SelectInputStyled'
import MapyCZAutocomplete from '../../../components/MapyCZAutocomplete'

const AddPatientAnamnesisFormStep0View = (
    props: IAddPatientAnamnesisFormStep0,
) => {
    const {
        submitForm,
        formMethods,
        setCurrentStep,
        currentStep,
        isLoading,
        disabledServerInputs,
        invalidDateInputs,
        doctorOptions,
        canEditCareAnamnesis,
        setSaveCurrentStepOnClose,
    } = props

    const {
        control,
        handleSubmit,
        watch,
        getValues,
        formState: { errors, isSubmitting },
    } = formMethods

    const isPernamentAddress = watch('PernamentAddress')

    const handleSubmitWrapper = useCallback(
        async (isNext?: boolean) => {
            const formData = getValues()
            await submitForm(formData, isNext ? isNext : false)
        },
        [getValues, submitForm],
    )
    useEffect(() => {
        setSaveCurrentStepOnClose(() => handleSubmitWrapper)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSaveCurrentStepOnClose])

    if (isLoading) {
        return (
            <Box className='!mt-12 flex justify-center'>
                <CircularProgress />
            </Box>
        )
    }

    return (
        <form
            onSubmit={() => handleSubmitWrapper(true)}
            className='grid gap-3'
        >
            <InputStyled
                control={control}
                disabled={
                    isSubmitting || disabledServerInputs.includes('Phone')
                }
                name='Phone'
                errors={errors}
                label='Telefon'
                type='tel'
                readOnly={!canEditCareAnamnesis}
                placeholder='telefonní číslo'
            />

            {isPernamentAddress ? (
                <InputStyled
                    control={control}
                    disabled
                    name='PernamentAddress'
                    errors={errors}
                    label='Trvalá adresa'
                    readOnly={!canEditCareAnamnesis}
                    placeholder='trvalou adresu'
                />
            ) : (
                <MapyCZAutocomplete
                    control={control}
                    disabled={
                        isSubmitting || disabledServerInputs.includes('Address')
                    }
                    name='Address'
                    errors={errors}
                    label='Trvalá adresa'
                    readOnly={!canEditCareAnamnesis}
                    placeholder='trvalou adresu'
                />
            )}

            <InputStyled
                control={control}
                disabled={isSubmitting}
                name='AddressNote'
                errors={errors}
                readOnly={!canEditCareAnamnesis}
                label='Poznámka k adrese'
                placeholder='poznámku k adrese'
            />

            <InputStyled
                control={control}
                disabled
                name='FamilyContact'
                errors={errors}
                empty='Kontakt lze přidat ve webové aplikaci'
                readOnly={!canEditCareAnamnesis}
                label='Kontakt na rodinného příslušníka'
                placeholder=''
            />
            <InputStyled
                control={control}
                disabled={isSubmitting}
                name='FamilyContactNote'
                errors={errors}
                readOnly={!canEditCareAnamnesis}
                label='Poznámka ke kontaktu'
                placeholder='poznámku ke kontaktu'
            />

            {disabledServerInputs.includes('PracticalDoctor') ? (
                <InputStyled
                    control={control}
                    disabled
                    name='SelectedPracitcalDoctorString'
                    readOnly={!canEditCareAnamnesis}
                    errors={errors}
                    label='Praktický lékař'
                    placeholder='praktického lékaře'
                />
            ) : (
                <SelectInputStyled
                    control={control}
                    name='PracticalDoctor'
                    errors={errors}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    options={doctorOptions}
                    label='Praktický lékař'
                    placeholder='praktického lékaře'
                />
            )}

            <SelectInputStyled
                control={control}
                disabled={isSubmitting}
                name='Doctor'
                options={doctorOptions}
                readOnly={!canEditCareAnamnesis}
                errors={errors}
                label='Indikující lékař'
                placeholder='indikujícího lékaře'
            />

            {invalidDateInputs.includes('IncomingDate') ? (
                <InputStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='IncomingDate'
                    errors={errors}
                    label='Datum přijetí'
                />
            ) : (
                <DateInputStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='IncomingDate'
                    errors={errors}
                    label='Datum přijetí'
                />
            )}

            <InputStyled
                control={control}
                disabled={isSubmitting}
                name='TransferFrom'
                errors={errors}
                readOnly={!canEditCareAnamnesis}
                label=' Přeložen ze zařízení'
                placeholder='zařízení, ze kterého byl pacient přeložen'
            />

            <InputStyled
                control={control}
                disabled={isSubmitting}
                name='Medication'
                errors={errors}
                readOnly={!canEditCareAnamnesis}
                label='Trvalá medikace'
                placeholder='trvalou medikaci'
            />

            <InputStyled
                control={control}
                disabled={isSubmitting}
                name='CareDiagnoses'
                readOnly={!canEditCareAnamnesis}
                errors={errors}
                label='Ošetřovatelské diagnózy'
                placeholder='ošetřovatelské diagnózy'
            />

            <InputStyled
                control={control}
                disabled={isSubmitting}
                name='MedicalDiagnoses'
                errors={errors}
                readOnly={!canEditCareAnamnesis}
                label='Lékařské diagnózy'
                placeholder='lékařské diagnózy'
            />
            <Stack spacing={2}>
                {/* Footer */}
                <AnamnesisFormFooter
                    canEditCareAnamnesis={canEditCareAnamnesis}
                    currentStep={currentStep}
                    disabled={isLoading || isSubmitting}
                    handleSubmitWrapper={handleSubmitWrapper}
                    setCurrentStep={setCurrentStep}
                />
            </Stack>
        </form>
    )
}

export const AddPatientAnamnesisFormStep0: FC<AddPatientsAnamnesisFormStep0Hook> =
    wrap(AddPatientAnamnesisFormStep0View, useAddPatientAnamnesisFormStep0)
