import {
    Box,
    CircularProgress,
    IconButton,
    Modal,
    Stack,
    Typography,
} from '@mui/material'

import { ReactComponent as StornoImage } from '../../../src/assets/icons/undefined.svg'
import CloseIcon from '@mui/icons-material/Close'
import classNames from 'classnames'
import { useBulletinDocumentFile } from './hooks/useBulletinDocumentFile'
import { useOpenState } from '../../hooks'
import { BulletinMessageAttachments } from '../../types/components'

const imgStyles = {
    maxWidth: '90vw',
    maxHeight: '90vh',
}

export const BulletinDocument = ({
    id,
    name,
    isError = false,
}: BulletinMessageAttachments) => {
    const { isOpen, stateSwitch } = useOpenState()
    const { documentImage, thumbError, documentFile, openPdf, isPdf } =
        useBulletinDocumentFile({
            id: id.toString(),
            filename: name.toString(),
            isOpen,
        })

    const source =
        'https://via.placeholder.com/250x350/f8833b/f8f8f8.png?text=PDF'

    return (
        <>
            <Box
                className='flex flex-col items-center text-center'
                onClick={(e: any) =>
                    isPdf ? openPdf(e, documentFile.data) : stateSwitch()
                }
            >
                {thumbError ? (
                    <StornoImage height='70px' />
                ) : (
                    <img
                        src={
                            isPdf
                                ? 'https://via.placeholder.com/55x70/f8833b/f8f8f8.png?text=PDF'
                                : isError
                                ? 'https://via.placeholder.com/55x70/f8833b/f8f8f8.png?text=Obrázek+nenačten'
                                : documentImage
                        }
                        alt='Dokument pacienta'
                        width='55'
                        height='70'
                        className='mb-1 rounded object-cover'
                    />
                )}
                <Typography className='mb-2 text-xs'>
                    {name.length > 10 ? name.slice(0, 8) + '...' : name}
                </Typography>
                <Modal
                    open={isOpen}
                    onClose={stateSwitch}
                    onClick={stateSwitch}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box onClick={(e) => e.stopPropagation()}>
                        {documentFile.isLoading ? (
                            <CircularProgress color='primary' />
                        ) : documentFile.isError ? (
                            <Stack
                                justifyContent='center'
                                alignItems='center'
                                spacing={2}
                                marginX={2}
                            >
                                <StornoImage
                                    style={{
                                        maxWidth: '90vw',
                                        maxHeight: '90vh',
                                    }}
                                />
                                <Typography
                                    variant='body2'
                                    padding={2}
                                    sx={{
                                        backgroundColor: '#f8f8f8',
                                        borderRadius: '6px',
                                    }}
                                >
                                    Soubor byl pravděpodobně smazán. Pro více
                                    informací kontaktujte podporu.
                                </Typography>
                            </Stack>
                        ) : (
                            <Box className='relative'>
                                <Box
                                    className={classNames({
                                        'absolute right-5 top-5 z-10 justify-end text-right':
                                            true,
                                    })}
                                >
                                    <IconButton
                                        className='ml-auto bg-orange-400 text-white-reduced'
                                        onClick={stateSwitch}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                                <Box className='relative'>
                                    <img
                                        style={imgStyles}
                                        src={documentFile.data || source}
                                        alt={name || 'Dokument pacienta'}
                                    />
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Modal>
            </Box>
        </>
    )
}
