import { OfficeInfoModal } from '../../pages/HomePage/components/OfficeInfoModal'
import DokumentyModal from './DokumentyModal'
import FnolContactsModal from './FnolContactsModal'
import { HelpErrorFormModal } from './HelpErrorFormModal'
import { InvitesModal } from './InvitesModal'
import MedicationModal from './MedicationModal'
import MedicationNotableModal from './MedicationNotableModal'
import MedicationNoteModal from './MedicationNoteModal'
import NoticeModal from './NoticeModal'
import ObsluhaKontaktuModal from './ObsluhaKontaktuModal'
import VisitHistoryModal from './VisitHistoryModal'
import VisitRecordModal from './VisitRecordModal'
import VisitSavingModal from './VisitSavingModal'
import { VisitStatusesModal } from './VisitStatusesModal'
import VisitsReturnedModal from './VisitsReturnedModal'
import { UseHelpModalWrapperProps } from './types'

const ModalWrapper = ({
    selectedModal,
    ...props
}: UseHelpModalWrapperProps) => {
    switch (selectedModal) {
        case 'error':
            return <HelpErrorFormModal {...props} />
        case 'visitsStatus':
            return <VisitStatusesModal {...props} />
        case 'visitRecord':
            return <VisitRecordModal {...props} />
        case 'visitSaving':
            return <VisitSavingModal {...props} />
        case 'office':
            return <OfficeInfoModal {...props} />
        case 'notice':
            return <NoticeModal {...props} />
        case 'fnolContacts':
            return <FnolContactsModal {...props} />
        case 'visitsProgress':
            return <InvitesModal {...props} />
        case 'serviceContact':
            return <ObsluhaKontaktuModal {...props} />
        case 'visitsDocuments':
            return <DokumentyModal {...props} />
        case 'visitHistory':
            return <VisitHistoryModal {...props} />
        case 'visitsReturned':
            return <VisitsReturnedModal {...props} />
        case 'medication':
            return <MedicationModal {...props} />
        case 'medicationNote':
            return <MedicationNoteModal {...props} />
        case 'medicationNotable':
            return <MedicationNotableModal {...props} />
        default:
            return null
    }
}
export default ModalWrapper
