import { useState, useCallback } from 'react'
import { PatientsDialActionTypes, PatientsDialHookProps } from '../types'

export const usePatientsDial = (): PatientsDialHookProps => {
    const [selectedAction, setSelectedAction] =
        useState<PatientsDialActionTypes>('')

    const handleActionSelect = useCallback(
        (actionName: PatientsDialActionTypes) => (e: any) => {
            e.stopPropagation()
            setSelectedAction(actionName)
        },
        [],
    )

    const closeAction = useCallback(() => {
        setSelectedAction('')
    }, [])

    return {
        selectedAction,
        handleActionSelect,
        closeAction,
    }
}
