import { Stack, Typography } from '@mui/material'
import Modal from '../Modal'
import { UseHelpModalProps } from './types'

function FnolContactsModal(props: UseHelpModalProps) {
    return (
        <Modal {...props}>
            <Stack className='gap-3'>
                <Stack className='gap-1'>
                    <Typography
                        className='mb-4 text-center font-bold'
                        color={'primary'}
                    >
                        Kontakty FNOL
                    </Typography>
                    <Typography
                        component='h2'
                        className='text-base font-semibold'
                    >
                        Po-pá: 8:00 - 15:00
                    </Typography>
                    <Typography className='text-sm'>
                        Nutriční ambulance:{' '}
                        <a href='tel:+420588445191'>588 445 191</a>
                    </Typography>
                </Stack>
                <Stack className='gap-1'>
                    <Typography
                        component='h2'
                        className='text-base font-semibold'
                    >
                        Po-pá: 7:00 - 15:30
                    </Typography>
                    <Typography className='text-sm'>
                        PICC tým: <a href='tel:+420588445129'>588 445 129</a>
                        {', '}
                        <a href='tel:+420588445305'>588 445 305</a>
                        {', '}
                        <a href='tel:+420588445186'>588 445 186</a>
                    </Typography>
                </Stack>
                <Stack className='gap-1'>
                    <Typography
                        component='h2'
                        className='text-base font-semibold'
                    >
                        Mimo pracovní dobu
                    </Typography>
                    <Typography className='text-sm'>
                        JIP: <a href='tel:+420588445238'>588 445 238</a>
                    </Typography>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default FnolContactsModal
