import {
    Controller,
    FieldValues,
    Control,
    Path,
    FieldErrors,
} from 'react-hook-form'
import {
    Stack,
    InputLabel,
    Typography,
    FormControl,
    Autocomplete,
    TextField,
} from '@mui/material'
import { get } from 'lodash'

interface SelectInputStyledProps<T extends FieldValues> {
    name: Path<T>
    control: Control<T>
    label: string
    options: { ID: number; FullName: string }[]
    disabled: boolean
    placeholder?: string
    errors: FieldErrors<T>
    readOnly?: boolean
}

const SelectInputStyled = <T extends FieldValues>({
    name,
    control,
    label,
    options,
    disabled,
    placeholder = '',
    errors,
    readOnly,
}: SelectInputStyledProps<T>) => {
    const errorMessage = get(errors, name)?.message
        ? String(get(errors, name)?.message)
        : ''

    return (
        <Stack spacing={1}>
            <InputLabel className='text-sm'>{label}</InputLabel>
            <FormControl fullWidth>
                <Controller
                    control={control}
                    name={name}
                    render={({ field }) => (
                        <Autocomplete
                            {...field}
                            disableClearable
                            className='!text-sm'
                            readOnly={readOnly}
                            noOptionsText={
                                <div
                                    style={{
                                        fontSize: '14px',
                                        padding: '4px !important',
                                    }}
                                >
                                    Žádné výsledky nenalezeny
                                </div>
                            }
                            options={options}
                            getOptionLabel={(option) => option.FullName || ''}
                            isOptionEqualToValue={(option, value) =>
                                option.ID === value.ID
                            }
                            disabled={disabled}
                            onChange={(_, value) => field.onChange(value || '')}
                            renderOption={(props, option, { selected }) => {
                                const baseClass =
                                    'cursor-pointer px-4 py-2 text-sm transition'
                                const selectedClass = selected
                                    ? 'bg-[#f5b895]'
                                    : 'bg-[#fff]'
                                return (
                                    <li
                                        {...props}
                                        className={`${baseClass} ${selectedClass} hover:bg-[#f3f3f3]`}
                                        key={option.ID}
                                    >
                                        {option.FullName}
                                    </li>
                                )
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={''}
                                    placeholder={
                                        placeholder
                                            ? `Uveďte ${placeholder} zde...`
                                            : ''
                                    }
                                    error={!!errors[name]}
                                    variant='outlined'
                                    size='small'
                                    InputProps={{
                                        ...params.InputProps,
                                        sx: {
                                            backgroundColor: '#f8f8f8',
                                            fontSize: '14px !important',
                                            ...(disabled && {
                                                backgroundColor: '#fde0ce',
                                                color: 'rgba(0, 0, 0, 0.87) !important',
                                                opacity: 1,
                                                '& .MuiOutlinedInput-input': {
                                                    color: 'rgba(0, 0, 0, 0.87) !important',
                                                    '-webkit-text-fill-color':
                                                        'rgba(0, 0, 0, 0.87) !important',
                                                    '-webkit-text-stroke': '0',
                                                },
                                            }),
                                        },
                                    }}
                                />
                            )}
                        />
                    )}
                />
            </FormControl>
            {errorMessage && (
                <Typography className='pt-1 text-sm text-error-2'>
                    {errorMessage}
                </Typography>
            )}
        </Stack>
    )
}

export default SelectInputStyled
