import { FC, useEffect, useState } from 'react'
import {
    BulletinMessageAttachments,
    SelectItemsCzech,
    type BulletinFormModalHook,
    type BulletinFormModalProps,
    type BulletinTag,
} from '../../types/components'
import { wrap } from '../../utils'
import { useBulletinFormModal } from './hooks'
import {
    Box,
    Button,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Modal as MaterialModal,
    Stack,
    Slide,
    Paper,
    Typography,
    IconButton,
} from '@mui/material'
import { Controller } from 'react-hook-form'
import { Delete } from '@mui/icons-material'
import LoadingButton from '@mui/lab/LoadingButton'
import { uid } from 'react-uid'
import BulletinUrlLinks from './BulletinUrlLinks'
import CloseIcon from '@mui/icons-material/Close'
import BulletinUpload from './BulletinUpload'
import BulletinMessageUsers from './BulletinMessageUsers'
import BulletinPatients from './BulletinPatients'

export const BulletinFormModalView: FC<BulletinFormModalProps> = (props) => {
    const {
        formMethods,
        submitMessage,
        handleClose,
        handleBulletinDelete,
        bulletinDeleteLoading,
        messageData,
        isFnol,
        isEdit,
    } = props

    const {
        control,
        watch,
        setValue,
        getValues,
        trigger,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = formMethods

    const selectItems: BulletinTag[] = ['Unknown', 'Important', 'Fnol']
    const [isVisibleLinkSection, setIsVisibleLinkSection] =
        useState<boolean>(false)

    useEffect(() => {
        if (isFnol && !isEdit) {
            setValue('tag', 'Fnol')
        }
    }, [isFnol, setValue, isEdit])

    useEffect(() => {
        const urlLinks = (messageData as any)?.urlLinks

        if (urlLinks && urlLinks.length > 0) {
            setIsVisibleLinkSection(true)
        }
    }, [messageData, setIsVisibleLinkSection])

    return (
        <MaterialModal {...props}>
            <Slide
                direction='up'
                in={true}
            >
                <Box
                    sx={{
                        position: 'absolute' as 'absolute',
                        bottom: '0',
                        width: '100%',
                        minHeight: '20vh',
                        overflowY: 'auto',
                        borderRadius: '6px',
                        maxWidth: '768px',
                        '@media screen and (min-width: 768px)': {
                            left: 'calc(50% - 384px)',
                            bottom: 'auto',
                            top: '50%',
                            transform: 'translateY(-50%) !important',
                        },
                    }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Paper
                        sx={{
                            background: '#feefe6',
                        }}
                    >
                        <form onSubmit={handleSubmit(submitMessage)}>
                            <Box>
                                <Box className='flex items-center  bg-[#feefe6] px-5 py-4'>
                                    <Typography
                                        className='w-full text-center font-bold'
                                        color={'primary'}
                                    >
                                        {isEdit
                                            ? `Upravit zprávu ${
                                                  isFnol ? 'pro FNOL' : ''
                                              }`
                                            : `Nová zpráva ${
                                                  isFnol ? 'pro FNOL' : ''
                                              }`}
                                    </Typography>
                                    <IconButton
                                        className=' bg-orange-400 p-1 text-white-reduced'
                                        onClick={handleClose}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                                <Stack
                                    spacing={2}
                                    style={{ maxHeight: 'calc(90vh - 120px)' }}
                                    className='overflow-y-auto px-5 py-2'
                                >
                                    <Stack spacing={1}>
                                        <InputLabel className='text-sm'>
                                            Název zprávy*
                                        </InputLabel>

                                        <Controller
                                            control={control}
                                            name='title'
                                            render={({ field }) => (
                                                <OutlinedInput
                                                    {...field}
                                                    placeholder='zde zadejte název...'
                                                    className='bg-white-reduced text-sm'
                                                    type='text'
                                                    fullWidth
                                                    size='small'
                                                    error={
                                                        errors.title !==
                                                        undefined
                                                    }
                                                    sx={{
                                                        marginBottom: 2,
                                                    }}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack spacing={1}>
                                        <InputLabel
                                            sx={{
                                                fontSize: '0.875rem',
                                            }}
                                        >
                                            Vyberte kategorii
                                        </InputLabel>
                                        <Controller
                                            control={control}
                                            name='tag'
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    variant='outlined'
                                                    size='small'
                                                    disabled={
                                                        messageData?.isAdmin ||
                                                        isFnol
                                                    }
                                                    error={
                                                        errors.tag !== undefined
                                                    }
                                                    MenuProps={{
                                                        MenuListProps: {
                                                            sx: {
                                                                maxHeight:
                                                                    '300px',
                                                            },
                                                        },
                                                    }}
                                                    inputProps={{
                                                        sx: {
                                                            fontSize: '13px',
                                                            backgroundColor:
                                                                '#f8f8f8',
                                                        },
                                                    }}
                                                >
                                                    {selectItems
                                                        ?.filter(
                                                            (item) =>
                                                                !(
                                                                    item ===
                                                                        'Fnol' &&
                                                                    !isFnol
                                                                ),
                                                        )
                                                        .map((selectItem) => {
                                                            return (
                                                                <MenuItem
                                                                    sx={{
                                                                        fontSize:
                                                                            '13px',
                                                                    }}
                                                                    key={uid(
                                                                        selectItem,
                                                                    )}
                                                                    value={
                                                                        selectItem
                                                                    }
                                                                >
                                                                    {
                                                                        SelectItemsCzech[
                                                                            selectItem
                                                                        ]
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        })}
                                                </Select>
                                            )}
                                        />
                                    </Stack>
                                    <Stack spacing={1}>
                                        <InputLabel className='text-sm'>
                                            Obsah zprávy
                                        </InputLabel>
                                        <Controller
                                            control={control}
                                            name='message'
                                            render={({ field }) => (
                                                <OutlinedInput
                                                    {...field}
                                                    placeholder='zde zadejte poznámku...'
                                                    className='bg-white-reduced text-sm'
                                                    type='text'
                                                    multiline
                                                    fullWidth
                                                    size='small'
                                                    rows={6}
                                                    error={
                                                        errors.message !==
                                                        undefined
                                                    }
                                                    sx={{
                                                        marginBottom: 2,
                                                    }}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <BulletinMessageUsers
                                        control={control}
                                        watch={watch}
                                        getValues={getValues}
                                        triggerValidation={trigger}
                                        setValue={setValue}
                                        errors={errors.selectedUsers}
                                        isFnol={isFnol}
                                    />

                                    <BulletinPatients
                                        control={control}
                                        watch={watch}
                                        getValues={getValues}
                                        triggerValidation={trigger}
                                        setValue={setValue}
                                        isFnol={isFnol}
                                        errors={errors.selectedPatients}
                                    />

                                    <BulletinUpload
                                        control={control}
                                        setValue={setValue}
                                        isFnol={isFnol}
                                        getValues={getValues}
                                        messageAttachments={
                                            (messageData as any)?.attachments
                                        }
                                        uploadedIds={
                                            isEdit
                                                ? (
                                                      messageData as any
                                                  )?.attachments.map(
                                                      (
                                                          attachment: BulletinMessageAttachments,
                                                      ) => attachment.id,
                                                  )
                                                : []
                                        }
                                        errors={errors.attachments}
                                    />
                                    {!isFnol && (
                                        <>
                                            <Button
                                                style={{
                                                    textTransform: 'initial',
                                                }}
                                                className='!mr-auto p-0'
                                                onClick={() =>
                                                    setIsVisibleLinkSection(
                                                        (prev) => !prev,
                                                    )
                                                }
                                            >
                                                {isVisibleLinkSection
                                                    ? 'Skrýt odkazy'
                                                    : 'Přidat odkazy'}
                                            </Button>
                                            {isVisibleLinkSection && (
                                                <BulletinUrlLinks
                                                    urlLinks={
                                                        (messageData as any)
                                                            ?.urlLinks
                                                    }
                                                    getValues={getValues}
                                                    setValue={setValue}
                                                    control={control}
                                                />
                                            )}
                                        </>
                                    )}
                                </Stack>

                                <Stack className='flex-row justify-between px-5 py-4'>
                                    {messageData ? (
                                        <LoadingButton
                                            type='button'
                                            variant='outlined'
                                            color='error'
                                            size='small'
                                            loading={bulletinDeleteLoading}
                                            className='bg-white-reduced text-sm normal-case shadow-none'
                                            endIcon={<Delete />}
                                            loadingPosition='end'
                                            onClick={handleBulletinDelete}
                                        >
                                            smazat
                                        </LoadingButton>
                                    ) : (
                                        <Box />
                                    )}
                                    <Stack className='flex-row justify-end gap-2'>
                                        <Button
                                            type='button'
                                            variant='outlined'
                                            size='small'
                                            className='bg-white-reduced text-sm font-bold normal-case shadow-none'
                                            onClick={handleClose}
                                        >
                                            zrušit
                                        </Button>
                                        <Button
                                            variant='contained'
                                            size='small'
                                            type='submit'
                                            disabled={isSubmitting}
                                            className='text-sm font-bold normal-case shadow-none'
                                        >
                                            uložit a zavřít
                                        </Button>
                                    </Stack>
                                </Stack>
                            </Box>
                        </form>
                    </Paper>
                </Box>
            </Slide>
        </MaterialModal>
    )
}

export const BulletinFormModal: FC<BulletinFormModalHook> = wrap(
    BulletinFormModalView,
    useBulletinFormModal,
)
