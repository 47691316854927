import { Grid, Typography } from '@mui/material'
import { VisitLengthBarProps } from '../types'

export const VisitLengthBar = (props: VisitLengthBarProps) => {
    const { hasEntries, officeStateCzech, isVisitLoading, visitData } = props

    return (
        <Grid
            container
            className='items-end pb-6 pt-6'
        >
            {hasEntries && (
                <Grid
                    item
                    xs={12}
                >
                    <Typography className='text-sm font-medium'>
                        Celkový počet {officeStateCzech} návštěv:{' '}
                        {isVisitLoading ? '...' : visitData?.totalItems || 0}
                    </Typography>
                </Grid>
            )}
        </Grid>
    )
}
