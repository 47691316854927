import { BulletinFormModal } from '../../../components'
import { useBulletinActionWrapperProps } from '../../../components/Bulletin/types'

const BulletinActionWrapper = ({
    selectedAction,
    ...props
}: useBulletinActionWrapperProps) => {
    switch (selectedAction) {
        case 'NEW_MESSAGE':
            return (
                <BulletinFormModal
                    isFnol={false}
                    {...props}
                />
            )
        case 'FNOL':
            return (
                <BulletinFormModal
                    isFnol
                    {...props}
                />
            )

        default:
            return null
    }
}
export default BulletinActionWrapper
