import { Box, Tab, Tabs, tabsClasses } from '@mui/material'
import { VisitTabsProps } from '../types'
import { twMerge } from 'tailwind-merge'

export const VisitTabs = (props: VisitTabsProps) => {
    const { activeTab, handleTabChange, patientHistory, intersectElement } =
        props

    return (
        <Box
            className={twMerge(
                'top-[4.7rem] z-40 bg-orange-50 transition-all duration-300',
            )}
        >
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                className='max-w-auto mb-3 px-[0.75rem] pt-2 lg:mx-auto lg:max-w-container'
                variant='scrollable'
                visibleScrollbar={true}
                scrollButtons={'auto'}
                allowScrollButtonsMobile
                sx={{
                    '.MuiTabs-scrollableX': {
                        paddingBottom: '0.5rem',
                        '::-webkit-scrollbar': {
                            height: '12px',
                        },
                        '::-webkit-scrollbar-track': {
                            boxShadow: 'inset 0 0 1px grey',
                            borderRadius: '10px',
                        },
                        '::-webkit-scrollbar-thumb': {
                            background: '#faa26b',
                            borderRadius: '10px',
                        },
                        '::-webkit-scrollbar-thumb:hover': {
                            background: '#c55007',
                        },
                    },
                    [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { opacity: 0.3 },
                    },
                }}
            >
                <Tab label='Detail' />
                <Tab label='Pacient' />
                <Tab label='Kontakty' />
                <Tab
                    onClick={() => patientHistory.handleRefetchQuery()}
                    label='Návštěvy'
                />
                <Tab
                    ref={intersectElement.targetRef}
                    label='Medikace'
                />
            </Tabs>
        </Box>
    )
}
