import { Routes, Route } from 'react-router-dom'
import { ProtectedRoute } from '../components'
import { PageWrapper } from '../containers'
import {
    BulletinPage,
    HomePage,
    LoginPage,
    OfficePage,
    Page404,
    ProfilePage,
    VisitDetailPage,
    VisitOrderPage,
} from '../pages'
import { NurseHistoryPage } from '../pages/NurseHistoryPage'
import type { ProtectedRouteProps } from '../types'
import moment from 'moment'
import { AdminPage } from '../pages/AdminPage'
import { PatientsPage } from '../pages/PatientsPage'

export const AppRouting = () => {
    const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
        authenticationPath: '/',
    }

    return (
        <Routes>
            <Route
                path='/'
                element={<LoginPage />}
            />
            <Route
                element={
                    <ProtectedRoute
                        {...defaultProtectedRouteProps}
                        outlet={<PageWrapper />}
                    />
                }
            >
                <Route
                    path='/home'
                    element={<HomePage />}
                />
                <Route
                    path='/admin'
                    element={<AdminPage />}
                />
                <Route
                    path='/profile'
                    element={<ProfilePage />}
                />
                <Route
                    path='/visit-order/:date?'
                    element={<VisitOrderPage />}
                />
                <Route
                    path='/visit-detail/:visitId'
                    element={<VisitDetailPage />}
                />
                <Route
                    path='/visits-history'
                    element={<NurseHistoryPage />}
                />
                <Route
                    path='/visits-inprogress'
                    element={
                        <OfficePage
                            stateIn={['New', 'Scheduled', 'Draft']}
                            dateTo={moment().format('YYYY-MM-DD')}
                            listType='toComplete'
                        />
                    }
                />
                <Route
                    path='/notifications'
                    element={<BulletinPage />}
                />
                <Route
                    path='/visits-returned'
                    element={
                        <OfficePage
                            listType='returned'
                            stateIn={['Returned']}
                        />
                    }
                />
                <Route
                    path='/patients'
                    element={<PatientsPage />}
                />

                <Route
                    path='*'
                    element={<Page404 />}
                />
            </Route>
            <Route
                path='*'
                element={<LoginPage />}
            />
        </Routes>
    )
}
