import {
    PatientFilterQueries,
    PatientsCardFilterType,
    PatientsFilterItems,
} from '../types'

export const getPatientsCardFilters = (
    patientsQueryFilters?: PatientFilterQueries,
): PatientsFilterItems[] => {
    const staticFilters: PatientsFilterItems[] = [
        {
            title: 'Stav pacienta',
            type: 'patientStates',
            items: [
                { active: false, value: 'Active', name: 'Aktivní' },
                { active: false, value: 'Waiting', name: 'Čekatel' },
                {
                    active: false,
                    value: 'Hospitalized',
                    name: 'Hospitalizován',
                },
                { active: false, value: 'Ended', name: 'Péče ukončena' },
                { active: false, value: 'Dead', name: 'Zemřel' },
            ],
        },
    ]

    if (!patientsQueryFilters) return staticFilters

    Object.keys(patientsQueryFilters).forEach((filterKey) => {
        const queryValues = patientsQueryFilters[filterKey]
        const filterGroup = staticFilters.find(
            (filter) => filter.type === filterKey,
        )

        if (filterGroup && queryValues) {
            // Adjust the 'active' state for the matching filter items
            filterGroup.items = filterGroup.items.map((item) => ({
                ...item,
                active: Array.isArray(queryValues)
                    ? queryValues.includes(item.value)
                    : queryValues === item.value,
            }))
        }
    })

    return staticFilters
}

export const singleSelectPatientsGroup: PatientsCardFilterType[] = []
