import { Stack, Typography } from '@mui/material'
import Modal from '../Modal'
import { UseHelpModalProps } from './types'
import { Close, Done } from '@mui/icons-material'
import { useHelpCamera } from '../../hooks/useHelpCamera'

function DokumentyModal(props: UseHelpModalProps) {
    const { cameraPermission } = useHelpCamera()

    return (
        <Modal
            {...props}
            title='Dokumenty'
        >
            <Stack className='gap-3'>
                <Stack className='gap-1'>
                    <Typography className='text-sm '>
                        Kliknutím na ikonu fotoaparátu lze vkládat fotografie
                        pořízené přímo v aplikaci. Nutné povolení přístupu
                        aplikace k fotoaparátu vašeho telefonu. Aktuálně{' '}
                        <span className='font-semibold'>
                            {cameraPermission === 'granted' ? 'máte' : 'nemáte'}
                        </span>{' '}
                        přístup povolen.
                        <>
                            {cameraPermission === 'granted' ? (
                                <Done
                                    style={{
                                        color: '#84c731',
                                        verticalAlign: 'bottom',
                                    }}
                                />
                            ) : (
                                <Close
                                    style={{
                                        color: '#ed1b2e',
                                        verticalAlign: 'bottom',
                                    }}
                                />
                            )}
                        </>
                    </Typography>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default DokumentyModal
