import React, { useCallback, useState } from 'react'
import { Box, Button, InputLabel, OutlinedInput, Stack } from '@mui/material'
import careAnamnesisBody from '../../../assets/careAnamnesisBody.png'
import Modal from '../../../components/Modal'
import { useCustomSnackbar } from '../../../hooks'

function AnamnesisDefectsModal({
    handleClose,
    isOpen,
    stateSwitch,
    onSave,
}: {
    handleClose: () => void
    isOpen: boolean
    stateSwitch: () => void
    onSave: (point: {
        Name: string
        Description: string
        Coordinates: {
            X: number
            Y: number
            ImageWidth: number
            ImageHeight: number
        }
    }) => void
}) {
    const [defectName, setDefectName] = useState('')

    const [defectDescription, setDefectDescription] = useState('')
    const [dot, setDot] = useState<{
        X: number
        Y: number
        ImageWidth: number
        ImageHeight: number
    } | null>(null)

    const snackbar = useCustomSnackbar()

    const handleImageClick = useCallback(
        (e: React.MouseEvent<HTMLImageElement>) => {
            const rect = e.currentTarget.getBoundingClientRect()
            const X = e.clientX - rect.left
            const Y = e.clientY - rect.top

            const ImageWidth = e.currentTarget.clientWidth
            const ImageHeight = e.currentTarget.clientHeight

            setDot({ X, Y, ImageWidth, ImageHeight })
        },
        [setDot], // Add dependencies if necessary
    )

    const handleSave = useCallback(() => {
        if (!defectName || !defectDescription) {
            snackbar('Je nutné vyplnit povinná pole.', 'error')
            return
        }

        if (!dot) {
            snackbar('Je nutné označit defekt na obrázku.', 'error')
            return
        }

        onSave({
            Name: defectName,
            Description: defectDescription,
            Coordinates: {
                X: dot.X,
                Y: dot.Y,
                ImageHeight: dot.ImageHeight,
                ImageWidth: dot.ImageWidth,
            },
        })
        handleClose()
    }, [defectName, defectDescription, dot, onSave, handleClose, snackbar])

    return (
        <Modal
            open={isOpen}
            handleClose={handleClose}
            title='Označení defektu'
            onClose={stateSwitch}
            onClick={stateSwitch}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                onClick={(e) => e.stopPropagation()}
                position='relative'
            >
                <Stack gap={2}>
                    <img
                        src={careAnamnesisBody}
                        alt='body'
                        className='w-full max-w-[400px]'
                        onClick={handleImageClick} // Add the click handler here
                    />
                    {dot && ( // Render dot if it exists
                        <Box
                            sx={{
                                position: 'absolute',
                                left: dot.X,
                                top: dot.Y,
                                width: '10px',
                                height: '10px',
                                backgroundColor: '#f8833b',
                                borderRadius: '50%',
                                transform: 'translate(-50%, -50%)', // Center the dot
                            }}
                        />
                    )}
                    <Stack spacing={1}>
                        <InputLabel className='text-sm'>Název*</InputLabel>
                        <OutlinedInput
                            sx={{
                                marginBottom: 2,
                                backgroundColor: '#F8F8F8',
                                fontSize: '14px',
                                height: '40px',
                            }}
                            name='defectName'
                            value={defectName}
                            onChange={(e) => setDefectName(e.target.value)}
                            placeholder='Uveďte název defektu zde...'
                        />
                    </Stack>

                    <Stack spacing={1}>
                        <InputLabel className='text-sm'>
                            Popis a rozměr defektu*
                        </InputLabel>
                        <OutlinedInput
                            sx={{
                                marginBottom: 2,
                                backgroundColor: '#F8F8F8',
                                fontSize: '14px',
                                height: '40px',
                            }}
                            name='defectDescription'
                            value={defectDescription}
                            onChange={(e) =>
                                setDefectDescription(e.target.value)
                            }
                            placeholder='Uveďte popis a rozměr defektu zde...'
                        />
                    </Stack>

                    <Button
                        variant='contained'
                        onClick={handleSave}
                        type='button'
                        size='small'
                        className='ml-auto capitalize'
                    >
                        Uložit
                    </Button>
                </Stack>
            </Box>
        </Modal>
    )
}

export default AnamnesisDefectsModal
