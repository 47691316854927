import { Box } from '@mui/material'
import { Modal } from '../../../components'
import type { ImportantNoteModalProps } from '../types'
import { ImportantNoteItem } from './ImportantNoteItem'
import { useUIDSeed } from 'react-uid'

export const ImportantNoteModal = ({
    importantNoteModal,
    importantNotesQuery,
}: ImportantNoteModalProps) => {
    const { isOpen, handleClose } = importantNoteModal
    const { data: importantNotes } = importantNotesQuery

    const uid = useUIDSeed()

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            handleClose={handleClose}
            title='Důležitá poznámka'
        >
            <Box>
                {(importantNotesQuery.isLoading
                    ? Array.from(new Array(3))
                    : importantNotes?.data
                )?.map((importantNote) => (
                    <ImportantNoteItem
                        key={uid(importantNote)}
                        isLoading={importantNotesQuery.isLoading}
                        importantNote={importantNote}
                    />
                ))}
            </Box>
        </Modal>
    )
}
