import { Stack, InputLabel, Box, Chip, Avatar, Button } from '@mui/material'
import { Controller } from 'react-hook-form'
import { uid } from 'react-uid'
import { useCustomSnackbar, useOpenState } from '../../hooks'
import { BulletinPatientsProps, BulletinPatientsSearchProps } from './types'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import BulletinPatientsModal from './BulletinPatientsModal'
import { useEffect } from 'react'
import classNames from 'classnames'

function BulletinPatients({
    control,
    setValue,
    getValues,
    errors,
    triggerValidation,
    isFnol,
}: BulletinPatientsProps) {
    const { isOpen, handleClose, handleOpen } = useOpenState()
    const customSnackbar = useCustomSnackbar()

    useEffect(() => {
        if (errors?.message) {
            customSnackbar('Je důležité vybrat pacienta.', 'error', false)
        }
    }, [errors?.message, customSnackbar])

    return (
        <Stack spacing={1}>
            <InputLabel className='text-sm'>
                Vztahuje se k pacientům{isFnol ? '*' : ''}
            </InputLabel>

            <Controller
                name='selectedPatients'
                control={control}
                defaultValue={[]}
                render={({ field }) => {
                    return (
                        <Box
                            className={classNames(
                                'rounded-[6px] border border-solid border-[#bfbfbf] bg-white-reduced p-[6px] text-sm',
                                {
                                    'border-[#e84a4a]': !!errors,
                                },
                            )}
                        >
                            <Box className='flex flex-wrap gap-2'>
                                {field.value &&
                                    field.value.map(
                                        (user: BulletinPatientsSearchProps) => (
                                            <Chip
                                                key={uid(
                                                    `${user.id}${user.fullName}${user.personalNumber}`,
                                                )}
                                                label={
                                                    user.id === 0
                                                        ? `${user.fullName} | ${user.personalNumber}`
                                                        : user.fullName
                                                }
                                                color='primary'
                                                sx={{
                                                    '& .MuiChip-deleteIcon': {
                                                        color: '#BDBDBD',
                                                    },
                                                }}
                                                className={
                                                    'bg-primary text-white  bg-[#33333314] text-[#333333de]'
                                                }
                                                avatar={
                                                    user.fullName?.split(' ')
                                                        .length > 1 &&
                                                    user.id !== 0 ? (
                                                        <Avatar
                                                            style={{
                                                                backgroundColor:
                                                                    '#BDBDBD',
                                                            }}
                                                            className='text-base'
                                                            src={''}
                                                            alt={
                                                                user.fullName ||
                                                                ''
                                                            }
                                                        >
                                                            {
                                                                user.fullName?.split(
                                                                    ' ',
                                                                )[0][0]
                                                            }
                                                            {
                                                                user.fullName?.split(
                                                                    ' ',
                                                                )[1][0]
                                                            }
                                                        </Avatar>
                                                    ) : undefined
                                                }
                                            />
                                        ),
                                    )}
                            </Box>

                            <Box className='mt-2 flex justify-end'>
                                <Button
                                    variant='contained'
                                    size='small'
                                    type='button'
                                    className='flex gap-1 text-sm font-bold normal-case shadow-none'
                                    onClick={handleOpen}
                                >
                                    přidat pacienta
                                    <PersonAddIcon
                                        style={{
                                            fontSize: '20px',
                                        }}
                                    />
                                </Button>
                            </Box>
                        </Box>
                    )
                }}
            />
            <BulletinPatientsModal
                isOpen={isOpen}
                isFnol={isFnol}
                handleClose={handleClose}
                triggerValidation={triggerValidation}
                setValue={setValue}
                preselectedPatients={getValues('selectedPatients')}
            />
        </Stack>
    )
}

export default BulletinPatients
