import { useCallback, useEffect, useState } from 'react'

export interface ScrollStatus {
    scrollDirection: 'up' | 'down' | null
    scrollPos: number
}

export const useScroll = () => {
    const [scrollStatus, setScrollStatus] = useState<ScrollStatus>({
        scrollDirection: null,
        scrollPos: 0,
    })

    const handleScrollDocument = useCallback(() => {
        setScrollStatus((prev) => ({
            scrollDirection:
                document.body.getBoundingClientRect().top > prev.scrollPos
                    ? 'up'
                    : 'down',
            scrollPos: document.body.getBoundingClientRect().top,
        }))
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', handleScrollDocument)

        return () => window.removeEventListener('scroll', handleScrollDocument)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollStatus])

    return scrollStatus
}
