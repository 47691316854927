import { CssBaseline, ThemeProvider } from '@mui/material'
import { BrowserRouter as Router } from 'react-router-dom'
import {
    AppQueryProvider,
    AuthProvider,
    CssPriorityProvider,
    AppRouting,
    PatientProvider,
    MainVisitProvider,
} from './providers'
import { muiTheme } from './styles'
import { cs } from 'date-fns/locale'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { csCZ } from '@mui/x-date-pickers/locales'
import { SnackbarProvider } from 'notistack'
import { useEffect } from 'react'
import disableIOSZoom from './utils/globalUtils'

function App() {
    useEffect(() => {
        disableIOSZoom()
    }, [])

    return (
        <AppQueryProvider>
            <CssPriorityProvider>
                <CssBaseline />
                <ThemeProvider theme={muiTheme}>
                    <LocalizationProvider
                        adapterLocale={cs}
                        dateAdapter={AdapterDateFns}
                        localeText={{
                            ...csCZ.components.MuiLocalizationProvider
                                .defaultProps.localeText,
                            todayButtonLabel: 'Nyní',
                        }}
                    >
                        <SnackbarProvider
                            hideIconVariant
                            maxSnack={5}
                        >
                            <AuthProvider>
                                <MainVisitProvider>
                                    <PatientProvider>
                                        <Router>
                                            <AppRouting />
                                        </Router>
                                    </PatientProvider>
                                </MainVisitProvider>
                            </AuthProvider>
                        </SnackbarProvider>
                    </LocalizationProvider>
                </ThemeProvider>
            </CssPriorityProvider>
        </AppQueryProvider>
    )
}

export default App
