import {
    InputLabel,
    OutlinedInput,
    OutlinedInputProps,
    Stack,
} from '@mui/material'

interface MedicationInputProps extends OutlinedInputProps {
    labelText: string
    inputData: string
}

export const MedicationInput = (props: MedicationInputProps) => {
    const { labelText, inputData, ...componentProps } = props

    return (
        <Stack gap='0.5rem'>
            <InputLabel
                sx={{
                    fontSize: '0.875rem',
                    fontWeight: 'medium',
                    lineHeight: 'normal',
                }}
            >
                {labelText}
            </InputLabel>
            <OutlinedInput
                value={inputData}
                inputProps={{
                    style: {
                        padding: '0.5rem 0.75rem',
                    },
                }}
                sx={{
                    borderRadius: '6px',
                    background: '#FEEFE6',
                    fontSize: '0.875rem',
                    fontWeight: 'medium',
                    color: '#333',
                }}
                {...componentProps}
            />
        </Stack>
    )
}
