import axios from 'axios'
import { getRestApiConfig } from '../../../api/config'
import {
    AddPatientFormFormatted,
    CreateCareAnamnesisResponse,
    PutSaveCareAnamnesisStep,
    PutSaveCareAnamnesisStepResponse,
} from '../types'
import { get } from 'lodash'

export const PATIENT_ANAMNESES_QUERY = 'patientAnamneses'
export const PATIENT_CARE_ANAMNESIS_QUERY = 'patientCareAnamnesis'

type PostNewPatient = {
    data: AddPatientFormFormatted
    token?: string
}

export const postNewPatient = async (props: PostNewPatient) => {
    const { token, data } = props

    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const endpointUrl = new URL(`/api-mob/Patient`, baseUrl).href

    const body = { ...data }

    const response = await axios.post(endpointUrl, body, headerConfig)

    return response.data
}

export const getPatientCareAnamneses = async (
    patientId: number,
    token?: string,
) => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const endpointUrl = new URL(`/api-mob/CareAnamneses`, baseUrl)
    endpointUrl.searchParams.append('patientID', patientId.toString())

    const response = await axios.get(endpointUrl.href, headerConfig)

    return response.data
}

export const getPatientCareAnamnesis = async (
    anamnesisID: number,
    currentStep: number,
    token?: string,
) => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const endpointUrl = new URL(
        `/api-mob/CareAnamneses/${anamnesisID}`,
        baseUrl,
    )

    endpointUrl.searchParams.append('step', currentStep.toString())

    const response = await axios.get(endpointUrl.href, headerConfig)

    return response.data
}

export const putSaveCareAnamnesisStep = async ({
    anamnesisID,
    data,
    token,
}: PutSaveCareAnamnesisStep): Promise<PutSaveCareAnamnesisStepResponse> => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const endpointUrl = new URL(
        `/api-mob/CareAnamneses/${anamnesisID}`,
        baseUrl,
    ).href

    const response = await axios.put(endpointUrl, data, headerConfig)

    const responseData = {
        ...get(response, 'data', {}),
        anamnesisID: anamnesisID,
        currentStep: get(data, 'currentStep', null),
    }
    return responseData
}

export const createCareAnamnesis = async ({
    patientId,
    token,
}: {
    patientId: number
    token?: string
}): Promise<CreateCareAnamnesisResponse> => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const endpointUrl = new URL(`/api-mob/CareAnamneses`, baseUrl)

    endpointUrl.searchParams.append('PatientID', patientId.toString())

    const response = await axios.post(endpointUrl.href, {}, headerConfig)

    return response.data
}
