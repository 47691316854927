import { Avatar, Box, Chip, Stack, StackProps, Typography } from '@mui/material'

import { twMerge } from 'tailwind-merge'
import { useMemo } from 'react'
import { useUIDSeed } from 'react-uid'
import { FilterItems } from './types'

export interface FilterItemValue<TValue> {
    active: boolean
    value: TValue
    name: string
    prefix?: string
    icon?: string
}

export interface FilterGroupProps<TFilterType, TValue>
    extends Omit<StackProps, 'children'> {
    title?: string
    type: TFilterType
    items: FilterItemValue<TValue>[]
    handleFilterActivate: (args: {
        item: FilterItemValue<TValue>
        group: FilterItems<TFilterType, TValue>
    }) => void
    resetFilterGroup: (groupType: TFilterType) => void
}

export const FilterGroup = <TFilterType, TValue>(
    props: FilterGroupProps<TFilterType, TValue>,
) => {
    const { title, type, items, handleFilterActivate, resetFilterGroup } = props

    const noneFilterActive = useMemo(
        () => !items.some(({ active }) => active),
        [items],
    )
    const uid = useUIDSeed()
    return (
        <Stack
            key={title}
            className='mb-4'
        >
            <Stack className='mb-3 flex-row items-baseline justify-between'>
                <Typography>{title}</Typography>
                <Box className='h-px w-4 bg-gray-600' />
            </Stack>
            <Stack className='flex-row flex-wrap gap-1'>
                <Chip
                    label='Vše'
                    variant={noneFilterActive ? 'filled' : 'outlined'}
                    color='primary'
                    className={twMerge(
                        noneFilterActive
                            ? 'bg-primary text-white'
                            : 'bg-white text-gray-600',
                    )}
                    onClick={() => resetFilterGroup(type)}
                />
                {items.map((item) => (
                    <Chip
                        key={uid(item.name)}
                        label={item.name}
                        variant={item.active ? 'filled' : 'outlined'}
                        color='primary'
                        className={twMerge(
                            item.active
                                ? 'bg-primary text-white'
                                : 'bg-white text-gray-600',
                        )}
                        avatar={
                            item.name?.split(' ').length > 1 || item.icon ? (
                                <Avatar
                                    sx={{
                                        '&.MuiAvatar-root': {
                                            display: 'none',
                                        },
                                    }}
                                    className='bg-orange-300 text-base'
                                    src={item.icon || ''}
                                    alt={item.name || ''}
                                >
                                    {!item.icon?.trim() &&
                                        `${item.name?.split(' ')[0][0]}${
                                            item.name?.split(' ')[1][0]
                                        }`}
                                </Avatar>
                            ) : undefined
                        }
                        onClick={() =>
                            handleFilterActivate({
                                item,
                                group: { title, type, items },
                            })
                        }
                    />
                ))}
            </Stack>
        </Stack>
    )
}
