import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { getMapyCZSuggestions } from '../api/mapyCZ'

const MAPY_CZ_SUGGESTIONS = 'MAPY_CZ_SUGGESTIONS'

export const useMapyCZHook = () => {
    const [query, setQuery] = useState('')

    const [inputValue, setInputValue] = useState('')
    useEffect(() => {
        const handler = setTimeout(() => {
            setQuery(inputValue)
        }, 400)

        return () => {
            clearTimeout(handler)
        }
    }, [inputValue, setQuery])

    const {
        data: suggestions = [],
        refetch,
        isFetching,
        isFetched,
    } = useQuery(
        [MAPY_CZ_SUGGESTIONS, query],
        () => getMapyCZSuggestions(query),
        {
            enabled: !!query && query.length >= 3,
            staleTime: 5000,
            retry: false,
        },
    )

    return {
        suggestions,
        refetch,
        isLoading: isFetching,
        setQuery,
        isFetched,
        query,
        inputValue,
        setInputValue,
    }
}
