import { getDashboardData } from './../api/dashboard'
import { useQuery } from '@tanstack/react-query'
import type { DashboardData } from '../api/dashboard/types'
import { useNavigate } from 'react-router-dom'

export const useDashboardQuery = (token?: string) => {
    const navigate = useNavigate()
    const { data, isLoading, isError } = useQuery<DashboardData>(
        ['dashboard', token],
        () => getDashboardData(token),
        {
            enabled: !!token,
            onError: () => {
                navigate('/')
            },
        },
    )

    return { data, isLoading, isError }
}
