import { FC, useCallback, useEffect } from 'react'
import { wrap } from '../../../utils'
import { useAddPatientAnamnesisFormStep9 } from '../hooks/useAddPatientAnamnesisFormStep9'
import {
    AddPatientsAnamnesisFormStep9Hook,
    IAddPatientAnamnesisFormStep9,
} from '../types'
import { Box, CircularProgress, Stack } from '@mui/material'
import { AnamnesisFormFooter } from './AnamnesisFormFooter'
import MultipleChoiceRadioGroup from '../../../components/MultipleChoicesRadioGroup'
import {
    eStressManagementOptions,
    otherStressManagementValue,
} from '../../../components/options'
import InputStyled from '../../../components/InputStyled'

const AddPatientAnamnesisFormStep9View = (
    props: IAddPatientAnamnesisFormStep9,
) => {
    const {
        submitForm,
        formMethods,
        setCurrentStep,
        currentStep,
        isLoading,
        canEditCareAnamnesis,
        setSaveCurrentStepOnClose,
    } = props

    const {
        control,
        handleSubmit,
        watch,
        getValues,
        formState: { errors, isSubmitting },
    } = formMethods

    const stressManagement = watch('StressManagement')

    const handleSubmitWrapper = useCallback(
        async (isNext?: boolean) => {
            const formData = getValues()
            await submitForm(formData, isNext ? isNext : false)
        },
        [getValues, submitForm],
    )
    useEffect(() => {
        setSaveCurrentStepOnClose(() => handleSubmitWrapper)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSaveCurrentStepOnClose])
    if (isLoading) {
        return (
            <Box className='!mt-12 flex justify-center'>
                <CircularProgress />
            </Box>
        )
    }

    return (
        <form
            onSubmit={() => handleSubmitWrapper(true)}
            className='grid gap-3'
        >
            <MultipleChoiceRadioGroup
                control={control}
                readOnly={!canEditCareAnamnesis}
                label='Zvládání stresu'
                name='StressManagement'
                options={eStressManagementOptions}
                isSubmitting={isSubmitting}
            />

            {stressManagement !== undefined &&
                stressManagement === (otherStressManagementValue as any) && (
                    <InputStyled
                        control={control}
                        disabled={isSubmitting}
                        readOnly={!canEditCareAnamnesis}
                        name='StressManagementOther'
                        errors={errors}
                        label='Důvod:'
                        placeholder='důvod'
                    />
                )}

            <Stack spacing={2}>
                <AnamnesisFormFooter
                    currentStep={currentStep}
                    canEditCareAnamnesis={canEditCareAnamnesis}
                    disabled={isLoading || isSubmitting}
                    handleSubmitWrapper={handleSubmitWrapper}
                    setCurrentStep={setCurrentStep}
                />
            </Stack>
        </form>
    )
}

export const AddPatientAnamnesisFormStep9: FC<AddPatientsAnamnesisFormStep9Hook> =
    wrap(AddPatientAnamnesisFormStep9View, useAddPatientAnamnesisFormStep9)
