import { useOpenState } from '../../../hooks'
import type { TreatmentStep, TreatmentStepHookProps } from '../types'
import { useStepDataChange } from './useStepDataChange'

export const useTreatmentStep = (
    props: TreatmentStepHookProps,
): TreatmentStep => {
    const {
        visitForm,
        canEdit,
        canSave,
        carePlanIndex,
        carePlans,
        updateVisitOnClose,
    } = props

    const { isOpen, handleClose, handleOpen } = useOpenState()

    const isStepDataChanged = useStepDataChange({
        carePlans,
        carePlanIndex,
        visitForm,
    })

    const handleModalClose = () => {
        handleClose()
        !!canEdit && !!canSave && isStepDataChanged && updateVisitOnClose()
    }

    return {
        ...props,
        isOpen,
        handleOpen,
        isStepDataChanged,
        handleModalClose,
    }
}
