import {
    Box,
    CircularProgress,
    Grid,
    IconButton,
    SpeedDialAction,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import type { FC } from 'react'
import { useUIDSeed } from 'react-uid'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { ReorderItem } from './components'
import { VisitOrderPageProps } from './types'
import { MobileDatePicker } from '@mui/x-date-pickers'
import ArrowLeftRoundedIcon from '@mui/icons-material/ArrowLeftRounded'
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded'
import { HelpDial, Navigation } from '../../components'
import { todaysVisitsActions } from '../../components/HelpDial/HelpDialActions'
import { HelpSpeedDialActions } from '../../components/HelpDial/types'
import HomeIcon from '@mui/icons-material/Home'
import { useNavigate } from 'react-router-dom'

export const VisitOrderView: FC<VisitOrderPageProps> = (props) => {
    const {
        visitData,
        isVisitLoading,
        isVisitError,
        onDragEnd,
        reorderData,
        activeDate,
        handleAddDay,
        handleDateChange,
        handleSubtractDay,
    } = props

    const uid = useUIDSeed()
    const navigate = useNavigate()

    return (
        <Box
            component='main'
            className='relative min-h-[calc(100vh-64px)] bg-orange-50 py-2'
        >
            <Navigation>
                <Box className='flex items-center'>
                    <IconButton
                        onClick={() => navigate('/home')}
                        className='mr-1'
                    >
                        <HomeIcon style={{ color: '#faa26b' }} />
                    </IconButton>
                    <Typography
                        variant='h1'
                        className='text-xl font-medium sm:text-2xl'
                    >
                        Dnešní návštěvy
                    </Typography>
                </Box>
            </Navigation>
            <Box className='container mx-auto mt-4 lg:max-w-container'>
                <Grid
                    container
                    className='items-center pb-2'
                >
                    <Grid
                        item
                        xs={6}
                    >
                        <Typography className='text-sm font-medium'>
                            Počet návštěv: {visitData?.totalItems || 0}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        textAlign='right'
                    >
                        <Stack
                            direction='row'
                            marginLeft='auto'
                            marginRight='-1rem'
                            alignItems='center'
                        >
                            <IconButton onClick={handleSubtractDay}>
                                <ArrowLeftRoundedIcon
                                    fontSize='large'
                                    color='primary'
                                />
                            </IconButton>
                            <MobileDatePicker
                                views={['day']}
                                openTo='day'
                                value={activeDate}
                                toolbarTitle='Vyberte datum'
                                onChange={handleDateChange}
                                inputFormat='dd.MM.'
                                renderInput={(params) => (
                                    <TextField
                                        variant='standard'
                                        sx={{
                                            '.MuiInputBase-root::before, .MuiInputBase-root::after':
                                                {
                                                    display: 'none',
                                                },
                                            input: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        {...params}
                                    />
                                )}
                            />
                            <IconButton onClick={handleAddDay}>
                                <ArrowRightRoundedIcon
                                    fontSize='large'
                                    color='primary'
                                />
                            </IconButton>
                        </Stack>
                    </Grid>
                </Grid>
                <DragDropContext onDragEnd={onDragEnd}>
                    {isVisitLoading ? (
                        <Stack
                            py={6}
                            textAlign='center'
                            alignItems='center'
                            spacing={3}
                        >
                            <Typography>Načítáme obsah</Typography>
                            <CircularProgress sx={{ marginX: 'auto' }} />
                        </Stack>
                    ) : isVisitError ? (
                        <Box
                            component='div'
                            className='mb-2 rounded bg-white-reduced p-3'
                        >
                            <Typography className='text-sm font-medium'>
                                Chyba při načítání, zkuste se prosím znovu
                                přihlásit.
                            </Typography>
                        </Box>
                    ) : reorderData?.length ? (
                        <Droppable droppableId='visitOrder'>
                            {(provided) => (
                                <Stack
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {reorderData?.map((visit, index) => (
                                        <ReorderItem
                                            key={uid(visit)}
                                            index={index}
                                            isLoading={isVisitLoading}
                                            {...visit}
                                        />
                                    ))}
                                    {provided.placeholder}
                                </Stack>
                            )}
                        </Droppable>
                    ) : (
                        <Stack
                            justifyContent='center'
                            direction='row'
                            spacing={2}
                        >
                            <Typography className='pt-1 text-sm'>
                                Zdá se, že tento den nemáte žádné návštěvy.
                            </Typography>
                            <Box className='relative w-[21px]'>
                                <Box className='h-[2px] w-[100%] rounded bg-gray-300' />
                                <Box className='absolute right-0 h-[16px] w-[2px] rounded bg-gray-300' />
                            </Box>
                        </Stack>
                    )}
                </DragDropContext>
                <HelpDial>
                    {({ handleActionClick }) => {
                        return todaysVisitsActions.map(
                            ({
                                name,
                                icon,
                                modalName,
                            }: HelpSpeedDialActions) => (
                                <SpeedDialAction
                                    key={uid(modalName)}
                                    icon={icon}
                                    tooltipTitle={name}
                                    tooltipPlacement='right'
                                    tooltipOpen
                                    sx={{
                                        '& .MuiSpeedDialAction-staticTooltipLabel':
                                            {
                                                width: 'max-content',
                                            },
                                    }}
                                    onClick={handleActionClick(modalName)}
                                />
                            ),
                        )
                    }}
                </HelpDial>
            </Box>
        </Box>
    )
}
