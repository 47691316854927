import {
    Box,
    ClickAwayListener,
    IconButton,
    InputLabel,
    Modal,
    OutlinedInput,
    Paper,
    Stack,
} from '@mui/material'
import { debounce } from 'lodash'
import { useRef, useState, type MouseEvent, useMemo } from 'react'
import { useSearchVisit } from '../api'
import { CarouselItem } from '../pages/HomePage/components'
import { Cancel, Close } from '@mui/icons-material'
import { ContentLoader } from './ContentLoader'
import { ContentError } from './ContentError'
import type { PatientSearchProps } from '../types'
import { useLocation, useNavigate } from 'react-router-dom'
import { useUIDSeed } from 'react-uid'

export const PatientSearch = (props: PatientSearchProps) => {
    const { isOpen, handleClose, location } = props
    const [searchValue, setSearchValue] = useState('')
    const currentHrefLocation = useLocation()
    const navigate = useNavigate()
    const uid = useUIDSeed()
    const pathName = useMemo(
        () => currentHrefLocation.pathname.split('/')?.[1],
        [currentHrefLocation.pathname],
    )

    const searchRef = useRef<HTMLInputElement>(null)

    const debouncedSearch = debounce((value: string) => {
        setSearchValue(value)
    }, 500)

    const { data: visitSearch, isLoading: searchLoading } =
        useSearchVisit(searchValue)

    const handleClearSearch = () => {
        setSearchValue('')
        if (searchRef.current) searchRef.current.value = ''
    }

    const modalClose = (e: MouseEvent) => {
        e.preventDefault()
        handleClose()
    }

    const handlePageRefresh = () => {
        pathName === 'visit-detail' && navigate(0)
    }

    return (
        <>
            <Modal
                open={isOpen}
                className='mx-auto max-w-container'
            >
                <ClickAwayListener
                    onClickAway={(e) => {
                        e.preventDefault()
                        handleClose()
                    }}
                >
                    <Box className='absolute max-h-[70vh] w-full overflow-y-auto rounded-b'>
                        <Paper
                            className=''
                            sx={{
                                padding: '36px 20px 24px',
                                background: '#feefe6',
                                borderTopRightRadius: '0px',
                                borderTopLeftRadius: '0px',
                                borderBottomRightRadius: '6px',
                                borderBottomLeftRadius: '6px',
                            }}
                        >
                            <IconButton
                                size='medium'
                                className='absolute right-4 top-3 z-10'
                                onClick={modalClose}
                            >
                                <Close
                                    fontSize='inherit'
                                    color='inherit'
                                />
                            </IconButton>
                            <Stack gap='1.25rem'>
                                <Stack>
                                    <InputLabel className='py-1 pb-2 text-sm font-medium'>
                                        Vyhledání pacienta
                                    </InputLabel>
                                    <Box className='flex w-full items-center gap-2'>
                                        <OutlinedInput
                                            size='medium'
                                            className='flex-1 bg-white-reduced'
                                            inputRef={searchRef}
                                            inputProps={{
                                                className:
                                                    'px-2 py-1 font-medium rounded text-sm',
                                                placeholder:
                                                    'Zde zadejte jméno nebo RČ pacienta...',
                                            }}
                                            onChange={({ target }) =>
                                                debouncedSearch(target.value)
                                            }
                                            endAdornment={
                                                <IconButton
                                                    size='small'
                                                    className='rounded'
                                                    onClick={handleClearSearch}
                                                    color='default'
                                                >
                                                    <Cancel
                                                        fontSize='inherit'
                                                        color='inherit'
                                                    />
                                                </IconButton>
                                            }
                                        />
                                    </Box>
                                </Stack>
                                {searchLoading && searchValue !== '' && (
                                    <ContentLoader />
                                )}
                                {!searchLoading &&
                                    !!visitSearch?.data.length && (
                                        <Stack gap='1.25rem'>
                                            {(searchLoading
                                                ? Array.from(new Array(3))
                                                : visitSearch?.data
                                            )?.map((visit) => (
                                                <CarouselItem
                                                    hasDate
                                                    key={uid(
                                                        visit?.id ||
                                                            Math.random(),
                                                    )}
                                                    visitCardClick={() => {
                                                        handleClose()
                                                        handlePageRefresh()
                                                    }}
                                                    latLng={location.latLng}
                                                    isLoading={searchLoading}
                                                    sx={{
                                                        height: 'auto',
                                                    }}
                                                    {...visit}
                                                />
                                            ))}
                                        </Stack>
                                    )}
                                {!searchLoading &&
                                    !visitSearch?.data.length &&
                                    searchValue !== '' && (
                                        <ContentError errorText='Pacienta se nám nepodařilo najít. Zkontrolujte prosím vyhledávání.' />
                                    )}
                            </Stack>
                        </Paper>
                    </Box>
                </ClickAwayListener>
            </Modal>
        </>
    )
}
