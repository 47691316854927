import { useState } from 'react'
import { useOpenState } from '../../../hooks'
import type { BulletinPageProps, UseBulletinPageHookProps } from '../types'

export const usePage = (props: UseBulletinPageHookProps): BulletinPageProps => {
    const newBulletinModalState = useOpenState()
    const [isMultiEditMode, setIsMultiEditMode] = useState(false)

    return { newBulletinModalState, isMultiEditMode, setIsMultiEditMode }
}
