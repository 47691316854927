import axios from 'axios'
import { getRestApiConfig } from './config'

interface Position {
    lon: number
    lat: number
}

interface RegionalStructure {
    name: string
    type: string
    isoCode: string | null
}

export interface MapyCZAdress {
    name: string
    label: string
    position: Position
    type: string
    location: string
    regionalStructure: RegionalStructure[]
    zip: string
}

export const getMapyCZSuggestions = async (query: string, token?: string) => {
    try {
        if (!query || query.length < 3) {
            return []
        }
        const { baseUrl, headerConfig } = getRestApiConfig(token)

        const encodedQuery = encodeURIComponent(query)

        const endpointUrl = new URL(
            `/api-mob/Maps/Hint?query=${encodedQuery}`,
            baseUrl,
        )

        const response = await axios.get(endpointUrl.href, headerConfig)

        return response.data
    } catch (error) {
        console.error('Error fetching data:', error)
        return []
    }
}
