import { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDocumentMutation } from '../../../api'
import { useCustomSnackbar, useOpenState } from '../../../hooks'
import { useHelpCamera } from '../../../hooks/useHelpCamera'

export const useDocumentUpload = () => {
    const { visitId } = useParams<{ visitId: string }>()
    const documentMutation = useDocumentMutation(visitId)
    const { cameraPermission } = useHelpCamera()
    const [fileIsUploading, setFileIsUploading] = useState(false)

    const [newUploadedFileName, setNewUploadedFileName] = useState<string>('')

    const [fileData, setFileData] = useState<{
        name: string
        filename: string
        data: string
    } | null>(null)

    const {
        isOpen: isPreviewOpen,
        handleClose: handleClosePreview,
        handleOpen: handleOpenPreview,
    } = useOpenState()

    const fileInputRef = useRef<HTMLInputElement | null>(null)
    const customSnackbar = useCustomSnackbar()

    const handleSendUpload = async () => {
        if (!fileData) return
        setFileIsUploading(true)

        const now = new Date()
        const dateForFilename = now
            .toLocaleDateString('en-GB')
            .replace(/\//g, '')
        const timeForFilename = now
            .toTimeString()
            .split(' ')[0]
            .replace(/:/g, '')
        const dateForName = now.toISOString().slice(0, 10)
        const timeForName = now.toTimeString().split(' ')[0].replace(/:/g, '')

        const fileExtension = fileData.filename.split('.').pop()
        const uniqueFilename = `${newUploadedFileName}-${dateForFilename}${timeForFilename}.${fileExtension}`
        const humanReadableName = `${newUploadedFileName} ${dateForName} ${timeForName}`

        const sendFileData = {
            ...fileData,
            name: humanReadableName,
            filename: uniqueFilename,
        }

        setFileIsUploading(true)
        try {
            await documentMutation.mutateAsync(sendFileData)
        } finally {
            setFileIsUploading(false)
            handleClosePreview()
        }
    }

    const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0]

        if (!file) return

        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = async () => {
            const fileData = {
                name: file.name.split('.')[0],
                filename: file.name,
                data: reader.result as string,
            }

            setFileData(fileData)
            setNewUploadedFileName(fileData.name)
            handleOpenPreview()
        }
    }

    const handleButtonClick = async () => {
        if (cameraPermission === 'denied') {
            customSnackbar(
                'Nebyl udělen přístup k Vaší kameře. V případě, že budete chtít vložit obrázek, vyberte jej z galerie nebo v nastavení Vašeho telefonu udělte prohlížeči náležité oprávnění.',
                'warning',
                true,
            )
        }

        fileInputRef.current?.click()
    }

    return {
        handleFileUpload,
        uploadLoading: fileIsUploading,
        fileData,
        handleButtonClick,
        newUploadedFileName,
        isPreviewOpen,
        handleClosePreview,
        setNewUploadedFileName,
        handleSendUpload,

        fileInputRef,
    }
}
