import { NoteAddOutlined } from '@mui/icons-material'
import { IconButton, Stack, Typography } from '@mui/material'
import { Modal } from '../../../../components'
import { NoteModalProps } from '../../types'
import { NewNoteModal } from './NewNoteModal'
import { NoteCard } from './NoteCard'
import { useUIDSeed } from 'react-uid'

export const NotesModal = (props: NoteModalProps) => {
    const { noteModal, newNoteModal, patientNotesQuery, newNoteForm } = props
    const handleNewNoteModalOpen = () => newNoteModal.handleOpen()

    const uid = useUIDSeed()
    return (
        <Modal
            open={noteModal.isOpen}
            onClose={noteModal.handleClose}
            handleClose={noteModal.handleClose}
            title='Poznámky k pacientovi'
        >
            <>
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    pb={2}
                >
                    <Typography fontSize='14px'>
                        počet: {patientNotesQuery.data?.totalItems || 0}
                    </Typography>
                    <IconButton
                        size='small'
                        onClick={handleNewNoteModalOpen}
                    >
                        <NoteAddOutlined />
                    </IconButton>
                    <NewNoteModal
                        newNoteForm={newNoteForm}
                        modalState={newNoteModal}
                    />
                </Stack>
                <Stack>
                    {patientNotesQuery.data?.data.map((note) => (
                        <NoteCard
                            key={uid(note.id)}
                            note={note}
                        />
                    ))}
                </Stack>
            </>
        </Modal>
    )
}
