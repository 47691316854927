import { useState, useCallback, useEffect } from 'react'

const useResizePage = (): {
    isMobile: boolean
    isTablet: boolean
    isDesktop: boolean
} => {
    const [width, setWidth] = useState(window.innerWidth)

    const handleResize = useCallback(() => {
        setWidth(window.innerWidth)
    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [handleResize])

    return {
        isMobile: width < 768,
        isTablet: width < 1024 && width >= 768,
        isDesktop: width >= 1024,
    }
}
export default useResizePage
