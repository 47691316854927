import { Stack, Typography } from '@mui/material'
import Modal from '../Modal'
import { UseHelpModalProps } from './types'

function MedicationNotableModal(props: UseHelpModalProps) {
    return (
        <Modal
            {...props}
            title='Významné údaje o současné medikaci'
        >
            <Stack className='gap-3'>
                <Stack className='gap-1'>
                    <Typography className='text-sm'>
                        Údaje zapsané na poukazu.
                    </Typography>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default MedicationNotableModal
