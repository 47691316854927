import type {
    VisitFormData,
    VisitFormDataProps,
} from './../../pages/VisitDetailPage/types'
import type {
    HealtActionCollection,
    ListOfVisitsQuery,
    ListOfVisitsResponse,
    VisitOrderItem,
} from './types'
import { getRestApiConfig } from '../config'
import axios from 'axios'
import type { VisitNurseDetails } from '../../types'
import { toQueryString } from '../../helpers'

export enum ListType {
    'normal' = 'VisitNurseList',
    'returned' = 'ReturnedVisits',
    'toComplete' = 'ToCompleteVisits',
}

export const getListOfVisits = async (
    query?: ListOfVisitsQuery,
    token?: string,
    listType?: keyof typeof ListType,
): Promise<ListOfVisitsResponse> => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const endpointUrl = new URL(
        `/api-mob/ListOfVisits/${ListType[listType || 'normal']}${
            listType === 'normal' ? '?' + toQueryString({ ...query }) : ''
        }`,
        baseUrl,
    ).href

    const response = await axios.get<ListOfVisitsResponse>(
        endpointUrl,
        headerConfig,
    )

    return response.data
}

export const getVisitDetails = async (
    visitId?: number,
    token?: string,
): Promise<VisitNurseDetails> => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const endpointUrl = new URL(
        `/api-mob/VisitDetails/VisitNurseDetails/${visitId}`,
        baseUrl,
    ).href

    const response = await axios.get<VisitNurseDetails>(
        endpointUrl,
        headerConfig,
    )

    return response.data
}

export const putVisitOrder = async (
    data: VisitOrderItem[],
    dateAt: string,
    token?: string,
) => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const endpointUrl = new URL(
        `/api-mob/ListOfVisits/VisitNurseListOrder?dateAt=${dateAt}`,
        baseUrl,
    ).href

    const response = await axios.put(endpointUrl, data, headerConfig)

    return response.data
}

export const updateVisitDetails = async (
    data?: VisitFormDataProps,
    visitId?: number,
    token?: string,
): Promise<VisitNurseDetails> => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const endpointUrl = new URL(
        `/api-mob/VisitDetails/VisitNurseDetails/${visitId}`,
        baseUrl,
    ).href

    const dataRequest = data?.data as VisitFormDataProps | undefined
    const response = await axios.put<VisitNurseDetails>(
        endpointUrl,
        dataRequest,
        headerConfig,
    )

    return response.data
}

export const stornoVisit = async (
    reasonNonDone: string,
    visitId?: number,
    token?: string,
) => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const endpointUrl = new URL(
        `/api-mob/VisitDetails/StornoVisit/${visitId}`,
        baseUrl,
    ).href

    const response = await axios.put(
        endpointUrl,
        { reasonNonDone },
        headerConfig,
    )

    return response.data
}

export const sendVisit = async (
    data?: VisitFormData,
    visitId?: number,
    token?: string,
) => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const endpointUrl = new URL(
        `/api-mob/VisitDetails/SendVisit/${visitId}`,
        baseUrl,
    ).href

    const response = await axios.put(endpointUrl, data, headerConfig)

    return response.data
}

export const getHealtAction = async (
    visitId?: number,
    token?: string,
    specialCodeId?: number,
): Promise<HealtActionCollection> => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const endpointUrl = new URL(
        `/api-mob/VisitDetails/HealthAction/${visitId}?specialCodeId=${specialCodeId}`,
        baseUrl,
    ).href

    const response = await axios.get<HealtActionCollection>(
        endpointUrl,
        headerConfig,
    )

    return response.data
}

export const searchVisits = async (
    searchQuery: string,
    token?: string,
): Promise<ListOfVisitsResponse> => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const endpointUrl = new URL(
        `/api-mob/search?search=${searchQuery}`,
        baseUrl,
    ).href

    const response = await axios.get<ListOfVisitsResponse>(
        endpointUrl,
        headerConfig,
    )

    return response.data
}
