import { Box, IconButton, Modal, Typography } from '@mui/material'
import { useOpenState } from '../../hooks'
import CloseIcon from '@mui/icons-material/Close'
import { FileProps } from '../Bulletin/BulletinUpload'

export const ErrorImage = ({ imageSrc, name }: FileProps) => {
    const { isOpen, stateSwitch, handleClose } = useOpenState()

    return (
        <>
            <Box
                onClick={stateSwitch}
                className='flex w-full flex-col items-center gap-2'
            >
                <img
                    width={55}
                    src={imageSrc}
                    alt={'priloha'}
                />
                {/*
                
                    {name && (
                    <Typography className='break-all text-center text-[0.75rem]'>
                        {name}
                    </Typography>
                )}
                */}
            </Box>

            <Box className='flex flex-col items-center text-center'>
                <Modal
                    open={isOpen}
                    onClose={stateSwitch}
                    onClick={handleClose}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box onClick={(e) => e.stopPropagation()}>
                        <Box sx={{ position: 'relative' }}>
                            <Box className='absolute right-5 top-5 z-10  justify-end text-right'>
                                <IconButton
                                    className='ml-auto bg-orange-400 text-white-reduced'
                                    onClick={handleClose}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <img
                                src={imageSrc}
                                style={{ maxWidth: '90vw', maxHeight: '90vh' }}
                                alt={'příloha'}
                            />
                        </Box>
                    </Box>
                </Modal>
            </Box>
        </>
    )
}
