import { Box, Button, Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { Page404Props } from './types'
import { useNavigate } from 'react-router-dom'
import { Navigation } from '../../components'

export const Page404View: FC<Page404Props> = (props) => {
    const navigate = useNavigate()

    return (
        <Box
            component='main'
            className='min-h-[calc(100vh-64px)] bg-orange-50 py-2'
        >
            <Navigation>
                <Typography
                    variant='h1'
                    className='text-2xl font-medium'
                >
                    Stránka nenalezena
                </Typography>
            </Navigation>
            <Box className='container mx-auto'>
                <Stack
                    justifyContent='center'
                    spacing={2}
                    className='pb-8'
                >
                    <Typography
                        variant='h1'
                        className='text-center font-bold'
                    >
                        404
                    </Typography>
                    <Typography className='pt-1 text-center text-sm font-medium'>
                        Zdá se, že stránka, kterou se snažíte najít neexistuje.
                        Můžete přejít na domovskou stránku a zkusit více štěstí
                        odtud.
                    </Typography>
                </Stack>
                <Box className='text-center'>
                    <Button
                        variant='contained'
                        className='lowercase text-white-reduced shadow-none'
                        size='small'
                        onClick={() => navigate('/home')}
                    >
                        Přejít na hlavní stránku
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
