import { PatientContext } from './../../../providers/PatientProvider'
import { useContext } from 'react'
import { useVisitQuery } from '../../../api/visit/hooks'
import { AuthContext } from '../../../providers'
import { useMonthHandle, useVisitMonthCollection } from '../../../hooks'
import { useQueryClient } from '@tanstack/react-query'

export const usePatientHistory = () => {
    const { token } = useContext(AuthContext)
    const { activePatient } = useContext(PatientContext)
    const queryClient = useQueryClient()

    const {
        activeMonthDate,
        handleDateChange,
        handleSubtractMonth,
        handleAddMonth,
        firstDayOfMonth,
        lastDayOfMonth,
    } = useMonthHandle()

    const visitParams = {
        patientId: activePatient?.id,
        dateFrom: firstDayOfMonth(activeMonthDate || new Date()),
        dateTo: lastDayOfMonth(activeMonthDate || new Date(), false),
    }

    const visitList = useVisitQuery(visitParams, token, !!!activePatient?.id)

    const visitCollection = useVisitMonthCollection({
        collectionData: visitList?.data?.data,
        isVisitLoading: visitList.isLoading,
        nearestElementAvailable: true,
    })

    const handleRefetchQuery = () => {
        queryClient.refetchQueries(['visit', visitParams])
    }

    return {
        activeMonthDate,
        handleDateChange,
        handleSubtractMonth,
        handleAddMonth,
        visitList,
        handleRefetchQuery,
        ...visitCollection,
    }
}
