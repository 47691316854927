import { Stack, Typography } from '@mui/material'
import Modal from '../Modal'
import { UseHelpModalProps } from './types'

function ObsluhaKontaktuModal(props: UseHelpModalProps) {
    return (
        <Modal
            {...props}
            title='Obsluha kontaktů'
        >
            <Stack className='gap-3'>
                <Stack className='gap-1'>
                    <Typography className='text-sm'>
                        Kliknutím na příslušnou kartu s kontaktem lze zahájit
                        hovor.
                    </Typography>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default ObsluhaKontaktuModal
