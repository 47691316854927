import {
    Box,
    Button,
    ButtonGroup,
    colors,
    Stack,
    TextField,
} from '@mui/material'
import { Controller } from 'react-hook-form'
import { Modal } from '../../../../components'
import type { NoteTypeFor } from '../../../../types'
import type { NewNoteModalProps } from '../../types'

export const NewNoteModal = ({
    modalState,
    newNoteForm,
}: NewNoteModalProps) => {
    const {
        formMethods: {
            control,
            handleSubmit,
            watch,
            formState: { errors },
        },
        submitNote,
        handleNoteTypeChange,
    } = newNoteForm

    const noteType = watch('noteType')

    const getVariant = (type: NoteTypeFor) => {
        return noteType === type ? 'contained' : 'outlined'
    }

    const getColor = (type: NoteTypeFor) => {
        return noteType === type ? '#f8f8f8' : colors.grey[800]
    }

    return (
        <Modal
            open={modalState.isOpen}
            onClose={modalState.handleClose}
            handleClose={modalState.handleClose}
            title='Nová poznámka'
        >
            <Stack
                justifyContent='center'
                width='100%'
            >
                <ButtonGroup
                    variant='outlined'
                    sx={{
                        marginX: 'auto',
                        background: '#fff',
                        marginBottom: 2,
                    }}
                >
                    <Button
                        size='small'
                        sx={{
                            fontSize: 12,
                            color: getColor('General'),
                            textTransform: 'none',
                        }}
                        onClick={handleNoteTypeChange('General')}
                        variant={getVariant('General')}
                    >
                        Všeobecná
                    </Button>
                    <Button
                        size='small'
                        sx={{
                            fontSize: 12,
                            color: getColor('Doctor'),
                            textTransform: 'none',
                        }}
                        onClick={handleNoteTypeChange('Doctor')}
                        variant={getVariant('Doctor')}
                    >
                        Pro lékaře
                    </Button>
                    <Button
                        size='small'
                        sx={{
                            fontSize: 12,
                            color: getColor('Nurse'),
                            textTransform: 'none',
                        }}
                        onClick={handleNoteTypeChange('Nurse')}
                        variant={getVariant('Nurse')}
                    >
                        Pro sestru
                    </Button>
                </ButtonGroup>
                <form onSubmit={handleSubmit(submitNote)}>
                    <Controller
                        control={control}
                        name='newNote'
                        render={({ field }) => (
                            <TextField
                                {...field}
                                placeholder='Zde napište poznámku...'
                                variant='outlined'
                                type='text'
                                multiline
                                fullWidth
                                size='small'
                                rows={6}
                                error={errors.newNote !== undefined}
                                helperText={errors.newNote?.message}
                                InputProps={{
                                    sx: {
                                        fontSize: '14px',
                                        color: colors.grey[800],
                                        background: '#f8f8f8',
                                    },
                                }}
                                sx={{
                                    marginBottom: 2,
                                }}
                            />
                        )}
                    />
                    <Box textAlign='right'>
                        <Button
                            variant='contained'
                            size='small'
                            type='submit'
                            sx={{
                                color: colors.grey[50],
                                fontSize: 12,
                                textTransform: 'none',
                            }}
                        >
                            odeslat a zavřít
                        </Button>
                    </Box>
                </form>
            </Stack>
        </Modal>
    )
}
