import { FC, useCallback, useEffect } from 'react'
import { wrap } from '../../../utils'
import { useAddPatientAnamnesisFormStep8 } from '../hooks/useAddPatientAnamnesisFormStep8'
import {
    AddPatientsAnamnesisFormStep8Hook,
    IAddPatientAnamnesisFormStep8,
} from '../types'
import { Box, CircularProgress, Stack } from '@mui/material'
import { AnamnesisFormFooter } from './AnamnesisFormFooter'
import YesNoRadioGroup from '../../../components/YesNoRadioGroup'
import InputStyled from '../../../components/InputStyled'

const AddPatientAnamnesisFormStep8View = (
    props: IAddPatientAnamnesisFormStep8,
) => {
    const {
        submitForm,
        formMethods,
        setCurrentStep,
        currentStep,
        isLoading,
        canEditCareAnamnesis,
        setSaveCurrentStepOnClose,
    } = props

    const {
        control,
        handleSubmit,
        watch,
        getValues,
        formState: { errors, isSubmitting },
    } = formMethods

    const isSexuality = watch('IsSexuality')

    const handleSubmitWrapper = useCallback(
        async (isNext?: boolean) => {
            const formData = getValues()
            await submitForm(formData, isNext ? isNext : false)
        },
        [getValues, submitForm],
    )
    useEffect(() => {
        setSaveCurrentStepOnClose(() => handleSubmitWrapper)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSaveCurrentStepOnClose])

    if (isLoading) {
        return (
            <Box className='!mt-12 flex justify-center'>
                <CircularProgress />
            </Box>
        )
    }

    return (
        <form
            onSubmit={() => handleSubmitWrapper(true)}
            className='grid gap-3'
        >
            <YesNoRadioGroup
                control={control}
                name='IsSexuality'
                readOnly={!canEditCareAnamnesis}
                label='Chcete nám něco sdělit k dané problematice?'
                disabled={isSubmitting}
            />

            {isSexuality && (
                <InputStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='Sexuality'
                    errors={errors}
                    label=''
                    placeholder='problém zde'
                />
            )}

            <Stack spacing={2}>
                <AnamnesisFormFooter
                    currentStep={currentStep}
                    canEditCareAnamnesis={canEditCareAnamnesis}
                    disabled={isLoading || isSubmitting}
                    handleSubmitWrapper={handleSubmitWrapper}
                    setCurrentStep={setCurrentStep}
                />
            </Stack>
        </form>
    )
}

export const AddPatientAnamnesisFormStep8: FC<AddPatientsAnamnesisFormStep8Hook> =
    wrap(AddPatientAnamnesisFormStep8View, useAddPatientAnamnesisFormStep8)
