import {
    Box,
    IconButton,
    Paper,
    Skeleton,
    Stack,
    Typography,
} from '@mui/material'
import {
    ConfirmationNumber,
    LocationOnRounded,
    NearMeRounded,
    PhoneEnabledRounded,
} from '@mui/icons-material'
import { twMerge } from 'tailwind-merge'

import { ReactComponent as NurseIcon } from '../assets/icons/nurse.svg'
import { ReactComponent as InsuranceIcon } from '../assets/icons/insurance.svg'
import { ReactComponent as DoctorIcon } from '../assets/icons/doctor.svg'
import { PatientCardComponentProps } from '../pages/PatientsPage/types'
import { usePhoneLocation } from '../hooks/usePhoneLocation'
import classnames from 'classnames'
import { formatAdress, formatPhoneNumber } from '../helpers/formatting'
import { PatientStateIcon } from './PatientStateIcon'
import PatientCardMenu from '../pages/PatientsPage/components/PatientCardMenu'

export const PatientCard = (props: PatientCardComponentProps) => {
    const {
        isLoading,
        className,
        onClick,
        setSelectedPatient,
        latLng,
        patient,
        handleOpenCareAnamneses,
    } = props

    const {
        fullName,
        state,
        phone,
        permanentAddress,
        id,
        doctorName,
        nurseName,
        nurseNote,
        personalNumber,
        voucherValidTo,
        insuranceCompanyID,
    } = patient

    const { handleLocationButtonClick, handlePhoneButtonClick } =
        usePhoneLocation(phone, permanentAddress, latLng)

    const formattedPhone = formatPhoneNumber(phone)
    const formattedAddress = formatAdress(permanentAddress)

    return (
        <Paper
            onClick={onClick}
            className={twMerge(
                'relative h-full w-full  rounded bg-white-reduced p-3 shadow-md transition hover:shadow-md',

                className,
            )}
        >
            <Stack
                className='relative gap-1 pb-4'
                sx={{
                    '&::before': {
                        position: 'absolute',
                        content: '""',
                        bottom: '8px',
                        left: 0,
                        width: '91%',
                        height: '1px',
                        backgroundColor: '#3333331F',
                    },
                }}
            >
                <Stack
                    direction='row'
                    className='items-center justify-between'
                >
                    {isLoading ? (
                        <Skeleton
                            animation='wave'
                            className='text-xl font-medium'
                            width='60%'
                        />
                    ) : (
                        <Box className='flex items-center gap-2'>
                            <Stack
                                className={twMerge(
                                    'h-[1.625rem] w-[1.625rem] items-center justify-center',
                                    isLoading && 'border-gray-400',
                                )}
                            >
                                {isLoading ? (
                                    <Skeleton
                                        animation='wave'
                                        variant='rectangular'
                                        width={28}
                                        height={28}
                                        className='rounded'
                                    />
                                ) : (
                                    <PatientStateIcon state={state} />
                                )}
                            </Stack>
                            <Typography className='text-xl font-medium'>
                                {fullName && fullName?.trim()?.length > 0
                                    ? fullName
                                    : 'neuvedeno'}
                            </Typography>
                        </Box>
                    )}

                    {props?.patient?.canReadCareAnamnesis && (
                        <PatientCardMenu
                            patient={patient}
                            canReadCareAnamnesis={
                                props?.patient?.canReadCareAnamnesis
                            }
                            setSelectedPatient={setSelectedPatient}
                            handleOpenCareAnamneses={handleOpenCareAnamneses}
                        />
                    )}
                </Stack>

                <Stack
                    direction='row'
                    className='items-center gap-[6px]'
                >
                    <Stack className='h-6 w-6 items-center justify-center'>
                        <DoctorIcon className='h-5 w-5' />
                    </Stack>

                    <Typography className='text-sm text-gray-500'>
                        {doctorName ?? 'neuvedeno'}
                    </Typography>

                    <Stack className='items-center justify-center'>
                        <Box className='h-2 w-px bg-gray-500' />
                    </Stack>

                    <Stack className='h-6 w-6 items-center justify-center'>
                        <NurseIcon className='h-5 w-5' />
                    </Stack>
                    <Typography className='text-sm text-gray-500'>
                        {nurseName ?? 'neuvedeno'}
                    </Typography>
                </Stack>

                <Stack
                    direction='row'
                    className='gap-[6px]'
                >
                    <Stack
                        direction='row'
                        className='items-center gap-[6px]'
                    >
                        <Stack className='h-6 w-6 items-center justify-center'>
                            <InsuranceIcon className='h-5 w-5' />
                        </Stack>
                        <Typography className='text-sm text-gray-500'>
                            {insuranceCompanyID}
                        </Typography>
                    </Stack>
                    <Stack className='items-center justify-center'>
                        <Box className='h-2 w-px bg-gray-500' />
                    </Stack>
                    <Stack
                        direction='row'
                        className='items-center gap-1 text-gray-500'
                    >
                        <ConfirmationNumber className='text-inherit w-5' />
                        <Typography className='text-sm text-gray-500'>
                            {voucherValidTo &&
                            !isNaN(new Date(voucherValidTo).getTime())
                                ? new Date(voucherValidTo).toLocaleDateString()
                                : 'neuvedeno'}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>

            <Stack
                spacing={0.5}
                className='max-w-[70%]'
            >
                {isLoading ? (
                    <>
                        <Skeleton
                            animation='wave'
                            height={12}
                            width='70%'
                        />
                        <Skeleton
                            animation='wave'
                            height={12}
                            width='50%'
                        />
                    </>
                ) : (
                    <>
                        <Stack
                            direction='row'
                            className='gap-1'
                        >
                            <LocationOnRounded className='text-base text-gray-500' />
                            <Typography className='truncate text-xs text-gray-500'>
                                {formattedAddress}
                            </Typography>
                        </Stack>
                        <Stack
                            direction='row'
                            className='gap-1'
                        >
                            <PhoneEnabledRounded className='text-base text-gray-500' />
                            <Typography className='truncate text-xs text-gray-500'>
                                {!!formattedPhone?.length
                                    ? formattedPhone
                                    : 'neuvedeno'}
                            </Typography>
                        </Stack>
                    </>
                )}
            </Stack>
            <Stack
                className='absolute -bottom-2 -right-2 gap-2'
                direction='row'
            >
                {isLoading ? (
                    <Skeleton
                        variant='rectangular'
                        width={68}
                        height={68}
                        className='rounded'
                        animation='wave'
                    />
                ) : (
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation()
                            handleLocationButtonClick(e)
                        }}
                        className='h-[3.0625rem] w-[3.0625rem] rounded bg-orange-300 py-4 text-white-reduced transition-colors hover:bg-orange-400'
                    >
                        <NearMeRounded className='text-3xl' />
                    </IconButton>
                )}
                {isLoading ? (
                    <Skeleton
                        variant='rectangular'
                        width={68}
                        height={68}
                        className='rounded'
                        animation='wave'
                    />
                ) : (
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation()
                            handlePhoneButtonClick(e)
                        }}
                        className={classnames(
                            'h-[3.0625rem] w-[3.0625rem] rounded bg-orange-300 py-4 text-white-reduced transition-colors hover:bg-orange-400',
                            {
                                'bg-gray-500 opacity-50 focus:bg-gray-500':
                                    !!!formattedPhone,
                            },
                        )}
                    >
                        <PhoneEnabledRounded className='text-3xl' />
                    </IconButton>
                )}
            </Stack>
        </Paper>
    )
}
