import type { SortedVisitProps } from '../../types'
import { SortedCollection } from './SortedCollection'
import { SortedEmpty } from './SortedEmpty'
import { ContentLoader } from '../ContentLoader'

export const SortedVisit = (props: SortedVisitProps) => {
    const { isLoading, hasEntries, loadingText, emptyText } = props

    return isLoading ? (
        <ContentLoader loadingText={loadingText} />
    ) : hasEntries ? (
        <SortedCollection {...props} />
    ) : (
        <SortedEmpty emptyText={emptyText} />
    )
}
