import axios from 'axios'
import { getRestApiConfig } from '../config'
import { HelpErrorForm } from '../../components/HelpDial/types'

type PostFaultReportProps = {
    data: HelpErrorForm
    token?: string
}

export const postFaultReport = async (props: PostFaultReportProps) => {
    const { token, data } = props

    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const endpointUrl = new URL(`/api-mob/FaultReport`, baseUrl).href

    const body = { ...data }

    const response = await axios.post(endpointUrl, body, headerConfig)

    return response.data
}
