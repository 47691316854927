import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    InputLabel,
    Modal,
    OutlinedInput,
    Stack,
    Typography,
} from '@mui/material'
import type { PatientDocument } from '../../../../types'
import { useDocumentFile } from '../../hooks/useDocumentFile'
import { useOpenState } from '../../../../hooks'
import { ReactComponent as StornoImage } from '../../../../assets/icons/undefined.svg'
import CloseIcon from '@mui/icons-material/Close'
import classNames from 'classnames'
import { usePatientDocument } from './hooks/usePatientDocument'
import { Controller } from 'react-hook-form'
import { DocumentItemMenu } from './DocumentItemMenu'
import { useContext } from 'react'
import { AuthContext } from '../../../../providers'

export const DocumentItem = ({
    id,
    name,
    filename,
    patientId,
    author,
    isError = false,
}: PatientDocument) => {
    const { user } = useContext(AuthContext)
    const { isOpen, stateSwitch, handleClose } = useOpenState()

    const { isOpen: isOpenDescription, stateSwitch: stateSwitchFilename } =
        useOpenState()

    const {
        documentImage,
        thumbError,
        documentFile,
        openPdf,
        isPdf,
        refetchImage,
    } = useDocumentFile({
        id,
        filename,
        isOpen,
    })

    const {
        rotation,
        imgStyles,
        handleRotate,
        formMethods,
        submitMessage,
        handleDeleteDocument,
        submitChangeRotation,
    } = usePatientDocument({
        name,
        patientId,
        documentId: parseInt(id),
        refetchImage,
        handleCloseParent: handleClose,
        closeFileNameModal: stateSwitchFilename,
    })

    const switchAndUpdateName = () => {
        formMethods.setValue('name', name)
        stateSwitchFilename()
    }

    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = formMethods

    const source =
        'https://via.placeholder.com/250x350/f8833b/f8f8f8.png?text=PDF'

    return (
        <>
            <Box
                className='flex flex-col items-center text-center'
                onClick={(e) =>
                    isPdf ? openPdf(e, documentFile.data) : stateSwitch()
                }
            >
                {thumbError ? (
                    <StornoImage height='70px' />
                ) : (
                    <img
                        src={
                            isPdf
                                ? 'https://via.placeholder.com/55x70/f8833b/f8f8f8.png?text=PDF'
                                : isError
                                ? 'https://via.placeholder.com/55x70/f8833b/f8f8f8.png?text=Obrázek+nenačten'
                                : documentImage
                        }
                        alt='Dokument pacienta'
                        width='55'
                        height='70'
                        className='mb-1 rounded object-cover'
                    />
                )}
                <Typography className='mb-2 text-xs'>
                    {name.length > 10 ? name.slice(0, 8) + '...' : name}
                </Typography>
                <Modal
                    open={isOpen}
                    onClose={() => {
                        submitChangeRotation(rotation)
                        handleClose()
                    }}
                    onClick={stateSwitch}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        onClick={(e) => e.stopPropagation()}
                        style={{
                            transform: `rotate(${rotation}deg)`,
                        }}
                    >
                        {documentFile.isLoading ? (
                            <CircularProgress color='primary' />
                        ) : documentFile.isError ? (
                            <Stack
                                justifyContent='center'
                                alignItems='center'
                                spacing={2}
                                marginX={2}
                            >
                                <StornoImage
                                    style={{
                                        maxWidth: '90vw',
                                        maxHeight: '90vh',
                                    }}
                                />
                                <Typography
                                    variant='body2'
                                    padding={2}
                                    sx={{
                                        backgroundColor: '#f8f8f8',
                                        borderRadius: '6px',
                                    }}
                                >
                                    Soubor byl pravděpodobně smazán. Pro více
                                    informací kontaktujte podporu.
                                </Typography>
                            </Stack>
                        ) : (
                            <Box className='relative'>
                                <Box className='relative'>
                                    <Box
                                        className={classNames({
                                            'left-5 right-auto':
                                                rotation === 90,
                                            'bottom-5 left-5 right-auto top-auto':
                                                rotation === 180,
                                            'bottom-5 top-auto':
                                                rotation === 270,
                                            'absolute right-5 top-5 z-10 justify-end text-right':
                                                true,
                                        })}
                                    >
                                        <IconButton
                                            className='left ml-auto  bg-orange-400 text-white-reduced'
                                            onClick={() => {
                                                submitChangeRotation(rotation)
                                                handleClose()
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                    <img
                                        className='rounded-[1rem]'
                                        style={imgStyles}
                                        src={documentFile.data || source}
                                        alt={name || 'Dokument pacienta'}
                                    />

                                    {!isPdf && (
                                        <DocumentItemMenu
                                            handleRotate={handleRotate}
                                            filename={filename}
                                            handleCloseParent={handleClose}
                                            rotation={rotation}
                                            base64String={documentFile.data}
                                            stateSwitchFilename={
                                                switchAndUpdateName
                                            }
                                        />
                                    )}
                                </Box>
                            </Box>
                        )}
                        <Modal
                            open={isOpenDescription}
                            onClose={
                                isSubmitting
                                    ? () => {}
                                    : () => stateSwitchFilename
                            }
                            onClick={
                                isSubmitting
                                    ? () => {}
                                    : () => stateSwitchFilename
                            }
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                onClick={(e) => e.stopPropagation()}
                                sx={{
                                    background: '#feefe6',
                                    width: '100%',
                                    maxWidth: '768px',
                                }}
                            >
                                <Box>
                                    <form
                                        onSubmit={handleSubmit(submitMessage)}
                                    >
                                        <Box className='flex items-center  bg-[#feefe6] px-5 py-4'>
                                            <Typography
                                                className='w-full text-center font-bold'
                                                color={'primary'}
                                            >
                                                Upravit dokument
                                            </Typography>
                                            <IconButton
                                                className='ml-3 bg-orange-400 p-1 text-white-reduced'
                                                disabled={isSubmitting}
                                                onClick={stateSwitchFilename}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </Box>
                                        <Stack
                                            spacing={2}
                                            className='px-5'
                                        >
                                            <Stack spacing={1}>
                                                <InputLabel className='text-sm'>
                                                    Název dokumentu*
                                                </InputLabel>

                                                <Controller
                                                    control={control}
                                                    name='name'
                                                    render={({ field }) => (
                                                        <OutlinedInput
                                                            {...field}
                                                            placeholder='zde zadejte název...'
                                                            className='bg-white-reduced text-sm'
                                                            type='text'
                                                            fullWidth
                                                            size='small'
                                                            error={
                                                                errors.name !==
                                                                undefined
                                                            }
                                                            sx={{
                                                                marginBottom: 2,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Stack>
                                        <Stack className='flex-row justify-end px-5 py-4'>
                                            {author?.id === user?.id && (
                                                <Stack className='mr-auto flex '>
                                                    <Button
                                                        type='button'
                                                        variant='contained'
                                                        color='error'
                                                        size='small'
                                                        disabled={isSubmitting}
                                                        className=' mr-auto text-sm font-bold normal-case shadow-none'
                                                        onClick={
                                                            handleDeleteDocument
                                                        }
                                                    >
                                                        Smazat
                                                    </Button>
                                                </Stack>
                                            )}
                                            <Stack className='flex flex-row justify-end gap-2'>
                                                <Button
                                                    type='button'
                                                    variant='outlined'
                                                    size='small'
                                                    disabled={isSubmitting}
                                                    className='bg-white-reduced text-sm font-bold normal-case shadow-none'
                                                    onClick={
                                                        stateSwitchFilename
                                                    }
                                                >
                                                    zrušit
                                                </Button>
                                                <Button
                                                    variant='contained'
                                                    size='small'
                                                    type='submit'
                                                    disabled={isSubmitting}
                                                    className='text-sm font-bold normal-case shadow-none'
                                                >
                                                    uložit a zavřít
                                                </Button>
                                            </Stack>
                                        </Stack>
                                    </form>
                                </Box>
                            </Box>
                        </Modal>
                    </Box>
                </Modal>
            </Box>
        </>
    )
}
