import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
} from '@mui/material'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'

interface Option {
    value: string | number
    label: string
}

interface MultipleChoiceRadioGroupProps<T extends FieldValues> {
    control: Control<T>
    name: Path<T>
    label: string
    isSubmitting: boolean
    options: Option[]
    readOnly?: boolean
}

const MultipleChoiceRadioGroup = <T extends FieldValues>({
    control,
    name,
    label,
    isSubmitting,
    options,
    readOnly,
}: MultipleChoiceRadioGroupProps<T>) => {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <FormControl>
                    <FormLabel
                        component='legend'
                        className='text-sm font-semibold'
                    >
                        {label}
                    </FormLabel>
                    <RadioGroup
                        {...field}
                        value={field.value !== undefined ? field.value : ''}
                        onChange={(e) => field.onChange(Number(e.target.value))}
                        row
                    >
                        {options.map((option) => (
                            <FormControlLabel
                                key={option.value}
                                disabled={isSubmitting || readOnly}
                                value={option.value}
                                control={<Radio size='small' />}
                                label={option.label}
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        fontSize: '1rem',
                                    },
                                }}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            )}
        />
    )
}

export default MultipleChoiceRadioGroup
