import {
    Box,
    Stack,
    IconButton,
    TextField,
    Typography,
    SpeedDialAction,
} from '@mui/material'
import { MobileDatePicker } from '@mui/x-date-pickers'
import { HelpDial, Navigation, SortedVisit } from '../../components'
import type { NurseHistoryPageProps } from './types'
import ArrowLeftRoundedIcon from '@mui/icons-material/ArrowLeftRounded'
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded'
import { historyActions } from '../../components/HelpDial/HelpDialActions'
import { HelpSpeedDialActions } from '../../components/HelpDial/types'
import { useUIDSeed } from 'react-uid'
import HomeIcon from '@mui/icons-material/Home'
import { useNavigate } from 'react-router-dom'

export const NurseHistoryView = (props: NurseHistoryPageProps) => {
    const {
        handleAddMonth,
        activeMonthDate,
        handleDateChange,
        handleSubtractMonth,
        visitList,
        sortedDateVisitCollections,
        hasEntries,
    } = props

    const navigate = useNavigate()
    const uid = useUIDSeed()
    return (
        <Box
            component='main'
            className='relative min-h-[calc(100vh-64px)] bg-orange-50 py-2'
        >
            <Navigation>
                <Box className='flex items-center'>
                    <IconButton
                        onClick={() => navigate('/home')}
                        className='mr-1'
                    >
                        <HomeIcon style={{ color: '#faa26b' }} />
                    </IconButton>
                    <Typography
                        variant='h1'
                        className='text-xl font-medium sm:text-2xl'
                    >
                        Historie návštěv
                    </Typography>
                </Box>
            </Navigation>
            <Box className='container mx-auto lg:max-w-container'>
                <Stack
                    direction='row'
                    className='mt-4 items-center justify-end md:justify-center'
                >
                    <IconButton onClick={handleSubtractMonth}>
                        <ArrowLeftRoundedIcon
                            fontSize='large'
                            color='primary'
                        />
                    </IconButton>
                    <MobileDatePicker
                        views={['year', 'month']}
                        openTo='month'
                        value={activeMonthDate}
                        toolbarTitle='Vyberte měsíc'
                        onChange={handleDateChange}
                        disableFuture
                        renderInput={(params) => (
                            <TextField
                                variant='standard'
                                sx={{
                                    '.MuiInputBase-root::before, .MuiInputBase-root::after':
                                        {
                                            display: 'none',
                                        },
                                    input: {
                                        textAlign: 'center',
                                    },
                                }}
                                {...params}
                            />
                        )}
                    />
                    <IconButton
                        onClick={() => handleAddMonth({ disableFuture: true })}
                    >
                        <ArrowRightRoundedIcon
                            fontSize='large'
                            color='primary'
                        />
                    </IconButton>
                </Stack>
                <SortedVisit
                    isLoading={visitList.isLoading}
                    hasEntries={hasEntries}
                    sortedDateVisitCollections={sortedDateVisitCollections}
                    shouldSetMainVisit
                    emptyText='Zdá se, že tento měsíc nemáte žádnou historii návštěv.'
                />
                <HelpDial>
                    {({ handleActionClick }) => {
                        return historyActions.map(
                            ({
                                name,
                                icon,
                                modalName,
                            }: HelpSpeedDialActions) => (
                                <SpeedDialAction
                                    key={uid(modalName)}
                                    icon={icon}
                                    tooltipTitle={name}
                                    tooltipPlacement='right'
                                    tooltipOpen
                                    sx={{
                                        '& .MuiSpeedDialAction-staticTooltipLabel':
                                            {
                                                width: 'max-content',
                                            },
                                    }}
                                    onClick={handleActionClick(modalName)}
                                />
                            ),
                        )
                    }}
                </HelpDial>
            </Box>
        </Box>
    )
}
