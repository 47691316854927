import moment from 'moment'

export const calculateTime = (time?: Date): string => {
    if (time) {
        const timeDuration = moment.duration(
            moment(new Date(), 'YYYY/MM/DD HH:mm').diff(
                moment(time, 'YYYY/MM/DD HH:mm'),
            ),
        )

        const isPastYear = moment(time).isBefore(moment(), 'year')
        const calcTimeHours = timeDuration.asHours()
        const calcTimeMinutes = timeDuration.asMinutes()

        if (calcTimeHours > 24)
            return moment(time).format(`DD.MM.${isPastYear ? 'YY' : ''}`)
        if (calcTimeHours > 1 && calcTimeHours <= 24)
            return `před ${timeDuration.asHours().toFixed(0)}hod.`
        if (calcTimeHours < 1 && calcTimeMinutes >= 1)
            return `před ${timeDuration.asMinutes().toFixed(0)}min.`
        if (timeDuration.asMinutes() < 1) return `právě teď`
    }
    return 'error'
}
