import { useContext } from 'react'
import { AuthContext } from '../../providers'
import { useMutation } from '@tanstack/react-query'
import { pingNavigationUsage, pingPhoneUsage } from './services'

export const usePhoneStatsMutation = () => {
    const { token } = useContext(AuthContext)
    const statsMobile = useMutation(() => pingPhoneUsage(token))

    return statsMobile
}

export const useNavigationStatsMutation = () => {
    const { token } = useContext(AuthContext)
    const statsMobile = useMutation(() => pingNavigationUsage(token))

    return statsMobile
}
