import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
} from '@mui/material'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'

interface YesNoRadioGroupProps<T extends FieldValues> {
    control: Control<T>
    name: Path<T>
    label: string
    disabled: boolean
    falseLabel?: string
    trueLabel?: string
    readOnly?: boolean
}

const YesNoRadioGroup = <T extends FieldValues>({
    control,
    name,
    label,
    disabled,
    falseLabel = 'Ne',
    trueLabel = 'Ano',
    readOnly,
}: YesNoRadioGroupProps<T>) => {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <FormControl>
                    <FormLabel
                        component='legend'
                        className='text-sm font-semibold'
                    >
                        {label}
                    </FormLabel>
                    <RadioGroup
                        {...field}
                        value={field.value === true ? 'true' : 'false'}
                        onChange={(e) =>
                            field.onChange(e.target.value === 'true')
                        }
                        row
                    >
                        <FormControlLabel
                            disabled={disabled || readOnly}
                            value='false'
                            control={<Radio size='small' />}
                            label={falseLabel}
                            sx={{
                                '& .MuiFormControlLabel-label': {
                                    fontSize: '1rem',
                                },
                            }}
                        />
                        <FormControlLabel
                            disabled={disabled || readOnly}
                            value='true'
                            control={<Radio size='small' />}
                            label={trueLabel}
                            sx={{
                                '& .MuiFormControlLabel-label': {
                                    fontSize: '1rem',
                                },
                            }}
                        />
                    </RadioGroup>
                </FormControl>
            )}
        />
    )
}
export default YesNoRadioGroup
