import { usePatientsActionWrapperProps } from '../types'
import AddPatientModalForm from './AddPatientModalForm'

const PatientsActionWrapper = ({
    selectedAction,
    ...props
}: usePatientsActionWrapperProps) => {
    switch (selectedAction) {
        case 'ADD_PATIENT':
            return <AddPatientModalForm {...props} />

        default:
            return null
    }
}
export default PatientsActionWrapper
