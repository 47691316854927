import { FC } from 'react'
import { AdminPageProps } from './types'
import { HelpDial, Navigation } from '../../components'
import { Alert, Box, Button, Stack, Typography } from '@mui/material'
import { SelectIdDropdown } from './components/SelectIdDropdown'

export const AdminPageView: FC<AdminPageProps> = (props) => {
    const {
        organizations,
        organizationsLoading,
        organizationsError,
        handleIdChange,
        users,
        usersLoading,
        usersError,
        setOrganizationId,
        setUserId,
        readonlyResponse,
        readonlyResponseError,
        readonlyResponseLoading,
        handleReadonlyLogin,
    } = props
    return (
        <Box
            component='main'
            className='relative min-h-[calc(100vh-64px)] bg-orange-50 py-6'
        >
            <Navigation>
                <Typography
                    variant='h1'
                    className='text-2xl font-medium'
                >
                    Administrace
                </Typography>
            </Navigation>
            <Box className='container mx-auto lg:max-w-container'>
                <Stack spacing={'1.5rem'}>
                    <Stack spacing={'0.75rem'}>
                        <SelectIdDropdown
                            labelTitle='Vybraná organizace'
                            handleIdChange={handleIdChange}
                            setIdFunction={setOrganizationId}
                            selectItems={organizations}
                            isLoading={organizationsLoading}
                            isError={organizationsError}
                        />
                        <SelectIdDropdown
                            labelTitle='Vybraný uživatel'
                            handleIdChange={handleIdChange}
                            setIdFunction={setUserId}
                            selectItems={users}
                            isLoading={usersLoading}
                            isError={usersError}
                        />
                    </Stack>
                    <Button
                        variant='contained'
                        className='normal-case disabled:bg-gray-400'
                        disabled={
                            organizationsError ||
                            usersError ||
                            organizationsLoading ||
                            usersLoading ||
                            readonlyResponseLoading ||
                            readonlyResponseError ||
                            !readonlyResponse
                        }
                        onClick={handleReadonlyLogin}
                    >
                        Přihlásit se jako vybraný uživatel
                    </Button>
                    <Alert
                        variant='filled'
                        severity='warning'
                    >
                        Po přihlášení jako superadministrátor nebude možné
                        uložit žádné změny.
                    </Alert>
                </Stack>
                <HelpDial />
            </Box>
        </Box>
    )
}
