import { Avatar, Box, colors, Stack, Typography } from '@mui/material'
import moment from 'moment'
import { useCallback, useRef } from 'react'
import { useNoteVisibility } from '../../hooks'
import { NoteCardProps } from '../../types'

export const NoteCard = ({ note }: NoteCardProps) => {
    const calculateTime = useCallback((time?: Date): string => {
        if (time) {
            const timeDuration = moment.duration(
                moment(new Date(), 'YYYY/MM/DD HH:mm').diff(
                    moment(time, 'YYYY/MM/DD HH:mm'),
                ),
            )

            const isPastYear = moment(time).isBefore(moment(), 'year')
            const calcTimeHours = timeDuration.asHours()
            const calcTimeMinutes = timeDuration.asMinutes()

            if (calcTimeHours > 24) {
                return moment(time).format(`DD.MM.${isPastYear ? 'YY' : ''}`)
            }
            if (calcTimeHours > 1 && calcTimeHours <= 24) {
                return `před ${timeDuration.asHours().toFixed(0)} hod.`
            }
            if (calcTimeHours < 1 && calcTimeMinutes >= 1) {
                return `před ${timeDuration.asMinutes().toFixed(0)} min.`
            }
            if (timeDuration.asMinutes() < 1) return `právě teď`
        }
        return 'error'
    }, [])

    const NoteCardRef = useRef<HTMLDivElement>(null)

    useNoteVisibility({
        noteId: note.id,
        isReaded: note.readedByLoginUser,
        noteRef: NoteCardRef,
    })

    return (
        <Stack
            width='100%'
            ref={NoteCardRef}
        >
            <Stack
                direction='row'
                justifyContent='space-between'
                width='102%'
                ml={'-2%'}
                pb={1}
            >
                <Stack
                    direction='row'
                    spacing={1}
                    alignItems='center'
                >
                    <Box
                        width='6px'
                        height='6px'
                        borderRadius='99999px'
                        bgcolor={note.readedByLoginUser ? undefined : '#faa26b'}
                    ></Box>
                    <Avatar sx={{ background: `#faa26b` }} />
                    <Box>
                        <Typography
                            fontSize={14}
                            fontWeight='medium'
                        >
                            {note.authorFullName}
                        </Typography>
                        <Typography
                            fontSize={12}
                            fontWeight='light'
                        >
                            {note.authorJob}
                        </Typography>
                    </Box>
                </Stack>
                <Typography
                    fontSize={12}
                    fontWeight='medium'
                    color={colors.grey[500]}
                >
                    {calculateTime(note.createdAt)}
                </Typography>
            </Stack>
            <Typography
                fontSize={12}
                color={colors.grey[700]}
                pb={1}
                pl={'2%'}
            >
                {note.text}
            </Typography>
        </Stack>
    )
}
