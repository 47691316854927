import { Stack, Typography } from '@mui/material'
import {
    CalendarMonth,
    KeyboardReturn,
    SendAndArchive,
    CancelScheduleSend,
} from '@mui/icons-material'
import { ReactComponent as NewStateIcon } from '../../assets/icons/visit-new.svg'
import { ReactComponent as SentStateIcon } from '../../assets/icons/visit-sent.svg'
import { ReactComponent as DoneStateIcon } from '../../assets/icons/visit-done.svg'
import Modal from '../Modal'
import { UseHelpModalProps } from './types'

export const VisitStatusesModal = (props: UseHelpModalProps) => {
    return (
        <Modal
            {...props}
            title='Stavy návštěv'
        >
            <Stack gap={1}>
                <Stack
                    direction='row'
                    alignItems='center'
                    gap={1}
                >
                    <CalendarMonth
                        style={{
                            width: 22,
                            height: 22,
                            color: 'rgb(130, 130, 130)',
                        }}
                    />
                    <Typography>Plánovaná</Typography>
                </Stack>
                <Stack
                    direction='row'
                    alignItems='center'
                    gap={1}
                >
                    <NewStateIcon style={{ width: 22, height: 22 }} />
                    <Typography>Rozpracovaná</Typography>
                </Stack>
                <Stack
                    direction='row'
                    alignItems='center'
                    gap={1}
                >
                    <SentStateIcon style={{ width: 22, height: 22 }} />
                    <Typography>Odeslaná</Typography>
                </Stack>
                <Stack
                    direction='row'
                    alignItems='center'
                    gap={1}
                >
                    <DoneStateIcon style={{ width: 22, height: 22 }} />
                    <Typography>Schválená</Typography>
                </Stack>
                <Stack
                    direction='row'
                    alignItems='center'
                    gap={1}
                >
                    <KeyboardReturn
                        style={{
                            width: 22,
                            height: 22,
                            color: 'rgb(238, 119, 119)',
                        }}
                    />
                    <Typography>Vrácená</Typography>
                </Stack>
                <Stack
                    direction='row'
                    alignItems='center'
                    gap={1}
                >
                    <SendAndArchive
                        style={{
                            width: 22,
                            height: 22,
                            color: 'rgba(111, 207, 151)',
                        }}
                    />
                    <Typography>Vykázaná</Typography>
                </Stack>
                <Stack
                    direction='row'
                    alignItems='center'
                    gap={1}
                >
                    <CancelScheduleSend
                        style={{
                            width: 22,
                            height: 22,
                            color: 'rgb(130, 130, 130)',
                        }}
                    />
                    <Typography>Neprovedená</Typography>
                </Stack>
            </Stack>
        </Modal>
    )
}
