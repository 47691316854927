import { useHelpErrorFormModal } from './hooks/useHelpErrorFormModal'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'

import {
    Box,
    Button,
    Grid,
    IconButton,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
} from '@mui/material'
import { Controller } from 'react-hook-form'
import Modal from '../Modal'
import { ErrorImage } from './ErrorImge'
import { UseHelpModalProps } from './types'
import { useUIDSeed } from 'react-uid'

export const HelpErrorFormModal = (props: UseHelpModalProps) => {
    const { image, handleClose } = props

    const {
        formMethods,
        submitMessage,
        selectedFiles,
        handleFileUpload,
        handleButtonClick,
        uploadInputRef,
    } = useHelpErrorFormModal(handleClose, image)

    const uid = useUIDSeed()
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = formMethods

    return (
        <Modal
            {...props}
            title=' Hlášení chyby'
        >
            <Stack>
                <form
                    onSubmit={handleSubmit(submitMessage)}
                    className='flex flex-col gap-2'
                >
                    <Stack>
                        <Typography
                            textAlign={'center'}
                            className='text-sm'
                        >
                            Našli jste chybu nebo nám chcete poslat podnět?
                            Napište nám.
                        </Typography>
                        <Stack
                            spacing={2}
                            mt={2}
                        >
                            <Stack spacing={1}>
                                <InputLabel className='text-sm'>
                                    Popis chyby
                                </InputLabel>
                                <Controller
                                    control={control}
                                    name='message'
                                    render={({ field }) => (
                                        <OutlinedInput
                                            defaultValue={''}
                                            size='small'
                                            placeholder='Zde zadejte popis chyby'
                                            className='bg-white-reduced text-sm'
                                            type='text'
                                            multiline
                                            fullWidth
                                            rows={6}
                                            {...field}
                                            error={!!errors.message}
                                        />
                                    )}
                                />
                                {errors?.message?.message && (
                                    <Typography className='pt-1 text-sm text-error-2'>
                                        {errors.message.message}
                                    </Typography>
                                )}
                            </Stack>
                        </Stack>
                        <Stack
                            spacing={1}
                            className='mt-5'
                        >
                            <InputLabel className='text-sm'>Přílohy</InputLabel>
                            <Box className='relative mb-4 min-h-[100px] w-full rounded border border-solid border-gray-400 bg-white-reduced p-4'>
                                <Grid container>
                                    <Grid
                                        xs={3}
                                        item
                                    >
                                        {image ? (
                                            <ErrorImage imageSrc={image} />
                                        ) : (
                                            'Načítání...'
                                        )}
                                    </Grid>

                                    {selectedFiles.length > 0 &&
                                        selectedFiles.map((file: string) => (
                                            <Grid
                                                key={uid(file)}
                                                xs={3}
                                                item
                                            >
                                                <ErrorImage
                                                    name='image'
                                                    imageSrc={file}
                                                    key={uid(file)}
                                                />
                                            </Grid>
                                        ))}
                                </Grid>
                                <div>
                                    <IconButton
                                        color='primary'
                                        aria-label='upload picture'
                                        component='span'
                                        className='absolute bottom-0 right-0 m-2 bg-orange-300 text-white-reduced'
                                        size='small'
                                        onClick={handleButtonClick}
                                    >
                                        <input
                                            hidden
                                            type='file'
                                            onChange={handleFileUpload}
                                            ref={uploadInputRef}
                                        />
                                        <AddAPhotoIcon fontSize='small' />
                                    </IconButton>
                                </div>
                            </Box>
                        </Stack>
                        <Button
                            color='primary'
                            variant='outlined'
                            className='mb-5 mt-5 bg-white-reduced'
                            size='small'
                            type='submit'
                        >
                            Odeslat
                        </Button>

                        <Typography
                            fontWeight={400}
                            mt={2}
                            fontSize={'small'}
                            textAlign={'center'}
                        >
                            Pokud nám nahlásíte chybu, v případě nejasností vás
                            budeme kontaktovat. Zároveň s tím si uložíme
                            informaci o typu zařízení, ze kterého chybu hlásíte
                            (tyto informace slouží pouze k našim potřebám a
                            nebudou nikde zveřejněny).
                        </Typography>
                    </Stack>
                </form>
            </Stack>
        </Modal>
    )
}
