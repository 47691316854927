import { createTheme } from '@mui/material'

const rootElement = document.getElementById('root')

export const muiTheme = createTheme({
    typography: {
        fontSize: 16,
        fontFamily: ['Noto Sans', 'sans-serif'].join(','),
        allVariants: {
            color: '#333',
        },
    },
    shape: {
        borderRadius: 6,
    },
    palette: {
        primary: {
            main: '#f8833b',
            contrastText: '#f8f8f8',
            dark: '#f8833b',
        },
        success: {
            main: '#6fcf97',
        },
        secondary: {
            main: '#f8f8f8',
        },
        error: {
            main: '#E84A4A',
        },
    },
    components: {
        MuiPopover: {
            defaultProps: {
                container: rootElement,
            },
        },
        MuiPopper: {
            defaultProps: {
                container: rootElement,
            },
        },
        MuiDialog: {
            defaultProps: {
                container: rootElement,
            },
        },
        MuiModal: {
            defaultProps: {
                container: rootElement,
            },
        },
        MuiMenu: {
            defaultProps: {
                container: rootElement,
            },
        },
        MuiTabs: {
            styleOverrides: {
                scroller: {
                    paddingTop: '10px',
                },
                root: {
                    // marginLeft: '-20px',
                    // marginRight: '-20px',
                    '& .MuiTabs-indicator': {
                        display: 'none',
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    overflow: 'visible',
                    marginRight: 8,
                    marginLeft: 8,
                    minHeight: 'auto',
                    minWidth: 'auto',
                    height: 36,
                    width: 'calc(35% - 16px)',
                    textTransform: 'none',
                    fontSize: 12,
                    border: '1px solid #bdbdbd',
                    borderRadius: 6,
                    transition: 'all 0.3s',
                    backgroundColor: '#fbf5f3',
                    '&:last-child': {
                        marginRight: '60px',
                    },
                    '&.Mui-selected': {
                        backgroundColor: '#f8833b',
                        borderColor: '#f8833b',
                        color: '#f8f8f8',
                    },

                    '@media (min-width: 48em)': {
                        width: '0',
                        flex: 1,

                        '&:last-child': {
                            marginRight: '0px',
                        },
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                },
            },
        },
    },
})
