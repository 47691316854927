import { useMemo, useCallback } from 'react'

import { FilterHookActivate, FilterItems } from './types'
import { FilterItemValue } from './FilterGroup'

export type FilterItemWithType<TFilterType, TValue> = {
    type: TFilterType
    active: boolean
    value: TValue
    name: string
    prefix?: string
    icon?: string
}

export const useFiltersActivate = <TFilterType, TValue>(
    props: FilterHookActivate<TFilterType, TValue>,
) => {
    const { filters, setFilters, singleSelectGroup } = props

    const handleFilterActivate = useCallback(
        ({
            item,
            group,
        }: {
            item: FilterItemValue<TValue>
            group: FilterItems<TFilterType, TValue>
        }) => {
            setFilters((currentData) => {
                return currentData.map((_group) => {
                    if (_group.type === group.type) {
                        const isSingleSelectGroup = singleSelectGroup.includes(
                            _group.type,
                        )

                        // Single select group handling
                        if (isSingleSelectGroup) {
                            return {
                                ..._group,
                                items: _group.items.map((_item) => ({
                                    ..._item,
                                    active:
                                        _item.name === item.name
                                            ? !_item.active
                                            : false,
                                })),
                            }
                        }

                        // Multi-select handling
                        return {
                            ..._group,
                            items: _group.items.map((_item) => {
                                if (_item.name === item.name) {
                                    return { ..._item, active: !_item.active }
                                }
                                return _item
                            }),
                        }
                    }
                    return _group
                })
            })
        },
        [setFilters, singleSelectGroup],
    )

    const extractedActiveFiltersWithType = useMemo(
        (): FilterItemWithType<TFilterType, TValue>[] =>
            filters.flatMap((group) =>
                group.items
                    .filter(({ active }) => active)
                    .map((item) => ({ ...item, type: group.type })),
            ),
        [filters],
    )

    const getActiveGroup = useCallback(
        (_type: TFilterType) =>
            extractedActiveFiltersWithType.filter(({ type }) => type === _type),
        [extractedActiveFiltersWithType],
    )

    return {
        handleFilterActivate,
        extractedActiveFiltersWithType,
        getActiveGroup,
    }
}
