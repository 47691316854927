import type {
    CarePlan,
    SpecialCode,
    VisitNurseDetails,
    VoucherCarePlan,
    LongTextFormatted,
    VisitCollectionProps,
    LastNurseVisit,
    Plan,
    User,
} from './../../../types/index'
import type { Dispatch, MouseEvent, SetStateAction } from 'react'
import type { BoxProps, StackProps } from '@mui/material'
import type { UseQueryResult } from '@tanstack/react-query'
import type { HTMLAttributes, PropsWithChildren, SyntheticEvent } from 'react'
import type { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form'
import type { Contact } from '../../../api/patient/types'
import type { ErrorResponse } from '../../../api/user/types'
import type {
    Collection,
    LoadingState,
    NoteTypeFor,
    OpenStateProps,
    Patient,
    PatientDocument,
    PatientNote,
    PatientSex,
    History,
} from '../../../types'
import { MainVisitType } from '../../../providers/MainVisitProvider'
import { ListMedication } from '../../../api/types'
import { ScrollStatus } from '../../../hooks/useScroll'

export interface UseVisitDetailHookProps {}

type NoteQueryResult = UseQueryResult<Collection<PatientNote>, ErrorResponse>

export interface VisitDetailPageProps {
    mainVisit?: MainVisitType
    activeTab: TabValue
    handleTabChange: (event: SyntheticEvent, newValue: TabValue) => void
    activePatient?: Patient
    visitData: VisitNurseDetails | undefined
    visitDetailsLoading: boolean
    importantNoteModal: OpenStateProps
    importantNotesQuery: NoteQueryResult
    patientDocumentsQuery: UseQueryResult<
        Collection<PatientDocument>,
        ErrorResponse
    >
    handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void
    uploadLoading: boolean
    visitForm: VisitFormProps
    patientHistory: History & VisitCollectionProps
    intersectElement: HorizontalIntersect
    isTimeClicked: boolean
    setIsTimeClicked: Dispatch<SetStateAction<boolean>>
    patientNotesQuery: UseQueryResult<Collection<PatientNote>, ErrorResponse>
    unreadNotes?: PatientNote[]
    handleButtonClick: (e: MouseEvent) => Promise<void>
    fileInputRef: React.RefObject<HTMLInputElement>
    noteModal: OpenStateProps
    newNoteModal: OpenStateProps
    newNoteForm: NewNoteForm
    handleSendUpload: () => void
    scrollStatus: ScrollStatus
    newUploadedFileName?: string
    setNewUploadedFileName: Dispatch<SetStateAction<string>>
    isPreviewOpen: boolean
    handleClosePreview: () => void
    fileData: any
    showTabs: boolean
    isScrollUpVisible: boolean
    visitId?: string
    patientId?: number
}

export interface TabPanelProps
    extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
    currentTab: TabValue
    tabName: TabValue
}

export interface ContactGroupProps extends BoxProps, LoadingState {
    title: string
    contacts: Contact[]
}

export type ContactCardProps = Pick<
    Contact,
    'name' | 'phone' | 'familyRelationship'
> &
    LoadingState

export type PatientTabProps = LoadingState &
    Pick<
        VisitDetailPageProps,
        | 'activePatient'
        | 'patientDocumentsQuery'
        | 'handleFileUpload'
        | 'handleButtonClick'
        | 'fileInputRef'
        | 'patientNotesQuery'
        | 'uploadLoading'
        | 'showTabs'
        | 'handleSendUpload'
        | 'patientId'
        | 'newUploadedFileName'
        | 'setNewUploadedFileName'
        | 'isPreviewOpen'
        | 'handleClosePreview'
        | 'fileData'
        | 'visitId'
    >

export enum TabValue {
    'detail',
    'patient',
    'contact',
    'history',
    'medication',
}

interface NewNoteForm {
    formMethods: UseFormReturn<NewNoteFormProps, any>
    submitNote: (data: NewNoteFormProps) => Promise<void>
    handleNoteTypeChange: (type: NoteTypeFor) => () => void
}

export type AsideProps = Pick<VisitDetailPageProps, 'patientNotesQuery'> & {
    patientSex?: PatientSex
    patientLoading: boolean
    noteModal: OpenStateProps
    newNoteModal: OpenStateProps
    newNoteForm: NewNoteForm
}

export type AsideHookProps = Pick<
    AsideProps,
    'patientLoading' | 'patientSex' | 'patientNotesQuery'
>

export type NoteModalProps = Pick<
    AsideProps,
    'noteModal' | 'newNoteModal' | 'newNoteForm' | 'patientNotesQuery'
>

export interface NoteCardProps extends StackProps {
    note: PatientNote
}

export interface NewNoteModalProps extends Pick<AsideProps, 'newNoteForm'> {
    modalState: OpenStateProps
}

export interface NewNoteFormProps {
    newNote: string
    noteType: string
}

export type ImportantNoteModalProps = Pick<
    VisitDetailPageProps,
    'importantNoteModal' | 'importantNotesQuery'
>

export interface ImportantNoteItemProps extends BoxProps, LoadingState {
    importantNote: PatientNote
}

export type VisitTabProps = Pick<
    VisitDetailPageProps,
    | 'visitData'
    | 'visitDetailsLoading'
    | 'visitForm'
    | 'isTimeClicked'
    | 'setIsTimeClicked'
    | 'showTabs'
>

export enum StateCzech {
    'New' = 'Nová',
    'Scheduled' = 'Plánovaná',
    'Draft' = 'Rozpracovaná',
    'Sent' = 'Odeslaná',
    'Returned' = 'Vrácená',
    'Approved' = 'Schválená',
    'Reported' = 'Vykázaná',
    'NotExecuted' = 'Neprovedená',
    'Cancel' = 'Zrušená',
}

export type VisitInfoProps = LoadingState &
    Pick<
        VisitDetailPageProps,
        'visitForm' | 'isTimeClicked' | 'setIsTimeClicked' | 'showTabs'
    > &
    Partial<
        Pick<
            VisitNurseDetails,
            | 'plannedVisitTime'
            | 'plannedVisitLength'
            | 'realVisitTime'
            | 'state'
            | 'isBloodPressureHighRequired'
            | 'isBloodPressureLowRequired'
            | 'isBreathingFreqRequired'
            | 'isGlycemiaRequired'
            | 'isPainRequired'
            | 'isPulseRequired'
            | 'isSaturationRequired'
            | 'isTemperatureRequired'
            | 'lastNurseVisit'
            | 'canEdit'
            | 'canSave'
            | 'isFixVisitTimeEnabled'
            | 'fixVisitTime'
        >
    >

export type VisitMutation = (
    e?: React.BaseSyntheticEvent<object, any, any> | undefined,
) => Promise<void>
export interface VisitFormProps {
    formMethods: UseFormReturn<VisitFormData>
    handleUpdateVisit: VisitMutation
    handleStornoVisit: VisitMutation
    handleSendVisit: VisitMutation
    carePlanMethods: UseFieldArrayReturn<VisitFormData, 'carePlans', 'id'>
}

export type VisitFormData = Pick<
    VisitNurseDetails,
    | 'bloodPressureHigh'
    | 'bloodPressureLow'
    | 'pulse'
    | 'breathingFreq'
    | 'saturation'
    | 'temperature'
    | 'glycemia'
    | 'pain'
    | 'subjective'
    | 'objective'
    | 'aplicationDrugs'
    | 'usedMaterials'
    | 'visitNote'
    | 'note'
    | 'carePlans'
    | 'reasonNonDone'
    | 'painLocalization'
    | 'fixVisitTime'
> & {
    time?: string | Date | null
    requiredFields?: boolean
}

export type VisitFormDataProps = {
    data: VisitFormData
    showSavedSnackbar: boolean
}

export interface FinalInfoProps {
    getCodeUsage: (
        code: string,
        usedCount: number,
        usedOnVisit: number,
    ) => number
    getNewHealtAction: ({
        e,
        code,
        usingLimit,
        id,
        usedCount,
    }: NewHealthActionProps) => Promise<void>
}

export type FinalInfoViewProps = Pick<
    VisitDetailPageProps,
    'visitForm' | 'visitData'
> &
    FinalInfoProps

export type FinalInfoHookProps = Omit<
    FinalInfoViewProps,
    'getCodeUsage' | 'getNewHealtAction'
>

interface TreatmentStepsPlans {
    carePlans?: CarePlan[]
    lastVisit?: LastNurseVisit
    updateVisitOnClose: VisitMutation
}

export type TreatmentStepsProps = Pick<VisitDetailPageProps, 'visitForm'> &
    Pick<VisitNurseDetails, 'canEdit' | 'canSave'> &
    LoadingState &
    TreatmentStepsPlans

export interface TreatmentStepInterface {
    carePlanIndex: number
}

export interface TreatmentStepProps {
    isStepDataChanged: boolean
    handleModalClose: () => void
}

export type TreatmentStep = CarePlan &
    LoadingState &
    StackProps &
    Pick<VisitNurseDetails, 'canEdit' | 'canSave'> &
    Pick<VisitDetailPageProps, 'visitForm'> &
    Pick<
        TreatmentStepsProps,
        'lastVisit' | 'updateVisitOnClose' | 'carePlans'
    > &
    TreatmentStepInterface &
    OpenStateProps &
    TreatmentStepProps

export type TreatmentStepHookProps = Omit<
    TreatmentStep,
    'isStepDataChanged' | 'handleModalClose' | 'isOpen' | 'handleOpen'
>

export interface TreatmentItemProps {
    carePlanIndex: number
    isThinner?: boolean
    hasBackground?: boolean
    hideSelectAll: boolean
    isError?: boolean
    diagnoseName?: string
    handleSelectAll: (e: MouseEvent) => void
    getNumberColor: () => 'error' | 'success' | 'warning'
    countNumber: number
    numberFormulaCount: number
    allStepsDone: boolean
    hasAllCodes?: boolean
}

export type PlanSetter = (P: Plan) => Plan

export type TreatmentItem = CarePlan &
    LoadingState &
    StackProps &
    Pick<VisitNurseDetails, 'canEdit' | 'canSave'> &
    Pick<VisitDetailPageProps, 'visitForm'> &
    TreatmentItemProps

export type TreatmentHookProps = Omit<
    TreatmentItem,
    | 'handleSelectAll'
    | 'diagnoseName'
    | 'getNumberColor'
    | 'numberFormulaCount'
    | 'countNumber'
    | 'allStepsDone'
>

export type HistoryTabProps = History &
    Pick<VisitDetailPageProps, 'handleTabChange'> &
    VisitCollectionProps

export type VisitActionsProps = Pick<
    VisitFormProps,
    | 'handleUpdateVisit'
    | 'handleStornoVisit'
    | 'formMethods'
    | 'handleSendVisit'
> &
    Pick<VisitDetailPageProps, 'visitData'>

export interface StornoModalProps
    extends Pick<VisitFormProps, 'handleStornoVisit' | 'formMethods'> {
    modalState: OpenStateProps
}

export interface HorizontalIntersect {
    isVisible: boolean
    targetRef: React.RefObject<HTMLDivElement>
}

export type VoucherPlanHookProps = Pick<
    TreatmentStep,
    'carePlanIndex' | 'visitForm' | 'diagnose' | 'canSave'
> &
    Pick<VisitNurseDetails, 'canEdit'> &
    Pick<TreatmentStepsPlans, 'lastVisit'> &
    VoucherCarePlan & {
        voucherIndex: number
    }

export interface VoucherPlanProps extends VoucherPlanHookProps {
    contentText: LongTextFormatted
    instructionsText: LongTextFormatted
}

export interface VoucherPlanTextProps {
    formattedText?: LongTextFormatted
    title: string
}

type SpecialCodeUpdate = Omit<SpecialCode, 'withCode' | 'canBeAdded'>

export interface NewHealthActionProps extends SpecialCodeUpdate {
    e: MouseEvent
}

export interface MainVisitActionProps {
    handleActionClick: (e: MouseEvent) => void
    mainVisitDate?: string
}

export type VisitTabsData = Pick<
    VisitDetailPageProps,
    | 'activeTab'
    | 'handleTabChange'
    | 'patientHistory'
    | 'intersectElement'
    | 'unreadNotes'
    | 'showTabs'
>

export type VisitTabsProps = VisitTabsData

export type SplitContentProps = Omit<
    PatientTabProps,
    | 'handleFileUpload'
    | 'patientDocumentsQuery'
    | 'handleButtonClick'
    | 'fileInputRef'
    | 'uploadLoading'
    | 'isPreviewOpen'
    | 'handleClosePreview'
    | 'newUploadedFileName'
    | 'fileData'
    | 'setNewUploadedFileName'
    | 'handleSendUpload'
>

interface LastVoucherData {
    voucherCarePlansDate?: Date
    lastInput?: string
}

export interface ConditionalInputProps {
    isInputShown: boolean
    inputTitle?: string
    isRequired?: boolean
    carePlanIndex: number
    voucherIndex: number
    inputControlName: keyof VoucherCarePlan
    visitForm: VisitFormProps
    isDisabled?: boolean
    copyNote?: string
    lastVoucherData?: LastVoucherData
    pasteFromLast: () => void
    pastFromNote: () => void
}

export type ConditionalInputHookProps = Omit<
    ConditionalInputProps,
    'pasteFromLast' | 'pastFromNote'
>

export type MedicationType = 'Current' | 'Palliative' | 'SOS'

export enum MedicationCzech {
    'Current' = 'Současná',
    'Palliative' = 'Paliativní',
    'SOS' = 'SOS',
}

export interface MedicationData {
    id: number
    drugNotice: string
    drugTitle: string
    drugId: string
    dosage: string
    user: User
    updateUser: User
    dateCreated: string
    dateEnd?: string
    note: string
    type: MedicationType
    implementationDrug: string
    readonly dateCreatedFor: string
    readonly dateEndFor: string
}

export type MedicationHookProps = {
    visitId?: string
    dateAt?: Date
}

export type MedicationProps = {
    medicationData?: ListMedication
    isMedicationLoading: boolean
    isMedicationError: boolean
    showOnlyCurrent: boolean
    handleCurrentChange: () => void
}
