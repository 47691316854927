import { FC, useCallback, useEffect } from 'react'
import { wrap } from '../../../utils'
import { useAddPatientAnamnesisFormStep6 } from '../hooks/useAddPatientAnamnesisFormStep6'
import {
    AddPatientsAnamnesisFormStep6Hook,
    IAddPatientAnamnesisFormStep6,
} from '../types'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { AnamnesisFormFooter } from './AnamnesisFormFooter'
import CheckboxStyled from '../../../components/CheckboxStyled'
import InputStyled from '../../../components/InputStyled'
import MultipleChoiceRadioGroup from '../../../components/MultipleChoicesRadioGroup'
import { eSelfCareOptions } from '../../../components/options'

const AddPatientAnamnesisFormStep6View = (
    props: IAddPatientAnamnesisFormStep6,
) => {
    const {
        submitForm,
        formMethods,
        setCurrentStep,
        currentStep,
        isLoading,
        canEditCareAnamnesis,
        setSaveCurrentStepOnClose,
    } = props

    const {
        control,
        handleSubmit,
        watch,
        getValues,
        formState: { errors, isSubmitting },
    } = formMethods

    const isEmotionalStateOther = watch('IsEmotionalStateOther')

    const handleSubmitWrapper = useCallback(
        async (isNext?: boolean) => {
            const formData = getValues()
            await submitForm(formData, isNext ? isNext : false)
        },
        [getValues, submitForm],
    )
    useEffect(() => {
        setSaveCurrentStepOnClose(() => handleSubmitWrapper)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSaveCurrentStepOnClose])

    if (isLoading) {
        return (
            <Box className='!mt-12 flex justify-center'>
                <CircularProgress />
            </Box>
        )
    }

    return (
        <form
            onSubmit={() => handleSubmitWrapper(true)}
            className='grid gap-3'
        >
            <Stack spacing={1}>
                <Typography className='!mt-2 flex text-sm font-semibold'>
                    Emocionální stav
                </Typography>
                <CheckboxStyled
                    readOnly={!canEditCareAnamnesis}
                    control={control}
                    disabled={isSubmitting}
                    name='IsEmotionalStateCalm'
                    label='klidný'
                />
                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsEmotionalStateScared'
                    label='ustrašený'
                />
                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsEmotionalStateAngry'
                    label='rozzlobený'
                />
                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsEmotionalStateStubborn'
                    label='tvrdohlavý'
                />
                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='IsEmotionalStateSelfPitying'
                    label='sebelítostivý'
                />
                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsEmotionalStateOther'
                    label='jiné'
                />

                {isEmotionalStateOther && (
                    <InputStyled
                        className='mt-2'
                        control={control}
                        readOnly={!canEditCareAnamnesis}
                        disabled={isSubmitting}
                        name='EmotionalStateOther'
                        errors={errors}
                        label='Jiné:'
                        placeholder='jiné'
                    />
                )}
            </Stack>

            <MultipleChoiceRadioGroup
                control={control}
                label='Hygienická péče'
                readOnly={!canEditCareAnamnesis}
                name='HygienicCare'
                options={eSelfCareOptions}
                isSubmitting={isSubmitting}
            />

            <MultipleChoiceRadioGroup
                control={control}
                label='Oblékání/svlékání'
                readOnly={!canEditCareAnamnesis}
                name='Clothing'
                options={eSelfCareOptions}
                isSubmitting={isSubmitting}
            />

            <MultipleChoiceRadioGroup
                control={control}
                label='Výživa/nají se'
                readOnly={!canEditCareAnamnesis}
                name='Nutrition'
                options={eSelfCareOptions}
                isSubmitting={isSubmitting}
            />

            <MultipleChoiceRadioGroup
                control={control}
                label='Použití WC'
                readOnly={!canEditCareAnamnesis}
                name='UsingToilet'
                options={eSelfCareOptions}
                isSubmitting={isSubmitting}
            />

            <MultipleChoiceRadioGroup
                control={control}
                readOnly={!canEditCareAnamnesis}
                label='Udržování pořádku'
                name='KeepingOrder'
                options={eSelfCareOptions}
                isSubmitting={isSubmitting}
            />

            <Stack spacing={2}>
                <AnamnesisFormFooter
                    currentStep={currentStep}
                    canEditCareAnamnesis={canEditCareAnamnesis}
                    handleSubmitWrapper={handleSubmitWrapper}
                    disabled={isLoading || isSubmitting}
                    setCurrentStep={setCurrentStep}
                />
            </Stack>
        </form>
    )
}

export const AddPatientAnamnesisFormStep6: FC<AddPatientsAnamnesisFormStep6Hook> =
    wrap(AddPatientAnamnesisFormStep6View, useAddPatientAnamnesisFormStep6)
