import { Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { VariantType, useSnackbar } from 'notistack'
import { useCallback } from 'react'
import { CustomSnackbar } from '../types/hooks'

export const useCustomSnackbar = (): CustomSnackbar => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const getSnackbar = useCallback(
        (text: string, variant?: VariantType, persist?: boolean) => {
            enqueueSnackbar(text, {
                variant: variant || 'success',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
                persist: persist || false,
                action: (key) => (
                    <IconButton onClick={() => closeSnackbar(key)}>
                        <Close color='secondary' />
                    </IconButton>
                ),
            })
        },
        [closeSnackbar, enqueueSnackbar],
    )

    return getSnackbar
}
