import { wrap } from '../../utils'
import { UsePatientsHookProps } from './types'
import { FC } from 'react'
import { PatientsPageView } from './PatientsPageView'
import { usePage } from './hooks'

export const PatientsPage: FC<UsePatientsHookProps> = wrap(
    PatientsPageView,
    usePage,
)
