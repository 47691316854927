import { Stack, Typography } from '@mui/material'
import { Modal } from '../../../components'
import { UseHelpModalProps } from '../../../components/HelpDial/types'

export const OfficeInfoModal = (props: UseHelpModalProps) => {
    return (
        <Modal
            {...props}
            title='Moje kancelář'
        >
            <Stack className='gap-3'>
                <Stack className='gap-1'>
                    <Typography
                        component='h2'
                        className='text-base font-semibold'
                    >
                        Vrácené návštěvy:
                    </Typography>
                    <Typography className='text-sm'>
                        Vámi odeslané návštěvy, které byly vráceny k opravě nebo
                        doplnění.
                    </Typography>
                </Stack>
                <Stack className='gap-1'>
                    <Typography
                        component='h2'
                        className='text-base font-semibold'
                    >
                        Návštěvy ke zpracování:
                    </Typography>
                    <Typography className='text-sm'>
                        Vámi neodeslané minulé návštěvy a budoucí rozpracované
                        návštěvy.
                    </Typography>
                </Stack>
            </Stack>
        </Modal>
    )
}
