import axios from 'axios'
import { toQueryString } from '../../helpers'
import { PatientsArguments, PatientsCardList } from './types'
import { getRestApiConfig } from '../../api/config'
import { filterUndefinedValues } from '../../utils/globalUtils'

export const getPatientsCard = async (
    props: PatientsArguments,
): Promise<PatientsCardList> => {
    const { token, queries, skip, take } = props
    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const filteredQueries = filterUndefinedValues(queries || {})

    const queryString = toQueryString(filteredQueries)

    const finalQueryString = `${queryString}${
        queryString ? '&' : ''
    }skip=${skip}&take=${take}`

    const endpointUrl = new URL(
        `/api-mob/Patients${finalQueryString ? `?${finalQueryString}` : ''}`,
        baseUrl,
    ).href

    const response = await axios.get<PatientsCardList>(
        endpointUrl,
        headerConfig,
    )

    return response.data
}
