import { useEffect, useMemo, useState } from 'react'
import type { CarePlan, Plan, VoucherCarePlan } from '../../../types'
import { isEqual } from 'lodash'
import type { TreatmentStep } from '../types'

export const useStepDataChange = (
    props: Pick<TreatmentStep, 'carePlanIndex' | 'carePlans' | 'visitForm'>,
) => {
    const {
        carePlanIndex,
        carePlans,
        visitForm: {
            formMethods: { watch },
        },
    } = props
    const [stepData, setStepData] = useState<{
        plans: Plan[] | undefined
        voucherCarePlans: VoucherCarePlan[] | undefined
    }>()

    useEffect(() => {
        const untouchedStepData = {
            plans: carePlans?.[carePlanIndex]?.plans,
            voucherCarePlans: carePlans?.[carePlanIndex]?.voucherCarePlans,
        }
        setStepData(untouchedStepData)
    }, [carePlanIndex, carePlans])

    const watchCarePlans = watch(`carePlans.${carePlanIndex}`) as
        | CarePlan
        | undefined

    const watchStepData = useMemo(
        () => ({
            plans: watchCarePlans?.plans,
            voucherCarePlans: watchCarePlans?.voucherCarePlans,
        }),
        [watchCarePlans],
    )

    const isStepDataChanged = !!carePlans && !isEqual(stepData, watchStepData)

    return isStepDataChanged
}
