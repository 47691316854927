import { useForm } from 'react-hook-form'
import {
    AddPatientsAnamnesisFormStep0Hook,
    CareAnamnesisStep0,
    CareAnamnesisStep0Keys,
    CareAnamnesisStepQuery,
    Doctor,
    IAddPatientAnamnesisFormStep0,
} from '../types'
import { yupResolver } from '@hookform/resolvers/yup'
import { useContext, useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import {
    getPatientCareAnamnesis,
    PATIENT_CARE_ANAMNESIS_QUERY,
} from './services'
import { AuthContext } from '../../../providers'
import { anamnesisStep0Schema } from './schema'
import { useSavePatientCareAnamnesisMutation } from './hooks'
import { validateDate } from '../../../utils/functionsUtils'

export const useAddPatientAnamnesisFormStep0 = (
    props: AddPatientsAnamnesisFormStep0Hook,
): IAddPatientAnamnesisFormStep0 => {
    const { setCurrentStep, anamnesisId, currentStep, canEditCareAnamnesis } =
        props
    const { token } = useContext(AuthContext)
    const [isFormLoading, setIsFormLoading] = useState(true)
    const [invalidDateInputs, setInvalidDateInputs] = useState<
        CareAnamnesisStep0Keys[]
    >([])
    const [disabledServerInputs, setDisabledServerInputs] = useState<
        CareAnamnesisStep0Keys[]
    >([])
    const [doctorOptions, setDoctorOptions] = useState<
        { ID: number; FullName: string }[]
    >([])

    const { mutatePatientCareAnamnesisStepPut } =
        useSavePatientCareAnamnesisMutation()

    const defaultValues: CareAnamnesisStep0 = {
        ClientName: '',
        InsuranceNumber: '',
        InsuranceCompanyID: 0,
        Address: null,
        Phone: '',
        AddressNote: '',
        FamilyContact: '',
        FamilyContactNote: '',
        PracticalDoctor: null,
        Doctor: null,
        IncomingDate: new Date(),
        TransferFrom: '',
        Medication: '',
        CareDiagnoses: '',
        MedicalDiagnoses: '',
    }

    const validationSchema = anamnesisStep0Schema

    const formMethods = useForm<CareAnamnesisStep0>({
        criteriaMode: 'all',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues,
    })

    const { data, isLoading, error } = useQuery<CareAnamnesisStepQuery, Error>(
        [PATIENT_CARE_ANAMNESIS_QUERY, anamnesisId, currentStep],
        () => getPatientCareAnamnesis(anamnesisId, currentStep, token),
        {
            enabled: !!anamnesisId && !!token,
        },
    )

    useEffect(() => {
        if (data && !isLoading) {
            if (data?.content) {
                const deserializedData: CareAnamnesisStep0 = JSON.parse(
                    data.content,
                )

                const doctors = deserializedData?.Doctors || []
                const formattedDoctors = doctors.map(
                    (doctor: { ID: number; FullName: string }) => ({
                        ID: doctor.ID,
                        FullName: doctor.FullName,
                    }),
                )
                setDoctorOptions(formattedDoctors)

                const disabledInputs: CareAnamnesisStep0Keys[] = []
                const invalidDateInputs: CareAnamnesisStep0Keys[] = []

                formMethods.reset(deserializedData)

                if (
                    deserializedData?.IncomingDate &&
                    !validateDate(deserializedData?.IncomingDate)
                ) {
                    invalidDateInputs.push('IncomingDate')
                }

                if (deserializedData?.Phone) {
                    disabledInputs.push('Phone')
                }

                if (deserializedData?.Address) {
                    disabledInputs.push('Address')
                }

                if (deserializedData?.SelectedDoctorID) {
                    const selectedDoctor = formattedDoctors.find(
                        (doctor: Doctor) =>
                            doctor.ID === deserializedData?.SelectedDoctorID,
                    )
                    if (selectedDoctor) {
                        formMethods.setValue('Doctor', selectedDoctor)
                    }
                }

                if (deserializedData?.SelectedPracticalDoctorID) {
                    const selectedPracticalDoctor = formattedDoctors.find(
                        (doctor: Doctor) =>
                            doctor.ID ===
                            deserializedData?.SelectedPracticalDoctorID,
                    )
                    if (selectedPracticalDoctor) {
                        formMethods.setValue(
                            'PracticalDoctor',
                            selectedPracticalDoctor,
                        )
                    }
                }
                if (deserializedData?.PracticalDoctor) {
                    formMethods.setValue(
                        'SelectedPracitcalDoctorString',
                        deserializedData?.PracticalDoctor?.FullName,
                    )
                    disabledInputs.push('PracticalDoctor')
                }

                setDisabledServerInputs(disabledInputs)
                setInvalidDateInputs(invalidDateInputs)
            }

            setIsFormLoading(false)
        }
    }, [data, isLoading, formMethods])

    const submitForm = async (
        formData: CareAnamnesisStep0,
        nextStep: boolean,
    ) => {
        try {
            const formattedData = {
                ...formData,
                Doctors: null,
                SelectedDoctorID: formData?.Doctor?.ID,
                SelectedPracticalDoctorID: formData?.PracticalDoctor?.ID,
            }

            if (canEditCareAnamnesis) {
                await mutatePatientCareAnamnesisStepPut({
                    anamnesisID: anamnesisId,
                    data: {
                        currentStep: currentStep,
                        content: JSON.stringify(formattedData),
                    },
                })
            }

            if (nextStep) setCurrentStep((prev) => prev + 1)
            else {
                setCurrentStep((prev) => prev - 1)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        isLoading: isFormLoading || isLoading,
        error: error ? !!error : false,
        doctorOptions,
        disabledServerInputs,
        invalidDateInputs,
        formMethods,
        submitForm,
        ...props,
    }
}
