import { createContext, PropsWithChildren } from 'react'
import { useAuth } from './hooks'
import type { AuthUser } from './types'

interface AuthContextProps {
    isAuthenticated: boolean
    user?: AuthUser
    token?: string
}

export const AuthContext = createContext<AuthContextProps>({
    isAuthenticated: false,
})

export const AuthProvider = ({ children }: PropsWithChildren) => {
    const contextValue = useAuth()

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    )
}
