import { Stack, Typography } from '@mui/material'
import Modal from '../Modal'
import { UseHelpModalProps } from './types'

function MedicationModal(props: UseHelpModalProps) {
    return (
        <Modal
            {...props}
            title='Medikace'
        >
            <Stack className='gap-3'>
                <Stack className='gap-1'>
                    <Typography
                        component='h2'
                        className='text-base font-semibold'
                    >
                        Medikace:
                    </Typography>
                    <Typography className='text-sm'>
                        Medikace zapsaná na kartě Odbornost - Medikace.
                    </Typography>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default MedicationModal
