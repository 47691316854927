import {
    Box,
    Modal as MaterialModal,
    Slide,
    Paper,
    IconButton,
    Stack,
    Typography,
} from '@mui/material'
import { FC, useState } from 'react'
import { usePatientsAnamnesisFormModal } from '../hooks/usePatientsFormModal'
import { wrap } from '../../../utils'
import CloseIcon from '@mui/icons-material/Close'

import {
    AddPatientAnamnesisFormModalProps,
    AddPatientsAnamnesisFormModalHook,
} from '../types'
import { AddPatientAnamnesisFormStep1 } from './AddPatientAnamnesisFormStep1'
import { AddPatientAnamnesisFormStep0 } from './AddPatientAnamnesisFormStep0'
import { AddPatientAnamnesisFormStep2 } from './AddPatientAnamnesisFormStep2'
import { AddPatientAnamnesisFormStep3 } from './AddPatientAnamnesisFormStep3'
import { AddPatientAnamnesisFormStep4 } from './AddPatientAnamnesisFormStep4'
import { AddPatientAnamnesisFormStep5 } from './AddPatientAnamnesisFormStep5'
import { AddPatientAnamnesisFormStep6 } from './AddPatientAnamnesisFormStep6'
import { AddPatientAnamnesisFormStep7 } from './AddPatientAnamnesisFormStep7'
import { AddPatientAnamnesisFormStep8 } from './AddPatientAnamnesisFormStep8'
import { AddPatientAnamnesisFormStep9 } from './AddPatientAnamnesisFormStep9'
import { AddPatientAnamnesisFormStep10 } from './AddPatientAnamnesisFormStep10'
import { AddPatientAnamnesisFormStep11 } from './AddPatientAnamnesisFormStep11'
import { AddPatientAnamnesisFormStep12 } from './AddPatientAnamnesisFormStep12'
export const AddPatientAnamnesisFormModalView: FC<
    AddPatientAnamnesisFormModalProps
> = (props) => {
    const {
        handleClose,
        step,
        setStep,
        selectedPatientAnamnesisID,
        anamnesisId,
        canEditCareAnamnesis,
        canReadCareAnamnesis,
    } = props

    const [saveCurrentStepOnClose, setSaveCurrentStepOnClose] = useState<
        (() => Promise<void>) | null
    >(null)

    const handleModalClose = async () => {
        if (saveCurrentStepOnClose !== null) {
            await saveCurrentStepOnClose()
        }
        handleClose()
    }

    return (
        <MaterialModal
            {...props}
            onClose={async () => await handleModalClose()}
        >
            <Slide
                direction='up'
                in={true}
            >
                <Box
                    sx={{
                        position: 'absolute' as 'absolute',
                        bottom: '0',
                        width: '100%',
                        minHeight: '20vh',
                        overflowY: 'auto',
                        borderRadius: '6px',
                        maxWidth: '768px',
                        '@media screen and (min-width: 768px)': {
                            left: 'calc(50% - 384px)',
                            bottom: 'auto',
                            top: '50%',
                            transform: 'translateY(-50%) !important',
                        },
                    }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Paper
                        sx={{
                            background: '#feefe6',
                        }}
                    >
                        <Box>
                            <Box className='flex flex-col items-center  bg-[#feefe6] px-5 py-4'>
                                <Box className='relative flex w-full items-center justify-center'>
                                    <Typography
                                        className='w-full text-center font-bold'
                                        color={'primary'}
                                    >
                                        Přidat ošetř. anamnézu
                                        <Typography className='text-[#33333399]'>
                                            {step === 0 && 'základní informace'}
                                            {step === 1 &&
                                                'Zdravotní stav/Podpora zdraví'}
                                            {step === 2 &&
                                                'Výživa a metabolismus'}
                                            {step === 3 &&
                                                'Vylučování a výměna'}
                                            {step === 4 &&
                                                'Aktivita a odpočinek'}
                                            {step === 5 &&
                                                'Vnímání a poznávání'}
                                            {step === 6 && 'Vnímání sebe sama'}
                                            {step === 7 &&
                                                'Plnění rolí, mezilidské vztahy'}
                                            {step === 8 && 'Sexualita'}
                                            {step === 9 && 'Zvládání zátěže'}
                                            {step === 10 && 'Životní principy'}
                                            {step === 11 &&
                                                'Bezpečnost/ochrana'}
                                            {step === 12 && 'Komfort/Bolest'}
                                        </Typography>
                                    </Typography>
                                    <IconButton
                                        className='absolute right-0 bg-orange-400 p-1 text-white-reduced'
                                        onClick={async () =>
                                            await handleModalClose()
                                        }
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Stack
                                spacing={2}
                                style={{
                                    maxHeight: 'calc(95vh - 120px)',
                                    minHeight: 'calc(95vh - 120px)',
                                }}
                                className='custom-scrollbar relative overflow-y-auto px-5 py-2 pb-24'
                            >
                                {step === 0 && (
                                    <AddPatientAnamnesisFormStep0
                                        currentStep={step}
                                        anamnesisId={anamnesisId}
                                        patientId={selectedPatientAnamnesisID}
                                        setSaveCurrentStepOnClose={
                                            setSaveCurrentStepOnClose
                                        }
                                        setCurrentStep={setStep}
                                        canEditCareAnamnesis={
                                            canEditCareAnamnesis
                                        }
                                    />
                                )}
                                {step === 1 && (
                                    <AddPatientAnamnesisFormStep1
                                        currentStep={step}
                                        setSaveCurrentStepOnClose={
                                            setSaveCurrentStepOnClose
                                        }
                                        anamnesisId={anamnesisId}
                                        patientId={selectedPatientAnamnesisID}
                                        setCurrentStep={setStep}
                                        canEditCareAnamnesis={
                                            canEditCareAnamnesis
                                        }
                                    />
                                )}
                                {step === 2 && (
                                    <AddPatientAnamnesisFormStep2
                                        currentStep={step}
                                        setSaveCurrentStepOnClose={
                                            setSaveCurrentStepOnClose
                                        }
                                        anamnesisId={anamnesisId}
                                        patientId={selectedPatientAnamnesisID}
                                        setCurrentStep={setStep}
                                        canEditCareAnamnesis={
                                            canEditCareAnamnesis
                                        }
                                    />
                                )}
                                {step === 3 && (
                                    <AddPatientAnamnesisFormStep3
                                        currentStep={step}
                                        setSaveCurrentStepOnClose={
                                            setSaveCurrentStepOnClose
                                        }
                                        anamnesisId={anamnesisId}
                                        patientId={selectedPatientAnamnesisID}
                                        setCurrentStep={setStep}
                                        canEditCareAnamnesis={
                                            canEditCareAnamnesis
                                        }
                                    />
                                )}

                                {step === 4 && (
                                    <AddPatientAnamnesisFormStep4
                                        currentStep={step}
                                        setSaveCurrentStepOnClose={
                                            setSaveCurrentStepOnClose
                                        }
                                        anamnesisId={anamnesisId}
                                        patientId={selectedPatientAnamnesisID}
                                        setCurrentStep={setStep}
                                        canEditCareAnamnesis={
                                            canEditCareAnamnesis
                                        }
                                    />
                                )}

                                {step === 5 && (
                                    <AddPatientAnamnesisFormStep5
                                        currentStep={step}
                                        setSaveCurrentStepOnClose={
                                            setSaveCurrentStepOnClose
                                        }
                                        anamnesisId={anamnesisId}
                                        patientId={selectedPatientAnamnesisID}
                                        setCurrentStep={setStep}
                                        canEditCareAnamnesis={
                                            canEditCareAnamnesis
                                        }
                                    />
                                )}

                                {step === 6 && (
                                    <AddPatientAnamnesisFormStep6
                                        currentStep={step}
                                        setSaveCurrentStepOnClose={
                                            setSaveCurrentStepOnClose
                                        }
                                        anamnesisId={anamnesisId}
                                        patientId={selectedPatientAnamnesisID}
                                        setCurrentStep={setStep}
                                        canEditCareAnamnesis={
                                            canEditCareAnamnesis
                                        }
                                    />
                                )}

                                {step === 7 && (
                                    <AddPatientAnamnesisFormStep7
                                        currentStep={step}
                                        setSaveCurrentStepOnClose={
                                            setSaveCurrentStepOnClose
                                        }
                                        anamnesisId={anamnesisId}
                                        patientId={selectedPatientAnamnesisID}
                                        setCurrentStep={setStep}
                                        canEditCareAnamnesis={
                                            canEditCareAnamnesis
                                        }
                                    />
                                )}

                                {step === 8 && (
                                    <AddPatientAnamnesisFormStep8
                                        currentStep={step}
                                        setSaveCurrentStepOnClose={
                                            setSaveCurrentStepOnClose
                                        }
                                        anamnesisId={anamnesisId}
                                        patientId={selectedPatientAnamnesisID}
                                        setCurrentStep={setStep}
                                        canEditCareAnamnesis={
                                            canEditCareAnamnesis
                                        }
                                    />
                                )}
                                {step === 9 && (
                                    <AddPatientAnamnesisFormStep9
                                        currentStep={step}
                                        setSaveCurrentStepOnClose={
                                            setSaveCurrentStepOnClose
                                        }
                                        anamnesisId={anamnesisId}
                                        patientId={selectedPatientAnamnesisID}
                                        setCurrentStep={setStep}
                                        canEditCareAnamnesis={
                                            canEditCareAnamnesis
                                        }
                                    />
                                )}

                                {step === 10 && (
                                    <AddPatientAnamnesisFormStep10
                                        currentStep={step}
                                        setSaveCurrentStepOnClose={
                                            setSaveCurrentStepOnClose
                                        }
                                        anamnesisId={anamnesisId}
                                        patientId={selectedPatientAnamnesisID}
                                        setCurrentStep={setStep}
                                        canEditCareAnamnesis={
                                            canEditCareAnamnesis
                                        }
                                    />
                                )}

                                {step === 11 && (
                                    <AddPatientAnamnesisFormStep11
                                        currentStep={step}
                                        setSaveCurrentStepOnClose={
                                            setSaveCurrentStepOnClose
                                        }
                                        anamnesisId={anamnesisId}
                                        patientId={selectedPatientAnamnesisID}
                                        setCurrentStep={setStep}
                                        canEditCareAnamnesis={
                                            canEditCareAnamnesis
                                        }
                                    />
                                )}

                                {step === 12 && (
                                    <AddPatientAnamnesisFormStep12
                                        currentStep={step}
                                        setSaveCurrentStepOnClose={
                                            setSaveCurrentStepOnClose
                                        }
                                        anamnesisId={anamnesisId}
                                        patientId={selectedPatientAnamnesisID}
                                        setCurrentStep={setStep}
                                        handleClose={handleClose}
                                        canEditCareAnamnesis={
                                            canEditCareAnamnesis
                                        }
                                    />
                                )}
                            </Stack>
                        </Box>
                    </Paper>
                </Box>
            </Slide>
        </MaterialModal>
    )
}

export const AddPatientAnamnesisFormModal: FC<AddPatientsAnamnesisFormModalHook> =
    wrap(AddPatientAnamnesisFormModalView, usePatientsAnamnesisFormModal)
