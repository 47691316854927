import { memo, type FC } from 'react'
import type { PatientsFilterHookProps, PatientsFilterProps } from '../types'
import { Stack, Chip, Avatar } from '@mui/material'
import { Tune } from '@mui/icons-material'
import { wrap } from '../../../utils'
import { useUIDSeed } from 'react-uid'
import { usePatientsFilters } from '../hooks/usePatientsFilters'
import { FilterModal } from '../../../utils/FilterModal'

const PatientsFiltersView: FC<PatientsFilterProps> = (props) => {
    const {
        isLoading,
        filterModalState,
        patientsFilters,
        handleFilterActivate,
        resetFilterGroup,
        getSameFilterGroup,
        resetFilterAllActiveGroup,
        handleUseFilters,
        actualFilter,
        totalPatients,
        handleDeleteItem,
    } = props

    const { handleOpen, isOpen, handleClose } = filterModalState

    const uid = useUIDSeed()
    return !isLoading ? (
        <Stack
            direction='row'
            className='mb-4 flex-wrap items-center justify-between gap-4'
        >
            {
                <FilterModal
                    open={isOpen}
                    handleUseFilters={handleUseFilters}
                    filters={patientsFilters}
                    resetFilterAllActiveGroup={resetFilterAllActiveGroup}
                    handleFilterActivate={handleFilterActivate as any}
                    resetFilterGroup={resetFilterGroup as any}
                    title='Filtry kartotéky'
                    {...filterModalState}
                    handleClose={() => {
                        handleClose()
                    }}
                />
            }

            <Stack
                direction={'row'}
                className='flex-wrap gap-1'
            >
                {actualFilter.map((item) => (
                    <Chip
                        key={uid(item.name)}
                        color='primary'
                        variant='outlined'
                        avatar={
                            item.name?.split(' ').length > 1 || item.icon ? (
                                <Avatar
                                    sx={{
                                        '&.MuiAvatar-root': {
                                            display: 'none',
                                        },
                                    }}
                                    className='bg-orange-300 text-base'
                                    src={item.icon || ''}
                                    alt={item.name || ''}
                                >
                                    {!item.icon?.trim() &&
                                        `${item.name?.split(' ')[0][0]}${
                                            item.name?.split(' ')[1][0]
                                        }`}
                                </Avatar>
                            ) : undefined
                        }
                        label={item.name}
                        onDelete={() => {
                            handleFilterActivate({
                                item,
                                group: getSameFilterGroup(item.type)!,
                            })

                            handleDeleteItem(item)
                        }}
                        onClick={() => {
                            handleFilterActivate({
                                item,
                                group: getSameFilterGroup(item.type)!,
                            })
                            handleDeleteItem(item)
                        }}
                    />
                ))}

                <Chip
                    color='primary'
                    label='Zvolit filtry'
                    onClick={handleOpen}
                    icon={<Tune fontSize='small' />}
                />
            </Stack>
            <span>
                <b>{totalPatients}</b> pacientů
            </span>
        </Stack>
    ) : null
}

export const PatientsFilters: FC<PatientsFilterHookProps> = memo(
    wrap(PatientsFiltersView, usePatientsFilters),
)
