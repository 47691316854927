import type { FC } from 'react'
import { TreatmentStepView } from './TreatmentStepView'
import { wrap } from '../../../../utils'
import type { TreatmentStepHookProps } from '../../types'
import { useTreatmentStep } from '../../hooks'

export const TreatmentStep: FC<TreatmentStepHookProps> = wrap(
    TreatmentStepView,
    useTreatmentStep,
)
