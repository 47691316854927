import { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { AuthContext } from '../providers'
import { ProtectedRouteProps } from '../types'

export const ProtectedRoute = ({ outlet }: ProtectedRouteProps) => {
    const { isAuthenticated } = useContext(AuthContext)

    if (!isAuthenticated) return <Navigate to='/' />
    if (isAuthenticated) return outlet
    return null
}
