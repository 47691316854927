/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/display-name */
import type { ComponentPropsWithoutRef, FC } from 'react'

/**
 * Creates an implementation of a component using a View and a Controller hook
 * @param View A view component
 * @param useController A controller hook, can be parametrized with an object parameter
 *
 * Any parameters passed to controller will be passed as props to the resulting component
 */
export const wrap =
    <
        V extends FC<any>,
        C extends (argsObj: any) => ComponentPropsWithoutRef<V>,
    >(
        View: V,
        useController: C,
    ): FC<Parameters<C>[0]> =>
    ({ children, ...controllerArgs }) => {
        const AnyView = View as any
        const controller = useController(controllerArgs)
        return (
            <AnyView
                {...controller}
                {...controllerArgs}
            >
                {children}
            </AnyView>
        )
    }
export const formatPhone = (phoneNum: string) =>
    phoneNum?.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3')

export const filterUndefinedValues = <T extends Record<string, any>>(
    queries: T,
): Partial<T> => {
    return Object.entries(queries)
        .filter(([_, value]) => value !== undefined)
        .reduce((acc, [key, value]) => {
            acc[key as keyof T] = value
            return acc
        }, {} as Partial<T>)
}

export const stringAvatar = (name: string) =>
    `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`

const addMaximumScaleToMetaViewport = () => {
    const el = document.querySelector('meta[name=viewport]')

    if (el !== null) {
        let content = el.getAttribute('content')
        const re = /maximum-scale=[0-9.]+/g

        if (content && re.test(content)) {
            content = content.replace(re, 'maximum-scale=1.0')
        } else {
            content = [content, 'maximum-scale=1.0'].join(', ')
        }

        el.setAttribute('content', content)
    }
}

const checkIsIOS = (): boolean => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
    const hasMSStream = typeof (window as any).MSStream !== 'undefined'
    return isIOS && !hasMSStream
}

const disableIOSZoom = () => {
    if (checkIsIOS()) {
        addMaximumScaleToMetaViewport()
    }
}

export default disableIOSZoom
