import {
    Box,
    IconButton,
    Modal,
    Paper,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import type { MouseEvent } from 'react'
import type { ModalComponentProps } from '../types/components'

const AppModal = (props: ModalComponentProps) => {
    const {
        title,
        children,
        closeButton = true,
        handleClose,
        ...elementProps
    } = props

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <Modal
            onClick={handleClose}
            {...elementProps}
        >
            <Box
                sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: isMobile ? '95%' : '90%',
                    minHeight: '20vh',
                    maxHeight: '80vh',
                    borderRadius: '6px',
                    maxWidth: '768px',
                }}
                onClick={(e: MouseEvent) => e.stopPropagation()}
            >
                <Paper
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxHeight: '80vh',
                        background: '#feefe6',
                        borderRadius: '6px',
                    }}
                >
                    {/* Header */}
                    <Stack
                        className='relative flex h-[60px] justify-center px-5 py-4 pb-4'
                        sx={{
                            flexShrink: 0,
                        }}
                    >
                        {title && (
                            <Typography
                                className='pb-0 text-center font-bold'
                                textAlign='center'
                                color='primary.main'
                                fontWeight='medium'
                                pb={3}
                            >
                                {title}
                            </Typography>
                        )}
                        {closeButton && (
                            <Box className='absolute right-5  top-4 z-10  justify-end text-right'>
                                <IconButton
                                    className='ml-auto bg-orange-400 text-white-reduced'
                                    onClick={handleClose}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        )}
                    </Stack>

                    <Box
                        sx={{
                            overflowY: 'auto',
                            padding: '8px 20px 35px',
                        }}
                    >
                        {children}
                    </Box>
                </Paper>
            </Box>
        </Modal>
    )
}

export default AppModal
