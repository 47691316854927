import { useContext, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from '../providers'
import { PAGE_LOGIN_REDIRECT } from '../pages/LoginPage/hooks/useLogin'

export const PageWrapper = () => {
    const { token } = useContext(AuthContext)

    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        const lsToken = localStorage.getItem('token')

        if (!!lsToken && !!token && token !== lsToken) {
            navigate(0)
        }
    }, [navigate, token])

    useEffect(() => {
        sessionStorage.setItem(PAGE_LOGIN_REDIRECT, location.pathname)
    }, [location.pathname])

    return (
        <div className='relative'>
            <div className='pt-16'>
                <Outlet />
            </div>
        </div>
    )
}
