import { format, isValid, parseISO } from 'date-fns'

export const formatDate = (date: Date | string | null): boolean => {
    if (!date) return false

    // Pokud je datum ve formátu dd.mm.yyyy, nejprve ho převedeme na Date
    let parsedDate: Date
    if (typeof date === 'string') {
        const parts = date.split('.')
        if (parts.length !== 3) return false // Zkontroluj, jestli má správný počet částí

        const day = parseInt(parts[0], 10)
        const month = parseInt(parts[1], 10) - 1 // Měsíc je 0-indexovaný
        const year = parseInt(parts[2], 10)

        parsedDate = new Date(year, month, day)
    } else {
        parsedDate = date
    }

    // Ověř platnost datumu
    if (!isValid(parsedDate)) return false

    // Převod na formát yyyy-MM-dd
    const formattedDate = format(parsedDate, 'yyyy-MM-dd')

    // Vráť platnost datumu
    return true // Platný datum
}

export const validateDate = (date: Date | string | null): boolean => {
    if (!date) return false

    const parsedDate = typeof date === 'string' ? parseISO(date) : date

    return isValid(parsedDate)
}

export function formatDateIfValid(date: string | Date | undefined): string {
    // Zkontroluj, zda je datum definované
    if (!date) return ''

    // Zkus převést string na Date objekt, pokud není už Date instance
    const parsedDate = date instanceof Date ? date : new Date(date)

    // Zkontroluj, jestli je datum platné
    if (isNaN(parsedDate.getTime())) return String(date)

    // Formátování do dd.mm.yyyy
    const day = String(parsedDate.getDate()).padStart(2, '0')
    const month = String(parsedDate.getMonth() + 1).padStart(2, '0')
    const year = String(parsedDate.getFullYear()) // Celý čtyřmístný rok

    return `${day}.${month}.${year}`
}

const isValidDateFormat = (dateString: any) => {
    // Kontrola formátu dd.mm.yyyy
    const regex = /^\d{2}\.\d{2}\.\d{4}$/
    return regex.test(dateString)
}

export const convertToDate = (dateString: any) => {
    // Allow empty string as valid input
    if (dateString === '') {
        return '' // Return empty string if input is empty
    }

    if (!isValidDateFormat(dateString)) {
        return null // Return null if format is invalid
    }

    const [day, month, year] = dateString.split('.').map(Number)
    const date = new Date(year, month - 1, day) // Month is 0-indexed

    // Return date formatted as dd.mm.yyyy
    return isValid(date) ? formatDateForInput(date) : null
}

// Helper function to format date as dd.mm.yyyy
const formatDateForInput = (date: Date): string => {
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0') // Month is 0-indexed
    const year = date.getFullYear()

    return `${day}.${month}.${year}`
}
