import Modal from '../Modal'
import {
    Box,
    Autocomplete,
    TextField,
    InputLabel,
    Chip,
    Avatar,
    Button,
} from '@mui/material'
import { uid } from 'react-uid'
import { useBulletinUsersSelect } from './hooks/hooks'
import { BulletinMessageUsersModalProps } from './types'

function BulletinMessageUsersModal({
    setValue,
    preSelectedUsers,
    isOpen,
    triggerValidation,
    handleClose,
}: BulletinMessageUsersModalProps) {
    const {
        selectedUsers,
        setSelectedUsers,
        users,
        handleUpdateActualUsers,
        handleInputChangeDebounced,
    } = useBulletinUsersSelect({
        preSelectedUsers,
        isOpen,
        setValue,
        handleClose,
        triggerValidation,
    })

    return (
        <Modal
            handleClose={handleClose}
            open={isOpen}
            closeButton={false}
            title='Komu je zpráva určena?'
        >
            <Box className='flex h-full flex-col justify-between '>
                <Autocomplete
                    key={selectedUsers.length}
                    options={users}
                    id='users-message'
                    onChange={(_, value) => {
                        if (value) {
                            setSelectedUsers((prev) => [...prev, value])
                        }
                    }}
                    getOptionDisabled={(option) => {
                        return selectedUsers.some(
                            (item) => item.id === option.id,
                        )
                    }}
                    getOptionKey={(option) => option.id}
                    noOptionsText={'Žádné výsledky'}
                    getOptionLabel={(option) => option.fullName}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className='bg-white-reduced'
                            label='Uživatelé'
                        />
                    )}
                    onInputChange={handleInputChangeDebounced}
                    isOptionEqualToValue={(option, value) => {
                        return option.id === value.id
                    }}
                />

                <InputLabel className='mb-[10px] mt-[1.56rem] text-sm font-normal'>
                    aktuální výběr
                </InputLabel>

                <Box className='flex max-h-[150px] min-h-[60px] flex-row flex-wrap gap-2 overflow-y-auto rounded-[6px] border border-solid border-[#bfbfbf] bg-white-reduced p-[6px] text-sm'>
                    {selectedUsers.length > 0 &&
                        selectedUsers.map((item) => {
                            return (
                                <Chip
                                    key={uid(item.id)}
                                    label={item.fullName}
                                    color='primary'
                                    className={'bg-primary text-white'}
                                    avatar={
                                        item.fullName?.split(' ').length > 1 ||
                                        item.icon ? (
                                            <Avatar
                                                style={{
                                                    backgroundColor: '#C55007',
                                                }}
                                                className='text-base'
                                                src={item.icon || ''}
                                                alt={item.fullName || ''}
                                            >
                                                {!item.icon?.trim() &&
                                                    `${
                                                        item.fullName?.split(
                                                            ' ',
                                                        )[0][0]
                                                    }${
                                                        item.fullName?.split(
                                                            ' ',
                                                        )[1][0]
                                                    }`}
                                            </Avatar>
                                        ) : undefined
                                    }
                                    onClick={() =>
                                        setSelectedUsers((prev) =>
                                            prev.filter(
                                                (user) => user.id !== item.id,
                                            ),
                                        )
                                    }
                                    onDelete={() => {
                                        setSelectedUsers((prev) =>
                                            prev.filter(
                                                (user) => user.id !== item.id,
                                            ),
                                        )
                                    }}
                                />
                            )
                        })}
                </Box>
                <Box className='mt-20 flex justify-end gap-2'>
                    <Button
                        type='button'
                        variant='outlined'
                        size='small'
                        className='bg-white-reduced text-sm font-bold normal-case shadow-none'
                        onClick={handleClose}
                    >
                        zrušit
                    </Button>
                    <Button
                        variant='contained'
                        size='small'
                        type='button'
                        className='text-sm font-bold normal-case shadow-none'
                        onClick={handleUpdateActualUsers}
                    >
                        uložit a zavřít
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default BulletinMessageUsersModal
