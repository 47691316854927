import { useEffect, useRef, useState } from 'react'
import type { HorizontalIntersect } from '../types'

export const useHorizontalIntersect = (): HorizontalIntersect => {
    const [isVisible, setIsVisible] = useState(false)
    const targetRef = useRef<HTMLDivElement>(null)
    const observer = useRef<IntersectionObserver>()

    useEffect(() => {
        observer.current = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting)
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 1,
            },
        )

        const targetNode = targetRef.current
        if (targetNode) {
            observer.current.observe(targetNode)
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            const cleanupTargetNode = targetRef.current
            if (cleanupTargetNode && observer.current) {
                observer.current.unobserve(cleanupTargetNode)
            }
        }
    }, [])

    return {
        isVisible,
        targetRef,
    }
}
