import AppModal from '../../../components/Modal'
import {
    Box,
    CircularProgress,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material'
import { EditPatientAnamnesisModalProps } from '../types'
import { useEditPatientAnamnesis } from '../hooks/useEditPatientAnamnesis'
import { useUIDSeed } from 'react-uid'
import PatientAnamneses from './PatientAnamneses'

import WarningAmberIcon from '@mui/icons-material/WarningAmber'

function EditCareAnamnesesModal({
    open,
    patient,
    handleClose,
    setSelectedPatientAnamnesis,
}: EditPatientAnamnesisModalProps) {
    const uid = useUIDSeed()
    const {
        CareAnamnesesList,
        isLoading,
        handleCreateAnamnesis,
        handleCloseConfirmationDialog,
        isOpenConfirmationDialog,
        handleCreateConfirmation,
    } = useEditPatientAnamnesis({
        patientId: patient.id,
    })

    return (
        <AppModal
            open={open}
            title='Ošetř. anamnéza'
            handleClose={handleClose}
        >
            <Box>
                {isLoading ? (
                    <Box className='flex justify-center'>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box>
                        <Typography className='mb-4 text-base font-bold'>
                            Nová anamnéza
                        </Typography>
                        <Button
                            type='button'
                            size='small'
                            className='mb-5 normal-case shadow-sm'
                            variant='contained'
                            disabled={!patient.canEditCareAnamnesis}
                            onClick={handleCreateAnamnesis}
                        >
                            Přidat novou ošetř. anamnézu
                        </Button>

                        {CareAnamnesesList.length === 0 ? (
                            <Typography
                                variant='body2'
                                className='mt-4 text-center'
                                color='text-secondary'
                            >
                                Nenalezeny žádné Ošetřovatelské anamnézy
                            </Typography>
                        ) : (
                            <Typography className='mb-4 text-base font-bold'>
                                Ostatní anamnézy ({CareAnamnesesList.length})
                            </Typography>
                        )}
                        <Box className='flex flex-col gap-4 '>
                            {CareAnamnesesList.map((careAnamnesis) => {
                                return (
                                    <PatientAnamneses
                                        setSelectedPatientAnamnesis={
                                            setSelectedPatientAnamnesis
                                        }
                                        anamnesis={careAnamnesis}
                                        key={uid(careAnamnesis.id)}
                                    />
                                )
                            })}
                        </Box>
                    </Box>
                )}

                <Dialog
                    open={isOpenConfirmationDialog}
                    onClose={handleCloseConfirmationDialog}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                    sx={{
                        '& .MuiPaper-root': {
                            backgroundColor: '#feefe6',

                            borderRadius: '10px',
                            boxShadow: 0,
                        },
                    }}
                >
                    <DialogTitle
                        id='alert-dialog-title'
                        className='flex items-center justify-center text-xl text-[#333]'
                    >
                        <WarningAmberIcon
                            sx={{
                                color: '#FFA500',
                                marginRight: '8px',
                                fontSize: '1.8rem',
                            }}
                        />{' '}
                        {'Zrušit anamnézu?'}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText
                            id='alert-dialog-description'
                            className='text-center text-base'
                        >
                            Již existuje aktivní anamnéza, vytvořením nové tuto
                            ukončíte.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className='justify-between p-4'>
                        <Button
                            type='button'
                            color='error'
                            variant='contained'
                            className='text-sm font-bold normal-case shadow-none'
                            onClick={handleCloseConfirmationDialog}
                        >
                            Zrušit
                        </Button>
                        <Button
                            type='button'
                            variant='contained'
                            className='text-sm font-bold normal-case shadow-none'
                            onClick={() => {
                                handleCreateConfirmation()
                                handleCloseConfirmationDialog()
                            }}
                            autoFocus
                        >
                            Pokračovat
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </AppModal>
    )
}

export default EditCareAnamnesesModal
