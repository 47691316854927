import { ConditionalInputHookProps } from '../../types'
import { ConditionalInputView } from './ConditionalInputView'
import { useConditionalInput } from '../../hooks/useConditionalInput'
import type { FC } from 'react'
import { wrap } from '../../../../utils'

export const ConditionalInput: FC<ConditionalInputHookProps> = wrap(
    ConditionalInputView,
    useConditionalInput,
)
