import { Button, Skeleton } from '@mui/material'
import type { MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import type { LoadingState } from '../../../types'
import moment from 'moment'

interface VisitOrderButtonProps extends LoadingState {
    disabled?: boolean
}

export const VisitOrderButton = ({
    isLoading,
    disabled,
}: VisitOrderButtonProps) => {
    const navigate = useNavigate()

    const handleNavigate = (e: MouseEvent) => {
        e.preventDefault()
        navigate(`/visit-order/${moment(new Date()).format('YYYY-MM-DD')}`)
    }

    return isLoading ? (
        <Skeleton
            height={34}
            variant='rectangular'
            className='mb-5 rounded'
            animation='wave'
        />
    ) : (
        <Button
            color='primary'
            variant='outlined'
            className='mb-5 bg-white-reduced'
            size='small'
            fullWidth
            sx={{ textTransform: 'none' }}
            onClick={handleNavigate}
            disabled={disabled}
        >
            zobrazit dnešní návštěvy
        </Button>
    )
}
