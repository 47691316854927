import type { LoginPageProps, UseLoginHookProps } from '../types'
import { useLogin } from './useLogin'
import { usePageTitle } from '../../../hooks/usePageTitle'

export const usePage = (props: UseLoginHookProps): LoginPageProps => {
    const form = useLogin()

    usePageTitle('Přihlášení')

    return { form }
}
