import {
    AddPatientAnamnesisFormModalProps,
    AddPatientsAnamnesisFormModalHook,
} from '../types'
import { useState } from 'react'

export const usePatientsAnamnesisFormModal = (
    props: AddPatientsAnamnesisFormModalHook,
): AddPatientAnamnesisFormModalProps => {
    const [step, setStep] = useState<number>(
        props.anamnesisStep > 12 || props.anamnesisStep === 1
            ? 0
            : props.anamnesisStep,
    )

    return {
        step,
        setStep,
        ...props,
    }
}
