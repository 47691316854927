import { Backdrop, SpeedDial, SpeedDialAction } from '@mui/material'
import { useOpenState } from '../../hooks'
import { QuestionMark, Warning } from '@mui/icons-material'

import { useHelpDial } from './hooks'
import { HelpDialProps } from './types'
import UseHelpModalWrapper from './UseHelpModalWrapper'

export const HelpDial = ({
    children,
    isBulletinEditModeEnabled,
}: HelpDialProps) => {
    const { isOpen, handleClose, handleOpen } = useOpenState()

    const { handleActionClick, closeModal, selectedModal, image } =
        useHelpDial()

    return (
        <div>
            <Backdrop
                open={isOpen}
                className='z-50'
            />
            <SpeedDial
                ariaLabel='Zobrazení nápovědy'
                className='fixed  left-4'
                sx={{
                    bottom: isBulletinEditModeEnabled ? '4rem' : '1rem',
                }}
                icon={<QuestionMark className='text-white-reduced' />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={isOpen && !selectedModal}
                FabProps={{
                    size: 'small',
                }}
            >
                <SpeedDialAction
                    icon={<Warning />}
                    tooltipTitle='Hlášení chyby'
                    tooltipPlacement='right'
                    tooltipOpen
                    sx={{
                        '& .MuiSpeedDialAction-staticTooltipLabel': {
                            width: 'max-content',
                        },
                    }}
                    onClick={handleActionClick('error')}
                />

                {children && children({ handleClose, handleActionClick })}
            </SpeedDial>

            {selectedModal && (
                <UseHelpModalWrapper
                    selectedModal={selectedModal}
                    image={image}
                    disableRestoreFocus
                    open={!!selectedModal}
                    handleClose={closeModal}
                    onClick={(e: React.MouseEvent) => {
                        e.stopPropagation()
                        closeModal()
                    }}
                />
            )}
        </div>
    )
}
