import { useLongText } from '../../../hooks'
import type { VoucherPlanHookProps, VoucherPlanProps } from '../types'

export const useVoucherPlan = (
    props: VoucherPlanHookProps,
): VoucherPlanProps => {
    const { content, instructions } = props

    const contentText = useLongText({ text: content })
    const instructionsText = useLongText({ text: instructions })

    return { contentText, instructionsText, ...props }
}
