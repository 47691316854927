import { Grid, Stack, Typography } from '@mui/material'
import { useUIDSeed } from 'react-uid'
import { TreatmentStepsProps } from '../../types'
import { TreatmentStep } from './TreatmentStep'
import { useMemo } from 'react'

export const TreatmentSteps = (props: TreatmentStepsProps) => {
    const {
        isLoading,
        visitForm,
        canEdit,
        lastVisit,
        carePlans,
        updateVisitOnClose,
        canSave,
    } = props

    const {
        formMethods: { watch },
    } = visitForm

    const uid = useUIDSeed()
    const currentValues = watch('carePlans')

    const itemsWithoutDiagnose = useMemo(
        () => currentValues?.filter((item) => !item?.diagnose),
        [currentValues],
    )

    return !!visitForm?.carePlanMethods?.fields?.length ? (
        <Grid
            item
            xs={12}
        >
            {!!itemsWithoutDiagnose?.length && (
                <>
                    <Typography className='pb-6 font-medium'>
                        Bez diagnózy
                    </Typography>
                    <Stack
                        direction='column'
                        spacing={2}
                        className='relative mb-6'
                    >
                        {(isLoading
                            ? Array.from(new Array(3))
                            : visitForm.carePlanMethods.fields
                        )
                            ?.filter((carePlan) => !carePlan?.diagnose)
                            .map((carePlan, carePlanIndex) => (
                                <TreatmentStep
                                    canEdit={canEdit}
                                    canSave={canSave}
                                    key={uid(carePlan?.id || Math.random())}
                                    isLoading={isLoading}
                                    visitForm={visitForm}
                                    carePlanIndex={carePlanIndex}
                                    lastVisit={lastVisit}
                                    carePlans={carePlans}
                                    updateVisitOnClose={updateVisitOnClose}
                                    {...carePlan}
                                />
                            ))}
                    </Stack>
                </>
            )}
            {!!currentValues?.filter((carePlan) => !!carePlan?.diagnose)
                .length && (
                <>
                    <Typography className='pb-6 font-medium'>
                        Kroky ošetření
                    </Typography>
                    <Stack
                        direction='column'
                        className='relative'
                    >
                        {(isLoading
                            ? Array.from(new Array(3))
                            : visitForm.carePlanMethods.fields
                        )
                            ?.filter((carePlan) => !!carePlan?.diagnose)
                            ?.map((carePlan, carePlanIndex) => (
                                <TreatmentStep
                                    canEdit={canEdit}
                                    canSave={canSave}
                                    key={uid(carePlan?.id || Math.random())}
                                    isLoading={isLoading}
                                    visitForm={visitForm}
                                    carePlanIndex={
                                        carePlanIndex +
                                        (itemsWithoutDiagnose?.length || 0)
                                    }
                                    lastVisit={lastVisit}
                                    carePlans={carePlans}
                                    updateVisitOnClose={updateVisitOnClose}
                                    {...carePlan}
                                />
                            ))}
                    </Stack>
                </>
            )}
        </Grid>
    ) : null
}
