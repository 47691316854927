import axios from 'axios'
import { getRestApiConfig } from '../../api/config'
import { BulletinPatientsSearchProps, BulletinUsersProps } from './types'

export const getBulletinUsersSelect = async (
    name: string,
    token?: string,
): Promise<BulletinUsersProps[]> => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const endpointUrl = new URL(`/api-mob/BulletinFilters/Users`, baseUrl)
    endpointUrl.searchParams.append('filterQuery', name)

    const response = await axios.get(endpointUrl.href, headerConfig)
    return response.data
}

export const getBulletinPatientsSelect = async (
    name: string,
    personalNumber?: string,
    token?: string,
): Promise<BulletinPatientsSearchProps[]> => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const endpointUrl = new URL(`/api-mob/BulletinFilters/Patients`, baseUrl)

    if (name && personalNumber) {
        // Append filterQuery with name and personalNumber separated by '&'
        endpointUrl.searchParams.append(
            'filterQuery',
            `${name} ${personalNumber}`,
        )
    } else if (name) {
        // Append filterQuery with only name
        endpointUrl.searchParams.append('filterQuery', name)
    } else if (personalNumber) {
        // Append filterQuery with only personalNumber
        endpointUrl.searchParams.append('filterQuery', personalNumber)
    }
    const response = await axios.get(endpointUrl.href, headerConfig)

    return response.data
}

export const getBulletinDocumentFile = async (
    documentId?: string,
    token?: string,
    width?: number,
    height?: number,
): Promise<string> => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const endpointUrl = new URL(
        `/api-mob/BulletinDocuments/${documentId}/?${
            width ? `width=${width}` : ''
        }&${height ? `height=${height}` : ''}`,
        baseUrl,
    ).href

    const response = await axios.get<string>(endpointUrl, headerConfig)
    return response.data
}
