import { useEffect, useState } from 'react'
import { UseFormSetValue } from 'react-hook-form'
import { BulletinForm, urlLink } from '../../../types/components'
import { useUIDSeed } from 'react-uid'

type useBulletinUrlLinksProps = {
    setValue: UseFormSetValue<BulletinForm>
    urlLinks: urlLink[]
}

export const useBulletinUrlLinks = ({
    setValue,
    urlLinks,
}: useBulletinUrlLinksProps) => {
    const uid = useUIDSeed()

    const [inputs, setInputs] = useState([
        {
            id: uid(Date.now() + Math.random()),
            value: { name: '', urlAddress: '' },
            editable: true,
        },
    ])

    useEffect(() => {
        if (urlLinks && urlLinks.length > 0) {
            const data = urlLinks.map((link) => ({
                id: uid(Date.now() + Math.random()),
                value: {
                    name: link?.name || '',
                    urlAddress: link?.urlAddress || '',
                },
                editable: true,
            }))
            setInputs(data)
        }
    }, [urlLinks, uid])

    useEffect(() => {
        const newValues = inputs.map((input) => ({
            name: input.value.name,
            urlAddress: input.value.urlAddress,
        }))
        setValue('urlLinks', newValues)
    }, [inputs, setValue])

    const handleAddInput = () => {
        setInputs((prevInputs) => [
            ...prevInputs,
            {
                id: uid(Date.now() + Math.random()),
                value: { name: '', urlAddress: '' },
                editable: true,
            },
        ])
    }

    const handleRemoveInput = (idToRemove: string) => {
        setInputs((prevInputs) => {
            if (prevInputs.length === 1) return prevInputs
            return prevInputs.filter((input) => input.id !== idToRemove)
        })
    }

    const handleToggleEdit = (id: string) => {
        setInputs((prevInputs) =>
            prevInputs.map((input) =>
                input.id === id
                    ? { ...input, editable: !input.editable }
                    : input,
            ),
        )
    }

    return {
        handleAddInput,
        setInputs,
        inputs,
        handleRemoveInput,
        handleToggleEdit,
    }
}
