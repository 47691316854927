import {
    type PropsWithChildren,
    useState,
    createContext,
    Dispatch,
    SetStateAction,
} from 'react'
import { PatientFilterQueries } from '../pages/PatientsPage/types'

interface PatientFiltersContextProps {
    patientsQueryFilters?: PatientFilterQueries
    setPatientsQueryFilters: Dispatch<
        SetStateAction<PatientFilterQueries | undefined>
    >
}

export const PatientsFiltersContext = createContext<PatientFiltersContextProps>(
    {
        patientsQueryFilters: undefined,
        setPatientsQueryFilters: () => {},
    },
)

export const PatientFiltersProvider = ({ children }: PropsWithChildren) => {
    const [patientsQueryFilters, setPatientsQueryFilters] =
        useState<PatientFilterQueries>()

    return (
        <PatientsFiltersContext.Provider
            value={{ patientsQueryFilters, setPatientsQueryFilters }}
        >
            {children}
        </PatientsFiltersContext.Provider>
    )
}
