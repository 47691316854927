import { useContext } from 'react'
import { AuthContext, BulletinFiltersContext } from '../../providers'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
    archiveMultipleNotifications,
    deleteMultipleNotifications,
    deleteNotification,
    getAvailableFilterUsers,
    getNotification,
    getNotifications,
    getNotificationsCount,
    postFnolNotification,
    postNotification,
    putNotification,
    readMultipleNotifications,
} from './services'
import type {
    BulletinCountResponse,
    BulletinDeleteProps,
    BulletinFilterQueries,
    BulletinMessageQueryResponse,
    BulletinMultipleArchiveProps,
    BulletinMultipleDeleteProps,
    BulletinMultipleReadProps,
    BulletinPostProps,
    BulletinQueryResponse,
    BulletinUpdateProps,
} from './types'
import type {
    BulletinFormFnolFormatted,
    BulletinMessage,
} from '../../types/components'
import type { ErrorResponse } from '../user/types'
import { useCustomSnackbar } from '../../hooks'
import type { AxiosError } from 'axios'
import { BulletinFilterItems } from '../../pages/BulletinPage/types'

export const useBulletinListQuery = (
    queries?: BulletinFilterQueries,
    isComponentLoading?: boolean,
): BulletinQueryResponse => {
    const { token } = useContext(AuthContext)
    const {
        data: bulletinList,
        isLoading,
        isError,
    } = useQuery(
        ['notifications', queries || {}],
        async () => await getNotifications({ token, queries }),
        {
            enabled: !isComponentLoading,
        },
    )

    return { bulletinList, isLoading, isError }
}

export const useBulletinMessageQuery = (
    id: number,
): BulletinMessageQueryResponse => {
    const { token } = useContext(AuthContext)
    const {
        data: bulletin,
        isLoading,
        isError,
    } = useQuery(
        ['notifications', id],
        async () => await getNotification(token, String(id)),
        {
            enabled: !!token && !!id,
        },
    )

    return { bulletin, isLoading, isError }
}

export const useBulletinCountQuery = (): BulletinCountResponse => {
    const { token } = useContext(AuthContext)

    const {
        data: bulletinCount,
        isLoading,
        isError,
    } = useQuery(
        ['notifications', 'count'],
        async () => await getNotificationsCount(token),
    )

    return { bulletinCount, isLoading, isError }
}

export const useBulletinUpdateMutation = (stopListInvalidate?: boolean) => {
    const { token } = useContext(AuthContext)
    const { bulletinQueryFilters } = useContext(BulletinFiltersContext)
    const queryClient = useQueryClient()
    const snackbar = useCustomSnackbar()

    const { mutateAsync: mutateBulletinUpdate, isLoading } = useMutation(
        async (body: BulletinUpdateProps) =>
            await putNotification({ token, ...body }),
        {
            onSuccess: (data: BulletinMessage) => {
                queryClient.setQueryData(['notifications', data.id], data)
                queryClient.invalidateQueries(['notifications', 'count'])
                if (!stopListInvalidate) {
                    queryClient.invalidateQueries(
                        ['notifications', bulletinQueryFilters || {}],
                        {
                            exact: true,
                        },
                    )
                }
            },
            onError: (res) => {
                const error = (res as AxiosError).response
                    ?.data as ErrorResponse
                snackbar(`${error.title}: ${error.detail}.`, 'error')
            },
        },
    )

    return { mutateBulletinUpdate, isLoading }
}

export const useBulletinUpdateFnolMutation = (stopListInvalidate?: boolean) => {
    const { token } = useContext(AuthContext)
    const { bulletinQueryFilters } = useContext(BulletinFiltersContext)
    const queryClient = useQueryClient()
    const snackbar = useCustomSnackbar()

    const { mutateAsync: mutateBulletinUpdate, isLoading } = useMutation(
        async (body: BulletinUpdateProps) =>
            await putNotification({ token, ...body }),
        {
            onSuccess: (data: BulletinMessage) => {
                queryClient.setQueryData(['notifications', data.id], data)
                queryClient.invalidateQueries(['notifications', 'count'])
                if (!stopListInvalidate) {
                    queryClient.invalidateQueries(
                        ['notifications', bulletinQueryFilters || {}],
                        {
                            exact: true,
                        },
                    )
                }
            },
            onError: (res) => {
                const error = (res as AxiosError).response
                    ?.data as ErrorResponse
                snackbar(`${error.title}: ${error.detail}.`, 'error')
            },
        },
    )

    return { mutateBulletinUpdate, isLoading }
}

export const useBulletinDeleteMutation = () => {
    const { token } = useContext(AuthContext)
    const snackbar = useCustomSnackbar()
    const queryClient = useQueryClient()
    const { bulletinQueryFilters } = useContext(BulletinFiltersContext)

    const { mutateAsync: mutateBulletinDelete, isLoading } = useMutation(
        async ({ id }: BulletinDeleteProps) =>
            await deleteNotification({ token, id }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(
                    ['notifications', bulletinQueryFilters || {}],
                    { exact: true },
                )
                queryClient.invalidateQueries(['filterUsers'])
                snackbar('Zpráva byla úspěšně smazána.', 'success')
            },
            onError: (res) => {
                const error = (res as AxiosError).response
                    ?.data as ErrorResponse
                snackbar(`${error.title}: ${error.detail}.`, 'error')
            },
        },
    )

    return { mutateBulletinDelete, isLoading }
}

export const useBulletinCreateMutation = () => {
    const { token } = useContext(AuthContext)
    const queryClient = useQueryClient()
    const snackbar = useCustomSnackbar()

    const { mutateAsync: mutateBulletinCreate, isLoading } = useMutation(
        async (body: BulletinPostProps) =>
            await postNotification({ token, ...body }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['notifications'])
                queryClient.invalidateQueries(['filterUsers'])
            },
            onError: (res) => {
                const error = (res as AxiosError).response
                    ?.data as ErrorResponse
                snackbar(`${error.title}: ${error.detail}.`, 'error')
            },
        },
    )

    return { mutateBulletinCreate, isLoading }
}

export const useBulletinCreateFnolMutation = () => {
    const { token } = useContext(AuthContext)
    const queryClient = useQueryClient()
    const snackbar = useCustomSnackbar()

    const { mutateAsync: mutateBulletinFnolCreate, isLoading } = useMutation(
        async (body: BulletinFormFnolFormatted) =>
            await postFnolNotification({ token, ...body }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['notifications'])
                queryClient.invalidateQueries(['filterUsers'])
            },
            onError: (res) => {
                const error = (res as AxiosError).response
                    ?.data as ErrorResponse
                snackbar(`${error.title}: ${error.errors.Message[0]}.`, 'error')
            },
        },
    )

    return { mutateBulletinFnolCreate, isLoading }
}

export const useFilterUsersQuery = (staticFilters: BulletinFilterItems[]) => {
    const { token } = useContext(AuthContext)

    const filterUsersQuery = useQuery(
        ['filterUsers'],
        async () => await getAvailableFilterUsers(token),
        {
            enabled: !!staticFilters,
        },
    )

    return filterUsersQuery
}

export const useDeleteMultipleNotifications = () => {
    const { token } = useContext(AuthContext)
    const snackbar = useCustomSnackbar()
    const queryClient = useQueryClient()
    const { bulletinQueryFilters } = useContext(BulletinFiltersContext)

    const { mutateAsync: mutateBulletinMultipleDelete, isLoading } =
        useMutation(
            async ({ ids }: BulletinMultipleDeleteProps) =>
                await deleteMultipleNotifications({ token, ids }),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(
                        ['notifications', bulletinQueryFilters || {}],
                        { exact: true },
                    )
                    queryClient.invalidateQueries(['filterUsers'])
                    snackbar('Zprávy byly úspěšně smazány.', 'success')
                },
                onError: (res) => {
                    const error = (res as AxiosError).response
                        ?.data as ErrorResponse
                    snackbar(`${error.title}: ${error.detail}.`, 'error')
                },
            },
        )

    return { mutateBulletinMultipleDelete, isLoading }
}

export const useArchiveMultipleNotifications = () => {
    const { token } = useContext(AuthContext)
    const snackbar = useCustomSnackbar()
    const queryClient = useQueryClient()
    const { bulletinQueryFilters } = useContext(BulletinFiltersContext)

    const { mutateAsync: mutateBulletinMultipleArchive, isLoading } =
        useMutation(
            async ({ ids, archiveStatus }: BulletinMultipleArchiveProps) =>
                await archiveMultipleNotifications({
                    token,
                    ids,
                    archiveStatus,
                }),
            {
                onSuccess: ({ archiveStatus }) => {
                    queryClient.invalidateQueries(
                        ['notifications', bulletinQueryFilters || {}],
                        { exact: true },
                    )

                    queryClient.invalidateQueries(['filterUsers'])

                    snackbar(
                        archiveStatus
                            ? 'Zprávy byly úspěšně archivovány.'
                            : 'Zprávy byly úspěšně obnoveny.',
                        'success',
                    )
                },
                onError: (res) => {
                    const error = (res as AxiosError).response
                        ?.data as ErrorResponse
                    snackbar(`${error.title}: ${error.detail}.`, 'error')
                },
            },
        )

    return { mutateBulletinMultipleArchive, isLoading }
}

export const useReadMultipleNotifications = () => {
    const { token } = useContext(AuthContext)
    const snackbar = useCustomSnackbar()
    const queryClient = useQueryClient()
    const { bulletinQueryFilters } = useContext(BulletinFiltersContext)

    const { mutateAsync: mutateBulletinMultipleRead, isLoading } = useMutation(
        async ({ ids }: BulletinMultipleReadProps) =>
            await readMultipleNotifications({ token, ids }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(
                    ['notifications', bulletinQueryFilters || {}],
                    { exact: true },
                )
                queryClient.invalidateQueries(['filterUsers'])
                snackbar('Zprávy byly úspěšně přečteny.', 'success')
            },
            onError: (res) => {
                const error = (res as AxiosError).response
                    ?.data as ErrorResponse
                snackbar(`${error.title}: ${error.detail}.`, 'error')
            },
        },
    )

    return { mutateBulletinMultipleRead, isLoading }
}
