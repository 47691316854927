import { useEffect, useState } from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { useOrderMutation } from '../../../api'
import { ListNurseVisit, VisitOrderItem } from '../../../api/visit/types'
import { VisitOrderPageProps } from '../types'

type ReorderProps = Pick<
    VisitOrderPageProps,
    'isVisitError' | 'isVisitLoading' | 'visitData'
> & {
    reorderDate: Date | null
}

export const useReorder = (props: ReorderProps) => {
    const { isVisitError, visitData, isVisitLoading, reorderDate } = props
    const [reorderData, setReorderData] = useState<ListNurseVisit[]>()

    const getNewOrder = (currentData?: ListNurseVisit[]) =>
        currentData?.reduce<VisitOrderItem[]>(
            (accumulator, currentValue, currentIndex) => {
                accumulator.push({
                    visitID: currentValue?.id,
                    order: currentIndex,
                })
                return accumulator
            },
            [],
        )

    const orderMutation = useOrderMutation(reorderDate)

    useEffect(() => {
        !isVisitLoading && !isVisitError && setReorderData(visitData?.data)
    }, [isVisitError, isVisitLoading, visitData?.data])

    const onDragEnd = async (result: DropResult) => {
        const { destination, source } = result

        if (!destination) {
            return
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return
        }

        const tempArray = reorderData
        const itemToMove = reorderData?.[source.index]
        const newIndex = destination.index

        if (!!itemToMove) {
            tempArray?.splice(source.index, 1)
            tempArray?.splice(newIndex, 0, itemToMove)
        }

        const orderData = getNewOrder(reorderData)
        orderData && (await orderMutation.mutateAsync(orderData))
    }

    return {
        reorderData,
        onDragEnd,
    }
}
