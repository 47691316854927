import { useSnackbar } from 'notistack'
import { useNavigationStatsMutation, usePhoneStatsMutation } from '../api'
import { Address } from '../types'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'

export const usePhoneLocation = (
    patientPhone?: string,
    patientAddress?: Address,
    latLng?: string,
): {
    handlePhoneButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void
    handleLocationButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void
} => {
    const phoneUsageStat = usePhoneStatsMutation()
    const navigationUsageStat = useNavigationStatsMutation()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const handlePhoneButtonClick = async (
        e: React.MouseEvent<HTMLButtonElement>,
    ) => {
        e.preventDefault()
        await phoneUsageStat.mutateAsync()
        if (!!patientPhone) {
            window.location.href = `tel:${patientPhone}`
        }
        if (!patientPhone) {
            enqueueSnackbar(`Telefon neuveden - není možné zavolat.`, {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
                action: (key) => (
                    <IconButton onClick={() => closeSnackbar(key)}>
                        <Close color='secondary' />
                    </IconButton>
                ),
            })
        }
    }

    const handleLocationButtonClick = async (
        e: React.MouseEvent<HTMLButtonElement>,
    ) => {
        e.preventDefault()
        await navigationUsageStat.mutateAsync()

        let url
        if (latLng === '' || !latLng) {
            url = `https://www.google.com/maps/place/${patientAddress?.latitude},${patientAddress?.longitude}`
        } else {
            url = `https://www.google.com/maps/dir/${latLng}/${patientAddress?.latitude},${patientAddress?.longitude}`
        }

        window.open(url, '_blank')
    }

    return {
        handlePhoneButtonClick,
        handleLocationButtonClick,
    }
}
