import { useMutation } from '@tanstack/react-query'
import { loginUser } from '../../../api'
import type { LoginFormData } from '../types'

export const useLoginMutation = () => {
    const loginMutation = useMutation(
        async (formData: LoginFormData) => await loginUser(formData),
    )

    return loginMutation
}
