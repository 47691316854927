import { useCallback, useContext, useEffect, useState } from 'react'
import { useOpenState } from '../../../hooks'
import {
    EPatientState,
    PatientFilterQueries,
    PatientsCardFilterType,
    PatientsFilterHookProps,
    PatientsFilterItems,
    PatientsFilterProps,
} from '../types'
import { PatientsFiltersContext } from '../../../providers/PatientFiltersProvider'
import { useFilterReset } from '../../BulletinPage/hooks/useBulletinFilterReset'
import { useFiltersActivate } from '../../../utils/useFiltersActivate'
import {
    getPatientsCardFilters,
    singleSelectPatientsGroup,
} from '../constatns/filters'
import { useSearchParams } from 'react-router-dom'

export const usePatientsFilters = (
    props: PatientsFilterHookProps,
): PatientsFilterProps => {
    const filterModalState = useOpenState()
    const { patientsQueryFilters } = useContext(PatientsFiltersContext)

    const [intialLoad, setIntialLoad] = useState(true)
    const [, setSearchParams] = useSearchParams()

    const [patientsFilters, setPatientsFilters] = useState<
        PatientsFilterItems[]
    >([])

    useEffect(() => {
        if (patientsQueryFilters && intialLoad) {
            setPatientsFilters(getPatientsCardFilters(patientsQueryFilters))
        }
    }, [patientsQueryFilters, intialLoad])

    const { resetFilterGroup, resetFilterAllActiveGroup } = useFilterReset({
        filterNameType: 'patients',
        filters: patientsFilters,
        setFilters: setPatientsFilters,
        singleSelectGroup: singleSelectPatientsGroup,
    })

    const {
        handleFilterActivate,
        extractedActiveFiltersWithType: activeFilters,
        getActiveGroup,
    } = useFiltersActivate<PatientsCardFilterType, string | boolean>({
        filters: patientsFilters,
        setFilters: setPatientsFilters,
        singleSelectGroup: singleSelectPatientsGroup,
    })

    const [actualFilter, setActualFilter] = useState(activeFilters)

    useEffect(() => {
        if (patientsFilters.length > 0 && intialLoad) {
            setActualFilter(activeFilters)
            setIntialLoad(false)
        }
    }, [patientsFilters, intialLoad, activeFilters])

    useEffect(() => {
        setActualFilter(activeFilters)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientsQueryFilters])

    const handleUseFilters = useCallback(
        (handleClose: () => void) => {
            const updatedFormat = activeFilters.reduce((acc, filter) => {
                if (filter.active) {
                    acc[filter.type] = acc[filter.type] ?? []

                    if (
                        typeof filter.value === 'string' &&
                        Object.values(EPatientState).includes(
                            filter.value as EPatientState,
                        )
                    ) {
                        ;(acc[filter.type] as EPatientState[]).push(
                            filter.value as EPatientState,
                        )
                    }
                }
                return acc
            }, {} as PatientFilterQueries)

            setSearchParams({
                page: '1',
                ...updatedFormat,
            })
            handleClose()
        },
        [activeFilters, setSearchParams],
    )

    const handleDeleteItem = useCallback(
        (item: any) => {
            const updatedFilters = activeFilters.filter((filter) => {
                return !(
                    filter.value === item.value && filter.type === item.type
                )
            })

            const updatedFormat = updatedFilters.reduce((acc, filter) => {
                acc[filter.type] = acc[filter.type] ?? []
                ;(acc[filter.type] as Array<string | boolean>).push(
                    filter.value,
                )

                return acc
            }, {} as PatientFilterQueries)

            setActualFilter(activeFilters)

            setSearchParams({
                page: '1',
                ...updatedFormat,
            })
        },
        [activeFilters, setSearchParams],
    )

    const getSameFilterGroup = useCallback(
        (groupType: PatientsCardFilterType) => {
            return patientsFilters.find(({ type }) => type === groupType)
        },
        [patientsFilters],
    )

    return {
        patientsFilters,
        actualFilter,
        activeFilters,
        handleFilterActivate,
        resetFilterGroup,
        filterModalState,
        handleUseFilters,
        resetFilterAllActiveGroup,
        handleDeleteItem,
        getSameFilterGroup,
        ...props,
    }
}
