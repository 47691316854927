import { Box, Button, Checkbox, Stack } from '@mui/material'

import { useUIDSeed } from 'react-uid'
import { usePatientsContent } from './hooks/usePatientsContent'
import {
    CareAnamneses,
    EDIT_PATIENTS_ENUM,
    PatientsCard,
    PatientsContentHook,
    PatientsPageContentProps,
} from './types'
import { PatientCard } from '../../components/PatientCard'

import { FC, useState } from 'react'
import { ContentLoader } from '../../components'
import { SortedEmpty } from '../../components/SortedVisit/SortedEmpty'
import { wrap } from '../../utils'
import { PatientsFilters } from './components/PatientsFilters'
import CustomPagination from '../../components/CustomPagination'
import { AddPatientAnamnesisFormModal } from './components/AddPatientAnamnesisFormModal'
import { useOpenState } from '../../hooks'
import EditCareAnamnesesModal from './components/EditCareAnamnesesModal'

const PatientsPageContentView: FC<PatientsPageContentProps> = (props) => {
    const {
        patientsQuery: { patientsCardList, isLoading },
        patientsQueryFilters,
        isMultiEditMode,
        setIsMultiEditMode,
        handlePatientCardSelect,
        handleSelectAll,
        location,
        handleMultiplePatientsAction,
        selectedPatientCards,
        handleSetNewPage,

        currentPage,
    } = props

    const uid = useUIDSeed()

    const [selectedPatient, setSelectedPatient] = useState<PatientsCard | null>(
        null,
    )
    const [selectedPatientAnamnesis, setSelectedPatientAnamnesis] =
        useState<CareAnamneses | null>(null)

    const {
        isOpen: isEditCareAnamnesisOpen,
        handleClose: handleCloseCareAnamneses,
        handleOpen: handleOpenCareAnamneses,
    } = useOpenState()
    return (
        <>
            <Box className='container'>
                <PatientsFilters
                    isLoading={isLoading}
                    totalPatients={patientsCardList?.totalItems}
                />
            </Box>
            {isMultiEditMode && (
                <Box className='container mb-4 mt-8 flex gap-2'>
                    <Button
                        type='button'
                        variant='contained'
                        size='small'
                        className='flex gap-1 text-sm font-bold normal-case shadow-none'
                        onClick={() => handleSelectAll()}
                    >
                        {selectedPatientCards.length ===
                        patientsCardList?.data?.length
                            ? 'Zrušit výběr'
                            : 'Vybrat vše'}
                    </Button>
                    <Button
                        type='button'
                        variant='outlined'
                        size='small'
                        className='flex gap-1 text-sm font-bold normal-case shadow-none'
                        onClick={() =>
                            setIsMultiEditMode && setIsMultiEditMode(false)
                        }
                    >
                        Zrušit úpravy
                    </Button>
                </Box>
            )}
            {isLoading ? (
                <ContentLoader />
            ) : patientsCardList?.data?.length ? (
                <Stack
                    className='gap-6'
                    sx={{
                        paddingLeft: isMultiEditMode ? '0.25rem' : '1.25rem',
                        paddingRight: isMultiEditMode ? '0.8rem' : '1.25rem',
                    }}
                >
                    {patientsCardList?.data?.map(
                        (patientCard: PatientsCard) => (
                            <Box
                                className='relative flex items-center gap-1 sm:gap-5'
                                key={uid(patientCard?.id) + 'box'}
                            >
                                {isMultiEditMode && (
                                    <Checkbox
                                        checked={selectedPatientCards.includes(
                                            patientCard?.id,
                                        )}
                                        onChange={() =>
                                            handlePatientCardSelect(
                                                patientCard?.id,
                                            )
                                        }
                                    />
                                )}
                                <PatientCard
                                    onClick={() => {
                                        console.log('DETAIL')
                                    }}
                                    setSelectedPatient={setSelectedPatient}
                                    handleOpenCareAnamneses={
                                        handleOpenCareAnamneses
                                    }
                                    isLoading={isLoading}
                                    latLng={location.latLng}
                                    className='flex-1 bg-white-reduced'
                                    patient={patientCard}
                                />
                            </Box>
                        ),
                    )}
                    {!isLoading && patientsQueryFilters && patientsCardList && (
                        <CustomPagination
                            count={Math.ceil(patientsCardList?.totalItems / 10)}
                            currentPage={currentPage}
                            handleSetNewPage={handleSetNewPage}
                        />
                    )}

                    {isEditCareAnamnesisOpen && selectedPatient && (
                        <EditCareAnamnesesModal
                            open={isEditCareAnamnesisOpen}
                            patient={selectedPatient}
                            setSelectedPatientAnamnesis={
                                setSelectedPatientAnamnesis
                            }
                            handleClose={handleCloseCareAnamneses}
                        />
                    )}

                    {selectedPatientAnamnesis !== null && selectedPatient && (
                        <AddPatientAnamnesisFormModal
                            anamnesisId={selectedPatientAnamnesis.id}
                            anamnesisStep={selectedPatientAnamnesis.state}
                            selectedPatientAnamnesisID={
                                selectedPatientAnamnesis.patientID
                            }
                            canEditCareAnamnesis={
                                selectedPatient?.canEditCareAnamnesis
                                    ? true
                                    : false
                            }
                            canReadCareAnamnesis={
                                selectedPatient?.canReadCareAnamnesis
                                    ? true
                                    : false
                            }
                            open={selectedPatientAnamnesis !== null}
                            handleClose={() =>
                                setSelectedPatientAnamnesis(null)
                            }
                        />
                    )}
                </Stack>
            ) : (
                <SortedEmpty emptyText='Momentálně zde nejsou žádní pacienti.' />
            )}

            {isMultiEditMode && (
                <Box
                    className='fixed bottom-0 left-0 flex  h-12 w-full items-center justify-center bg-[#F9A26C] px-2'
                    sx={{
                        borderTop: '1px solid #F88035',
                    }}
                >
                    <Box className='m-auto flex w-full max-w-[500px] items-center  justify-between '>
                        <Button
                            className='lowercase text-white-reduced'
                            type='button'
                            onClick={() =>
                                handleMultiplePatientsAction(
                                    EDIT_PATIENTS_ENUM.RenamePatients,
                                )
                            }
                        >
                            Přejmenovat pacienty
                        </Button>
                    </Box>
                </Box>
            )}
        </>
    )
}

export const PatientsPageContent: FC<PatientsContentHook> = wrap(
    PatientsPageContentView,
    usePatientsContent,
)
