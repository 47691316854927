import { Stack, Typography } from '@mui/material'
import Modal from '../Modal'
import { UseHelpModalProps } from './types'

function NoticeModal(props: UseHelpModalProps) {
    return (
        <Modal
            {...props}
            title='Nástěnka'
        >
            <Stack className='gap-3'>
                <Stack className='gap-1'>
                    <Typography
                        component='h2'
                        className='text-base font-semibold'
                    >
                        Nástěnka:
                    </Typography>
                    <Typography className='text-sm'>
                        Nástroj pro předávání zpráv ve vašem týmu.
                    </Typography>
                </Stack>
                <Stack className='gap-1'>
                    <Typography
                        component='h2'
                        className='text-base font-semibold'
                    >
                        Začínáme nástěnkou aneb jak komunikovat s týmem:
                    </Typography>
                    <Typography className='text-sm'>
                        <a href='https://webmiumtest.blob.core.windows.net/users/1358/assets/5cd9130967da6f57ad0d94f4577da777/esestrickanastenkatvorenizprav.mp4'>
                            Návod pro psaní zpráv na Nástěnce.
                        </a>
                    </Typography>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default NoticeModal
