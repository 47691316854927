import type { FC } from 'react'
import {
    Avatar,
    Button,
    CircularProgress,
    Grid,
    Input,
    Menu,
    MenuItem,
    Stack,
    Typography,
} from '@mui/material'
import { ProfileInput } from './components'
import { Navigation } from '../../components'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import { stringAvatar } from '../../utils'
import { useNavigate } from 'react-router-dom'
import type { ProfilePageProps } from './types'
import { KeyboardArrowDownRounded } from '@mui/icons-material'

export const ProfileView: FC<ProfilePageProps> = (props) => {
    const {
        user,
        profilePhotoHandles,
        handleLogOut,
        userPhotoQuery,
        menuDropdown,
        profilePhotoChange,
    } = props

    const {
        anchorEl,
        open: menuOpen,
        handleCloseMenu,
        handleOpenMenu,
    } = menuDropdown

    const { photoUploadLoading, handlePhotoDelete, photoDeleteLoading } =
        profilePhotoHandles
    const { data: profilePhoto } = userPhotoQuery

    const navigate = useNavigate()
    const trimmedUser = {
        firstname: user?.firstname?.trim(),
        surname: user?.surname?.trim(),
    }

    const mutationLoading = photoUploadLoading || photoDeleteLoading

    return (
        <main className='relative'>
            <Navigation>
                <Button
                    startIcon={<ArrowBackRoundedIcon />}
                    variant='contained'
                    size='small'
                    className='shadow-none'
                    onClick={() => navigate(-1)}
                >
                    <Typography
                        fontSize='inherit'
                        textTransform='lowercase'
                        className='text-white-reduced'
                    >
                        Zpět
                    </Typography>
                </Button>
            </Navigation>
            <section className='bg-orange-50'>
                <Stack
                    gap='1rem'
                    justifyContent='center'
                    alignItems='center'
                    className='relative mb-6 py-6 md:mb-10 '
                >
                    <Avatar
                        className='bg-orange-300 text-white-reduced'
                        alt={user?.fullName}
                        sx={{
                            width: '4.25rem',
                            height: '4.25rem',
                            fontSize: '1.75rem',
                        }}
                        src={profilePhoto}
                    >
                        {mutationLoading ? (
                            <CircularProgress color='secondary' />
                        ) : !trimmedUser?.firstname ||
                          !trimmedUser?.surname ? null : (
                            stringAvatar(
                                `${trimmedUser.firstname} ${trimmedUser.surname}`,
                            )
                        )}
                    </Avatar>
                    <Button
                        variant='outlined'
                        size='small'
                        className='border-2 bg-white-reduced lowercase text-gray-700 shadow-none'
                        endIcon={<KeyboardArrowDownRounded />}
                        onClick={handleOpenMenu}
                    >
                        změnit obrázek
                    </Button>
                    <Menu
                        id='basic-menu'
                        anchorEl={anchorEl}
                        open={menuOpen}
                        onClose={handleCloseMenu}
                    >
                        <MenuItem
                            // onClick={handleCloseMenu}
                            className='text-sm'
                        >
                            <Button
                                href='#file-upload'
                                component='label'
                                className='h-full w-full px-0 text-sm font-normal normal-case text-gray-700 shadow-none'
                            >
                                Vybrat ze souborů
                                <Input
                                    sx={{
                                        clip: 'rect(0 0 0 0)',
                                        clipPath: 'inset(50%)',
                                        height: '1px',
                                        overflow: 'hidden',
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        whiteSpace: 'nowrap',
                                        width: '1px',
                                    }}
                                    onChange={profilePhotoChange}
                                    type='file'
                                />
                            </Button>
                        </MenuItem>
                        <MenuItem
                            className='text-sm'
                            onClick={() => {
                                handleCloseMenu()
                                handlePhotoDelete()
                            }}
                        >
                            Vyměnit za iniciály
                        </MenuItem>
                    </Menu>
                </Stack>
            </section>
            <section className='mx-auto max-w-container px-5'>
                <Stack className='pb-8'>
                    <Grid
                        item
                        container
                        spacing={2}
                    >
                        {user?.fullName && (
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <ProfileInput
                                    labelText='Jméno a příjmení'
                                    inputData={user?.fullName}
                                />
                            </Grid>
                        )}
                        {user?.email && (
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <ProfileInput
                                    labelText='E-mail'
                                    inputData={user?.email}
                                />
                            </Grid>
                        )}
                        {user?.phone && (
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <ProfileInput
                                    labelText='Telefonní číslo'
                                    inputData={user?.phone}
                                />
                            </Grid>
                        )}
                        {user?.organizationName && (
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <ProfileInput
                                    labelText='Organizace'
                                    inputData={user?.organizationName}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Stack>
                <Button
                    variant='outlined'
                    size='small'
                    className='border-2 bg-white-reduced lowercase text-gray-700 shadow-none'
                    onClick={handleLogOut}
                >
                    odhlásit se
                </Button>
            </section>
        </main>
    )
}
