import {
    type PropsWithChildren,
    useState,
    createContext,
    Dispatch,
    SetStateAction,
} from 'react'
import type { BulletinFilterQueries } from '../api/notifications/types'

interface BulletinFiltersContextProps {
    bulletinQueryFilters?: BulletinFilterQueries
    setBulletinQueryFilters: Dispatch<
        SetStateAction<BulletinFilterQueries | undefined>
    >
}

export const BulletinFiltersContext =
    createContext<BulletinFiltersContextProps>({
        bulletinQueryFilters: undefined,
        setBulletinQueryFilters: () => {},
    })

export const BulletinFiltersProvider = ({ children }: PropsWithChildren) => {
    const [bulletinQueryFilters, setBulletinQueryFilters] =
        useState<BulletinFilterQueries>()

    return (
        <BulletinFiltersContext.Provider
            value={{ bulletinQueryFilters, setBulletinQueryFilters }}
        >
            {children}
        </BulletinFiltersContext.Provider>
    )
}
