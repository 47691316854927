import {
    Grid,
    Typography,
    InputLabel,
    OutlinedInput,
    Stack,
    Button,
} from '@mui/material'
import { Controller } from 'react-hook-form'
import type { FinalInfoViewProps } from '../../types'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import type { FC, MouseEvent } from 'react'
import { useUIDSeed } from 'react-uid'
import classNames from 'classnames'
import { getEffectiveLimit } from '../../hooks/useFinalInfo'

export const FinalInfoView: FC<FinalInfoViewProps> = (props) => {
    const { visitForm, visitData, getCodeUsage, getNewHealtAction } = props

    const {
        formMethods: { control },
    } = visitForm

    const uid = useUIDSeed()

    return (
        <Grid
            container
            item
            xs={12}
            spacing={2}
        >
            <Grid
                item
                xs={12}
            >
                <Typography className='font-medium'>Závěrečná sekce</Typography>
            </Grid>

            {!!visitData?.specialCodes?.length && (
                <Grid
                    item
                    xs={12}
                >
                    <InputLabel className='pb-2 text-sm font-medium'>
                        Speciální výkony
                    </InputLabel>
                    <Stack
                        direction='row'
                        flexWrap='wrap'
                        gap={1}
                    >
                        {visitData?.specialCodes.map(
                            ({
                                id,
                                canBeAdded,
                                code,
                                usedCount,
                                usingLimit,
                                usedOnVisit,
                                usingLimitPerVisit,
                                withCode,
                            }) => {
                                const effectiveLimit = getEffectiveLimit(
                                    usingLimit,
                                    usingLimitPerVisit,
                                )

                                return (
                                    <Button
                                        key={uid(id)}
                                        disabled={
                                            !visitData.canSave ||
                                            !canBeAdded ||
                                            !(
                                                effectiveLimit === null ||
                                                getCodeUsage(
                                                    code,
                                                    usedCount,
                                                    usedOnVisit,
                                                ) < effectiveLimit
                                            )
                                        }
                                        onClick={(e: MouseEvent) =>
                                            getNewHealtAction({
                                                e,
                                                code,
                                                usingLimit,
                                                id,
                                                usedCount,
                                                usedOnVisit,
                                                usingLimitPerVisit,
                                            })
                                        }
                                        variant='contained'
                                        color='success'
                                        endIcon={<AddCircleOutlineIcon />}
                                        className='rounded text-white-reduced shadow-none'
                                    >
                                        {code}
                                        {withCode && `/${withCode}`}
                                    </Button>
                                )
                            },
                        )}
                    </Stack>
                </Grid>
            )}
            {!!visitData?.isMedication && (
                <>
                    <Grid
                        item
                        xs={12}
                    >
                        <InputLabel className='pb-2 text-sm font-medium'>
                            Aplikace léků
                        </InputLabel>
                        <Controller
                            control={control}
                            name='aplicationDrugs'
                            render={({ field }) => (
                                <OutlinedInput
                                    {...field}
                                    size='small'
                                    fullWidth
                                    value={field.value || ''}
                                    placeholder='Zde zadejte text'
                                    multiline
                                    minRows={1}
                                    maxRows={4}
                                    readOnly={!visitData.canSave}
                                    className={classNames(
                                        'rounded text-sm font-medium',
                                        {
                                            'bg-orange-100':
                                                visitData?.canSave === false,
                                            'bg-white-reduced':
                                                visitData?.canSave,
                                        },
                                    )}
                                />
                            )}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <InputLabel className='pb-2 text-sm font-medium'>
                            Poznámka k medikaci
                        </InputLabel>
                        <OutlinedInput
                            size='small'
                            readOnly
                            fullWidth
                            multiline
                            minRows={1}
                            maxRows={4}
                            value={visitData?.patient.medicationNote || ''}
                            className={classNames(
                                'rounded text-sm font-medium',
                                {
                                    'bg-orange-100':
                                        visitData?.canSave === false,
                                    'bg-white-reduced': visitData?.canSave,
                                },
                            )}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <InputLabel className='pb-2 text-sm font-medium'>
                            Použitý materiál
                        </InputLabel>
                        <Controller
                            control={control}
                            name='usedMaterials'
                            render={({ field }) => (
                                <OutlinedInput
                                    {...field}
                                    size='small'
                                    fullWidth
                                    placeholder='Zde zadejte text'
                                    value={field.value || ''}
                                    multiline
                                    minRows={1}
                                    maxRows={4}
                                    readOnly={!visitData.canSave}
                                    className={classNames(
                                        'rounded text-sm font-medium',
                                        {
                                            'bg-orange-100':
                                                visitData?.canSave === false,
                                            'bg-white-reduced':
                                                visitData?.canSave,
                                        },
                                    )}
                                />
                            )}
                        />
                    </Grid>
                </>
            )}
            <Grid
                item
                xs={12}
            >
                <InputLabel className='pb-2 text-sm font-medium'>
                    Popis ošetření
                </InputLabel>
                <Controller
                    control={control}
                    name='note'
                    render={({ field }) => (
                        <OutlinedInput
                            {...field}
                            size='small'
                            fullWidth
                            placeholder='Zde zadejte text'
                            value={field.value || ''}
                            multiline
                            minRows={1}
                            maxRows={4}
                            readOnly={!visitData?.canSave}
                            className={classNames(
                                'rounded text-sm font-medium',
                                {
                                    'bg-orange-100':
                                        visitData?.canSave === false,
                                    'bg-white-reduced': visitData?.canSave,
                                },
                            )}
                        />
                    )}
                />
            </Grid>
            <Grid
                item
                xs={12}
            >
                <InputLabel className='pb-2 text-sm font-medium'>
                    Poznámka sestry k návštěvě
                </InputLabel>
                <Controller
                    control={control}
                    name='visitNote'
                    render={({ field }) => (
                        <OutlinedInput
                            {...field}
                            size='small'
                            fullWidth
                            placeholder='Zde zadejte text'
                            value={field.value || ''}
                            multiline
                            minRows={1}
                            maxRows={4}
                            readOnly={!visitData?.canSave}
                            className={classNames(
                                'rounded text-sm font-medium',
                                {
                                    'bg-orange-100':
                                        visitData?.canSave === false,
                                    'bg-white-reduced': visitData?.canSave,
                                },
                            )}
                        />
                    )}
                />
            </Grid>
        </Grid>
    )
}
