import { Box, Button, Checkbox, Stack } from '@mui/material'
import { BulletinCard, ContentLoader } from '../../../components'
import { BulletinFilters } from './BulletinFilters'
import { Bulletin, EDIT_NOTIFICATIONS_ENUM } from '../../../types/components'
import { SortedEmpty } from '../../../components/SortedVisit/SortedEmpty'
import { FC, useContext } from 'react'
import { wrap } from '../../../utils'
import { BulletinContentHook, BulletinPageContentProps } from '../types'
import { useBulletinContent } from '../hooks'
import { useUIDSeed } from 'react-uid'
import { AuthContext } from '../../../providers'

const BulletinPageContentView: FC<BulletinPageContentProps> = (props) => {
    const {
        bulletinQuery: { bulletinList, isLoading: isFilterLoading },
        bulletinQueryFilters,
        isMultiEditMode,
        setIsMultiEditMode,
        handleCardSelect,
        handleSelectAll,
        handleMultipleNotificationsAction,
        selectedCards,
        setIsComponentLoading,
        isComponentLoading,
    } = props

    const uid = useUIDSeed()

    const { user } = useContext(AuthContext)

    return (
        <>
            <Box className='container'>
                <BulletinFilters
                    handleSelectAll={handleSelectAll}
                    isComponentLoading={isComponentLoading}
                    setIsComponentLoading={setIsComponentLoading}
                    isLoading={isFilterLoading}
                />
            </Box>
            {isMultiEditMode && (
                <Box className='container mb-4 mt-8 flex gap-2'>
                    <Button
                        type='button'
                        variant='contained'
                        size='small'
                        className='flex gap-1 text-sm font-bold normal-case shadow-none'
                        onClick={() => handleSelectAll()}
                    >
                        {selectedCards.length === bulletinList?.length
                            ? 'Zrušit výběr'
                            : 'Vybrat vše'}
                    </Button>
                    <Button
                        type='button'
                        variant='outlined'
                        size='small'
                        className='flex gap-1 text-sm font-bold normal-case shadow-none'
                        onClick={() =>
                            setIsMultiEditMode && setIsMultiEditMode(false)
                        }
                    >
                        Zrušit úpravy
                    </Button>
                </Box>
            )}
            {isFilterLoading || isComponentLoading ? (
                <ContentLoader />
            ) : bulletinList?.length ? (
                <Stack
                    className='gap-6'
                    sx={{
                        paddingLeft: isMultiEditMode ? '0.25rem' : '1.25rem',
                        paddingRight: isMultiEditMode ? '0.8rem' : '1.25rem',
                    }}
                >
                    {bulletinList.map((bulletin: Bulletin) => (
                        <Box
                            className='relative flex items-center gap-1 sm:gap-5'
                            key={uid(bulletin?.id) + 'box'}
                        >
                            {isMultiEditMode && (
                                <Checkbox
                                    checked={selectedCards.includes(
                                        bulletin?.id,
                                    )}
                                    onChange={() =>
                                        handleCardSelect(bulletin?.id)
                                    }
                                />
                            )}
                            <BulletinCard
                                isLoading={
                                    isFilterLoading && isComponentLoading
                                }
                                className='flex-1 bg-white-reduced'
                                stopListInvalidate={
                                    bulletinQueryFilters?.showUnread
                                }
                                {...bulletin}
                            />
                        </Box>
                    ))}
                </Stack>
            ) : (
                <SortedEmpty emptyText='Momentálně zde nejsou žádné notifikace.' />
            )}
            {isMultiEditMode && (
                <Box
                    className='fixed bottom-0 left-0 flex  h-12 w-full items-center justify-center bg-[#F9A26C] px-2'
                    sx={{
                        borderTop: '1px solid #F88035',
                    }}
                >
                    <Box className='m-auto flex w-full max-w-[500px] items-center  justify-between '>
                        <Button
                            className='lowercase text-white-reduced'
                            type='button'
                            onClick={() =>
                                handleMultipleNotificationsAction(
                                    EDIT_NOTIFICATIONS_ENUM.Read,
                                )
                            }
                        >
                            Přečíst
                        </Button>
                        {(user?.isAdmin || user?.isHeadNurse) && (
                            <Button
                                className='lowercase text-white-reduced'
                                type='button'
                                onClick={() =>
                                    handleMultipleNotificationsAction(
                                        EDIT_NOTIFICATIONS_ENUM.Archive,
                                    )
                                }
                            >
                                Archivovat
                            </Button>
                        )}

                        <Button
                            className='lowercase text-white-reduced'
                            type='button'
                            onClick={() =>
                                handleMultipleNotificationsAction(
                                    EDIT_NOTIFICATIONS_ENUM.Delete,
                                )
                            }
                        >
                            Smazat
                        </Button>
                        {(user?.isAdmin || user?.isHeadNurse) && (
                            <Button
                                className='lowercase text-white-reduced'
                                type='button'
                                onClick={() =>
                                    handleMultipleNotificationsAction(
                                        EDIT_NOTIFICATIONS_ENUM.UnArchive,
                                    )
                                }
                            >
                                Obnovit
                            </Button>
                        )}
                    </Box>
                </Box>
            )}
        </>
    )
}

export const BulletinPageContent: FC<BulletinContentHook> = wrap(
    BulletinPageContentView,
    useBulletinContent,
)
