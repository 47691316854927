import { type MouseEvent } from 'react'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import {
    Box,
    Typography,
    Skeleton,
    IconButton,
    CircularProgress,
    Stack,
    Button,
} from '@mui/material'
import { PatientTabProps } from '../../types'
import { DocumentItem } from './DocumentItem'
import { useUIDSeed } from 'react-uid'
import Modal from '../../../../components/Modal'
import InputStyledUncontrolled from '../../../../components/InputStyledUncontrolled'

type DocumentProps = Pick<
    PatientTabProps,
    | 'patientDocumentsQuery'
    | 'handleFileUpload'
    | 'handleButtonClick'
    | 'fileInputRef'
    | 'uploadLoading'
    | 'handleSendUpload'
    | 'patientId'
    | 'setNewUploadedFileName'
    | 'newUploadedFileName'
    | 'fileData'
    | 'handleClosePreview'
    | 'isPreviewOpen'
>

export const PatientDocuments = (props: DocumentProps) => {
    const {
        patientDocumentsQuery,
        handleFileUpload,
        uploadLoading,
        handleButtonClick,
        fileInputRef,
        isPreviewOpen,
        newUploadedFileName,
        setNewUploadedFileName,
        handleClosePreview,
        handleSendUpload,
        fileData,
    } = props

    const uid = useUIDSeed()

    return (
        <Box>
            <Typography className='pb-2 text-sm font-medium'>
                Dokumenty
            </Typography>
            {patientDocumentsQuery.isLoading ? (
                <Skeleton
                    variant='rounded'
                    width='100%'
                    height={100}
                    className='mb-4'
                />
            ) : (
                <Box className='relative mb-4 min-h-[100px] w-full rounded border border-solid border-gray-400 bg-white-reduced'>
                    {patientDocumentsQuery.data?.totalItems === 0 &&
                        !patientDocumentsQuery.isFetching &&
                        !uploadLoading && (
                            <Box className='absolute inset-0 flex items-center justify-center'>
                                <Typography className='text-sm'>
                                    Žádné dokumenty nebyly přiřazeny
                                </Typography>
                            </Box>
                        )}

                    <Box className='grid grid-cols-4 p-2 pb-0'>
                        {patientDocumentsQuery.data?.data?.map(
                            (document: any) => {
                                return (
                                    <DocumentItem
                                        key={uid(document.id)}
                                        patientId={props.patientId}
                                        {...document}
                                    />
                                )
                            },
                        )}
                        {uploadLoading && (
                            <Stack
                                className='mb-2 flex flex-col items-center rounded border-[1px] border-solid border-gray-400 pb-1 pt-3 text-center'
                                gap={1}
                            >
                                <CircularProgress />
                                <Typography className='mb-2 text-xs'>
                                    Nahrávání...
                                </Typography>
                            </Stack>
                        )}
                    </Box>

                    <div>
                        <IconButton
                            color='primary'
                            aria-label='upload picture'
                            component='span'
                            className='absolute bottom-0 right-0 m-2 bg-orange-300 text-white-reduced hover:bg-orange-300 hover:shadow-md'
                            size='small'
                            onClick={(e: MouseEvent) => handleButtonClick(e)}
                        >
                            <AddAPhotoIcon fontSize='small' />
                        </IconButton>
                        <input
                            hidden
                            accept='.png, .jpg, .jpeg, .pdf'
                            type='file'
                            onChange={handleFileUpload}
                            ref={fileInputRef}
                        />
                    </div>

                    <Modal
                        open={isPreviewOpen}
                        handleClose={handleClosePreview}
                        title='Nahrát dokument?'
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Stack>
                            <Stack>
                                <Box className='my-4 flex items-center justify-center'>
                                    {fileData?.filename?.includes('.pdf') ? (
                                        <img
                                            src='https://via.placeholder.com/155x170/f8833b/f8f8f8.png?text=PDF'
                                            alt='PDF Document Thumbnail'
                                            width='155'
                                            height='170'
                                            className='mb-1 cursor-pointer rounded object-cover'
                                            onClick={() =>
                                                window.open(
                                                    fileData?.data,
                                                    '_blank',
                                                )
                                            }
                                        />
                                    ) : (
                                        <img
                                            src={fileData?.data}
                                            alt='Dokument pacienta'
                                            width='155'
                                            height='170'
                                            className='mb-1 rounded object-cover'
                                        />
                                    )}
                                </Box>
                                <InputStyledUncontrolled
                                    disabled={uploadLoading}
                                    name='name'
                                    value={newUploadedFileName}
                                    onChange={(e) =>
                                        setNewUploadedFileName(e.target.value)
                                    }
                                    label='název dokumentu'
                                />
                            </Stack>
                            <Stack className='mb-[-16px] mt-5 flex-row justify-end gap-2'>
                                <Button
                                    type='button'
                                    variant='outlined'
                                    size='small'
                                    disabled={uploadLoading}
                                    className='bg-white-reduced text-sm font-bold normal-case shadow-none'
                                    onClick={handleClosePreview}
                                >
                                    zrušit
                                </Button>
                                <Button
                                    variant='contained'
                                    size='small'
                                    onClick={handleSendUpload}
                                    disabled={uploadLoading}
                                    className='text-sm font-bold normal-case shadow-none'
                                >
                                    uložit a zavřít
                                </Button>
                            </Stack>
                        </Stack>
                    </Modal>
                </Box>
            )}
        </Box>
    )
}
