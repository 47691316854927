import {
    Avatar,
    Badge,
    Box,
    ButtonBase,
    CircularProgress,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Stack,
} from '@mui/material'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import classnames from 'classnames'
import type { FC } from 'react'
import { twMerge } from 'tailwind-merge'
import { stringAvatar, wrap } from '../../utils'
import { PatientSearch } from '../PatientSearch'
import type { NavigationHookProps, NavigationProps } from '../../types'
import { useNavigation } from './hooks'
import { NotificationsNoneOutlined } from '@mui/icons-material'

const NavigationView = (props: NavigationProps) => {
    const {
        navigate,
        anchorEl,
        open,
        openSearch,
        user,
        trimmedUser,
        handleLogOut,
        navigationOpen,
        navigationClose,
        children,
        containerProps,
        className,
        location,
        userPhotoQuery,
        notifications,
        ...elementProps
    } = props

    return (
        <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            className={twMerge(
                'fixed top-0 z-50 w-full bg-orange-50',
                className,
            )}
            {...elementProps}
        >
            <Box className='space-between flex w-full items-center  justify-between px-2 py-4  sm:px-5 sm:py-5 lg:mx-auto lg:max-w-container '>
                <Box {...containerProps}>{children}</Box>
                <Stack direction='row'>
                    <IconButton
                        onClick={() => navigate('/notifications')}
                        className='mr-1'
                    >
                        <Badge
                            color='error'
                            sx={{
                                '& .MuiBadge-badge': {
                                    top: '3px',
                                    right: '3px',
                                },
                            }}
                            badgeContent={
                                notifications.isLoading ? (
                                    <CircularProgress
                                        size='0.6rem'
                                        className='text-[#fff]'
                                    />
                                ) : (
                                    notifications?.bulletinCount
                                        ?.notReadedBulletinCount
                                )
                            }
                            invisible={
                                notifications.bulletinCount
                                    ?.notReadedBulletinCount === 0
                            }
                        >
                            <NotificationsNoneOutlined />
                        </Badge>
                    </IconButton>
                    <ButtonBase
                        focusRipple
                        className='rounded'
                        onClick={navigationOpen}
                    >
                        <Avatar
                            className='bg-orange-300 text-[1rem]'
                            src={userPhotoQuery.data}
                        >
                            {!trimmedUser?.firstname || !trimmedUser?.surname
                                ? null
                                : stringAvatar(
                                      `${trimmedUser.firstname} ${trimmedUser.surname}`,
                                  )}
                        </Avatar>
                        <KeyboardArrowDownRoundedIcon
                            className={classnames(' transition-transform', {
                                'rotate-180': open,
                            })}
                        />
                    </ButtonBase>
                </Stack>
                <Menu
                    anchorEl={anchorEl}
                    id='account-menu'
                    open={open}
                    onClose={navigationClose}
                    onClick={navigationClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 42,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    {user?.organizationID !== 0 && (
                        <Box>
                            <MenuItem
                                className='px-4 text-sm'
                                onClick={openSearch.handleOpen}
                            >
                                Vyhledat pacienta
                            </MenuItem>
                            <MenuItem
                                className='px-4 text-sm'
                                onClick={() => navigate('/home')}
                            >
                                Denní přehled
                            </MenuItem>
                            <MenuItem
                                className='px-4 text-sm'
                                onClick={() => navigate('/visits-inprogress')}
                            >
                                Návštěvy ke zpracování
                            </MenuItem>
                            <MenuItem
                                className='px-4 text-sm'
                                onClick={() => navigate('/visits-returned')}
                            >
                                Vrácené návštěvy
                            </MenuItem>
                            <MenuItem
                                className='px-4 text-sm'
                                onClick={() => navigate('/visits-history')}
                            >
                                Historie návštěv
                            </MenuItem>
                        </Box>
                    )}
                    <MenuItem
                        className='px-4 text-sm'
                        onClick={() => navigate('/profile')}
                    >
                        Můj profil
                    </MenuItem>
                    <Divider />
                    <MenuItem
                        className='px-4 text-sm'
                        onClick={handleLogOut}
                    >
                        Odhlásit se
                    </MenuItem>
                </Menu>
                <PatientSearch
                    location={location}
                    {...openSearch}
                />
            </Box>
        </Stack>
    )
}

export const Navigation: FC<NavigationHookProps> = wrap(
    NavigationView,
    useNavigation,
)
