import {
    Box,
    IconButton,
    SpeedDialAction,
    Stack,
    TextField,
} from '@mui/material'
import { MobileDatePicker } from '@mui/x-date-pickers'
import ArrowLeftRoundedIcon from '@mui/icons-material/ArrowLeftRounded'
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded'
import type { HistoryTabProps } from '../../types'
import { HelpDial, SortedVisit } from '../../../../components'
import type { MouseEvent } from 'react'
import { visitDetailVisitsActions } from '../../../../components/HelpDial/HelpDialActions'
import { HelpSpeedDialActions } from '../../../../components/HelpDial/types'
import { useUIDSeed } from 'react-uid'

export const History = (props: HistoryTabProps) => {
    const {
        handleAddMonth,
        handleDateChange,
        handleSubtractMonth,
        activeMonthDate,
        visitList,
        handleTabChange,
    } = props

    const uid = useUIDSeed()

    const handleCardWrapperClick = (e: MouseEvent) => {
        handleTabChange(e, 0)
        window.scroll({ top: 0, behavior: 'smooth' })
    }

    return (
        <Box>
            <Stack
                direction='row'
                className='items-center justify-end md:justify-center'
            >
                <IconButton onClick={handleSubtractMonth}>
                    <ArrowLeftRoundedIcon
                        fontSize='large'
                        color='primary'
                    />
                </IconButton>
                <MobileDatePicker
                    views={['year', 'month']}
                    openTo='month'
                    value={activeMonthDate}
                    toolbarTitle='Vyberte měsíc'
                    onChange={handleDateChange}
                    renderInput={(params) => (
                        <TextField
                            variant='standard'
                            sx={{
                                '.MuiInputBase-root::before, .MuiInputBase-root::after':
                                    {
                                        display: 'none',
                                    },
                                input: {
                                    textAlign: 'center',
                                },
                            }}
                            {...params}
                        />
                    )}
                />
                <IconButton
                    onClick={() => handleAddMonth({ disableFuture: false })}
                >
                    <ArrowRightRoundedIcon
                        fontSize='large'
                        color='primary'
                    />
                </IconButton>
            </Stack>
            <SortedVisit
                isLoading={visitList.isLoading}
                shouldSetMainVisit={false}
                // TODO: fix this neareast element scrolling
                // nearestElementAvailable
                cardWrapClick={handleCardWrapperClick}
                {...props}
            />
            <HelpDial>
                {({ handleClose, handleActionClick }) => {
                    return visitDetailVisitsActions.map(
                        ({ name, icon, modalName }: HelpSpeedDialActions) => (
                            <SpeedDialAction
                                key={uid(modalName)}
                                icon={icon}
                                tooltipTitle={name}
                                tooltipPlacement='right'
                                tooltipOpen
                                sx={{
                                    '& .MuiSpeedDialAction-staticTooltipLabel':
                                        {
                                            width: 'max-content',
                                        },
                                }}
                                onClick={handleActionClick(modalName)}
                            />
                        ),
                    )
                }}
            </HelpDial>
        </Box>
    )
}
