import { Box, TextField, colors, Stack, Button } from '@mui/material'
import { Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Modal } from '../../../components'
import type { StornoModalProps } from '../types'

export const StornoModal = (props: StornoModalProps) => {
    const {
        modalState: { isOpen, handleClose },
        formMethods: {
            control,
            formState: { errors },
        },
        handleStornoVisit,
    } = props

    const navigate = useNavigate()

    const handleStornoClick = () => {
        handleStornoVisit()
        navigate(-1)
        handleClose()
    }

    return (
        <Modal
            title='Důvod neprovedení'
            open={isOpen}
            handleClose={handleClose}
        >
            <Box>
                <Controller
                    control={control}
                    name='reasonNonDone'
                    render={({ field }) => (
                        <TextField
                            {...field}
                            placeholder='Zde napište důvod...'
                            variant='outlined'
                            type='text'
                            multiline
                            fullWidth
                            size='small'
                            rows={6}
                            error={errors.reasonNonDone !== undefined}
                            helperText={errors.reasonNonDone?.message}
                            InputProps={{
                                sx: {
                                    fontSize: '14px',
                                    color: colors.grey[800],
                                    background: '#f8f8f8',
                                },
                            }}
                            sx={{
                                marginBottom: 2,
                            }}
                        />
                    )}
                />
                <Stack
                    direction='row'
                    justifyContent='space-between'
                >
                    <Button
                        size='small'
                        variant='outlined'
                        onClick={handleClose}
                        sx={{
                            textTransform: 'none',
                            fontSize: '0.875rem',
                            background: '#F8F8F8',
                        }}
                    >
                        zrušit
                    </Button>
                    <Button
                        size='small'
                        variant='contained'
                        onClick={handleStornoClick}
                        sx={{
                            color: '#F8F8F8',
                            textTransform: 'none',
                            fontSize: '0.875rem',
                            boxShadow: 'none',
                        }}
                    >
                        odeslat
                    </Button>
                </Stack>
            </Box>
        </Modal>
    )
}
