import {
    Controller,
    Control,
    FieldValues,
    Path,
    FieldErrors,
} from 'react-hook-form'
import { OutlinedInput, InputLabel, Typography, Stack } from '@mui/material'
import { get } from 'lodash' // For nested error access

interface InputStyledProps<T extends FieldValues> {
    name: Path<T>
    control: Control<T>
    label: string
    className?: string
    classNameInput?: string
    type?:
        | 'text'
        | 'email'
        | 'password'
        | 'number'
        | 'url'
        | 'tel'
        | 'date'
        | 'checkbox'
        | 'radio'
    empty?: string
    positiveOnly?: boolean
    placeholder?: string
    disabled: boolean
    max?: number
    min?: number
    readOnly?: boolean
    errors: FieldErrors<T>
}

const InputStyled = <T extends FieldValues>({
    name,
    control,
    label,
    type = 'text',
    disabled,
    empty,
    errors,
    placeholder = '',
    readOnly,
    className,
    classNameInput,
    max,
    min,
    positiveOnly = false,
}: InputStyledProps<T>) => {
    const errorMessage = get(errors, name)?.message
        ? String(get(errors, name)?.message)
        : ''

    const inputProps =
        type === 'number' && positiveOnly
            ? {
                  min: 0,
                  ...(min !== undefined ? { min } : {}),
                  ...(max !== undefined ? { max } : {}),
              }
            : undefined
    return (
        <Stack
            spacing={1}
            className={className}
        >
            <InputLabel className='mb-1 text-sm'>{label}</InputLabel>
            <Controller
                control={control}
                name={name}
                render={({ field }) => (
                    <OutlinedInput
                        {...field}
                        value={field.value ?? ''}
                        disabled={disabled}
                        readOnly={readOnly}
                        placeholder={
                            empty
                                ? empty
                                : placeholder
                                ? `Uveďte ${placeholder} zde...`
                                : ''
                        }
                        className={`bg-white-reduced text-sm ${classNameInput}`}
                        type={type}
                        fullWidth
                        onChange={(e) => {
                            let value = e.target.value

                            if (type === 'number') {
                                const numericValue = parseFloat(value)

                                if (min !== undefined && numericValue < min) {
                                    value = String(min)
                                }

                                if (max !== undefined && numericValue > max) {
                                    value = String(max)
                                }
                            }

                            field.onChange(value)
                        }}
                        size='small'
                        error={!!errors[name]}
                        inputProps={inputProps}
                        sx={{
                            marginBottom: 2,
                            '&.Mui-disabled': {
                                backgroundColor: '#fde0ce',
                                color: 'rgba(0, 0, 0, 0.87) !important',
                                opacity: 1,
                                '& .MuiOutlinedInput-input': {
                                    color: 'rgba(0, 0, 0, 0.87) !important',
                                    '-webkit-text-fill-color':
                                        'rgba(0, 0, 0, 0.87) !important',
                                    '-webkit-text-stroke': '0',
                                },
                            },
                        }}
                    />
                )}
            />
            {errorMessage && (
                <Typography className='pt-1 text-sm text-error-2'>
                    {errorMessage}
                </Typography>
            )}
        </Stack>
    )
}

export default InputStyled
