import { Info } from '@mui/icons-material'
import { HelpSpeedDialActions } from './types'

export const visitsInProgressActions: HelpSpeedDialActions[] = [
    {
        icon: <Info />,
        name: 'Návštěvy ke zpracování',
        modalName: 'visitsProgress',
    },
    { icon: <Info />, name: 'Stavy návštěv', modalName: 'visitsStatus' },
]

export const visitsReturnedActions: HelpSpeedDialActions[] = [
    { icon: <Info />, name: 'Vrácené návštěvy', modalName: 'visitsReturned' },
    { icon: <Info />, name: 'Stavy návštěv', modalName: 'visitsStatus' },
]

export const notificationsActions: HelpSpeedDialActions[] = [
    { icon: <Info />, name: 'Stavy návštěv', modalName: 'visitsStatus' },
]

export const homeActions: HelpSpeedDialActions[] = [
    { icon: <Info />, name: 'Moje kancelář', modalName: 'office' },
    { icon: <Info />, name: 'Nástěnka', modalName: 'notice' },
    { icon: <Info />, name: 'Stavy návštěv', modalName: 'visitsStatus' },
]
export const todaysVisitsActions: HelpSpeedDialActions[] = [
    { icon: <Info />, name: 'Stavy návštěv', modalName: 'visitsStatus' },
]

export const bulletinActions: HelpSpeedDialActions[] = [
    { icon: <Info />, name: 'Nástěnka', modalName: 'notice' },
    { icon: <Info />, name: 'Kontakty FNOL', modalName: 'fnolContacts' },
]

export const visitDetailDetailActions: HelpSpeedDialActions[] = [
    {
        icon: <Info />,
        name: 'Uložení a odeslání návštěvy',
        modalName: 'visitSaving',
    },
    { icon: <Info />, name: 'Zápis návštěvy', modalName: 'visitRecord' },
]

export const visitDetailPatientActions: HelpSpeedDialActions[] = [
    { icon: <Info />, name: 'Dokumenty', modalName: 'visitsDocuments' },
]

export const visitDetailContactsActions: HelpSpeedDialActions[] = [
    { icon: <Info />, name: 'Obsluha kontaktů', modalName: 'serviceContact' },
]

export const historyActions: HelpSpeedDialActions[] = [
    { icon: <Info />, name: 'Stavy návštěv', modalName: 'visitsStatus' },
]

export const visitDetailVisitsActions: HelpSpeedDialActions[] = [
    { icon: <Info />, name: 'Stavy návštěv', modalName: 'visitsStatus' },
    {
        icon: <Info />,
        name: 'Historie návštěv pacienta',
        modalName: 'visitHistory',
    },
]

export const visitDetailMedicationActions: HelpSpeedDialActions[] = [
    {
        icon: <Info />,
        name: 'Významné údaje o současné medikaci',
        modalName: 'medicationNotable',
    },
    {
        icon: <Info />,
        name: 'Poznámka k medikaci',
        modalName: 'medicationNote',
    },

    { icon: <Info />, name: 'Medikace', modalName: 'medication' },
]
