import React, { useState, useEffect, useRef } from 'react'
import Pagination from '@mui/material/Pagination'
import { Box } from '@mui/material'

const CustomPagination = ({
    handleSetNewPage,
    currentPage,
    count,
}: {
    count: number
    handleSetNewPage: (page: number) => void
    currentPage: number
}) => {
    const [siblingCount, setSiblingCount] = useState(1)
    const containerRef = useRef<HTMLDivElement>(null)

    const updateSiblingCount = () => {
        if (containerRef.current) {
            const containerWidth = containerRef.current.offsetWidth
            if (containerWidth > 900) {
                setSiblingCount(8)
            } else if (containerWidth > 750) {
                setSiblingCount(6)
            } else if (containerWidth > 600) {
                setSiblingCount(4)
            } else if (containerWidth > 450) {
                setSiblingCount(2)
            } else if (containerWidth > 345) {
                setSiblingCount(1)
            } else {
                setSiblingCount(0)
            }
        }
    }

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            updateSiblingCount()
        })

        const currentContainer = containerRef.current

        if (currentContainer) {
            resizeObserver.observe(currentContainer)
        }

        return () => {
            if (currentContainer) {
                resizeObserver.unobserve(currentContainer)
            }
        }
    }, [])

    return (
        <Box ref={containerRef}>
            <Pagination
                variant='outlined'
                shape='rounded'
                className='mt-4 flex justify-center'
                siblingCount={siblingCount}
                boundaryCount={1}
                sx={{
                    '& .Mui-selected': {
                        backgroundColor: '#faa26b !important',
                        color: '#f8f8f8 !important',
                    },
                    '& .MuiPaginationItem-root': {
                        backgroundColor: '#f8f8f8',
                        color: '#333',
                    },
                }}
                count={Math.ceil(count)}
                page={currentPage}
                onChange={(e, page) => {
                    handleSetNewPage(page)
                }}
            />
        </Box>
    )
}

export default CustomPagination
