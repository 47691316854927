import { Stack, Typography } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { ContentErrorProps } from '../types'

export const ContentError = ({ errorText }: ContentErrorProps) => {
    return (
        <Stack
            padding='2.5rem'
            justifyContent='center'
            alignItems='center'
            textAlign='center'
            gap='1rem'
        >
            <ErrorOutlineIcon
                fontSize='large'
                color='primary'
            />
            <Typography>
                {errorText ||
                    'Někde se stal problém. Zkuste stránku načíst znovu.'}
            </Typography>
        </Stack>
    )
}
