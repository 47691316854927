import { FC, useCallback, useEffect } from 'react'
import { wrap } from '../../../utils'
import { useAddPatientAnamnesisFormStep5 } from '../hooks/useAddPatientAnamnesisFormStep5'
import {
    AddPatientsAnamnesisFormStep5Hook,
    IAddPatientAnamnesisFormStep5,
} from '../types'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { AnamnesisFormFooter } from './AnamnesisFormFooter'
import YesNoRadioGroup from '../../../components/YesNoRadioGroup'
import CheckboxStyled from '../../../components/CheckboxStyled'
import MultipleChoiceRadioGroup from '../../../components/MultipleChoicesRadioGroup'
import {
    eCommunicationOptions,
    eConcentrationOptions,
    otherCommunicationValue,
} from '../../../components/options'
import InputStyled from '../../../components/InputStyled'

const AddPatientAnamnesisFormStep5View = (
    props: IAddPatientAnamnesisFormStep5,
) => {
    const {
        submitForm,
        formMethods,
        setCurrentStep,
        currentStep,
        canEditCareAnamnesis,
        setSaveCurrentStepOnClose,
        isLoading,
    } = props

    const {
        control,
        handleSubmit,
        watch,
        getValues,
        formState: { errors, isSubmitting },
    } = formMethods

    const IsHearingDeaf = watch('IsHearingDeaf')
    const IsHearingHearingAid = watch('IsHearingHearingAid')
    const Communication = watch('Communication')
    const IsOrientation = watch('IsOrientation')

    const handleSubmitWrapper = useCallback(
        async (isNext?: boolean) => {
            const formData = getValues()
            await submitForm(formData, isNext ? isNext : false)
        },
        [getValues, submitForm],
    )
    useEffect(() => {
        setSaveCurrentStepOnClose(() => handleSubmitWrapper)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSaveCurrentStepOnClose])

    if (isLoading) {
        return (
            <Box className='!mt-12 flex justify-center'>
                <CircularProgress />
            </Box>
        )
    }
    return (
        <form
            onSubmit={() => handleSubmitWrapper(true)}
            className='grid gap-3'
        >
            <Stack spacing={1}>
                <Typography className='!mt-2 flex text-sm font-semibold'>
                    Sluch
                </Typography>
                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsHearingGood'
                    label='dobrý'
                />
                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='IsHearingDeteriorated'
                    label='zhoršený'
                />
                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='IsHearingDeaf'
                    label='neslyšící'
                />
                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsHearingHearingAid'
                    label='naslouchátko'
                />
                {IsHearingDeaf && (
                    <Stack>
                        <Typography className='!mt-2 flex text-base font-semibold'>
                            Neslyšící:
                        </Typography>
                        <CheckboxStyled
                            control={control}
                            readOnly={!canEditCareAnamnesis}
                            disabled={isSubmitting}
                            name='IsHearingDeafRight'
                            label='Pravá'
                        />
                        <CheckboxStyled
                            control={control}
                            readOnly={!canEditCareAnamnesis}
                            disabled={isSubmitting}
                            name='IsHearingDeafLeft'
                            label='Levá'
                        />
                    </Stack>
                )}
                {IsHearingHearingAid && (
                    <Stack>
                        <Typography className='!mt-2 text-sm font-semibold'>
                            Naslouchátko:
                        </Typography>
                        <CheckboxStyled
                            control={control}
                            disabled={isSubmitting}
                            readOnly={!canEditCareAnamnesis}
                            name='IsHearingHearingAidRight'
                            label='Pravá'
                        />
                        <CheckboxStyled
                            control={control}
                            readOnly={!canEditCareAnamnesis}
                            disabled={isSubmitting}
                            name='IsHearingHearingAidLeft'
                            label='Levá'
                        />
                    </Stack>
                )}
            </Stack>

            <Stack spacing={1}>
                <Typography className='!mt-2 flex text-sm font-semibold'>
                    Zrak
                </Typography>
                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='IsAirGood'
                    label='dobrý'
                />
                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='IsAirDeteriorated'
                    label='zhoršený'
                />
                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='IsAirBlind'
                    label='nevidomý'
                />
                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsAirGlasses'
                    label='brýle'
                />
            </Stack>

            <MultipleChoiceRadioGroup
                control={control}
                label='Komunikace'
                name='Communication'
                readOnly={!canEditCareAnamnesis}
                options={eCommunicationOptions}
                isSubmitting={isSubmitting}
            />

            {Communication !== undefined &&
                Communication === (otherCommunicationValue as any) && (
                    <InputStyled
                        control={control}
                        readOnly={!canEditCareAnamnesis}
                        disabled={isSubmitting}
                        name='CommunicationOther'
                        errors={errors}
                        label='Jiné:'
                        placeholder='jiné komunikace'
                    />
                )}

            <YesNoRadioGroup
                control={control}
                readOnly={!canEditCareAnamnesis}
                name='IsOrientation'
                label='Orientace'
                disabled={isSubmitting}
            />

            {!IsOrientation && (
                <>
                    <CheckboxStyled
                        control={control}
                        readOnly={!canEditCareAnamnesis}
                        disabled={isSubmitting}
                        name='IsOrientationPlace'
                        label='místem'
                    />
                    <CheckboxStyled
                        control={control}
                        disabled={isSubmitting}
                        readOnly={!canEditCareAnamnesis}
                        name='IsOrientationTime'
                        label='časem'
                    />
                    <CheckboxStyled
                        control={control}
                        readOnly={!canEditCareAnamnesis}
                        disabled={isSubmitting}
                        name='IsOrientationPerson'
                        label='osobou'
                    />
                </>
            )}

            <MultipleChoiceRadioGroup
                control={control}
                label='Koncentrace pozornosti'
                readOnly={!canEditCareAnamnesis}
                name='Concentration'
                options={eConcentrationOptions}
                isSubmitting={isSubmitting}
            />

            <Stack spacing={2}>
                <AnamnesisFormFooter
                    currentStep={currentStep}
                    canEditCareAnamnesis={canEditCareAnamnesis}
                    handleSubmitWrapper={handleSubmitWrapper}
                    disabled={isLoading || isSubmitting}
                    setCurrentStep={setCurrentStep}
                />
            </Stack>
        </form>
    )
}

export const AddPatientAnamnesisFormStep5: FC<AddPatientsAnamnesisFormStep5Hook> =
    wrap(AddPatientAnamnesisFormStep5View, useAddPatientAnamnesisFormStep5)
