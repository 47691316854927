import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useHelpUpload } from './useHelpUpload'
import { useFaultReportMutation } from '../../../api/owner/hooks'
import { HelpErrorForm, HelpErrorFormModalHook } from '../types'

export const useHelpErrorFormModal = (
    handleClose: () => void,
    image?: string,
): HelpErrorFormModalHook => {
    const defaultValues: HelpErrorForm = {
        message: '',
        deviceInfo: '',
        attachments: [],
    }

    const {
        uploadInputRef,
        selectedFiles,
        handleFileUpload,
        handleButtonClick,
    } = useHelpUpload()

    const faultReportMutation = useFaultReportMutation()

    const validationSchema = yup.object().shape({
        message: yup.string().required('Toto pole je povinné'),
        deviceInfo: yup.string(),
        attachments: yup.array(),
    })

    const formMethods = useForm<HelpErrorForm>({
        criteriaMode: 'all',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues,
    })

    const submitMessage = async (data: HelpErrorForm) => {
        const sendData = {
            message: data.message,
            deviceInfo: navigator.userAgent,
            attachments: [image, ...selectedFiles],
        }

        await faultReportMutation.mutateAsync(sendData)
        handleClose()
    }

    return {
        formMethods,
        submitMessage,
        uploadInputRef,
        selectedFiles,
        handleFileUpload,
        handleButtonClick,
    }
}
