import { useForm } from 'react-hook-form'
import {
    BulletinFormModalProps,
    BulletinFormModalHook,
    BulletinForm,
    BulletinFormFormatted,
} from '../../../types/components'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
    useBulletinCreateFnolMutation,
    useBulletinCreateMutation,
    useBulletinDeleteMutation,
    useBulletinUpdateMutation,
} from '../../../api/notifications/hooks'
import { useEffect } from 'react'

export const useBulletinFormModal = (
    props: BulletinFormModalHook,
): BulletinFormModalProps => {
    const { handleClose, messageData } = props
    const { mutateBulletinUpdate } = useBulletinUpdateMutation()
    const { mutateBulletinCreate } = useBulletinCreateMutation()

    const { mutateBulletinFnolCreate } = useBulletinCreateFnolMutation()

    const { mutateBulletinDelete, isLoading: bulletinDeleteLoading } =
        useBulletinDeleteMutation()

    const defaultValues: BulletinForm = {
        title: '',
        tag: 'Unknown',
        message: '',
        selectedUsers: [],
        selectedPatients: [],
        urlLinks: [],
        attachments: [],
    }
    const validationSchema = yup.object().shape({
        title: yup
            .string()
            .transform((value, original) => (original === '' ? '' : value))
            .required(),
        tag: yup.string().required(),
        message: yup.string().when([], {
            is: () => props.isFnol === false,
            then: yup.string().required(),
            otherwise: yup.string(),
        }),
        selectedPatients: yup.array().when([], {
            is: () => props.isFnol === true,
            then: yup.array().min(1).max(1).required(),
            otherwise: yup.array(),
        }),
        attachments: yup.array(),
    })

    const formMethods = useForm<BulletinForm>({
        criteriaMode: 'all',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues,
    })

    const handleBulletinDelete = async () => {
        messageData?.id && (await mutateBulletinDelete({ id: messageData.id }))
        handleClose()
    }

    useEffect(() => {
        if (!!messageData) {
            formMethods.reset({ ...messageData })
        }
    }, [formMethods, messageData])

    const handleFormSuccess = () => {
        formMethods.reset()
        handleClose()
    }

    const formatData = (data: BulletinForm): BulletinFormFormatted => {
        const selectedUsers = data?.selectedUsers
            ? data?.selectedUsers?.map((user) => user.id)
            : undefined
        const selectedPatients = data?.selectedPatients
            ? data?.selectedPatients?.map((patient) => patient.id)
            : undefined
        const filteredUrlLinks = data?.urlLinks
            ? data?.urlLinks
                  ?.map((link) => link.urlAddress)
                  .filter((link) => link?.trim() !== '')
            : []

        return {
            ...data,
            selectedUsers,
            selectedPatients,
            urlLinks: filteredUrlLinks,
        }
    }

    const formDataFnol = (data: BulletinForm) => {
        const patientID = data.selectedPatients
            ? data.selectedPatients[0]?.id ?? 0
            : 0
        const title = data.title ?? ''
        const message = data.message
        const media = data.attachments.map((attachment) => attachment.imageSrc)

        return { patientID, title, message, media }
    }

    const submitMessage = async (data: BulletinForm) => {
        try {
            const formatedData = formatData(data)

            if (!!messageData?.id) {
                await mutateBulletinUpdate(
                    { ...formatedData, id: messageData?.id, isReaded: true },
                    {
                        onSuccess: () => handleFormSuccess(),
                    },
                )
            } else {
                if (props.isFnol) {
                    const formatedDataFnol = formDataFnol(data)
                    await mutateBulletinFnolCreate(
                        { ...formatedDataFnol },
                        {
                            onSuccess: () => handleFormSuccess(),
                        },
                    )
                    return
                }
                await mutateBulletinCreate(
                    { ...formatedData },
                    {
                        onSuccess: () => handleFormSuccess(),
                    },
                )
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        formMethods,
        submitMessage,
        handleBulletinDelete,
        bulletinDeleteLoading,
        ...props,
    }
}
