import { OpenStateProps } from './../../../types/index'
import { useEffect, useState, MouseEvent } from 'react'
import { useQueryDocumentFile } from '../../../api'
import type { PatientDocument } from '../../../types'
import { useSnackbar } from 'notistack'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'

export type UseDocumentFileProps = Pick<PatientDocument, 'id' | 'filename'> &
    Pick<OpenStateProps, 'isOpen'>

export const useDocumentFile = (props: UseDocumentFileProps) => {
    const { id, filename, isOpen } = props
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const isPdf = filename.split('.')[1] === 'pdf'

    const documentFileThumb = useQueryDocumentFile({
        documentId: id,
        height: 70,
        disable: isPdf,
    })

    const documentFile = useQueryDocumentFile({
        documentId: id,
        normalSize: true,
        disable: !isOpen && !isPdf,
    })

    const [documentImage, setDocumentImage] = useState(
        'https://via.placeholder.com/55x70/f8833b/f8f8f8.png?text=Loading',
    )

    const openPdf = (e: MouseEvent, pdfData?: string) => {
        e.preventDefault()
        if (!pdfData || documentFile.isLoading || !isPdf) {
            return enqueueSnackbar(
                `Soubor byl pravděpodobně smazán. Pro více
            informací kontaktujte podporu.`,
                {
                    variant: 'error',
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    action: (key) => (
                        <IconButton onClick={() => closeSnackbar(key)}>
                            <Close color='secondary' />
                        </IconButton>
                    ),
                },
            )
        }

        const binary = atob(pdfData.split(',')[1])
        const array = []
        for (let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i))
        }
        const blob = new Blob([new Uint8Array(array)], {
            type: 'application/pdf',
        })
        const objectUrl = URL.createObjectURL(blob)
        window.open(objectUrl, '_blank')
    }

    useEffect(() => {
        if (documentFileThumb.data) {
            setDocumentImage(documentFileThumb.data)
        }
    }, [documentFileThumb.data, isOpen])

    const refetchImage = () => {
        documentFileThumb.refetch()
        documentFile.refetch()
    }

    return {
        thumbError: documentFileThumb.isError,
        documentImage,
        documentFile,
        refetchImage,
        openPdf,
        isPdf,
    }
}
