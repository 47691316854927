import { Address } from '../types'

export const formatPhoneNumber = (phoneNumber?: string): string => {
    if (!phoneNumber) return ''

    return phoneNumber?.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3')
}

export const formatAdress = (adress?: Address): string => {
    return adress?.fullText?.replace(', CZ', '') || 'neuvedeno'
}
