import { yupResolver } from '@hookform/resolvers/yup'
import { AddPatientForm, AddPatientFormModalHook } from '../types'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useAddPatientMutation } from './hooks'

export const useAddPatientModalForm = ({
    handleClose,
}: {
    handleClose: () => void
}): AddPatientFormModalHook => {
    const navigate = useNavigate()

    const defaultValues: AddPatientForm = {
        firstname: '',
        surname: '',
        insuranceNumber: '',
        dateOfBirth: new Date(),
    }

    const addPatientMutation = useAddPatientMutation()

    const validationSchema = yup.object().shape({
        firstname: yup.string(),
        surname: yup.string().required('Toto pole je povinné'),
        insuranceNumber: yup.string(),
        dateOfBirth: yup
            .string()
            .required('Toto pole je povinné')
            .test('is-valid-date', 'Zadejte platné datum', (value) => {
                if (!value) return false
                const date = new Date(value)
                return !isNaN(date.getTime())
            })
            .nullable(),
    })

    const formMethods = useForm<AddPatientForm>({
        criteriaMode: 'all',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues,
    })

    const submitMessage = async (data: AddPatientForm) => {
        try {
            const sendData = {
                firstname: data.firstname,
                surname: data.surname,
                insuranceNumber: data.insuranceNumber,
                dateOfBirth: new Date(data.dateOfBirth)
                    .toISOString()
                    .split('T')[0],
            }

            await addPatientMutation.mutateAsync(sendData)

            navigate('/patients?page=1&patientStates=Waiting')
            handleClose()
        } catch (error: any) {
            console.log(error)
        }
    }

    return {
        formMethods,
        submitMessage,
    }
}
