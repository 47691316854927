import { Stack, Typography } from '@mui/material'
import Modal from '../Modal'
import { UseHelpModalProps } from './types'

function VisitsReturnedModal(props: UseHelpModalProps) {
    return (
        <Modal {...props}>
            <Stack className='gap-3'>
                <Stack className='gap-1'>
                    <Typography
                        className='mb-4 text-center font-bold'
                        color={'primary'}
                    >
                        Vrácené návštěvy
                    </Typography>
                    <Typography
                        component='h2'
                        className='text-base font-semibold'
                    >
                        Vrácené návštěvy:
                    </Typography>
                    <Typography className='text-sm'>
                        Vámi odeslané návštěvy, které byly vráceny k opravě nebo
                        doplnění
                    </Typography>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default VisitsReturnedModal
