import { useUserQuery } from './../../../api/user/hooks'
import { getRefreshToken, logoutUser } from './../../../api/user/services'
import { useCallback, useEffect, useState } from 'react'
import { AuthUser } from '../types'
import jwtDecode from 'jwt-decode'
import moment from 'moment'

export const useAuth = () => {
    const [token, setToken] = useState<string>()
    const [user, setUser] = useState<AuthUser>()
    const [isAuthenticated, setIsAuthenticated] = useState(true)

    const userInfo = useUserQuery(token)

    const logOut = useCallback(() => {
        !!token && logoutUser(token)
        localStorage.removeItem('token')
        setToken(undefined)
        return
    }, [token])

    const getNewToken = useCallback(
        async (_token: string) => {
            try {
                const refreshResponse: { accessToken: string } =
                    await getRefreshToken(_token)

                localStorage.setItem('token', refreshResponse.accessToken)
                window.location.reload()
            } catch (error) {
                window.location.href = '/'
                setIsAuthenticated(false)
                return logOut()
            }
        },
        [logOut],
    )

    const getTokenExpiration = useCallback((_token: string) => {
        const decodedJwt: { exp: number } = jwtDecode(_token)
        const expirationDate = moment(decodedJwt.exp * 1000).toDate()
        const now = moment()
        const tenMinutesAfterNow = moment(now).add(10, 'minutes').toDate()
        const isExpirationAfterWindow =
            moment(expirationDate).isBefore(tenMinutesAfterNow)

        return isExpirationAfterWindow
    }, [])

    useEffect(() => {
        const lsToken = localStorage.getItem('token')

        if (lsToken === null) {
            // console.log('No token found')
            setIsAuthenticated(false)
            return logOut()
        }

        if (!!lsToken && lsToken !== token) {
            setToken(lsToken)
            setIsAuthenticated(true)
        }

        if (userInfo.isError) {
            return logOut()
        }

        !(userInfo.isLoading || userInfo.isError) && setUser(userInfo.data)
    }, [logOut, token, userInfo.data, userInfo.isError, userInfo.isLoading])

    useEffect(() => {
        const lsToken = localStorage.getItem('token')

        if (lsToken === null) {
            logOut()
            return
        }

        const isTokenExpired = getTokenExpiration(lsToken)

        isTokenExpired && getNewToken(lsToken)
    })

    return {
        isAuthenticated,
        user,
        token,
        logOut,
    }
}
