import {
    QueryCache,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AxiosError } from 'axios'

const AppQueryProvider = ({
    children,
    hideDevTools = false,
}: React.PropsWithChildren<unknown> & { hideDevTools?: boolean }) => {
    const client = new QueryClient({
        defaultOptions: {
            queries: {
                retry: false,
            },
        },
        queryCache: new QueryCache({
            onError: (err) => {
                const error = err as AxiosError
                if (error?.response?.status === 401) {
                    localStorage.removeItem('token')
                    window.location.href = '/'
                }
            },
        }),
    })

    return (
        <QueryClientProvider client={client}>
            {children}
            {!hideDevTools && <ReactQueryDevtools />}
        </QueryClientProvider>
    )
}

export default AppQueryProvider
