import { FC } from 'react'
import { wrap } from '../../utils'
import { usePage } from './hooks'
import { NurseHistoryView } from './NurseHistoryView'
import type { UseNurseHistoryHookProps } from './types'

export const NurseHistoryPage: FC<UseNurseHistoryHookProps> = wrap(
    NurseHistoryView,
    usePage,
)
