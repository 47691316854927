import { FC, useCallback, useEffect } from 'react'
import { wrap } from '../../../utils'
import { useAddPatientAnamnesisFormStep2 } from '../hooks/useAddPatientAnamnesisFormStep2'
import {
    AddPatientsAnamnesisFormStep2Hook,
    IAddPatientAnamnesisFormStep2,
} from '../types'
import { Stack, InputLabel, CircularProgress, Box } from '@mui/material'
import { AnamnesisFormFooter } from './AnamnesisFormFooter'
import YesNoRadioGroup from '../../../components/YesNoRadioGroup'
import MultipleChoiceRadioGroup from '../../../components/MultipleChoicesRadioGroup'
import CheckboxStyled from '../../../components/CheckboxStyled'
import InputStyled from '../../../components/InputStyled'
import { turgorOptions } from '../../../components/options'
import DateInputStyled from '../../../components/DateInput'

const AddPatientAnamnesisFormStep2View = (
    props: IAddPatientAnamnesisFormStep2,
) => {
    const {
        submitForm,
        formMethods,
        setCurrentStep,
        currentStep,
        setSaveCurrentStepOnClose,
        invalidDateInputs,
        isLoading,
        canEditCareAnamnesis,
    } = props

    const {
        control,
        handleSubmit,
        getValues,
        watch,
        formState: { errors, isSubmitting },
    } = formMethods

    const isWeightChange = watch('IsWeightChange')
    const isEnteral = watch('IsEnteral')
    const isDiet = watch('IsDiet')

    const isFoodIntakeNasogastricProbe = watch('IsFoodIntakeNasogastricProbe')
    const isFoodIntakeGastrostomy = watch('IsFoodIntakeGastrostomy')
    const isFoodIntakePEG = watch('IsFoodIntakePEG')
    const isFoodIntakeParenterally = watch('IsFoodIntakeParenterally')

    const isDiabetes = watch('IsDiabetes')
    const isDiabetesTypeInsulin = watch('IsDiabetesTypeInsulin')

    const handleSubmitWrapper = useCallback(
        async (isNext?: boolean) => {
            const formData = getValues()
            await submitForm(formData, isNext ? isNext : false)
        },
        [getValues, submitForm],
    )
    useEffect(() => {
        setSaveCurrentStepOnClose(() => handleSubmitWrapper)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSaveCurrentStepOnClose])

    if (isLoading) {
        return (
            <Box className='!mt-12 flex justify-center'>
                <CircularProgress />
            </Box>
        )
    }

    return (
        <form
            onSubmit={() => handleSubmitWrapper(true)}
            className='grid gap-3'
        >
            <InputStyled
                control={control}
                disabled={isSubmitting}
                name='CurrentWeight'
                errors={errors}
                positiveOnly
                label='Současná váha:'
                readOnly={!canEditCareAnamnesis}
                placeholder='váhu'
            />

            <InputStyled
                control={control}
                disabled={isSubmitting}
                name='CurrentHeight'
                errors={errors}
                readOnly={!canEditCareAnamnesis}
                positiveOnly
                label='Výška:'
                placeholder='výšku'
            />

            <YesNoRadioGroup
                control={control}
                name='IsWeightChange'
                disabled={isSubmitting}
                readOnly={!canEditCareAnamnesis}
                label='Změna hmotnosti za poslední 1/2 rok'
            />

            {isWeightChange && (
                <>
                    <InputStyled
                        control={control}
                        disabled={isSubmitting}
                        readOnly={!canEditCareAnamnesis}
                        name='IncreaseWeight'
                        errors={errors}
                        label='Přírůstek:'
                        placeholder='přírůstek'
                    />

                    <InputStyled
                        control={control}
                        disabled={isSubmitting}
                        readOnly={!canEditCareAnamnesis}
                        name='DecreaseWeight'
                        errors={errors}
                        label='Úbytek:'
                        placeholder='úbytek'
                    />
                </>
            )}

            <YesNoRadioGroup
                control={control}
                name='IsEnteral'
                readOnly={!canEditCareAnamnesis}
                label='Enterální (nutriční přípravky)'
                disabled={isSubmitting}
            />

            {isEnteral && (
                <InputStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='Enteral'
                    errors={errors}
                    label='Jaké:'
                    placeholder='jaké nutriční přípravky'
                />
            )}

            <Stack spacing={1}>
                <InputLabel className='text-sm font-semibold'>
                    Příjem potravy
                </InputLabel>

                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    name='IsFoodIntakeMouth'
                    readOnly={!canEditCareAnamnesis}
                    label='ústy'
                />

                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    name='IsFoodIntakeNasogastricProbe'
                    readOnly={!canEditCareAnamnesis}
                    label='nazogastrická sonda'
                />

                {isFoodIntakeNasogastricProbe &&
                    (invalidDateInputs.includes(
                        'FoodIntakeNasogastricProbe',
                    ) ? (
                        <InputStyled
                            control={control}
                            disabled={isSubmitting}
                            name='FoodIntakeNasogastricProbe'
                            errors={errors}
                            readOnly={!canEditCareAnamnesis}
                            label='Datum zavedení:'
                            placeholder='datum zavedení'
                        />
                    ) : (
                        <DateInputStyled
                            control={control}
                            disabled={isSubmitting}
                            name='FoodIntakeNasogastricProbe'
                            readOnly={!canEditCareAnamnesis}
                            errors={errors}
                            label='Datum zavedení'
                        />
                    ))}

                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    name='IsFoodIntakeGastrostomy'
                    readOnly={!canEditCareAnamnesis}
                    label='gastrostomie'
                />

                {isFoodIntakeGastrostomy &&
                    (invalidDateInputs.includes('FoodIntakeGastrostomy') ? (
                        <InputStyled
                            control={control}
                            disabled={isSubmitting}
                            readOnly={!canEditCareAnamnesis}
                            name='FoodIntakeGastrostomy'
                            errors={errors}
                            label='Datum zavedení:'
                            placeholder='datum zavedení'
                        />
                    ) : (
                        <DateInputStyled
                            control={control}
                            disabled={isSubmitting}
                            name='FoodIntakeGastrostomy'
                            readOnly={!canEditCareAnamnesis}
                            errors={errors}
                            label='Datum zavedení'
                        />
                    ))}

                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='IsFoodIntakePEG'
                    label='PEG'
                />

                {isFoodIntakePEG &&
                    (invalidDateInputs.includes('FoodIntakePEG') ? (
                        <InputStyled
                            control={control}
                            disabled={isSubmitting}
                            readOnly={!canEditCareAnamnesis}
                            name='FoodIntakePEG'
                            errors={errors}
                            label='Datum zavedení:'
                            placeholder='datum zavedení'
                        />
                    ) : (
                        <DateInputStyled
                            control={control}
                            disabled={isSubmitting}
                            readOnly={!canEditCareAnamnesis}
                            name='FoodIntakePEG'
                            errors={errors}
                            label='Datum zavedení'
                        />
                    ))}

                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='IsFoodIntakeParenterally'
                    label='parenterálně'
                />

                {isFoodIntakeParenterally && (
                    <>
                        <InputStyled
                            control={control}
                            disabled={isSubmitting}
                            name='FoodIntakeParenterally'
                            readOnly={!canEditCareAnamnesis}
                            errors={errors}
                            label='Datum zavedení katetru a jaký:'
                            placeholder='datum zavedení a jaký'
                        />
                    </>
                )}
            </Stack>

            <Stack spacing={1}>
                <InputLabel className='text-sm font-semibold'>
                    Příjem tekutin
                </InputLabel>
                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='IsFluidIntakeMouth'
                    label='ústy'
                />
                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    name='IsFluidIntakeEnterally'
                    readOnly={!canEditCareAnamnesis}
                    label='enterálně'
                />
                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsFluidIntakeParenterally'
                    label='parenterálně'
                />
                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsFluidIntakeSipping'
                    label='sipping'
                />
                <InputStyled
                    className='mt-2'
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='FluidIntakePerDay'
                    errors={errors}
                    label='Příjem tekutin za 24 h:'
                    placeholder='příjem tekutin'
                />
            </Stack>

            <Stack spacing={1}>
                <InputLabel className='text-sm font-semibold'>
                    Sliznice DÚ
                </InputLabel>
                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='IsMucosalPink'
                    label='růžová'
                />
                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='IsMucosalPale'
                    label='bledá'
                />
                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='IsMucosalMoist'
                    label='vlhká'
                />
                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='IsMucosalDry'
                    label='suchá'
                />
                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsMucosalSoor'
                    label='soor'
                />
                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsMucosalAphthae'
                    label='afty'
                />
                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsMucosalCoatings'
                    label='povlaky'
                />
            </Stack>

            <MultipleChoiceRadioGroup
                control={control}
                label='Turgor kůže'
                name='Turgor'
                readOnly={!canEditCareAnamnesis}
                options={turgorOptions}
                isSubmitting={isSubmitting}
            />

            <YesNoRadioGroup
                control={control}
                name='IsDiet'
                label='Dieta'
                readOnly={!canEditCareAnamnesis}
                disabled={isSubmitting}
            />

            {isDiet && (
                <InputStyled
                    control={control}
                    disabled={isSubmitting}
                    name='Diet'
                    readOnly={!canEditCareAnamnesis}
                    errors={errors}
                    label='Jaká:'
                    placeholder='jaká dieta'
                />
            )}

            <Stack spacing={1}>
                <YesNoRadioGroup
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    name='IsDiabetes'
                    label='Diabetes mellitus'
                    disabled={isSubmitting}
                />

                {isDiabetes && (
                    <>
                        <CheckboxStyled
                            control={control}
                            disabled={isSubmitting}
                            readOnly={!canEditCareAnamnesis}
                            name='IsDiabetesTypeDiet'
                            label='Dieta'
                        />
                        <CheckboxStyled
                            control={control}
                            disabled={isSubmitting}
                            readOnly={!canEditCareAnamnesis}
                            name='IsDiabetesTypePAD'
                            label='PAD'
                        />
                        <CheckboxStyled
                            control={control}
                            disabled={isSubmitting}
                            readOnly={!canEditCareAnamnesis}
                            name='IsDiabetesTypeInsulin'
                            label='Inzulin'
                        />

                        {isDiabetesTypeInsulin && (
                            <>
                                <InputStyled
                                    className='mt-2'
                                    control={control}
                                    disabled={isSubmitting}
                                    name='Insulin'
                                    errors={errors}
                                    readOnly={!canEditCareAnamnesis}
                                    label='Typ inzulínu:'
                                    placeholder='typ inzulínu'
                                />

                                <InputStyled
                                    control={control}
                                    readOnly={!canEditCareAnamnesis}
                                    disabled={isSubmitting}
                                    name='Dosage'
                                    errors={errors}
                                    label='Dávkování:'
                                    placeholder='dávkování'
                                />

                                <Stack spacing={1}>
                                    <InputLabel className='text-sm font-semibold'>
                                        Způsob aplikace
                                    </InputLabel>
                                    <CheckboxStyled
                                        control={control}
                                        readOnly={!canEditCareAnamnesis}
                                        disabled={isSubmitting}
                                        name='IsInsulineApplicationInsulin'
                                        label='inzulinka'
                                    />
                                    <CheckboxStyled
                                        control={control}
                                        disabled={isSubmitting}
                                        readOnly={!canEditCareAnamnesis}
                                        name='IsInsulineApplicationPen'
                                        label='pero'
                                    />
                                    <CheckboxStyled
                                        control={control}
                                        readOnly={!canEditCareAnamnesis}
                                        disabled={isSubmitting}
                                        name='IsInsulineApplicationPump'
                                        label='pumpa'
                                    />
                                </Stack>

                                <Stack spacing={1}>
                                    <InputLabel className='text-sm font-semibold'>
                                        Kdo aplikuje:
                                    </InputLabel>
                                    <CheckboxStyled
                                        control={control}
                                        readOnly={!canEditCareAnamnesis}
                                        disabled={isSubmitting}
                                        name='IsInsulineAppliesMyself'
                                        label='sám'
                                    />
                                    <CheckboxStyled
                                        control={control}
                                        readOnly={!canEditCareAnamnesis}
                                        disabled={isSubmitting}
                                        name='IsInsulineAppliesFamily'
                                        label='rodina'
                                    />
                                    <CheckboxStyled
                                        control={control}
                                        disabled={isSubmitting}
                                        readOnly={!canEditCareAnamnesis}
                                        name='IsInsulineAppliesOther'
                                        label='jiný'
                                    />
                                </Stack>
                            </>
                        )}
                    </>
                )}
            </Stack>

            <Stack spacing={2}>
                <AnamnesisFormFooter
                    currentStep={currentStep}
                    canEditCareAnamnesis={canEditCareAnamnesis}
                    handleSubmitWrapper={handleSubmitWrapper}
                    disabled={isLoading || isSubmitting}
                    setCurrentStep={setCurrentStep}
                />
            </Stack>
        </form>
    )
}

export const AddPatientAnamnesisFormStep2: FC<AddPatientsAnamnesisFormStep2Hook> =
    wrap(AddPatientAnamnesisFormStep2View, useAddPatientAnamnesisFormStep2)
