import {
    ECommunication,
    EConcentration,
    EConsistency,
    EExcretion,
    EFrequency,
    EHousingIn,
    ENefrostomyType,
    EPainType,
    ESelfCare,
    EStressManagement,
    ETurgor,
    EUrinaryEmptying,
} from '../pages/PatientsPage/types'

function getOptionsFromEnum<T extends object>(enumObject: T) {
    return Object.keys(enumObject)
        .filter((key) => isNaN(Number(key)))
        .map((key, index) => ({
            value: index,
            label: (enumObject as any)[key],
        }))
}

function getOtherValue<T extends object>(
    enumOptions: { label: string; value: number }[],
    otherLabel: string,
): number | undefined {
    const otherOption = enumOptions.find(
        (option) => option.label === otherLabel,
    )
    return otherOption ? otherOption.value : undefined
}

const getOptionsFromEntries = (enumObject: object) =>
    Object.entries(enumObject).map(([key, value]) => ({
        value: key,
        label: value,
    }))

export const turgorOptions = getOptionsFromEnum(ETurgor)
export const eExcretionOptions = getOptionsFromEnum(EExcretion)
export const eFrequencyOptions = getOptionsFromEnum(EFrequency)

export const eConsistencyOptions = getOptionsFromEnum(EConsistency)

export const eUrinaryEmptyingOptions = getOptionsFromEnum(EUrinaryEmptying)
export const eNefrostomyOptions = getOptionsFromEnum(ENefrostomyType)

export const eCommunicationOptions = getOptionsFromEnum(ECommunication)
export const eConcentrationOptions = getOptionsFromEnum(EConcentration)

export const eSelfCareOptions = getOptionsFromEnum(ESelfCare)
export const eHousingInOptions = getOptionsFromEnum(EHousingIn)
export const eStressManagementOptions = getOptionsFromEnum(EStressManagement)

export const ePainTypeOptions = getOptionsFromEnum(EPainType)

export const otherCommunicationValue = getOtherValue(
    eCommunicationOptions,
    'jiné',
)
export const otherConsistencyValue = getOtherValue(eConsistencyOptions, 'jiná')
export const otherStressManagementValue = getOtherValue(
    eStressManagementOptions,
    'jiné',
)
