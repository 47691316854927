import { yupResolver } from '@hookform/resolvers/yup'
import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useNewNoteMutation } from '../../../api'
import { PatientContext } from '../../../providers'
import type { NoteTypeFor } from '../../../types'
import type { NewNoteFormProps } from '../types'

export const useNewNoteForm = (handleCloseModal: () => void) => {
    const { activePatient } = useContext(PatientContext)
    const newNoteMutation = useNewNoteMutation(activePatient?.id)
    const [noteType, setNoteType] = useState<NoteTypeFor>('General')

    const handleNoteTypeChange = (type: NoteTypeFor) => () => {
        setNoteType(type)
    }

    const defaultValues: NewNoteFormProps = {
        newNote: '',
        noteType: 'General',
    }

    const validationSchema = yup.object().shape({
        newNote: yup.string().required('Toto pole je povinné'),
        noteType: yup.string(),
    })

    const formMethods = useForm<NewNoteFormProps>({
        criteriaMode: 'all',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues,
    })

    useEffect(() => {
        formMethods.setValue('noteType', noteType)
    }, [formMethods, noteType])

    const submitNote = async (data: NewNoteFormProps) => {
        await newNoteMutation.mutateAsync(data, {
            onSuccess: () => {
                formMethods.reset()
                handleCloseModal()
            },
        })
    }

    return { handleNoteTypeChange, formMethods, submitNote }
}
