import { FC, useCallback, useEffect } from 'react'
import { wrap } from '../../../utils'
import { useAddPatientAnamnesisFormStep3 } from '../hooks/useAddPatientAnamnesisFormStep3'
import {
    AddPatientsAnamnesisFormStep3Hook,
    IAddPatientAnamnesisFormStep3,
} from '../types'
import { Box, CircularProgress, Stack } from '@mui/material'
import { AnamnesisFormFooter } from './AnamnesisFormFooter'
import YesNoRadioGroup from '../../../components/YesNoRadioGroup'
import MultipleChoiceRadioGroup from '../../../components/MultipleChoicesRadioGroup'
import {
    eConsistencyOptions,
    eExcretionOptions,
    eFrequencyOptions,
    eNefrostomyOptions,
    eUrinaryEmptyingOptions,
    otherConsistencyValue,
} from '../../../components/options'
import InputStyled from '../../../components/InputStyled'
import DateInputStyled from '../../../components/DateInput'

const AddPatientAnamnesisFormStep3View = (
    props: IAddPatientAnamnesisFormStep3,
) => {
    const {
        submitForm,
        formMethods,
        setCurrentStep,
        currentStep,
        invalidDateInputs,
        canEditCareAnamnesis,
        setSaveCurrentStepOnClose,
        isLoading,
    } = props

    const {
        control,
        handleSubmit,
        watch,
        getValues,
        formState: { errors, isSubmitting },
    } = formMethods

    const consistency = watch('Consistency')
    const isStomie = watch('IsStomie')
    const isUrinaryCatheter = watch('IsUrinaryCatheter')
    const isEpicystostomy = watch('IsEpicystostomy')
    const isNefrostomy = watch('IsNefrostomy')
    const isIslands = watch('IsIslands')
    const isIncontinenceAids = watch('IsIncontinenceAids')
    const isTakingDiuretics = watch('IsTakingDiuretics')

    const handleSubmitWrapper = useCallback(
        async (isNext?: boolean) => {
            const formData = getValues()
            await submitForm(formData, isNext ? isNext : false)
        },
        [getValues, submitForm],
    )
    useEffect(() => {
        setSaveCurrentStepOnClose(() => handleSubmitWrapper)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSaveCurrentStepOnClose])

    if (isLoading) {
        return (
            <Box className='!mt-12 flex justify-center'>
                <CircularProgress />
            </Box>
        )
    }

    return (
        <form
            onSubmit={() => handleSubmitWrapper(true)}
            className='grid gap-3'
        >
            <MultipleChoiceRadioGroup
                control={control}
                name='Excretion'
                options={eExcretionOptions}
                readOnly={!canEditCareAnamnesis}
                label='Vylučování stolice'
                isSubmitting={isSubmitting}
            />
            <MultipleChoiceRadioGroup
                control={control}
                name='Frequency'
                options={eFrequencyOptions}
                label='Frekvence'
                readOnly={!canEditCareAnamnesis}
                isSubmitting={isSubmitting}
            />
            <InputStyled
                control={control}
                disabled={isSubmitting}
                name='FrequencyPerDay'
                errors={errors}
                readOnly={!canEditCareAnamnesis}
                type='number'
                positiveOnly
                label='Počet:'
                placeholder='počet'
            />
            <MultipleChoiceRadioGroup
                control={control}
                name='Consistency'
                readOnly={!canEditCareAnamnesis}
                options={eConsistencyOptions}
                label='Konzistence'
                isSubmitting={isSubmitting}
            />
            {consistency !== undefined &&
                consistency === (otherConsistencyValue as any) && (
                    <InputStyled
                        control={control}
                        disabled={isSubmitting}
                        readOnly={!canEditCareAnamnesis}
                        name='ConsistencyOther'
                        errors={errors}
                        label='Jiná:'
                        placeholder='konzistenci'
                    />
                )}
            <YesNoRadioGroup
                control={control}
                name='IsStomie'
                readOnly={!canEditCareAnamnesis}
                label='Stomie'
                disabled={isSubmitting}
            />
            {isStomie && (
                <InputStyled
                    readOnly={!canEditCareAnamnesis}
                    control={control}
                    disabled={isSubmitting}
                    name='Stomie'
                    errors={errors}
                    label='Jaká:'
                    placeholder='stomie'
                />
            )}

            <MultipleChoiceRadioGroup
                control={control}
                name='UrinaryEmptying'
                readOnly={!canEditCareAnamnesis}
                options={eUrinaryEmptyingOptions}
                label='Vyprazdňování moče'
                isSubmitting={isSubmitting}
            />

            <YesNoRadioGroup
                control={control}
                name='IsIncontinenceAids'
                readOnly={!canEditCareAnamnesis}
                disabled={isSubmitting}
                label='Inkontinenční pomůcky'
            />
            {isIncontinenceAids && (
                <InputStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='IncontinenceAids'
                    errors={errors}
                    label='které:'
                    placeholder='které pomůcky'
                />
            )}

            <YesNoRadioGroup
                control={control}
                readOnly={!canEditCareAnamnesis}
                name='IsUrinaryCatheter'
                disabled={isSubmitting}
                label='Močový katétr'
            />

            {isUrinaryCatheter && (
                <>
                    {invalidDateInputs.includes('UrinaryCatheterDate') ? (
                        <InputStyled
                            control={control}
                            readOnly={!canEditCareAnamnesis}
                            disabled={isSubmitting}
                            name='UrinaryCatheterDate'
                            errors={errors}
                            label='Datum zavedení:'
                        />
                    ) : (
                        <DateInputStyled
                            control={control}
                            disabled={isSubmitting}
                            readOnly={!canEditCareAnamnesis}
                            name='UrinaryCatheterDate'
                            errors={errors}
                            label='Datum zavedení:'
                        />
                    )}

                    <InputStyled
                        control={control}
                        disabled={isSubmitting}
                        readOnly={!canEditCareAnamnesis}
                        name='UrinaryCatheterSize'
                        errors={errors}
                        type='number'
                        positiveOnly
                        label='Velikost:'
                        placeholder='velikost'
                    />
                </>
            )}

            <YesNoRadioGroup
                control={control}
                name='IsEpicystostomy'
                readOnly={!canEditCareAnamnesis}
                disabled={isSubmitting}
                label='Epicystostomie'
            />

            {isEpicystostomy && (
                <>
                    {invalidDateInputs.includes('Epicystostomy') ? (
                        <InputStyled
                            control={control}
                            disabled={isSubmitting}
                            readOnly={!canEditCareAnamnesis}
                            name='Epicystostomy'
                            errors={errors}
                            label='Datum zavedení:'
                        />
                    ) : (
                        <DateInputStyled
                            control={control}
                            disabled={isSubmitting}
                            name='Epicystostomy'
                            readOnly={!canEditCareAnamnesis}
                            errors={errors}
                            label='Datum zavedení:'
                        />
                    )}
                </>
            )}

            <YesNoRadioGroup
                control={control}
                name='IsNefrostomy'
                disabled={isSubmitting}
                label='Nefrostomie'
                readOnly={!canEditCareAnamnesis}
            />

            {isNefrostomy && (
                <>
                    {invalidDateInputs.includes('Nefrostomy') ? (
                        <InputStyled
                            control={control}
                            disabled={isSubmitting}
                            name='Nefrostomy'
                            readOnly={!canEditCareAnamnesis}
                            errors={errors}
                            label='Datum zavedení:'
                        />
                    ) : (
                        <DateInputStyled
                            control={control}
                            disabled={isSubmitting}
                            readOnly={!canEditCareAnamnesis}
                            name='Nefrostomy'
                            errors={errors}
                            label='Datum zavedení:'
                        />
                    )}

                    <MultipleChoiceRadioGroup
                        control={control}
                        name='NefrostomyType'
                        readOnly={!canEditCareAnamnesis}
                        options={eNefrostomyOptions}
                        label='Typ nefrostomie'
                        isSubmitting={isSubmitting}
                    />
                </>
            )}

            <YesNoRadioGroup
                control={control}
                name='IsIslands'
                readOnly={!canEditCareAnamnesis}
                disabled={isSubmitting}
                label='Otoky'
            />

            {isIslands && (
                <InputStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='Islands'
                    errors={errors}
                    label='Kde:'
                    placeholder='kde'
                />
            )}

            <YesNoRadioGroup
                control={control}
                name='IsTakingDiuretics'
                readOnly={!canEditCareAnamnesis}
                disabled={isSubmitting}
                label='Užívání diuretik'
            />

            {isTakingDiuretics && (
                <InputStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='TakingDiuretics'
                    errors={errors}
                    label='Jaké:'
                    placeholder='jaké'
                />
            )}

            <Stack spacing={2}>
                <AnamnesisFormFooter
                    currentStep={currentStep}
                    canEditCareAnamnesis={canEditCareAnamnesis}
                    handleSubmitWrapper={handleSubmitWrapper}
                    disabled={isLoading || isSubmitting}
                    setCurrentStep={setCurrentStep}
                />
            </Stack>
        </form>
    )
}

export const AddPatientAnamnesisFormStep3: FC<AddPatientsAnamnesisFormStep3Hook> =
    wrap(AddPatientAnamnesisFormStep3View, useAddPatientAnamnesisFormStep3)
