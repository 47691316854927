import { Female, Male, ContentPasteGo, Close, Help } from '@mui/icons-material'
import {
    Avatar,
    Box,
    Checkbox,
    CircularProgress,
    ClickAwayListener,
    Fab,
    Fade,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Skeleton,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import classnames from 'classnames'
import moment from 'moment'
import { useCallback, useContext, useState } from 'react'
import { Controller } from 'react-hook-form'
import { PatientContext } from '../../../../providers'
import { StateCzech, VisitInfoProps } from '../../types'
import { NumberFormatValues, NumericFormat } from 'react-number-format'
import { VariantType, useSnackbar } from 'notistack'
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker'
import { twMerge } from 'tailwind-merge'
import { LastNurseVisit } from '../../../../types'

export const VisitInfo = (props: VisitInfoProps) => {
    const {
        visitForm,
        isLoading,
        plannedVisitLength,
        plannedVisitTime,
        isBloodPressureHighRequired,
        isBloodPressureLowRequired,
        isBreathingFreqRequired,
        isGlycemiaRequired,
        isPainRequired,
        isPulseRequired,
        isTemperatureRequired,
        isSaturationRequired,
        lastNurseVisit,
        state,
        canSave,
        isTimeClicked,
        setIsTimeClicked,
        isFixVisitTimeEnabled,
        fixVisitTime,
        showTabs,
    } = props

    const { activePatient } = useContext(PatientContext)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [isTooltipOpen, setIsTooltipOpen] = useState(false)

    const handleTooltipOpen = () => setIsTooltipOpen(true)
    const handleTooltipClose = () => setIsTooltipOpen(false)

    const [isSubjectiveDisabledOnClick, setIsSubjectiveDisabledOnClick] =
        useState(false)
    const [isObjectiveDisabledOnClick, setIsObjectiveDisabledOnClick] =
        useState(false)

    const getSnackbar = useCallback(
        (text: string, variant?: VariantType) => {
            enqueueSnackbar(text, {
                variant: variant || 'success',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
                action: (key) => (
                    <IconButton onClick={() => closeSnackbar(key)}>
                        <Close color='secondary' />
                    </IconButton>
                ),
            })
        },
        [closeSnackbar, enqueueSnackbar],
    )

    moment.updateLocale('cz', {
        weekdaysMin: ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So'],
    })

    const {
        formMethods: {
            control,
            setValue,
            getValues,
            watch,
            formState: { errors },
        },
    } = visitForm

    const watchVisitInfo = watch([
        'bloodPressureHigh',
        'bloodPressureLow',
        'pulse',
        'breathingFreq',
        'saturation',
        'temperature',
        'glycemia',
        'pain',
        'subjective',
        'objective',
        'time',
        'painLocalization',
    ])

    const watchPain = watch('pain')
    const subjectiveValue = getValues('subjective')
    const objectiveValue = getValues('objective')

    const watchVisitInfoCount = watchVisitInfo.filter(
        (item) => item !== '' && item !== undefined && item !== null,
    ).length

    const numberFormulaCount = (watchVisitInfoCount / 12) * 100

    const getNumberColor = () => {
        if (numberFormulaCount > 75) {
            return 'success'
        } else if (numberFormulaCount > 50) {
            return 'warning'
        } else {
            return 'error'
        }
    }

    const getAllowedNumber = (values: NumberFormatValues, maxVal = 999) => {
        const { floatValue } = values
        return floatValue !== undefined ? floatValue < maxVal : true
    }

    const getStarSign = (isRequired?: boolean) => isRequired && '*'

    type pasteFromLastType = keyof Pick<
        LastNurseVisit,
        'painLocalization' | 'objective' | 'subjective'
    >

    const pasteFromLast = (textType: pasteFromLastType) => {
        if (!lastNurseVisit) return

        switch (textType) {
            case 'objective':
                setValue(
                    'objective',
                    (objectiveValue || '') + lastNurseVisit?.objective,
                )
                getSnackbar(
                    `Data byla vložena do pole "Objektivně" z poslední návštěvy - ${moment(
                        lastNurseVisit?.date,
                    ).format('DD.MM.')}.`,
                )
                break
            case 'subjective':
                setValue(
                    'subjective',
                    (subjectiveValue || '') + lastNurseVisit?.subjective,
                )
                getSnackbar(
                    `Data byla vložena do pole "Subjektivně" z poslední návštěvy - ${moment(
                        lastNurseVisit?.date,
                    ).format('DD.MM.')}.`,
                )
                break
            case 'painLocalization':
                setValue(
                    'painLocalization',
                    (subjectiveValue || '') + lastNurseVisit?.painLocalization,
                )
                getSnackbar(
                    `Data byla vložena do pole "Lokalizace bolesti" z poslední návštěvy - ${moment(
                        lastNurseVisit?.date,
                    ).format('DD.MM.')}.`,
                )
                break
        }
    }

    return (
        <Grid
            container
            item
            xs={12}
            spacing={2}
        >
            <Grid
                item
                xs={3}
            >
                <InputLabel className='pb-2 text-sm font-medium'>
                    Délka náv.
                </InputLabel>
                <OutlinedInput
                    size='small'
                    readOnly
                    value={`${plannedVisitLength || 0} min`}
                    className='w-full rounded bg-orange-100 text-sm font-medium'
                    inputProps={{
                        className: 'px-2 py-1',
                    }}
                />
            </Grid>
            <Grid
                item
                xs={3}
            >
                <InputLabel className='pb-2 text-sm font-medium'>
                    Příjezd
                </InputLabel>
                <OutlinedInput
                    name='plannedVisitTime'
                    readOnly
                    size='small'
                    value={
                        plannedVisitTime
                            ? moment(plannedVisitTime).format('HH:mm')
                            : 0
                    }
                    className='w-full rounded bg-orange-100 text-sm font-medium'
                    inputProps={{
                        className: 'px-2 py-1',
                    }}
                />
            </Grid>
            <Grid
                item
                xs={6}
            >
                <InputLabel className='pb-2 text-sm font-medium'>
                    Datum návštěvy
                </InputLabel>
                <OutlinedInput
                    readOnly
                    size='small'
                    value={
                        plannedVisitTime
                            ? moment(plannedVisitTime)
                                  .locale('cs')
                                  .format('dd - DD.MM.YYYY')
                            : 0
                    }
                    className='w-full rounded bg-orange-100 text-sm font-medium '
                    inputProps={{
                        className: 'px-2 py-1',
                    }}
                />
            </Grid>
            <Grid
                item
                xs={6}
                className='text-center'
            >
                <Box
                    className={twMerge(
                        'sticky top-48 inline-flex',
                        !showTabs && 'top-24',
                    )}
                >
                    <CircularProgress
                        variant='determinate'
                        className='absolute inset-0 flex items-center justify-center text-white-reduced'
                        size={142}
                        thickness={3.5}
                        value={100}
                    />
                    <CircularProgress
                        variant='determinate'
                        color={getNumberColor()}
                        value={numberFormulaCount}
                        size={142}
                        thickness={3.5}
                        sx={{ strokeLinecap: 'round' }}
                    />
                    <Box className='absolute inset-0 flex flex-col items-center justify-center'>
                        <Avatar className='mb-1 bg-orange-300'>
                            {isLoading ? (
                                <Skeleton
                                    animation='wave'
                                    variant='circular'
                                    width={40}
                                    height={40}
                                />
                            ) : activePatient?.sex === 'Male' ? (
                                <Male className='text-white-reduced' />
                            ) : (
                                <Female className='text-white-reduced' />
                            )}
                        </Avatar>
                        <Typography className='max-w-[100px] text-sm font-medium'>
                            {activePatient?.fullName}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid
                container
                item
                xs={6}
                spacing={2}
            >
                {!!state && (
                    <Grid
                        item
                        xs={12}
                    >
                        <InputLabel className='pb-2 text-sm font-medium'>
                            Stav návštěvy
                        </InputLabel>
                        <OutlinedInput
                            readOnly
                            size='small'
                            value={StateCzech[state]}
                            className='w-full rounded bg-orange-100 text-sm font-medium'
                            inputProps={{
                                className: 'px-2 py-1',
                            }}
                        />
                    </Grid>
                )}
                <Grid
                    item
                    container
                    className='flex flex-col'
                    xs={12}
                >
                    <InputLabel className='pb-2 text-sm font-medium'>
                        Reálný čas příjezdu*
                    </InputLabel>
                    <Controller
                        name='time'
                        control={control}
                        render={({ field: { ref, ...field } }) => {
                            if (!field.value && isTimeClicked) {
                                field.onChange(moment().format('HH:mm'))
                                setIsTimeClicked(false)
                            }

                            return (
                                <MobileTimePicker
                                    {...field}
                                    readOnly={!canSave}
                                    value={
                                        !!field.value
                                            ? moment(
                                                  field.value,
                                                  'HH:mm',
                                              ).toDate()
                                            : ''
                                    }
                                    componentsProps={{
                                        actionBar: {
                                            lang: 'cs',
                                            actions: [
                                                'cancel',
                                                'today',
                                                'accept',
                                            ],
                                        },
                                    }}
                                    views={['hours', 'minutes']}
                                    onChange={(date) => {
                                        if (moment(date).isValid()) {
                                            field.onChange(
                                                moment(date).format('HH:mm'),
                                            )
                                        } else {
                                            field.onChange(null)
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            value={params.value || ''}
                                            error={!!errors.time}
                                            name={field.name}
                                            inputProps={{
                                                ...params.inputProps,
                                                className: classnames(
                                                    'rounded text-sm font-medium px-2 py-1',
                                                    {
                                                        ' bg-orange-100':
                                                            canSave === false,

                                                        'bg-white-reduced':
                                                            errors.time ===
                                                                undefined &&
                                                            canSave === true,
                                                        'bg-error-2/20':
                                                            errors.time !==
                                                            undefined,
                                                    },
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            )
                        }}
                    />
                    {errors.time?.message && (
                        <Typography className='pt-1 text-sm text-error-2'>
                            Toto pole je povinné
                        </Typography>
                    )}
                    {isFixVisitTimeEnabled && (
                        <Grid
                            container
                            item
                            alignItems='center'
                            className='mt-2'
                        >
                            <Grid item>
                                <Controller
                                    name='fixVisitTime'
                                    control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    {...field}
                                                    readOnly={!canSave}
                                                    defaultChecked={
                                                        !!fixVisitTime
                                                    }
                                                    size='small'
                                                />
                                            }
                                            label='Zafixovat čas'
                                            classes={{
                                                label: 'text-sm font-medium',
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item>
                                <ClickAwayListener
                                    onClickAway={handleTooltipClose}
                                >
                                    <Tooltip
                                        PopperProps={{
                                            disablePortal: true,
                                        }}
                                        TransitionComponent={Fade}
                                        arrow
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        onClose={handleTooltipClose}
                                        open={isTooltipOpen}
                                        title='Zvolte, pokud je opravdu nutné vykázat skutečný čas (z důvodu podání inzulinu, příjezdu lékaře apod.)'
                                    >
                                        <IconButton
                                            size='small'
                                            onClick={handleTooltipOpen}
                                        >
                                            <Help fontSize='inherit' />
                                        </IconButton>
                                    </Tooltip>
                                </ClickAwayListener>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <Stack>
                        <InputLabel className='pb-2 text-sm font-medium'>
                            Krevní tlak
                            {getStarSign(
                                isBloodPressureHighRequired &&
                                    isBloodPressureLowRequired,
                            )}
                        </InputLabel>
                        <Stack
                            direction='row'
                            spacing={1}
                        >
                            <Controller
                                control={control}
                                name='bloodPressureHigh'
                                render={({ field }) => (
                                    <NumericFormat
                                        {...field}
                                        size='small'
                                        isAllowed={getAllowedNumber}
                                        readOnly={!canSave}
                                        decimalScale={0}
                                        onChange={(e) => {
                                            const inputValue =
                                                e?.target?.value?.trim()
                                            if (!inputValue) return
                                            const numericValue =
                                                inputValue === ''
                                                    ? null
                                                    : Number(inputValue)

                                            if (!numericValue) return
                                            field.onChange(numericValue)
                                        }}
                                        value={field.value}
                                        customInput={OutlinedInput}
                                        className={classnames(
                                            'flex-1 rounded text-sm font-medium',
                                            {
                                                ' bg-orange-100':
                                                    canSave === false,

                                                'bg-white-reduced':
                                                    errors.bloodPressureHigh ===
                                                        undefined &&
                                                    canSave === true,
                                                'bg-error-2/20':
                                                    errors.bloodPressureHigh !==
                                                    undefined,
                                            },
                                        )}
                                        inputProps={{
                                            className: 'px-2 py-1',
                                            inputMode: 'numeric',
                                        }}
                                    />
                                )}
                            />
                            <Typography>/</Typography>
                            <Controller
                                control={control}
                                name='bloodPressureLow'
                                render={({ field }) => (
                                    <NumericFormat
                                        {...field}
                                        size='small'
                                        isAllowed={getAllowedNumber}
                                        readOnly={!canSave}
                                        decimalScale={0}
                                        onChange={(e) => {
                                            const inputValue =
                                                e?.target?.value?.trim()
                                            if (!inputValue) return
                                            const numericValue =
                                                inputValue === ''
                                                    ? null
                                                    : Number(inputValue)
                                            if (!numericValue) return
                                            field.onChange(numericValue)
                                        }}
                                        value={field.value}
                                        customInput={OutlinedInput}
                                        className={classnames(
                                            'flex-1 rounded text-sm font-medium',
                                            {
                                                ' bg-orange-100':
                                                    canSave === false,
                                                'bg-white-reduced':
                                                    errors.bloodPressureLow ===
                                                        undefined &&
                                                    canSave === true,
                                                'bg-error-2/20':
                                                    errors.bloodPressureLow !==
                                                    undefined,
                                            },
                                        )}
                                        inputProps={{
                                            className: 'px-2 py-1',
                                            inputMode: 'numeric',
                                        }}
                                    />
                                )}
                            />
                        </Stack>
                    </Stack>
                </Grid>
                <Grid
                    item
                    xs={6}
                    className='flex flex-col'
                >
                    <InputLabel className='pb-2 text-sm font-medium'>
                        Pulz{getStarSign(isPulseRequired)}
                    </InputLabel>
                    <Controller
                        control={control}
                        name='pulse'
                        render={({ field }) => (
                            <NumericFormat
                                {...field}
                                size='small'
                                inputMode='numeric'
                                readOnly={!canSave}
                                customInput={OutlinedInput}
                                isAllowed={getAllowedNumber}
                                decimalScale={0}
                                value={field.value}
                                onChange={(e) => {
                                    const inputValue = e?.target?.value?.trim()
                                    if (!inputValue) return
                                    const numericValue =
                                        inputValue === ''
                                            ? null
                                            : Number(inputValue)
                                    if (!numericValue) return
                                    field.onChange(numericValue)
                                }}
                                className={classnames(
                                    'flex-1 rounded text-sm font-medium',
                                    {
                                        ' bg-orange-100': canSave === false,

                                        'bg-white-reduced':
                                            errors.pulse === undefined &&
                                            canSave === true,
                                        'bg-error-2/20':
                                            errors.pulse !== undefined,
                                    },
                                )}
                                inputProps={{
                                    className: 'px-2 py-1',
                                    inputMode: 'numeric',
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                    className='flex flex-col'
                >
                    <InputLabel className='pb-2 text-sm font-medium'>
                        Dech. fr.{getStarSign(isBreathingFreqRequired)}
                    </InputLabel>
                    <Controller
                        control={control}
                        name='breathingFreq'
                        render={({ field }) => (
                            <NumericFormat
                                {...field}
                                size='small'
                                customInput={OutlinedInput}
                                readOnly={!canSave}
                                value={field.value}
                                isAllowed={getAllowedNumber}
                                decimalScale={0}
                                onChange={(e) => {
                                    const inputValue = e?.target?.value?.trim()
                                    if (!inputValue) return
                                    const numericValue =
                                        inputValue === ''
                                            ? null
                                            : Number(inputValue)
                                    if (!numericValue) return
                                    field.onChange(numericValue)
                                }}
                                className={classnames(
                                    'flex-1 rounded text-sm font-medium',
                                    {
                                        'bg-orange-100': canSave === false,
                                        'bg-white-reduced':
                                            errors.breathingFreq ===
                                                undefined && canSave === true,
                                        'bg-error-2/20':
                                            errors.breathingFreq !== undefined,
                                    },
                                )}
                                inputProps={{
                                    className: 'px-2 py-1',
                                    inputMode: 'numeric',
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                    className='flex flex-col'
                >
                    <InputLabel className='pb-2 text-sm font-medium'>
                        Saturace{getStarSign(isSaturationRequired)}
                    </InputLabel>
                    <Controller
                        control={control}
                        name='saturation'
                        render={({ field }) => (
                            <NumericFormat
                                {...field}
                                size='small'
                                isAllowed={(values) =>
                                    getAllowedNumber(values, 101)
                                }
                                decimalScale={0}
                                customInput={OutlinedInput}
                                readOnly={!canSave}
                                value={field.value}
                                onChange={(e) => {
                                    const inputValue = e?.target?.value?.trim()
                                    if (!inputValue) return
                                    const numericValue =
                                        inputValue === ''
                                            ? null
                                            : Number(inputValue)

                                    if (!numericValue) return
                                    field.onChange(numericValue)
                                }}
                                className={classnames(
                                    'rounded text-sm font-medium',
                                    {
                                        'bg-orange-100': canSave === false,
                                        'bg-white-reduced':
                                            errors.saturation === undefined &&
                                            canSave === true,
                                        'bg-error-2/20':
                                            errors.saturation !== undefined,
                                    },
                                )}
                                inputProps={{
                                    className: 'px-2 py-1',
                                    inputMode: 'numeric',
                                }}
                                endAdornment={'%'}
                            />
                        )}
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                    className='flex flex-col'
                >
                    <InputLabel className='pb-2 text-sm font-medium'>
                        Teplota{getStarSign(isTemperatureRequired)}
                    </InputLabel>
                    <Controller
                        control={control}
                        name='temperature'
                        render={({ field }) => (
                            <NumericFormat
                                {...field}
                                size='small'
                                decimalScale={1}
                                customInput={OutlinedInput}
                                decimalSeparator=','
                                fixedDecimalScale
                                isAllowed={(values) =>
                                    getAllowedNumber(values, 60)
                                }
                                readOnly={!canSave}
                                value={field.value}
                                onChange={(e) => {
                                    const inputValue = e?.target?.value?.trim()
                                    if (!inputValue) return

                                    const numericValue = parseFloat(
                                        inputValue.replace(/,/g, '.'),
                                    )
                                    if (isNaN(numericValue)) return

                                    field.onChange(numericValue)
                                }}
                                className={classnames(
                                    'rounded text-sm font-medium',
                                    {
                                        'bg-orange-100': canSave === false,
                                        'bg-white-reduced':
                                            errors.temperature === undefined &&
                                            canSave === true,
                                        'bg-error-2/20':
                                            errors.temperature !== undefined,
                                    },
                                )}
                                inputProps={{
                                    className: 'px-2 py-1',
                                    inputMode: 'numeric',
                                }}
                                endAdornment={'°C'}
                            />
                        )}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    className='flex flex-col'
                >
                    <InputLabel className='pb-2 text-sm font-medium'>
                        Glykémie{getStarSign(isGlycemiaRequired)}
                    </InputLabel>
                    <Controller
                        control={control}
                        name='glycemia'
                        render={({ field }) => (
                            <NumericFormat
                                {...field}
                                size='small'
                                decimalScale={1}
                                customInput={OutlinedInput}
                                readOnly={!canSave}
                                value={field.value}
                                decimalSeparator=','
                                fixedDecimalScale
                                isAllowed={(values) =>
                                    getAllowedNumber(values, 50)
                                }
                                onChange={(e) => {
                                    const inputValue = e?.target?.value?.trim()
                                    if (!inputValue) return

                                    const numericValue = parseFloat(
                                        inputValue.replace(/,/g, '.'),
                                    )
                                    if (isNaN(numericValue)) return

                                    field.onChange(numericValue)
                                }}
                                className={classnames(
                                    'whitespace-nowrap rounded text-sm font-medium',
                                    {
                                        'bg-orange-100': canSave === false,
                                        'bg-white-reduced':
                                            errors.glycemia === undefined &&
                                            canSave === true,
                                        'bg-error-2/20':
                                            errors.glycemia !== undefined,
                                    },
                                )}
                                inputProps={{
                                    className: 'px-2 py-1',
                                    inputMode: 'numeric',
                                }}
                                endAdornment={'mnol/l'}
                            />
                        )}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    className='flex flex-col'
                >
                    <InputLabel className='pb-2 text-sm font-medium'>
                        Bolest{getStarSign(isPainRequired)}
                    </InputLabel>
                    <Controller
                        control={control}
                        name='pain'
                        render={({ field }) => (
                            <NumericFormat
                                {...field}
                                size='small'
                                customInput={OutlinedInput}
                                isAllowed={(values) =>
                                    getAllowedNumber(values, 11)
                                }
                                decimalScale={0}
                                readOnly={!canSave}
                                value={field.value}
                                onChange={(e) => {
                                    const inputValue = e?.target?.value?.trim()
                                    if (inputValue === '') {
                                        field.onChange(null)
                                        return
                                    }

                                    const numericValue = Number(inputValue)
                                    if (isNaN(numericValue)) return
                                    field.onChange(numericValue)
                                }}
                                className={classnames(
                                    'rounded text-sm font-medium',
                                    {
                                        'bg-orange-100': canSave === false,
                                        'bg-white-reduced':
                                            errors.pain === undefined &&
                                            canSave === true,
                                        'bg-error-2/20':
                                            errors.pain !== undefined,
                                    },
                                )}
                                inputProps={{
                                    className: 'px-2 py-1',
                                    inputMode: 'numeric',
                                }}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            {watchPain != null && (
                <Grid
                    item
                    xs={12}
                >
                    <InputLabel className='pb-2 text-sm font-medium'>
                        Lokalizace bolesti
                    </InputLabel>
                    <Controller
                        control={control}
                        name='painLocalization'
                        render={({ field }) => (
                            <OutlinedInput
                                {...field}
                                size='small'
                                fullWidth
                                multiline
                                minRows={2}
                                maxRows={8}
                                value={field.value || ''}
                                readOnly={!canSave}
                                className={classnames(
                                    'rounded pb-16 text-sm font-medium',
                                    {
                                        'bg-orange-100': canSave === false,
                                        'bg-white-reduced':
                                            errors.painLocalization ===
                                                undefined && canSave === true,
                                        'bg-error-2/20':
                                            errors.painLocalization !==
                                            undefined,
                                    },
                                )}
                                endAdornment={
                                    <InputAdornment
                                        className='position absolute bottom-7 right-2'
                                        position='end'
                                    >
                                        <Fab
                                            variant='extended'
                                            size='small'
                                            color='primary'
                                            aria-label='paste last'
                                            sx={{
                                                color: '#f8f8f8',
                                                zIndex: 1,
                                            }}
                                            disabled={
                                                !canSave ||
                                                !lastNurseVisit
                                                    ?.painLocalization?.length
                                            }
                                            onClick={() =>
                                                pasteFromLast(
                                                    'painLocalization',
                                                )
                                            }
                                        >
                                            <ContentPasteGo sx={{ mr: 1 }} />
                                            {lastNurseVisit?.date
                                                ? moment(
                                                      lastNurseVisit?.date,
                                                  ).format('DD.MM.')
                                                : 'není'}
                                        </Fab>
                                    </InputAdornment>
                                }
                            />
                        )}
                    />
                    {errors.painLocalization?.message && (
                        <Typography className='pt-1 text-sm text-error-2'>
                            Toto pole je povinné
                        </Typography>
                    )}
                </Grid>
            )}
            <Grid
                container
                paddingLeft={2}
                spacing={2}
                marginTop={2}
            >
                <Grid
                    item
                    xs={12}
                    md={6}
                >
                    <InputLabel className='pb-2 text-sm font-medium'>
                        Subjektivně*
                    </InputLabel>
                    <Controller
                        control={control}
                        name='subjective'
                        render={({ field }) => (
                            <OutlinedInput
                                {...field}
                                size='small'
                                fullWidth
                                multiline
                                minRows={1.4}
                                maxRows={8}
                                readOnly={!canSave}
                                value={field.value || ''}
                                className={classnames(
                                    'items-start rounded pb-16 text-sm font-medium lg:pb-24',
                                    {
                                        'bg-orange-100': canSave === false,
                                        'bg-white-reduced':
                                            errors.subjective === undefined &&
                                            canSave === true,
                                        'bg-error-2/20':
                                            errors.subjective !== undefined,
                                    },
                                )}
                                endAdornment={
                                    <InputAdornment
                                        className='position absolute bottom-7 right-2'
                                        position='end'
                                    >
                                        <Fab
                                            variant='extended'
                                            size='small'
                                            color='primary'
                                            aria-label='paste last'
                                            sx={{
                                                color: '#f8f8f8',
                                                zIndex: 1,
                                            }}
                                            disabled={
                                                !canSave ||
                                                isSubjectiveDisabledOnClick ||
                                                !lastNurseVisit?.subjective
                                                    ?.length
                                            }
                                            onClick={() => {
                                                pasteFromLast('subjective')
                                                setIsSubjectiveDisabledOnClick(
                                                    (prev) => !prev,
                                                )
                                            }}
                                        >
                                            <ContentPasteGo sx={{ mr: 1 }} />
                                            {lastNurseVisit?.date
                                                ? moment(
                                                      lastNurseVisit?.date,
                                                  ).format('DD.MM.')
                                                : 'není'}
                                        </Fab>
                                    </InputAdornment>
                                }
                            />
                        )}
                    />
                    {errors.subjective?.message && (
                        <Typography className='pt-1 text-sm text-error-2'>
                            Toto pole je povinné
                        </Typography>
                    )}
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                >
                    <InputLabel className='pb-2 text-sm font-medium'>
                        Objektivně*
                    </InputLabel>
                    <Controller
                        control={control}
                        name='objective'
                        render={({ field }) => (
                            <OutlinedInput
                                {...field}
                                size='small'
                                fullWidth
                                multiline
                                minRows={1.4}
                                maxRows={8}
                                readOnly={!canSave}
                                value={field.value || ''}
                                className={classnames(
                                    ' items-start rounded  pb-16 text-sm font-medium lg:pb-24',
                                    {
                                        'bg-orange-100': canSave === false,
                                        'bg-white-reduced':
                                            errors.objective === undefined &&
                                            canSave === true,
                                        'bg-error-2/20':
                                            errors.objective !== undefined,
                                    },
                                )}
                                endAdornment={
                                    <InputAdornment
                                        className='position absolute bottom-7 right-2'
                                        position='end'
                                    >
                                        <Fab
                                            variant='extended'
                                            size='small'
                                            color='primary'
                                            aria-label='paste last'
                                            sx={{
                                                color: '#f8f8f8',
                                                zIndex: 1,
                                            }}
                                            disabled={
                                                !canSave ||
                                                isObjectiveDisabledOnClick ||
                                                !lastNurseVisit?.objective
                                                    ?.length
                                            }
                                            onClick={() => {
                                                pasteFromLast('objective')
                                                setIsObjectiveDisabledOnClick(
                                                    (prev) => !prev,
                                                )
                                            }}
                                        >
                                            <ContentPasteGo sx={{ mr: 1 }} />
                                            {lastNurseVisit?.date
                                                ? moment(
                                                      lastNurseVisit?.date,
                                                  ).format('DD.MM.')
                                                : 'není'}
                                        </Fab>
                                    </InputAdornment>
                                }
                            />
                        )}
                    />
                    {errors.objective?.message && (
                        <Typography className='pt-1 text-sm text-error-2'>
                            Toto pole je povinné
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}
