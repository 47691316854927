import { FC } from 'react'
import { PatientsPageProps } from './types'
import { Box, IconButton, Typography } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home'
import { useNavigate } from 'react-router-dom'
import { Navigation } from '../../components'
import { PatientsDial } from './components/PatientsDial'
import { PatientFiltersProvider } from '../../providers/PatientFiltersProvider'
import { PatientsPageContent } from './PatientsPageContent'

export const PatientsPageView: FC<PatientsPageProps> = (props) => {
    const { isMultiEditMode, setIsMultiEditMode } = props
    const navigate = useNavigate()
    return (
        <Box
            component='main'
            className='relative bg-orange-50 pt-6'
            sx={{
                minHeight: isMultiEditMode
                    ? 'calc(100vh - 112px)'
                    : 'calc(100vh - 64px)',
                paddingBottom: isMultiEditMode ? '7rem' : '4rem',
            }}
        >
            <Navigation>
                <Box className='flex items-center'>
                    <IconButton
                        onClick={() => navigate('/home')}
                        className='mr-1'
                    >
                        <HomeIcon style={{ color: '#faa26b' }} />
                    </IconButton>
                    <Typography
                        variant='h1'
                        className='text-xl font-medium sm:text-2xl'
                    >
                        Kartotéka pacientů
                    </Typography>
                </Box>
            </Navigation>
            <Box className='mx-auto pt-2 lg:max-w-container'>
                <PatientFiltersProvider>
                    <PatientsPageContent
                        isMultiEditMode={isMultiEditMode}
                        setIsMultiEditMode={setIsMultiEditMode}
                    />
                </PatientFiltersProvider>
            </Box>
            <PatientsDial
                isPatientsEditModeEnabled={isMultiEditMode}
                setIsMultiEditMode={setIsMultiEditMode}
            />
        </Box>
    )
}
