import { SyntheticEvent, useState } from 'react'
import { TabValue } from '../types'

export const useTabs = () => {
    const [activeTab, setActiveTab] = useState<TabValue>(0)

    const handleTabChange = (event: SyntheticEvent, newValue: TabValue) => {
        setActiveTab(newValue)
        window.scrollTo(0, 0)
    }

    return {
        activeTab,
        handleTabChange,
    }
}
