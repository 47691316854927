import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    AddPatientsAnamnesisFormStep2Hook,
    CareAnamnesisStep2,
    CareAnamnesisStep2Keys,
    CareAnamnesisStepQuery,
    IAddPatientAnamnesisFormStep2,
} from '../types'
import { useQuery } from '@tanstack/react-query'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../providers'
import { anamnesisStep2Schema } from './schema'
import {
    getPatientCareAnamnesis,
    PATIENT_CARE_ANAMNESIS_QUERY,
} from './services'
import { useSavePatientCareAnamnesisMutation } from './hooks'
import { convertToDate, formatDateIfValid } from '../../../utils/functionsUtils'

export const useAddPatientAnamnesisFormStep2 = (
    props: AddPatientsAnamnesisFormStep2Hook,
): IAddPatientAnamnesisFormStep2 => {
    const { setCurrentStep, currentStep, anamnesisId, canEditCareAnamnesis } =
        props
    const { token } = useContext(AuthContext)
    const [isFormLoading, setIsFormLoading] = useState(true)
    const [invalidDateInputs, setInvalidDateInputs] = useState<
        CareAnamnesisStep2Keys[]
    >([])

    const { mutatePatientCareAnamnesisStepPut } =
        useSavePatientCareAnamnesisMutation()

    const defaultValues: CareAnamnesisStep2 = {
        IsWeightChange: false,
        CurrentWeight: '',
        CurrentHeight: '',
        IncreaseWeight: '',
        DecreaseWeight: '',
        IsEnteral: false,
        Enteral: '',
        FluidIntakePerDay: '',
        IsFoodIntakeMouth: false,
        IsFoodIntakeNasogastricProbe: false,
        FoodIntakeNasogastricProbe: '',
        IsFoodIntakeGastrostomy: false,
        FoodIntakeGastrostomy: '',
        IsFoodIntakePEG: false,
        FoodIntakePEG: '',
        IsFoodIntakeParenterally: false,
        FoodIntakeParenterally: '',
        IsFluidIntakeMouth: false,
        IsFluidIntakeEnterally: false,
        IsFluidIntakeParenterally: false,
        IsFluidIntakeSipping: false,
        IsMucosalPink: false,
        IsMucosalPale: false,
        IsMucosalMoist: false,
        IsMucosalDry: false,
        IsMucosalSoor: false,
        IsMucosalAphthae: false,
        IsMucosalCoatings: false,
        Turgor: null,
        IsDiet: false,
        Diet: '',
        IsDiabetes: false,
        IsDiabetesTypeDiet: false,
        IsDiabetesTypePAD: false,
        IsDiabetesTypeInsulin: false,
        Insulin: '',
        Dosage: '',
        IsInsulineApplicationInsulin: false,
        IsInsulineApplicationPen: false,
        IsInsulineApplicationPump: false,
        IsInsulineAppliesMyself: false,
        IsInsulineAppliesFamily: false,
        IsInsulineAppliesOther: false,
    }

    const validationSchema = anamnesisStep2Schema

    const formMethods = useForm<CareAnamnesisStep2>({
        criteriaMode: 'all',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues,
    })

    const { data, isLoading, error } = useQuery<CareAnamnesisStepQuery, Error>(
        [PATIENT_CARE_ANAMNESIS_QUERY, anamnesisId, currentStep],
        () => getPatientCareAnamnesis(anamnesisId, currentStep, token),
        {
            enabled: !!anamnesisId && !!currentStep && !!token,
        },
    )

    useEffect(() => {
        if (data && !isLoading) {
            if (data?.content) {
                const deserializedData: CareAnamnesisStep2 = JSON.parse(
                    data.content,
                )
                formMethods.reset(deserializedData)

                const invalidDateInputs: CareAnamnesisStep2Keys[] = []

                const nasogastricProbeDate = convertToDate(
                    deserializedData?.FoodIntakeNasogastricProbe,
                )
                if (nasogastricProbeDate === null) {
                    invalidDateInputs.push('FoodIntakeNasogastricProbe')
                } else {
                    formMethods.setValue(
                        'FoodIntakeNasogastricProbe',
                        nasogastricProbeDate,
                    )
                }

                const gastrostomyDate = convertToDate(
                    deserializedData?.FoodIntakeGastrostomy,
                )
                if (gastrostomyDate === null) {
                    invalidDateInputs.push('FoodIntakeGastrostomy')
                } else {
                    formMethods.setValue(
                        'FoodIntakeGastrostomy',
                        gastrostomyDate,
                    )
                }

                const pegDate = convertToDate(deserializedData?.FoodIntakePEG)
                if (pegDate === null) {
                    invalidDateInputs.push('FoodIntakePEG')
                } else {
                    formMethods.setValue('FoodIntakePEG', pegDate)
                }

                setInvalidDateInputs(invalidDateInputs)
            }

            setIsFormLoading(false)
        }
    }, [data, isLoading, formMethods])

    const submitForm = async (
        formData: CareAnamnesisStep2,
        nextStep: boolean,
    ) => {
        try {
            const formattedData = {
                ...formData,
                FoodIntakeNasogastricProbe: formatDateIfValid(
                    formData.FoodIntakeNasogastricProbe,
                ),
                FoodIntakeGastrostomy: formatDateIfValid(
                    formData.FoodIntakeGastrostomy,
                ),
                FoodIntakePEG: formatDateIfValid(formData.FoodIntakePEG),
            }

            if (canEditCareAnamnesis) {
                await mutatePatientCareAnamnesisStepPut({
                    anamnesisID: anamnesisId,
                    data: {
                        currentStep: currentStep,
                        content: JSON.stringify(formattedData),
                    },
                })
            }

            if (nextStep) setCurrentStep((prev) => prev + 1)
            else {
                setCurrentStep((prev) => prev - 1)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        formMethods,
        isLoading: isFormLoading || isLoading,
        error: error ? !!error : false,
        invalidDateInputs,
        submitForm,
        ...props,
    }
}
