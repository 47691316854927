import { FC, useCallback, useEffect } from 'react'
import { wrap } from '../../../utils'
import { useAddPatientAnamnesisFormStep10 } from '../hooks/useAddPatientAnamnesisFormStep10'
import {
    AddPatientsAnamnesisFormStep10Hook,
    IAddPatientAnamnesisFormStep10,
} from '../types'
import { Stack, InputLabel, CircularProgress, Box } from '@mui/material'
import { AnamnesisFormFooter } from './AnamnesisFormFooter'
import CheckboxStyled from '../../../components/CheckboxStyled'
import InputStyled from '../../../components/InputStyled'

const AddPatientAnamnesisFormStep10View = (
    props: IAddPatientAnamnesisFormStep10,
) => {
    const {
        submitForm,
        formMethods,
        setCurrentStep,
        currentStep,
        isLoading,
        canEditCareAnamnesis,
        setSaveCurrentStepOnClose,
    } = props

    const {
        control,
        handleSubmit,
        watch,
        getValues,
        formState: { errors, isSubmitting },
    } = formMethods

    const isLifePrinciplesSport = watch('IsLifePrinciplesSport')

    const handleSubmitWrapper = useCallback(
        async (isNext?: boolean) => {
            const formData = getValues()
            await submitForm(formData, isNext ? isNext : false)
        },
        [getValues, submitForm],
    )
    useEffect(() => {
        setSaveCurrentStepOnClose(() => handleSubmitWrapper)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSaveCurrentStepOnClose])

    if (isLoading) {
        return (
            <Box className='!mt-12 flex justify-center'>
                <CircularProgress />
            </Box>
        )
    }

    return (
        <form
            onSubmit={() => handleSubmitWrapper(true)}
            className='grid gap-3'
        >
            <Stack spacing={1}>
                <InputLabel className='text-sm font-semibold'>
                    Trávení volného času, zájem na společenských aktivitách
                </InputLabel>
                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsLifePrinciplesReading'
                    label='četba'
                />
                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsLifePrinciplesTV'
                    label='televize'
                />
                <CheckboxStyled
                    readOnly={!canEditCareAnamnesis}
                    control={control}
                    disabled={isSubmitting}
                    name='IsLifePrinciplesRadio'
                    label='rádio'
                />
                <CheckboxStyled
                    readOnly={!canEditCareAnamnesis}
                    control={control}
                    disabled={isSubmitting}
                    name='IsLifePrinciplesSleep'
                    label='spánek'
                />
                <CheckboxStyled
                    readOnly={!canEditCareAnamnesis}
                    control={control}
                    disabled={isSubmitting}
                    name='IsLifePrinciplesRest'
                    label='odpočinek'
                />
                <CheckboxStyled
                    readOnly={!canEditCareAnamnesis}
                    control={control}
                    disabled={isSubmitting}
                    name='IsLifePrinciplesCompanyOfPeople'
                    label='společnost lidí'
                />
                <CheckboxStyled
                    readOnly={!canEditCareAnamnesis}
                    control={control}
                    disabled={isSubmitting}
                    name='IsLifePrinciplesSport'
                    label='sport'
                />
                {isLifePrinciplesSport && (
                    <InputStyled
                        className='mt-2'
                        readOnly={!canEditCareAnamnesis}
                        control={control}
                        disabled={isSubmitting}
                        name='LifePrinciplesSport'
                        errors={errors}
                        label='Jaký:'
                        placeholder='jaký'
                    />
                )}
            </Stack>

            <Stack spacing={2}>
                <AnamnesisFormFooter
                    currentStep={currentStep}
                    canEditCareAnamnesis={canEditCareAnamnesis}
                    disabled={isLoading || isSubmitting}
                    handleSubmitWrapper={handleSubmitWrapper}
                    setCurrentStep={setCurrentStep}
                />
            </Stack>
        </form>
    )
}

export const AddPatientAnamnesisFormStep10: FC<AddPatientsAnamnesisFormStep10Hook> =
    wrap(AddPatientAnamnesisFormStep10View, useAddPatientAnamnesisFormStep10)
