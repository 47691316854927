import type { MouseEvent } from 'react'
import { Box, Modal, Paper, Stack } from '@mui/material'
import type { OpenStateProps } from '../../../../types'
import { MedicationData } from '../../types'
import { MedicationHeader } from './MedicationHeader'
import { MedicationInput } from './MedicationInput'

export const MedicationModal = (props: OpenStateProps & MedicationData) => {
    const {
        isOpen,
        handleClose,
        dosage,
        implementationDrug,
        drugNotice,
        note,
    } = props

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
        >
            <Box
                sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    minHeight: '20vh',
                    maxHeight: '80vh',
                    overflowY: 'auto',
                    borderRadius: '6px',
                    maxWidth: '768px',
                }}
                onClick={(e: MouseEvent) => e.stopPropagation()}
            >
                <Paper
                    sx={{
                        background: '#f8f8f8',
                    }}
                >
                    <MedicationHeader {...props} />
                    <Stack
                        padding='1rem'
                        gap='1rem'
                    >
                        <MedicationInput
                            readOnly
                            labelText='Dávkování'
                            inputData={dosage}
                        />
                        <MedicationInput
                            readOnly
                            labelText='Cesta podání'
                            inputData={implementationDrug}
                        />
                        <MedicationInput
                            readOnly
                            labelText='Popis léku'
                            inputData={drugNotice}
                        />
                        <MedicationInput
                            readOnly
                            multiline
                            size='small'
                            labelText='Poznámka'
                            inputData={note || 'neuvedeno'}
                        />
                    </Stack>
                </Paper>
            </Box>
        </Modal>
    )
}
