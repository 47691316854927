import { Grid, OutlinedInput, SpeedDialAction, Typography } from '@mui/material'
import type { VisitTabProps } from '../../types'
import { FinalInfo } from './FinalInfo'
import { TreatmentSteps } from './TreatmentSteps'
import { VisitInfo } from './VisitInfo'
import { uid } from 'react-uid'
import { HelpDial } from '../../../../components'
import { visitDetailDetailActions } from '../../../../components/HelpDial/HelpDialActions'
import { HelpSpeedDialActions } from '../../../../components/HelpDial/types'

export const Visit = (props: VisitTabProps) => {
    const {
        visitData,
        visitDetailsLoading,
        visitForm,
        isTimeClicked,
        setIsTimeClicked,
        showTabs,
    } = props

    return (
        <Grid
            container
            spacing={5}
            className='pb-6'
        >
            <VisitInfo
                plannedVisitLength={visitData?.plannedVisitLength}
                plannedVisitTime={visitData?.plannedVisitTime}
                realVisitTime={visitData?.realVisitTime}
                isBloodPressureHighRequired={
                    visitData?.isBloodPressureHighRequired
                }
                isBloodPressureLowRequired={
                    visitData?.isBloodPressureLowRequired
                }
                isBreathingFreqRequired={visitData?.isBreathingFreqRequired}
                isGlycemiaRequired={visitData?.isGlycemiaRequired}
                isPainRequired={visitData?.isPainRequired}
                isPulseRequired={visitData?.isPulseRequired}
                isTemperatureRequired={visitData?.isTemperatureRequired}
                isSaturationRequired={visitData?.isSaturationRequired}
                isLoading={visitDetailsLoading}
                visitForm={visitForm}
                state={visitData?.state}
                lastNurseVisit={visitData?.lastNurseVisit}
                canSave={visitData?.canSave}
                canEdit={visitData?.canEdit}
                isTimeClicked={isTimeClicked}
                setIsTimeClicked={setIsTimeClicked}
                isFixVisitTimeEnabled={visitData?.isFixVisitTimeEnabled}
                fixVisitTime={visitData?.fixVisitTime}
                showTabs={showTabs}
            />
            <TreatmentSteps
                visitForm={visitForm}
                isLoading={visitDetailsLoading}
                carePlans={visitData?.carePlans}
                lastVisit={visitData?.lastNurseVisit}
                canEdit={visitData?.canEdit!}
                canSave={visitData?.canSave!}
                updateVisitOnClose={visitForm.handleUpdateVisit}
            />

            <Grid
                item
                xs={12}
            >
                <Typography className='pb-6 font-medium'>Alergie</Typography>
                <OutlinedInput
                    fullWidth
                    readOnly
                    size='small'
                    value={visitData?.patient?.alergy || 'Žádné'}
                    multiline
                    className='min-h-[37px] rounded bg-orange-100 px-[14px] py-1 text-sm font-medium'
                />
            </Grid>

            <FinalInfo
                visitData={visitData}
                visitForm={visitForm}
            />
            <HelpDial>
                {({ handleActionClick }) => {
                    return visitDetailDetailActions.map(
                        ({ name, icon, modalName }: HelpSpeedDialActions) => (
                            <SpeedDialAction
                                key={uid(modalName)}
                                icon={icon}
                                tooltipTitle={name}
                                tooltipPlacement='right'
                                tooltipOpen
                                sx={{
                                    '& .MuiSpeedDialAction-staticTooltipLabel':
                                        {
                                            width: 'max-content',
                                        },
                                }}
                                onClick={handleActionClick(modalName)}
                            />
                        ),
                    )
                }}
            </HelpDial>
        </Grid>
    )
}
