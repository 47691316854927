import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useOpenState } from '../../../hooks'
import type {
    BulletinFilterHookProps,
    BulletinFilterItems,
    BulletinFilterType,
    FilterItemValue,
} from '../types'
import { AuthContext, BulletinFiltersContext } from '../../../providers'

import {
    getBulletinsFilters,
    singleSelectBulletinGroup,
} from '../constants/filters'
import { useFnol } from '../../../providers/AuthProvider/hooks'
import { useFilterReset } from './useBulletinFilterReset'
import { useFiltersActivate } from '../../../utils/useFiltersActivate'
import { BulletinFilterUser, BulletinTag } from '../../../types/components'
import { useFilterUsersQuery } from '../../../api/notifications/hooks'
import { BULLETIN_FILTERS_KEY } from '../../../constants'

export const useBulletinFilters = (props: BulletinFilterHookProps) => {
    const filterModalState = useOpenState()

    const { isComponentLoading, setIsComponentLoading, handleSelectAll } = props

    const { isFnol } = useFnol()
    const { user } = useContext(AuthContext)

    const staticFilters = useMemo(() => getBulletinsFilters(isFnol), [isFnol])

    const [bulletinFilters, setBulletinFilters] =
        useState<BulletinFilterItems[]>(staticFilters)

    const { data: filterUsers, isLoading: filterUsersLoading } =
        useFilterUsersQuery(bulletinFilters)

    const { setBulletinQueryFilters } = useContext(BulletinFiltersContext)

    const { resetFilterGroup, resetFilterAllActiveGroup } = useFilterReset({
        filterNameType: 'bulletins',
        filters: bulletinFilters,
        setFilters: setBulletinFilters,
        singleSelectGroup: singleSelectBulletinGroup,
    })

    useEffect(() => {
        if (filterUsers) {
            const formattedUserItems: FilterItemValue[] = filterUsers
                .map((user) => ({
                    active: false,
                    name: user.fullName || '',
                    value: String(user.id),
                    icon: user.icon,
                }))
                .sort((a, b) => a.name.localeCompare(b.name))

            setBulletinFilters((prev: any[]) => {
                const newFilters = [
                    ...prev.filter(({ type }) => type !== 'users'),
                    {
                        title: 'Tým',
                        type: 'users',
                        items: formattedUserItems,
                    },
                ]

                return newFilters
            })
        }
    }, [filterUsers])

    const {
        handleFilterActivate,
        extractedActiveFiltersWithType: activeFilters,
        getActiveGroup,
    } = useFiltersActivate<BulletinFilterType, string | boolean>({
        filters: bulletinFilters,
        setFilters: setBulletinFilters,
        singleSelectGroup: singleSelectBulletinGroup,
    })

    const handleActiveQueries = useCallback(
        (
            data?: {
                active: boolean
                value: string | boolean
                type: string
                name: string
            }[],
            filterUsersArg?: BulletinFilterUser[],
        ) => {
            if (data && filterUsersArg) {
                const hideAdmin = data.find(
                    ({ type }) => type === 'admin',
                )?.value
                const readability = data.find(
                    ({ type }) => type === 'readability',
                )?.value
                const archived = data.find(
                    ({ type }) => type === 'archived',
                )?.value
                const activeTags = data
                    .filter(({ type, active }) => type === 'tag' && active)
                    .map(({ value }) => value)
                const activeUsers = filterUsersArg
                    .filter((user) =>
                        data.some(({ value }) => value === String(user?.id)),
                    )
                    .map(({ id }) => Number(id))

                setBulletinQueryFilters({
                    showUnread:
                        typeof readability === 'boolean'
                            ? readability
                            : undefined,
                    hideAdmin:
                        typeof hideAdmin === 'boolean' ? hideAdmin : undefined,
                    hideArchived:
                        typeof archived === 'boolean' ? archived : undefined,
                    bulletinTag: activeTags as BulletinTag[],
                    userId: activeUsers as number[],
                })

                const updatedFilters = staticFilters.map((filter) => {
                    const relevantData = data.filter(
                        ({ type }) => type === filter.type,
                    )

                    if (singleSelectBulletinGroup.includes(filter.type)) {
                        // Single-select group: Activate only one item based on `value` from `data`
                        const activeValue = relevantData[0]?.value
                        return {
                            ...filter,
                            items: filter.items.map((item) => ({
                                ...item,
                                active: item.value === activeValue,
                            })),
                        }
                    } else if (filter.type === 'tag') {
                        // Multi-select group: Activate all matching `tag` values
                        const activeTags = relevantData
                            .filter(({ active }) => active)
                            .map(({ value }) => value)
                        return {
                            ...filter,
                            items: filter.items.map((item) => ({
                                ...item,
                                active: activeTags.includes(item.value),
                            })),
                        }
                    }

                    return filter
                })

                const commonUsers = filterUsersArg.map((user) => {
                    return {
                        active: data.some(
                            ({ value }) => value === String(user?.id),
                        ),
                        name: user?.fullName || '',
                        value: String(user?.id),
                        icon: user?.icon,
                    }
                })

                updatedFilters.push({
                    title: 'Tým',
                    type: 'users',
                    items: commonUsers,
                })

                setBulletinFilters(updatedFilters)
                setUpdateFilter(true)
                setIsComponentLoading(false)
            } else {
                const hideAdmin = getActiveGroup('admin')[0]
                const readability = getActiveGroup('readability')[0]
                const archived = getActiveGroup('archived')[0]
                const activeTags = getActiveGroup('tag')
                const activeUsers = getActiveGroup('users')

                setBulletinQueryFilters({
                    showUnread:
                        typeof readability?.value === 'boolean'
                            ? readability?.value
                            : undefined,
                    hideAdmin:
                        typeof hideAdmin?.value === 'boolean'
                            ? hideAdmin?.value
                            : undefined,
                    hideArchived:
                        typeof archived?.value === 'boolean'
                            ? archived?.value
                            : undefined,
                    bulletinTag: activeTags.length
                        ? (activeTags.map(
                              ({ value }) => value,
                          ) as BulletinTag[])
                        : [],
                    userId: activeUsers.map(({ value }) =>
                        Number(value),
                    ) as number[],
                })
                setIsComponentLoading(false)
            }
        },
        [
            getActiveGroup,
            setBulletinQueryFilters,
            staticFilters,
            setIsComponentLoading,
        ],
    )

    useEffect(() => {
        if (!filterUsersLoading && filterUsers && isComponentLoading) {
            const data = localStorage.getItem(BULLETIN_FILTERS_KEY + user?.id)

            const localStorageData = data ? JSON.parse(data) : null

            handleActiveQueries(localStorageData, filterUsers)
        }
    }, [
        filterUsersLoading,
        filterUsers,
        isComponentLoading,
        handleActiveQueries,
        user?.id,
    ])

    const [deleteTrigger, setDeleteTrigger] = useState(false)
    const [actualFilter, setActualFilter] = useState(activeFilters)

    const [updateFilter, setUpdateFilter] = useState<boolean>(false)

    useEffect(() => {
        if (deleteTrigger) {
            handleActiveQueries()
            setActualFilter(activeFilters)
            localStorage.setItem(
                BULLETIN_FILTERS_KEY + user?.id,
                JSON.stringify(activeFilters),
            )
            handleSelectAll(true)
            setDeleteTrigger(false)
        }
    }, [
        activeFilters,
        handleActiveQueries,
        setActualFilter,
        deleteTrigger,
        user?.id,
        handleSelectAll,
    ])

    useEffect(() => {
        if (updateFilter) {
            setActualFilter(activeFilters)
            localStorage.setItem(
                BULLETIN_FILTERS_KEY + user?.id,
                JSON.stringify(activeFilters),
            )
            handleSelectAll(true)
            setUpdateFilter(false)
        }
    }, [
        activeFilters,
        actualFilter,
        handleActiveQueries,
        updateFilter,
        user?.id,
        handleSelectAll,
    ])

    const getSameFilterGroup = useCallback(
        (groupType: BulletinFilterType) => {
            return bulletinFilters.find(({ type }) => type === groupType)
        },
        [bulletinFilters],
    )

    const handleUseFilters = (handleClose: () => void) => {
        handleActiveQueries()
        setUpdateFilter(true)
        handleSelectAll(true)
        handleClose()
    }

    return {
        bulletinFilters,
        setDeleteTrigger,
        actualFilter,
        activeFilters,
        handleFilterActivate,
        resetFilterGroup,
        filterModalState,
        handleUseFilters,
        setUpdateFilter,
        resetFilterAllActiveGroup,
        handleActiveQueries,
        getSameFilterGroup,
        ...props,
    }
}
