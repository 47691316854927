import { useMonthHandle } from './../../../hooks/useMonthHandle'
import { useContext } from 'react'
import { useVisitQuery } from '../../../api/visit/hooks'
import { AuthContext } from '../../../providers'

export const useNurseHistory = () => {
    const { token } = useContext(AuthContext)

    const {
        activeMonthDate,
        firstDayOfMonth,
        lastDayOfMonth,
        handleAddMonth,
        handleDateChange,
        handleSubtractMonth,
    } = useMonthHandle()

    const visitList = useVisitQuery(
        {
            dateFrom: firstDayOfMonth(activeMonthDate || new Date()),
            dateTo: lastDayOfMonth(activeMonthDate || new Date()),
        },
        token,
    )

    return {
        activeMonthDate,
        handleDateChange,
        handleSubtractMonth,
        handleAddMonth,
        visitList,
    }
}
