import { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocation, useOpenState } from '../../../hooks'
import { AuthContext } from '../../../providers'
import { useAuth } from '../../../providers/AuthProvider/hooks'
import { NavigationHookProps, NavigationProps } from '../../../types/components'
import { useBulletinCountQuery, useUserPhotoQuery } from '../../../api'
import { PAGE_LOGIN_REDIRECT } from '../../../pages/LoginPage/hooks/useLogin'

export const useNavigation = (props: NavigationHookProps): NavigationProps => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const openSearch = useOpenState()
    const { user } = useContext(AuthContext)
    const { logOut } = useAuth()
    const navigate = useNavigate()
    const location = useLocation()

    const userPhotoQuery = useUserPhotoQuery()

    const trimmedUser = {
        firstname: user?.firstname?.trim(),
        surname: user?.surname?.trim(),
    }

    const notifications = useBulletinCountQuery()

    const handleLogOut = () => {
        sessionStorage.removeItem(PAGE_LOGIN_REDIRECT)
        logOut()
        navigate('/')
    }

    const open = Boolean(anchorEl)

    const navigationOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const navigationClose = () => {
        setAnchorEl(null)
    }

    useEffect(() => {
        if (location.latLng === '') {
            location.handleTrackLocation()
        }
    }, [location])

    return {
        ...props,
        userPhotoQuery,
        navigate,
        anchorEl,
        open,
        openSearch,
        user,
        trimmedUser,
        handleLogOut,
        navigationOpen,
        navigationClose,
        location,
        notifications,
    }
}
