import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    AddPatientsAnamnesisFormStep3Hook,
    CareAnamnesisStep3,
    CareAnamnesisStep3Keys,
    CareAnamnesisStepQuery,
    IAddPatientAnamnesisFormStep3,
} from '../types'
import { useQuery } from '@tanstack/react-query'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../providers'
import {
    getPatientCareAnamnesis,
    PATIENT_CARE_ANAMNESIS_QUERY,
} from './services'
import { useSavePatientCareAnamnesisMutation } from './hooks'
import { convertToDate, formatDateIfValid } from '../../../utils/functionsUtils'

export const useAddPatientAnamnesisFormStep3 = (
    props: AddPatientsAnamnesisFormStep3Hook,
): IAddPatientAnamnesisFormStep3 => {
    const { setCurrentStep, anamnesisId, currentStep, canEditCareAnamnesis } =
        props
    const { token } = useContext(AuthContext)
    const [isFormLoading, setIsFormLoading] = useState(true)
    const [invalidDateInputs, setInvalidDateInputs] = useState<
        CareAnamnesisStep3Keys[]
    >([])

    const { mutatePatientCareAnamnesisStepPut } =
        useSavePatientCareAnamnesisMutation()

    const defaultValues: CareAnamnesisStep3 = {
        Excretion: undefined,
        Frequency: undefined,
        FrequencyPerDay: '',
        Consistency: undefined,
        ConsistencyOther: '',
        IsStomie: false,
        Stomie: '',
        UrinaryEmptying: undefined,
        IsIncontinenceAids: false,
        IncontinenceAids: '',
        IsUrinaryCatheter: false,
        UrinaryCatheterDate: '',
        UrinaryCatheterSize: '',
        IsEpicystostomy: false,
        Epicystostomy: '',
        IsNefrostomy: false,
        Nefrostomy: '',
        NefrostomyType: undefined,
        IsIslands: false,
        Islands: '',
        IsTakingDiuretics: false,
        TakingDiuretics: '',
    }

    const validationSchema = yup.object().shape({})

    const formMethods = useForm<CareAnamnesisStep3>({
        criteriaMode: 'all',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues,
    })

    const { data, isLoading, error } = useQuery<CareAnamnesisStepQuery, Error>(
        [PATIENT_CARE_ANAMNESIS_QUERY, anamnesisId, currentStep],
        () => getPatientCareAnamnesis(anamnesisId, currentStep, token),
        {
            enabled: !!anamnesisId && !!currentStep && !!token,
        },
    )

    useEffect(() => {
        if (data && !isLoading) {
            if (data?.content) {
                const deserializedData = JSON.parse(data.content)
                formMethods.reset(deserializedData)

                const invalidDateInputs: CareAnamnesisStep3Keys[] = []

                const urinaryCatheterDate = convertToDate(
                    deserializedData?.UrinaryCatheterDate,
                )

                if (urinaryCatheterDate === null) {
                    invalidDateInputs.push('UrinaryCatheterDate')
                } else {
                    formMethods.setValue(
                        'UrinaryCatheterDate',
                        urinaryCatheterDate,
                    )
                }

                const epicystostomyDate = convertToDate(
                    deserializedData?.Epicystostomy,
                )

                if (epicystostomyDate === null) {
                    invalidDateInputs.push('Epicystostomy')
                } else {
                    formMethods.setValue('Epicystostomy', epicystostomyDate)
                }

                const nefrostomyDate = convertToDate(
                    deserializedData?.Nefrostomy,
                )

                if (nefrostomyDate === null) {
                    invalidDateInputs.push('Nefrostomy')
                } else {
                    formMethods.setValue('Nefrostomy', nefrostomyDate)
                }

                setInvalidDateInputs(invalidDateInputs)
            }

            setIsFormLoading(false)
        }
    }, [data, isLoading, formMethods])

    const submitForm = async (
        formData: CareAnamnesisStep3,
        nextStep: boolean,
    ) => {
        try {
            const formattedData = {
                ...formData,
                UrinaryCatheterDate: formatDateIfValid(
                    formData.UrinaryCatheterDate,
                ),
                Epicystostomy: formatDateIfValid(formData.Epicystostomy),
                Nefrostomy: formatDateIfValid(formData.Nefrostomy),
            }

            if (canEditCareAnamnesis) {
                await mutatePatientCareAnamnesisStepPut({
                    anamnesisID: anamnesisId,
                    data: {
                        currentStep: currentStep,
                        content: JSON.stringify(formattedData),
                    },
                })
            }

            if (nextStep) setCurrentStep((prev) => prev + 1)
            else {
                setCurrentStep((prev) => prev - 1)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        formMethods,
        isLoading: isFormLoading || isLoading,
        error: error ? !!error : false,
        invalidDateInputs,
        submitForm,
        ...props,
    }
}
