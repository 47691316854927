import { useState } from 'react'
import type { LongTextProps, LongTextFormatted } from '../types'

export const useLongText = ({
    text,
    maxLength = 120,
    customTextLength,
}: LongTextProps): LongTextFormatted => {
    const [showFullText, setShowFullText] = useState(false)

    const toggleText = () => {
        setShowFullText((prevState) => !prevState)
    }

    const isTextLonger = !!text && text.length > maxLength

    const displayedText = !!text
        ? showFullText || !isTextLonger
            ? text
            : `${text.slice(0, customTextLength || maxLength)}...`
        : ''

    return { isTextLonger, showFullText, displayedText, toggleText }
}
