import { Stack, Button } from '@mui/material'
import { useOpenState } from '../../../hooks'
import { VisitActionsProps, VisitMutation } from '../types'
import { StornoModal } from './StornoModal'

export const VisitActions = (props: VisitActionsProps) => {
    const {
        handleUpdateVisit,
        formMethods,
        handleStornoVisit,
        handleSendVisit,
        visitData,
    } = props
    const stornoModalState = useOpenState()

    const handleClick = (mutateFunction: VisitMutation) => {
        mutateFunction()
    }

    return (
        <Stack
            direction='row'
            justifyContent='space-between'
            className='pb-4'
        >
            <Button
                disabled={!(visitData?.canSave && visitData?.canNotExecuted)}
                size='small'
                variant='outlined'
                className='bg-white-reduced'
                onClick={stornoModalState.handleOpen}
                sx={{
                    textTransform: 'none',
                    fontSize: '0.875rem',
                }}
            >
                neprovedeno
            </Button>
            <StornoModal
                formMethods={formMethods}
                handleStornoVisit={handleStornoVisit}
                modalState={stornoModalState}
            />
            <Stack
                direction='row'
                spacing={1}
            >
                <Button
                    disabled={!visitData?.canSave}
                    size='small'
                    variant='outlined'
                    className='bg-white-reduced'
                    onClick={() => handleClick(handleUpdateVisit)}
                    sx={{
                        textTransform: 'none',
                        fontSize: '0.875rem',
                    }}
                >
                    uložit
                </Button>
                <Button
                    disabled={!(visitData?.canSave && visitData?.canSent)}
                    size='small'
                    variant='contained'
                    className='text-white-reduced shadow-none'
                    sx={{
                        textTransform: 'none',
                        fontSize: '0.875rem',
                    }}
                    onClick={() => handleClick(handleSendVisit)}
                >
                    uložit a odeslat
                </Button>
            </Stack>
        </Stack>
    )
}
