import type { NurseHistoryPageProps, UseNurseHistoryHookProps } from '../types'
import { useNurseHistory } from './useNurseHistory'
import type { MouseEvent } from 'react'
import { usePageTitle } from '../../../hooks/usePageTitle'
import { useVisitMonthCollection } from '../../../hooks'

export const usePage = (
    props: UseNurseHistoryHookProps,
): NurseHistoryPageProps => {
    const nurseVisitHistory = useNurseHistory()

    const visitCollection = useVisitMonthCollection({
        collectionData: nurseVisitHistory.visitList?.data?.data,
        isVisitLoading: nurseVisitHistory.visitList.isLoading,
    })

    usePageTitle('Historie návštěv')

    const handleCardWrapperClick = (e: MouseEvent) => {
        window.scroll({ top: 0, behavior: 'smooth' })
    }

    return {
        handleCardWrapperClick,
        ...visitCollection,
        ...nurseVisitHistory,
    }
}
