import { FC, useCallback, useEffect, useState } from 'react'
import { wrap } from '../../../utils'
import { useAddPatientAnamnesisFormStep11 } from '../hooks/useAddPatientAnamnesisFormStep11'
import {
    AddPatientsAnamnesisFormStep11Hook,
    IAddPatientAnamnesisFormStep11,
} from '../types'
import {
    Box,
    CircularProgress,
    InputLabel,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material'
import { AnamnesisFormFooter } from './AnamnesisFormFooter'
import YesNoRadioGroup from '../../../components/YesNoRadioGroup'
import CheckboxStyled from '../../../components/CheckboxStyled'
import InputStyled from '../../../components/InputStyled'
import { useFieldArray } from 'react-hook-form'
import careAnamnesisBody from '../../../assets/careAnamnesisBody.png'
import { Button } from '@mui/material'
import { useOpenState } from '../../../hooks'
import AnamnesisDefectsModal from './AnamnesisDefectsModal'
import DeleteIcon from '@mui/icons-material/Delete'
import { v4 as uuidv4 } from 'uuid'
import DateInputStyled from '../../../components/DateInput'

const AddPatientAnamnesisFormStep11View = (
    props: IAddPatientAnamnesisFormStep11,
) => {
    const {
        submitForm,
        formMethods,
        setCurrentStep,
        currentStep,
        invalidDateInputs,
        isLoading,
        canEditCareAnamnesis,
        setSaveCurrentStepOnClose,
    } = props

    const {
        control,
        handleSubmit,
        watch,
        getValues,
        setValue,
        formState: { errors, isSubmitting },
    } = formMethods

    const isVascularInputs = watch('IsVascularInputs')
    const isVascularInputsOther = watch('IsVascularInputsOther')

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'Points',
    })

    const { isOpen, handleClose, handleOpen } = useOpenState()

    const [imageDimensions, setImageDimensions] = useState({
        width: 0,
        height: 0,
    })

    const handleImageLoad = useCallback(
        (e: React.SyntheticEvent<HTMLImageElement>) => {
            setImageDimensions({
                width: e.currentTarget.width,
                height: e.currentTarget.height,
            })
        },
        [setImageDimensions],
    )

    const handleSavePoint = useCallback(
        (point: {
            Name: string
            Description: string
            Coordinates: {
                X: number
                Y: number
                ImageWidth: number
                ImageHeight: number
            }
        }) => {
            append({
                Id: uuidv4(),
                Coordinates: {
                    X: point.Coordinates.X,
                    Y: point.Coordinates.Y,
                    ImageWidth: point.Coordinates.ImageWidth,
                    ImageHeight: point.Coordinates.ImageHeight,
                },
                Order: fields.length + 1,
                Name: point.Name,
                Description: point.Description,
            })
        },
        [append, fields.length],
    )

    const handleDeletePoint = useCallback(
        (orderToDelete: number) => {
            const index = fields.findIndex(
                (field) => field.Order === orderToDelete,
            )
            if (index !== -1) {
                remove(index)

                const updatedFields = fields
                    .filter((_, idx) => idx !== index)
                    .map((field, idx) => ({
                        ...field,
                        order: idx + 1,
                    }))

                setValue('Points', updatedFields)
            }
        },
        [remove, setValue, fields],
    )

    const handleSubmitWrapper = useCallback(
        async (isNext?: boolean) => {
            const formData = getValues()
            await submitForm(formData, isNext ? isNext : false)
        },
        [getValues, submitForm],
    )
    useEffect(() => {
        setSaveCurrentStepOnClose(() => handleSubmitWrapper)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSaveCurrentStepOnClose])
    if (isLoading) {
        return (
            <Box className='!mt-12 flex justify-center'>
                <CircularProgress />
            </Box>
        )
    }
    return (
        <form
            onSubmit={() => handleSubmitWrapper(true)}
            className='grid gap-3'
        >
            <Typography className='!mt-2 flex text-sm font-semibold'>
                Kožní defekty
            </Typography>
            <Stack className='flex flex-col flex-wrap gap-4'>
                <Stack className='relative w-fit'>
                    <img
                        src={careAnamnesisBody}
                        alt='body'
                        className='w-full max-w-[300px]'
                        onLoad={handleImageLoad}
                    />
                    {fields.map((field) => {
                        const scaleX =
                            imageDimensions.width /
                            field?.Coordinates?.ImageWidth
                        const scaleY =
                            imageDimensions.height /
                            field?.Coordinates?.ImageHeight

                        const adjustedX = field?.Coordinates?.X * scaleX
                        const adjustedY = field?.Coordinates?.Y * scaleY

                        return (
                            <Box
                                key={field.id}
                                className='absolute h-[10px] w-[10px] -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-[#f8833b]'
                                style={{
                                    left: adjustedX,
                                    top: adjustedY,
                                }}
                            >
                                <Typography className='ml-3 text-sm font-semibold'>
                                    {field.Order}
                                </Typography>
                            </Box>
                        )
                    })}
                </Stack>
                <Stack className='flex flex-col flex-wrap gap-4'>
                    <TableContainer
                        component={Paper}
                        sx={{ flexGrow: 1, boxShadow: 'none' }}
                    >
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                            width: '50%',
                                        }}
                                    >
                                        Defekt
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                            width: '50%',
                                        }}
                                    >
                                        Popis a rozměr defektu
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                            width: 'auto',
                                        }}
                                    ></TableCell>
                                </TableRow>
                                {fields.map((field, index) => (
                                    <TableRow key={field.id}>
                                        <TableCell
                                            sx={{
                                                fontSize: '14px',
                                                width: '50%',
                                            }}
                                        >
                                            {field.Name}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                fontSize: '14px',
                                                width: '50%',
                                            }}
                                        >
                                            {field.Description}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                fontSize: '14px',
                                                width: 'auto',
                                            }}
                                        >
                                            <Button
                                                variant='contained'
                                                disabled={!canEditCareAnamnesis}
                                                color='error'
                                                onClick={() => {
                                                    if (field.Order) {
                                                        handleDeletePoint(
                                                            field.Order,
                                                        )
                                                    }
                                                }}
                                                type='button'
                                                size='small'
                                                className='ml-auto text-sm capitalize shadow-sm'
                                            >
                                                <DeleteIcon className='mr-[2px] text-base' />
                                                Smazat
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Button
                        variant='contained'
                        size='small'
                        className='mr-auto w-fit capitalize shadow-sm'
                        type='button'
                        disabled={
                            isLoading || isSubmitting || !canEditCareAnamnesis
                        }
                        onClick={() => handleOpen()}
                    >
                        +Přidat
                    </Button>
                </Stack>
            </Stack>
            <YesNoRadioGroup
                control={control}
                name='IsVascularInputs'
                label='Cévní vstupy'
                readOnly={!canEditCareAnamnesis}
                disabled={isSubmitting}
            />

            {isVascularInputs && (
                <>
                    <CheckboxStyled
                        control={control}
                        readOnly={!canEditCareAnamnesis}
                        disabled={isSubmitting}
                        name='IsVascularInputsPeripheral'
                        label='periferní'
                    />

                    <CheckboxStyled
                        control={control}
                        readOnly={!canEditCareAnamnesis}
                        disabled={isSubmitting}
                        name='IsVascularInputsCentral'
                        label='centrální'
                    />

                    <CheckboxStyled
                        control={control}
                        readOnly={!canEditCareAnamnesis}
                        disabled={isSubmitting}
                        name='IsVascularInputsOther'
                        label='jiné'
                    />

                    {isVascularInputsOther && (
                        <InputStyled
                            control={control}
                            readOnly={!canEditCareAnamnesis}
                            disabled={isSubmitting}
                            name='VascularInputsOther'
                            errors={errors}
                            label='Jaké:'
                            placeholder='jaké'
                        />
                    )}
                    <InputStyled
                        control={control}
                        readOnly={!canEditCareAnamnesis}
                        disabled={isSubmitting}
                        name='LocatedAt'
                        errors={errors}
                        label='Lokalizace:'
                        placeholder='lokalizaci'
                    />

                    {invalidDateInputs.includes('DayOfIntroduction') ? (
                        <InputStyled
                            control={control}
                            readOnly={!canEditCareAnamnesis}
                            disabled={isSubmitting}
                            name='DayOfIntroduction'
                            errors={errors}
                            label='Den zavedení:'
                            placeholder='den zavedení'
                        />
                    ) : (
                        <DateInputStyled
                            control={control}
                            readOnly={!canEditCareAnamnesis}
                            disabled={isSubmitting}
                            name='DayOfIntroduction'
                            errors={errors}
                            label='Den zavedení:'
                        />
                    )}

                    {invalidDateInputs.includes('LastChange') ? (
                        <InputStyled
                            control={control}
                            readOnly={!canEditCareAnamnesis}
                            disabled={isSubmitting}
                            name='LastChange'
                            errors={errors}
                            label='Poslední převaz dne:'
                            placeholder='poslední převaz dne'
                        />
                    ) : (
                        <DateInputStyled
                            control={control}
                            disabled={isSubmitting}
                            readOnly={!canEditCareAnamnesis}
                            name='LastChange'
                            errors={errors}
                            label='Poslední převaz dne:'
                        />
                    )}

                    <Stack spacing={1}>
                        <InputLabel className='text-sm font-semibold'>
                            Okolí vstupu
                        </InputLabel>
                        <CheckboxStyled
                            control={control}
                            readOnly={!canEditCareAnamnesis}
                            disabled={isSubmitting}
                            name='IsAroundEntranceCalm'
                            label='klidné'
                        />
                        <CheckboxStyled
                            control={control}
                            readOnly={!canEditCareAnamnesis}
                            disabled={isSubmitting}
                            name='IsAroundEntranceReddened'
                            label='zarudlé'
                        />
                        <CheckboxStyled
                            control={control}
                            disabled={isSubmitting}
                            readOnly={!canEditCareAnamnesis}
                            name='IsAroundEntranceIsland'
                            label='otok'
                        />
                        <CheckboxStyled
                            control={control}
                            readOnly={!canEditCareAnamnesis}
                            disabled={isSubmitting}
                            name='IsAroundEntranceSecretion'
                            label='sekrece'
                        />
                    </Stack>
                </>
            )}

            {isOpen && (
                <AnamnesisDefectsModal
                    isOpen={isOpen}
                    stateSwitch={handleOpen}
                    handleClose={handleClose}
                    onSave={handleSavePoint}
                />
            )}

            <Stack spacing={2}>
                <AnamnesisFormFooter
                    currentStep={currentStep}
                    canEditCareAnamnesis={canEditCareAnamnesis}
                    disabled={isLoading || isSubmitting}
                    handleSubmitWrapper={handleSubmitWrapper}
                    setCurrentStep={setCurrentStep}
                />
            </Stack>
        </form>
    )
}

export const AddPatientAnamnesisFormStep11: FC<AddPatientsAnamnesisFormStep11Hook> =
    wrap(AddPatientAnamnesisFormStep11View, useAddPatientAnamnesisFormStep11)
