import type { FC } from 'react'
import { usePage } from './hooks'
import { wrap } from '../../utils'
import { BulletinPageView } from './BulletinPageView'
import { UseBulletinPageHookProps } from './types'

export const BulletinPage: FC<UseBulletinPageHookProps> = wrap(
    BulletinPageView,
    usePage,
)
