import { usePageTitle } from './../../../hooks/usePageTitle'
import { useDashboardQuery, useLocation, useOpenState } from './../../../hooks'
import { useCallback, useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../providers'
import { HomePageProps, UseHomeHookProps } from '../types'

import { useVisitQuery } from '../../../api/visit/hooks'
import moment from 'moment'
import { useBulletinListQuery } from '../../../api'
import useResizePage from '../../../hooks/useResizePage'
import { ListNurseVisit } from '../../../api/visit/types'

export const usePage = (props: UseHomeHookProps): HomePageProps => {
    const { user, token } = useContext(AuthContext)
    const { data: visitData, isLoading: visitLoading } = useVisitQuery(
        {
            stateIn: ['New', 'Scheduled', 'Draft'],
            dateAt: moment().format('YYYY-MM-DD'),
            includeNurseOrdering: true,
        },
        token,
    )
    const { isMobile, isTablet } = useResizePage()
    const officeHelpModalState = useOpenState(false)
    const unreadNotifications = useBulletinListQuery({
        showUnread: true,
        hideArchived: true,
    })

    const [carouselHeight, setCarouselHeight] = useState<string>()
    const [slidesPerPage, setSlidesPerPage] = useState<number>(1)
    const [slides, setSlides] = useState<ListNurseVisit[][]>([])

    const dashboard = useDashboardQuery(token)
    const location = useLocation()

    usePageTitle('Denní přehled')

    const getInitialHeight = useCallback(
        (nurseNotesAndNames: ListNurseVisit[]) => {
            const nurseNoteExists = nurseNotesAndNames.some(
                (item) => item.nurseNote?.length,
            )
            const nurseNameExists = nurseNotesAndNames.some(
                (item) => item.nurseName?.length,
            )
            if (nurseNameExists && nurseNoteExists) {
                setCarouselHeight('280px')
            } else if (nurseNameExists) {
                setCarouselHeight('225px')
            } else if (nurseNoteExists) {
                setCarouselHeight('219px')
            } else {
                setCarouselHeight('195px')
            }
        },
        [],
    )

    useEffect(() => {
        if (!visitLoading && visitData?.data.length) {
            const nurseNotesAndNames = visitData.data.slice(0, slidesPerPage)
            getInitialHeight(nurseNotesAndNames)
        }
    }, [getInitialHeight, slidesPerPage, visitData?.data, visitLoading])

    useEffect(() => {
        if (location.latLng === '') {
            location.handleTrackLocation()
        }
    }, [location])

    useEffect(() => {
        if (isMobile) {
            setSlidesPerPage(1)
        } else if (isTablet) {
            setSlidesPerPage(2)
        } else {
            setSlidesPerPage(3)
        }
    }, [isMobile, isTablet])

    useEffect(() => {
        if (visitData?.data) {
            const newSlides = []
            for (let i = 0; i < visitData.data.length; i += slidesPerPage) {
                newSlides.push(
                    visitData.data?.slice(i, i + slidesPerPage) || [],
                )
            }
            setSlides(newSlides)
        }
    }, [visitData?.data, slidesPerPage])

    return {
        user,
        visitLoading,
        dashboard,
        location,
        carouselState: {
            carouselHeight,
            setCarouselHeight,
            slidesPerPage,
            slides,
            isMobile,
        },
        officeHelpModalState,
        unreadNotifications,
    }
}
