import { Stack, Typography } from '@mui/material'
import Modal from '../Modal'
import { UseHelpModalProps } from './types'

function VisitRecordModal(props: UseHelpModalProps) {
    return (
        <Modal
            {...props}
            title='Zápis návštěvy'
        >
            <Stack className='gap-3'>
                <Stack className='gap-1'>
                    <Typography
                        component='h2'
                        className='text-base font-semibold'
                    >
                        Obsah jednotlivých polí lze zapsat třemi způsoby:
                    </Typography>
                </Stack>
                <Stack spacing={2}>
                    <Typography className='text-sm'>
                        <strong>Textový zápis:</strong> Zápis pomocí klávesnice
                        mobilního telefonu.
                    </Typography>
                    <Typography className='text-sm'>
                        <strong>Hlasové nahrávání:</strong> Diktování záznamu po
                        zvolení mikrofonu na klávesnici mobilního telefonu.
                    </Typography>
                    <Typography className='text-sm'>
                        <strong>Kopie předchozího záznamu:</strong> Kopírování
                        záznamu z předchozí návštěvy, je-li k dispozici.
                    </Typography>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default VisitRecordModal
