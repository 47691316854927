import { Box, Stack, Typography } from '@mui/material'
import moment from 'moment'
import { VisitCard } from '../VisitCard'
import type { SortedCollectionProps } from '../../types'
import { useUIDSeed } from 'react-uid'

export const SortedCollection = (props: SortedCollectionProps) => {
    const {
        isLoading,
        sortedDateVisitCollections,
        nearestElementAvailable,
        nearestDateElement,
        nearestDates,
        shouldSetMainVisit = false,
        cardWrapClick,
    } = props

    const uid = useUIDSeed()
    return (
        <Box>
            {Object.entries(sortedDateVisitCollections).map(
                ([date, visits]) => {
                    const isNearestDate = nearestDates?.includes(date)
                    return (
                        <Stack
                            key={uid(date)}
                            spacing={3}
                            className='pb-6'
                            ref={
                                nearestElementAvailable
                                    ? isNearestDate
                                        ? nearestDateElement
                                        : null
                                    : null
                            }
                        >
                            <Stack
                                direction='row'
                                justifyContent='space-between'
                                alignItems='center'
                            >
                                <Typography className='text-sm font-medium'>
                                    {moment(date, 'MM/DD/YYYY').format(
                                        'DD.MM.YYYY',
                                    )}
                                </Typography>
                                <Box className='h-[2px] w-[21%] rounded bg-gray-300' />
                            </Stack>
                            <Stack className='grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-4'>
                                {visits.map((visit) => (
                                    <Box
                                        key={uid(visit?.id)}
                                        onClick={cardWrapClick}
                                    >
                                        <VisitCard
                                            isLoading={isLoading}
                                            mainVisitData={
                                                shouldSetMainVisit
                                                    ? {
                                                          id: visit?.id,
                                                          date: visit?.date,
                                                      }
                                                    : undefined
                                            }
                                            {...visit}
                                        />
                                    </Box>
                                ))}
                            </Stack>
                        </Stack>
                    )
                },
            )}
        </Box>
    )
}
