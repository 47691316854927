import { KeyboardReturn } from '@mui/icons-material'
import { Fab } from '@mui/material'
import type { MainVisitActionProps } from '../types'

export const MainVisitAction = (props: MainVisitActionProps) => {
    const { handleActionClick, mainVisitDate } = props

    return (
        <Fab
            className='fixed bottom-4 right-2'
            variant='extended'
            size='large'
            color='primary'
            aria-label='paste last'
            sx={{
                color: '#f8f8f8',
                zIndex: 100,
            }}
            onClick={handleActionClick}
        >
            <KeyboardReturn sx={{ mr: 1 }} />
            zpět - {mainVisitDate}
        </Fab>
    )
}
