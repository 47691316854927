import { Close, AccessTime } from '@mui/icons-material'
import { Paper, Stack, Chip, IconButton, Typography } from '@mui/material'
import { MedicationCzech, MedicationData } from '../../types'
import { OpenStateProps } from '../../../../types'

type MedicationHeaderProps = Pick<
    MedicationData,
    'type' | 'drugTitle' | 'user' | 'dateEndFor'
> &
    Pick<OpenStateProps, 'handleClose'>

export const MedicationHeader = (props: MedicationHeaderProps) => {
    const { type, drugTitle, user, dateEndFor, handleClose } = props

    return (
        <Paper
            sx={{
                background: '#feefe6',
                padding: '1rem',
                boxShadow: 'none',
            }}
        >
            <Stack gap='0.75rem'>
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Chip
                        label={MedicationCzech[type].toLocaleLowerCase()}
                        color='primary'
                        size='small'
                        sx={{
                            borderRadius: '0.375rem',
                            fontSize: '0.875rem',
                            lineHeight: '1.25rem',
                            color: '#f8f8f8',
                        }}
                    />
                    <IconButton
                        aria-label='close medication modal'
                        onClick={handleClose}
                    >
                        <Close fontSize='inherit' />
                    </IconButton>
                </Stack>
                <Stack gap='0.25rem'>
                    <Typography
                        fontWeight='500'
                        lineHeight='normal'
                        color='#333'
                    >
                        {drugTitle}
                    </Typography>
                    <Typography
                        fontSize='0.75rem'
                        fontWeight='300'
                    >
                        {user.fullName}
                    </Typography>
                </Stack>
                <Stack
                    direction='row'
                    gap='0.75rem'
                    alignItems='center'
                >
                    <Typography
                        fontSize='0.875rem'
                        lineHeight='normal'
                        color='#333'
                    >
                        Platnost do:
                    </Typography>
                    <Stack
                        direction='row'
                        gap='0.38rem'
                        alignItems='center'
                        sx={{
                            fontSize: '0.875rem',
                        }}
                    >
                        <AccessTime
                            sx={{
                                color: '#828282',
                                fontSize: '1.25rem',
                            }}
                        />
                        <Typography
                            fontSize='inherit'
                            color='#828282'
                        >
                            {dateEndFor || 'neuvedeno'}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Paper>
    )
}
