import { useCallback, useState } from 'react'
import { BulletinDialHookProps, BulletinDialActionTypes } from '../types'

export const useBulletinDial = (): BulletinDialHookProps => {
    const [selectedAction, setSelectedAction] =
        useState<BulletinDialActionTypes>('')

    const handleActionSelect = useCallback(
        (actionName: BulletinDialActionTypes) => (e: any) => {
            e.stopPropagation()
            setSelectedAction(actionName)
        },
        [],
    )

    const closeAction = useCallback(() => {
        setSelectedAction('')
    }, [])

    return {
        selectedAction,
        handleActionSelect,
        closeAction,
    }
}
