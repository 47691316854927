import { Box, Card, CardContent, Typography } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'
import { CareAnamneses, ECareAnamnesisState } from '../types'
import {
    CalendarToday,
    CheckCircle,
    Edit,
    Info,
    Lock,
} from '@mui/icons-material'

const getStateLabel = (state: ECareAnamnesisState) => {
    switch (state) {
        case ECareAnamnesisState.Step1:
            return '1. Krok'
        case ECareAnamnesisState.Step2:
            return '2. Krok'
        case ECareAnamnesisState.Step3:
            return '3. Krok'
        case ECareAnamnesisState.Step4:
            return '4. Krok'
        case ECareAnamnesisState.Step5:
            return '5. Krok'
        case ECareAnamnesisState.Step6:
            return '6. Krok'
        case ECareAnamnesisState.Step7:
            return '7. Krok'
        case ECareAnamnesisState.Step8:
            return '8. Krok'
        case ECareAnamnesisState.Step9:
            return '9. Krok'
        case ECareAnamnesisState.Step10:
            return '10. Krok'
        case ECareAnamnesisState.Step11:
            return '11. Krok'
        case ECareAnamnesisState.Step12:
            return '12. Krok'
        case ECareAnamnesisState.Ready:
            return 'Vyplněná'
        case ECareAnamnesisState.Closed:
            return 'Ukončená'
        default:
            return 'Rozpracovaná'
    }
}

const getStateColor = (state: ECareAnamnesisState) => {
    switch (state) {
        case ECareAnamnesisState.Step1:
        case ECareAnamnesisState.Step2:
        case ECareAnamnesisState.Step3:
        case ECareAnamnesisState.Step4:
        case ECareAnamnesisState.Step5:
        case ECareAnamnesisState.Step6:
        case ECareAnamnesisState.Step7:
        case ECareAnamnesisState.Step8:
        case ECareAnamnesisState.Step9:
        case ECareAnamnesisState.Step10:
        case ECareAnamnesisState.Step11:
        case ECareAnamnesisState.Step12:
        case ECareAnamnesisState.Ready:
            return 'success.main'
        case ECareAnamnesisState.Closed:
            return 'grey.500'
        default:
            return 'text.secondary'
    }
}

const getStateIcon = (state: ECareAnamnesisState) => {
    switch (state) {
        case ECareAnamnesisState.Step1:
        case ECareAnamnesisState.Step2:
        case ECareAnamnesisState.Step3:
        case ECareAnamnesisState.Step4:
        case ECareAnamnesisState.Step5:
        case ECareAnamnesisState.Step6:
        case ECareAnamnesisState.Step7:
        case ECareAnamnesisState.Step8:
        case ECareAnamnesisState.Step9:
        case ECareAnamnesisState.Step10:
        case ECareAnamnesisState.Step11:
        case ECareAnamnesisState.Step12:
        case ECareAnamnesisState.Ready:
            return (
                <CheckCircle
                    fontSize='small'
                    sx={{ fontSize: '16px', color: 'success.main' }}
                />
            )
        case ECareAnamnesisState.Closed:
            return (
                <Lock
                    fontSize='small'
                    sx={{ fontSize: '16px', color: 'grey.500' }}
                />
            )
        default:
            return (
                <Info
                    fontSize='small'
                    sx={{ fontSize: '16px', color: 'text.secondary' }}
                />
            )
    }
}

function PatientAnamneses({
    anamnesis,
    setSelectedPatientAnamnesis,
}: {
    anamnesis: CareAnamneses
    setSelectedPatientAnamnesis: Dispatch<SetStateAction<CareAnamneses | null>>
}) {
    return (
        <>
            <Card
                className='!shadow-sm transition-all hover:bg-[#f7f7f7]'
                sx={{
                    borderRadius: 1,
                    boxShadow: 'none',
                    backgroundColor: '#ffffff',
                    cursor: 'pointer',
                    padding: '8px',
                }}
                onClick={() => setSelectedPatientAnamnesis(anamnesis)}
            >
                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        alignItems: { xs: 'flex-start', md: 'center' },
                        justifyContent: 'space-between',
                        gap: 2,
                        padding: '8px 8px !important',
                    }}
                >
                    <Box sx={{ flex: 1 }}>
                        <Typography
                            variant='body2'
                            sx={{
                                fontSize: '16px',
                                fontWeight: 'medium',
                                color: '#333',
                            }}
                        >
                            {anamnesis.createBy}
                        </Typography>
                        <Typography
                            variant='body2'
                            sx={{
                                fontSize: '16px',
                                mt: 0.2,
                                color: 'text.secondary',
                            }}
                        >
                            Vytvořil/a
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            alignItems: { xs: 'flex-start', md: 'flex-end' },
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0.5,
                            }}
                        >
                            <CalendarToday
                                fontSize='small'
                                sx={{ fontSize: '16px' }}
                                color='action'
                            />
                            <Typography
                                variant='body2'
                                sx={{
                                    fontSize: '14px',
                                    color: 'text.secondary',
                                }}
                            >
                                Vytvořeno:{' '}
                                {new Date(
                                    anamnesis.createAt,
                                ).toLocaleDateString()}
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0.5,
                            }}
                        >
                            <Edit
                                fontSize='small'
                                sx={{ fontSize: '16px' }}
                                color='action'
                            />
                            <Typography
                                variant='body2'
                                sx={{
                                    fontSize: '14px',
                                    color: 'text.secondary',
                                }}
                            >
                                Upraveno:{' '}
                                {new Date(
                                    anamnesis.editAt,
                                ).toLocaleDateString()}
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0.5,
                            }}
                        >
                            {getStateIcon(anamnesis.state)}{' '}
                            <Typography
                                variant='body2'
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    color: getStateColor(anamnesis.state),
                                }}
                            >
                                Status: {getStateLabel(anamnesis.state)}
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
}

export default PatientAnamneses
