import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const useDateHandle = () => {
    const [activeDate, setActiveDate] = useState<Date | null>(new Date())
    const navigate = useNavigate()
    const { date } = useParams<{ date?: string }>()

    useEffect(() => {
        if (date) {
            const newDate = new Date(date)
            if (newDate.toDateString() !== activeDate?.toDateString()) {
                setActiveDate(new Date(date))
            }
        } else {
            const savedDate = localStorage.getItem('activeDate')
            if (savedDate) {
                setActiveDate(new Date(savedDate))
            } else {
                setActiveDate(new Date())
            }
        }
    }, [date])

    useEffect(() => {
        if (activeDate) {
            localStorage.setItem('activeDate', activeDate.toISOString())
        }
    }, [activeDate])

    const handleDateChange = (newValue: Date | null) => {
        setActiveDate(newValue)
        if (newValue) {
            navigate(`/visit-order/${moment(newValue).format('YYYY-MM-DD')}`)
        }
    }

    const handleSubtractDay = () => {
        setActiveDate((prevValue) => {
            const newDate = moment(prevValue).subtract(1, 'day').toDate()
            navigate(`/visit-order/${moment(newDate).format('YYYY-MM-DD')}`)
            return newDate
        })
    }

    const handleAddDay = () => {
        setActiveDate((prevValue) => {
            const newDate = moment(prevValue).add(1, 'day').toDate()
            navigate(`/visit-order/${moment(newDate).format('YYYY-MM-DD')}`)
            return newDate
        })
    }

    return {
        activeDate,
        handleDateChange,
        handleSubtractDay,
        handleAddDay,
    }
}
