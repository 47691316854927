import { Delete } from '@mui/icons-material'
import {
    Box,
    IconButton,
    OutlinedInput,
    Stack,
    Typography,
} from '@mui/material'
import { Controller } from 'react-hook-form'
import EditIcon from '@mui/icons-material/Edit'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { BulletinUrlLinksProps } from './types'
import { useBulletinUrlLinks } from './hooks/useBulletinUrlLinks'

function BulletinUrlLinks({
    control,
    setValue,
    urlLinks,
    getValues,
}: BulletinUrlLinksProps) {
    const { inputs, handleAddInput, handleRemoveInput, handleToggleEdit } =
        useBulletinUrlLinks({ setValue, urlLinks })

    return (
        <Stack className='!mt-1'>
            <Typography className='mb-2 text-sm'>Odkazy</Typography>
            {inputs.length > 0 &&
                inputs.map((input, index) => {
                    return (
                        <Box
                            key={index}
                            className='mb-2 flex gap-1'
                        >
                            <Box className='w-full'>
                                <Controller
                                    name={`urlLinks.${index}.urlAddress`}
                                    control={control}
                                    defaultValue={input.value.urlAddress}
                                    render={({ field }) => (
                                        <OutlinedInput
                                            {...field}
                                            placeholder='zde zadejte odkaz...'
                                            className='bg-white-reduced text-sm'
                                            type='text'
                                            fullWidth
                                            size='small'
                                            readOnly={!input.editable}
                                            sx={{
                                                color: input.editable
                                                    ? undefined
                                                    : '#828282',
                                            }}
                                        />
                                    )}
                                />
                            </Box>
                            <Box className='flex gap-1'>
                                {inputs.length !== index + 1 && (
                                    <>
                                        <IconButton
                                            type='button'
                                            onClick={() =>
                                                handleRemoveInput(input.id)
                                            }
                                            disabled={inputs.length === 1}
                                        >
                                            <Delete
                                                style={{ fontSize: '24px' }}
                                            />
                                        </IconButton>
                                        {!input.editable && (
                                            <IconButton
                                                type='button'
                                                onClick={() =>
                                                    handleToggleEdit(input.id)
                                                }
                                            >
                                                <EditIcon
                                                    style={{
                                                        fontSize: '24px',
                                                    }}
                                                />
                                            </IconButton>
                                        )}
                                    </>
                                )}

                                {inputs.length === index + 1 && (
                                    <IconButton
                                        type='button'
                                        onClick={() => handleAddInput()}
                                    >
                                        <AddCircleIcon
                                            style={{ fontSize: '24px' }}
                                        />
                                    </IconButton>
                                )}
                            </Box>
                        </Box>
                    )
                })}
        </Stack>
    )
}

export default BulletinUrlLinks
