import {
    Box,
    Fab,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    SpeedDialAction,
} from '@mui/material'
import { PatientTabProps } from '../../types'
import { PatientDocuments } from './PatientDocuments'
import { SplitContent } from './SplitContent'
import { uid } from 'react-uid'
import { HelpDial } from '../../../../components'
import { visitDetailPatientActions } from '../../../../components/HelpDial/HelpDialActions'
import { HelpSpeedDialActions } from '../../../../components/HelpDial/types'
import { useContext, useState } from 'react'
import { updatePatientNurseNote } from '../../../../api/patient/services'
import { AuthContext } from '../../../../providers'
import { useCustomSnackbar } from '../../../../hooks'
import { useQueryClient } from '@tanstack/react-query'

export const Patient = (props: PatientTabProps) => {
    const {
        activePatient,
        isLoading,
        patientDocumentsQuery,
        handleFileUpload,
        uploadLoading,
        handleButtonClick,
        fileInputRef,
        patientNotesQuery,
        setNewUploadedFileName,
        newUploadedFileName,
        handleSendUpload,
        isPreviewOpen,
        visitId,
        handleClosePreview,
        patientId,
        fileData,
        showTabs,
    } = props

    const { token } = useContext(AuthContext)
    const [nurseNote, setNurseNote] = useState(activePatient?.nurseNote || '')
    const [isLoadingNurseNote, setIsLoadingNurseNote] = useState(false)
    const snackbar = useCustomSnackbar()
    const queryClient = useQueryClient()

    const handleSubmitPatientNurseNote = async () => {
        if (!nurseNote || !token || !patientId || !visitId) return
        try {
            setIsLoadingNurseNote(true)
            await updatePatientNurseNote(token, patientId, nurseNote.trim())
            snackbar('Poznámka byla úspěšně uložena', 'success')
            setNurseNote((prev) => prev.trim())
            queryClient.refetchQueries(['visitDetails', visitId])
        } catch (e) {
            snackbar('Nepodařilo se uložit poznámku', 'error')
        } finally {
            setIsLoadingNurseNote(false)
        }
    }

    return (
        <>
            <SplitContent
                showTabs={showTabs}
                isLoading={isLoading}
                activePatient={activePatient}
                patientNotesQuery={patientNotesQuery}
            />

            <Box className='flex flex-wrap gap-4'>
                <Box
                    flex={1}
                    minWidth={300}
                >
                    <InputLabel className='pb-2 text-sm font-medium'>
                        Interní poznámka
                    </InputLabel>
                    <OutlinedInput
                        fullWidth
                        readOnly
                        size='small'
                        minRows={6}
                        maxRows={8}
                        value={activePatient?.note}
                        multiline
                        className='mb-6 rounded bg-orange-100 px-[0.875rem] py-2 text-sm font-medium'
                    />
                </Box>
                <Box
                    flex={1}
                    minWidth={300}
                >
                    <InputLabel className='pb-2 text-sm font-medium'>
                        Poznámka pro terénní sestru
                    </InputLabel>
                    <OutlinedInput
                        fullWidth
                        size='small'
                        minRows={1.4}
                        maxRows={8}
                        onChange={(e) => setNurseNote(e.target.value)}
                        value={nurseNote}
                        multiline
                        className='tems-start mb-6 rounded bg-[#f8f8f8] px-[0.875rem] py-2 pb-16 text-sm font-medium lg:pb-[100px]'
                        endAdornment={
                            <InputAdornment
                                className='position absolute bottom-7 right-3'
                                position='end'
                            >
                                <Fab
                                    variant='extended'
                                    size='small'
                                    color='primary'
                                    className='rounded text-sm normal-case shadow-md'
                                    sx={{
                                        color: '#f8f8f8',
                                        zIndex: 1,
                                    }}
                                    disabled={isLoadingNurseNote}
                                    onClick={() => {
                                        handleSubmitPatientNurseNote()
                                    }}
                                >
                                    Uložit poznámku
                                </Fab>
                            </InputAdornment>
                        }
                    />
                </Box>
            </Box>

            <PatientDocuments
                handleButtonClick={handleButtonClick}
                fileInputRef={fileInputRef}
                newUploadedFileName={newUploadedFileName}
                setNewUploadedFileName={setNewUploadedFileName}
                handleFileUpload={handleFileUpload}
                fileData={fileData}
                handleClosePreview={handleClosePreview}
                isPreviewOpen={isPreviewOpen}
                handleSendUpload={handleSendUpload}
                patientDocumentsQuery={patientDocumentsQuery}
                uploadLoading={uploadLoading}
                patientId={activePatient?.id}
            />
            <HelpDial>
                {({ handleActionClick }) => {
                    return visitDetailPatientActions.map(
                        ({ name, icon, modalName }: HelpSpeedDialActions) => (
                            <SpeedDialAction
                                key={uid(modalName)}
                                icon={icon}
                                tooltipTitle={name}
                                tooltipPlacement='right'
                                tooltipOpen
                                sx={{
                                    '& .MuiSpeedDialAction-staticTooltipLabel':
                                        {
                                            width: 'max-content',
                                        },
                                }}
                                onClick={handleActionClick(modalName)}
                            />
                        ),
                    )
                }}
            </HelpDial>
        </>
    )
}
