import { memo } from 'react'
import { Modal } from '../components'
import { useUIDSeed } from 'react-uid'
import { Button, Stack } from '@mui/material'
import { FilterModalProps } from './types'
import { FilterGroup } from './FilterGroup'

export const FilterModal = memo(
    <TFilterType, TValue>(props: FilterModalProps<TFilterType, TValue>) => {
        const {
            open,
            handleClose,
            filters,
            handleFilterActivate,
            resetFilterAllActiveGroup,
            handleUseFilters,
            resetFilterGroup,
            title,
        } = props

        const uid = useUIDSeed()
        return (
            <Modal
                open={open}
                handleClose={handleClose}
                title={title}
            >
                <>
                    {filters.map((filterData) => (
                        <FilterGroup
                            key={uid(filterData?.title)}
                            handleFilterActivate={handleFilterActivate}
                            resetFilterGroup={resetFilterGroup}
                            {...filterData}
                        />
                    ))}
                    <Stack className='flex-row justify-end gap-2'>
                        <Button
                            type='button'
                            variant='outlined'
                            size='medium'
                            className='bg-white-reduced text-sm normal-case shadow-none'
                            onClick={() => {
                                resetFilterAllActiveGroup(true)
                            }}
                        >
                            zrušit filtry
                        </Button>
                        <Button
                            variant='contained'
                            size='medium'
                            type='submit'
                            onClick={() => handleUseFilters(handleClose)}
                            className='text-sm normal-case shadow-none'
                        >
                            Použít filtry
                        </Button>
                    </Stack>
                </>
            </Modal>
        )
    },
)
