import {
    Box,
    colors,
    Fab,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography,
} from '@mui/material'
import { Controller } from 'react-hook-form'
import { ConditionalInputProps } from '../../types'
import moment from 'moment'
import { useState, type FC } from 'react'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import classNames from 'classnames'

export const ConditionalInputView: FC<ConditionalInputProps> = (props) => {
    const {
        isInputShown,
        inputTitle,
        isDisabled = false,
        carePlanIndex,
        voucherIndex,
        inputControlName,
        visitForm,
        lastVoucherData,
        pasteFromLast,
        copyNote,
        isRequired,
        pastFromNote,
    } = props

    const {
        formMethods: {
            control,
            formState: { errors },
        },
    } = visitForm

    const [isPastFromNoteDisabled, setIsPastFromNoteDisabled] = useState(false)
    const [isPastFromPreviousDisabled, setIsPastFromPreviousDisabled] =
        useState(false)

    return isInputShown ? (
        <Box>
            <InputLabel
                sx={{
                    paddingBottom: '8px',
                    fontSize: '14px',
                    fontWeight: '500',
                }}
            >
                {inputTitle}
            </InputLabel>
            <Controller
                control={control}
                name={`carePlans.${carePlanIndex}.voucherCarePlans.${voucherIndex}.${inputControlName}`}
                render={({ field }) => (
                    <OutlinedInput
                        {...field}
                        placeholder='zde zadejte informace'
                        multiline
                        maxRows={6}
                        minRows={4}
                        readOnly={isDisabled}
                        className={classNames('rounded text-sm font-medium', {
                            'bg-orange-100': isDisabled === true,
                            'bg-white-reduced': isDisabled === false,
                        })}
                        value={field.value || ''}
                        error={
                            errors.carePlans?.[carePlanIndex]
                                ?.voucherCarePlans?.[voucherIndex]?.[
                                inputControlName
                            ] !== undefined
                        }
                        sx={{
                            width: '100%',
                            bgcolor: '#F8F8F8',
                            fontSize: '14px',
                            color: '#4B5563',
                            paddingBottom: '4rem',
                        }}
                        endAdornment={
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    position: 'absolute',
                                    bottom: '1.75rem',
                                    right: '0.5rem',
                                }}
                            >
                                {copyNote && (
                                    <InputAdornment position='end'>
                                        <Fab
                                            variant='extended'
                                            size='small'
                                            color='primary'
                                            className='!rounded-md'
                                            aria-label='paste note'
                                            sx={{
                                                zIndex: 1,
                                                borderRadius: '6px',
                                                textTransform: 'lowercase',
                                                fontSize: '14px',
                                                background: 'transparent',
                                                color: '#F88035',
                                                boxShadow: 'none',
                                                border:
                                                    isDisabled ||
                                                    isPastFromNoteDisabled
                                                        ? '1px solid #dadada'
                                                        : '1px solid #F88035',
                                                '&:hover': {
                                                    background:
                                                        isDisabled ||
                                                        isPastFromNoteDisabled
                                                            ? '#dadada'
                                                            : '#F88035',
                                                    color:
                                                        isDisabled ||
                                                        isPastFromNoteDisabled
                                                            ? '#00000042'
                                                            : '#f8f8f8',
                                                },
                                            }}
                                            disabled={
                                                isDisabled ||
                                                isPastFromNoteDisabled
                                            }
                                            onClick={() => {
                                                pastFromNote()
                                                setIsPastFromNoteDisabled(
                                                    (prev) => !prev,
                                                )
                                            }}
                                        >
                                            z poznámky
                                            <ContentPasteIcon
                                                sx={{
                                                    fontSize: '18px',
                                                    ml: '0.5rem',
                                                }}
                                            />
                                        </Fab>
                                    </InputAdornment>
                                )}

                                <InputAdornment position='end'>
                                    <Fab
                                        variant='extended'
                                        size='small'
                                        color='primary'
                                        aria-label='paste last'
                                        sx={{
                                            color: '#f8f8f8',
                                            zIndex: 1,
                                            borderRadius: '6px',
                                            fontSize: '14px',
                                            textTransform: 'lowercase',
                                        }}
                                        disabled={
                                            isDisabled ||
                                            isPastFromPreviousDisabled ||
                                            !lastVoucherData?.lastInput?.length
                                        }
                                        onClick={() => {
                                            pasteFromLast()
                                            setIsPastFromPreviousDisabled(
                                                (prev) => !prev,
                                            )
                                        }}
                                    >
                                        {lastVoucherData?.voucherCarePlansDate
                                            ? 'z ' +
                                              moment(
                                                  lastVoucherData?.voucherCarePlansDate,
                                              ).format('DD.MM.')
                                            : 'není'}
                                        <ContentPasteIcon
                                            sx={{
                                                fontSize: '18px',
                                                ml: '0.5rem',
                                            }}
                                        />
                                    </Fab>
                                </InputAdornment>
                            </Box>
                        }
                    />
                )}
            />
            {!!errors.carePlans?.[carePlanIndex]?.voucherCarePlans?.[
                voucherIndex
            ]?.[inputControlName] && (
                <Typography
                    fontSize='12px'
                    color={colors.red[400]}
                >
                    Toto pole je povinné
                </Typography>
            )}
        </Box>
    ) : null
}
