import { useCallback, useContext, useMemo } from 'react'
import { getHealtAction } from '../../../api/visit/services'
import {
    FinalInfoHookProps,
    FinalInfoViewProps,
    NewHealthActionProps,
} from '../types'
import { HealtActionCollection } from '../../../api/visit/types'
import { AuthContext } from '../../../providers'
import { useParams } from 'react-router-dom'

export const useFinalInfo = (props: FinalInfoHookProps): FinalInfoViewProps => {
    const { visitForm } = props

    const {
        formMethods: { getValues, reset },
    } = visitForm

    const currentCarePlans = getValues('carePlans')

    const { visitId } = useParams()

    const { token } = useContext(AuthContext)

    const createNewHealthAction = (
        healtActionCollection: HealtActionCollection,
    ) =>
        currentCarePlans && [
            ...currentCarePlans.filter((carePlan) => !carePlan.diagnose),
            {
                diagnose: null,
                addition: '',
                plans: [],
                voucherCarePlans: [...healtActionCollection.data],
                allowSelectAllPlans: false,
            },
            ...currentCarePlans.filter((carePlan) => !!carePlan.diagnose),
        ]

    const realoadWithNewAction = (
        healthActionCollection: HealtActionCollection,
    ) =>
        reset({
            ...getValues(),
            carePlans: createNewHealthAction(healthActionCollection),
        })

    const getNewHealtAction = async ({
        e,
        code,
        usingLimit,
        id,
        usedCount,
        usedOnVisit,
        usingLimitPerVisit,
    }: NewHealthActionProps) => {
        e.preventDefault()
        e.stopPropagation()

        const effectiveLimit = getEffectiveLimit(usingLimit, usingLimitPerVisit)

        // Zkontrolujeme, zda je počet použití pod efektivním limitem, pokud existuje
        if (
            effectiveLimit === null ||
            getCodeUsage(code, usedCount, usedOnVisit) < effectiveLimit
        ) {
            return getHealtAction(Number(visitId), token, id).then(
                (healthAction) => realoadWithNewAction(healthAction),
            )
        }

        return
    }

    const currentNotDiagnosedCodes = useMemo(
        () =>
            currentCarePlans
                ?.filter((plan) => !plan.diagnose)
                .map((item) => item.voucherCarePlans)
                ?.flatMap(
                    (item) => item?.map((voucher) => voucher.code),
                    [currentCarePlans],
                ),
        [currentCarePlans],
    )

    const getCodeUsage = useCallback(
        (code: string, usedCount: number, usedOnVisit: number) => {
            if (!currentNotDiagnosedCodes) {
                return usedCount
            }

            return (
                currentNotDiagnosedCodes.filter((_c) => _c === String(code))
                    .length +
                usedCount -
                usedOnVisit
            )
        },
        [currentNotDiagnosedCodes],
    )

    return {
        getCodeUsage,
        getNewHealtAction,
        ...props,
    }
}

export const getEffectiveLimit = (
    usingLimit: number | null,
    usingLimitPerVisit: number | null,
): number | null => {
    if (usingLimitPerVisit !== null) {
        return usingLimitPerVisit
    }
    if (usingLimit !== null) {
        return usingLimit
    }
    return null // Pokud není žádný limit
}
