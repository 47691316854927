import { Button, Stack } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'

export const AnamnesisFormFooter = ({
    currentStep,
    setCurrentStep,
    maxSteps = 12,
    disabled,
    canEditCareAnamnesis,
    handleSubmitWrapper,
}: {
    disabled: boolean
    currentStep: number
    canEditCareAnamnesis: boolean
    maxSteps?: number
    handleSubmitWrapper: (nextStep: boolean) => void
    setCurrentStep: Dispatch<SetStateAction<number>>
}) => {
    return (
        <Stack className='fixed bottom-0 left-0 z-10 flex w-full flex-row items-center justify-between bg-[#feefe6] p-5'>
            <Button
                className='capitalize'
                disabled={disabled || currentStep === 0}
                type='button'
                onClick={() => handleSubmitWrapper(false)}
            >
                {'<'} zpět
            </Button>
            <span>{`${currentStep}/${maxSteps}`}</span>
            <Button
                type='button'
                onClick={() => handleSubmitWrapper(true)}
                className='capitalize'
                disabled={
                    disabled ||
                    (currentStep === maxSteps && !canEditCareAnamnesis)
                }
            >
                {currentStep === maxSteps ? 'Dokončit' : 'Další >'}
            </Button>
        </Stack>
    )
}
