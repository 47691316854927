import { useContext } from 'react'
import { AuthContext } from '..'
import { OLOMOUC_FNOL_ID } from '../../../constants'

export const useFnol = () => {
    const { user } = useContext(AuthContext)

    const isFnol = user?.organizationID === OLOMOUC_FNOL_ID

    return { isFnol }
}
