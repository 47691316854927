import { Avatar, Box, IconButton, Skeleton, Typography } from '@mui/material'
import { MouseEvent } from 'react'
import { formatPhone } from '../../../../utils'
import type { ContactCardProps } from '../../types'
import classnames from 'classnames'
import { Close } from '@mui/icons-material'
import { useSnackbar } from 'notistack'

export const ContactCard = ({
    name,
    familyRelationship,
    phone,
    isLoading,
}: ContactCardProps) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const onCardClick = (e: MouseEvent) => {
        e.preventDefault()
        if (!phone) {
            return enqueueSnackbar(`Telefon neuveden - není možné zavolat.`, {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
                action: (key) => (
                    <IconButton onClick={() => closeSnackbar(key)}>
                        <Close color='secondary' />
                    </IconButton>
                ),
            })
        } else {
            window.location.href = `tel:${phone}`
        }
    }

    return (
        <Box
            onClick={onCardClick}
            className={classnames(
                'h-full rounded border border-solid border-orange-400 bg-orange-100 p-3 text-center text-gray-700',
                {
                    'border-gray-400 bg-gray-200': !phone,
                },
            )}
        >
            {isLoading ? (
                <Skeleton
                    animation='wave'
                    variant='circular'
                    width={40}
                    height={40}
                    className='mx-auto bg-orange-300'
                />
            ) : (
                <Avatar
                    className={classnames(
                        'mx-auto mb-3 bg-orange-300 text-white-reduced',
                        {
                            'bg-gray-400': !phone,
                        },
                    )}
                />
            )}
            <Box className='pb-2'>
                {isLoading ? (
                    <Skeleton
                        animation='wave'
                        height={14}
                        width='60%'
                        sx={{
                            mx: 'auto',
                        }}
                    />
                ) : name ? (
                    <Typography className='text-sm font-medium'>
                        {name}
                    </Typography>
                ) : null}
                {isLoading ? (
                    <Skeleton
                        animation='wave'
                        height={14}
                        width='30%'
                        sx={{
                            mx: 'auto',
                        }}
                    />
                ) : (
                    <Typography className='text-xs font-medium text-gray-600'>
                        {familyRelationship}
                    </Typography>
                )}
            </Box>
            {isLoading ? (
                <Skeleton
                    animation='wave'
                    height={14}
                    width='60%'
                    sx={{
                        mx: 'auto',
                    }}
                />
            ) : phone ? (
                <Typography className='text-sm font-medium'>
                    {formatPhone(phone)}
                </Typography>
            ) : (
                <Typography className='text-sm font-medium'>
                    neuvedeno
                </Typography>
            )}
        </Box>
    )
}
