import { Stack, InputLabel, Box, Button, Grid } from '@mui/material'
import { Controller } from 'react-hook-form'
import { ErrorImage } from '../HelpDial/ErrorImge'
import { uid } from 'react-uid'
import { BulletinUploadProps } from './types'
import classNames from 'classnames'
import {
    useBulletinDocumentForm,
    useBulletinUpload,
} from './hooks/useBulletinUpload'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'

export type FileProps = {
    id?: number
    name?: string
    imageSrc: string
    type?: string
}

export type MessageAttachmentsProps = {
    id: number
    name: string
    type: string
    description?: string
    dateUpload: string
}

function BulletinUpload({
    control,
    errors,
    setValue,
    getValues,
    messageAttachments,
}: BulletinUploadProps) {
    const {
        selectedFiles,
        handleUploadDiscard,
        setSelectedFiles,
        inputRef,
        handleButtonClick,
        handleUploadFile,
    } = useBulletinUpload({
        setValue,
    })

    useBulletinDocumentForm({
        attachments: messageAttachments,
        setSelectedFiles,
    })

    return (
        <Stack
            spacing={1}
            className='mt-5'
        >
            <InputLabel className='text-sm'>Přílohy</InputLabel>
            <Box
                className={classNames(
                    'relative mb-4 flex min-h-[100px] w-full flex-col justify-end rounded border border-solid  bg-white-reduced p-2',
                    {
                        'border-[#e84a4a]': !!errors,
                        'border-gray-400': !errors,
                    },
                )}
            >
                <div className='flex gap-3'></div>

                {selectedFiles.length > 0 && (
                    <Grid
                        spacing={2}
                        container
                    >
                        {selectedFiles.map(
                            (document: FileProps, index: number) => {
                                return (
                                    <Grid
                                        item
                                        xs={3}
                                        key={`${uid(document.name)}-${index}`}
                                        className='relative flex flex-col items-center'
                                    >
                                        <ErrorImage {...document} />
                                        <button
                                            type='button'
                                            onClick={() =>
                                                handleUploadDiscard(
                                                    document.imageSrc,
                                                )
                                            }
                                        >
                                            Odstranit
                                        </button>
                                    </Grid>
                                )
                            },
                        )}
                    </Grid>
                )}

                <Box className='ml-auto mt-2 flex justify-end'>
                    <Controller
                        name='attachments'
                        control={control}
                        render={({ field }) => (
                            <Button
                                variant='contained'
                                size='small'
                                type='button'
                                aria-label='upload picture'
                                className='flex min-w-0 gap-1 rounded-[100%] border-r p-[5px] text-sm font-bold normal-case shadow-none'
                                onClick={handleButtonClick}
                            >
                                <input
                                    hidden
                                    ref={inputRef}
                                    multiple
                                    type='file'
                                    onChange={(e) => {
                                        handleUploadFile(e, field)
                                    }}
                                />

                                <AddAPhotoIcon fontSize='small' />
                            </Button>
                        )}
                    />
                </Box>
            </Box>
        </Stack>
    )
}

export default BulletinUpload
