import { Box, InputLabel, Chip, Stack, Button, Avatar } from '@mui/material'
import { uid } from 'react-uid'
import { useOpenState } from '../../hooks'
import { Controller } from 'react-hook-form'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import BulletinMessageUsersModal from './BulletinMessageUsersModal'
import { BulletinMessageUsersProps, BulletinUsersProps } from './types'
import classNames from 'classnames'
import { Apartment } from '@mui/icons-material'

function BulletinMessageUsers({
    control,
    setValue,
    getValues,
    triggerValidation,
    errors,
    isFnol,
}: BulletinMessageUsersProps) {
    const { isOpen, handleClose, handleOpen } = useOpenState()
    return (
        <Stack spacing={1}>
            <InputLabel className='text-sm'>
                Komu je zpráva určena?{isFnol ? '*' : ''}
            </InputLabel>

            <Controller
                name='selectedUsers'
                control={control}
                defaultValue={[]}
                render={({ field }) => {
                    return (
                        <Box
                            className={classNames(
                                'rounded-[6px] border border-solid border-[#bfbfbf] bg-white-reduced p-[6px] text-sm',
                                {
                                    'border-[#e84a4a]': !!errors,
                                },
                            )}
                        >
                            <Box className='flex flex-wrap gap-2'>
                                {isFnol && (
                                    <Chip
                                        key={uid('FNOL')}
                                        color='primary'
                                        label='FNOL'
                                        className={'bg-primary text-white'}
                                        avatar={
                                            <Avatar
                                                style={{
                                                    backgroundColor: '#C55007',
                                                }}
                                                className='text-base'
                                            >
                                                <Apartment className='size-5' />
                                            </Avatar>
                                        }
                                    ></Chip>
                                )}
                                {field.value &&
                                    field.value.map(
                                        (user: BulletinUsersProps) => (
                                            <Chip
                                                key={uid(user.id)}
                                                label={user.fullName}
                                                color='primary'
                                                className={
                                                    'bg-primary text-white'
                                                }
                                                avatar={
                                                    user.fullName?.split(' ')
                                                        .length > 1 ||
                                                    user.icon ? (
                                                        <Avatar
                                                            style={{
                                                                backgroundColor:
                                                                    '#C55007',
                                                            }}
                                                            className='text-base'
                                                            src={
                                                                user.icon || ''
                                                            }
                                                            alt={
                                                                user.fullName ||
                                                                ''
                                                            }
                                                        >
                                                            {!isFnol &&
                                                                !user.icon?.trim() &&
                                                                `${
                                                                    user.fullName?.split(
                                                                        ' ',
                                                                    )[0][0]
                                                                }${
                                                                    user.fullName?.split(
                                                                        ' ',
                                                                    )[1][0]
                                                                }`}
                                                        </Avatar>
                                                    ) : isFnol ? (
                                                        <Apartment />
                                                    ) : undefined
                                                }
                                            />
                                        ),
                                    )}
                            </Box>

                            <Box className='mt-2 flex justify-end'>
                                <Button
                                    variant='contained'
                                    size='small'
                                    type='button'
                                    disabled={isFnol}
                                    className='flex gap-1 text-sm font-bold normal-case shadow-none'
                                    onClick={handleOpen}
                                >
                                    upravit určení
                                    <PersonAddIcon
                                        style={{
                                            fontSize: '20px',
                                        }}
                                    />
                                </Button>
                            </Box>
                        </Box>
                    )
                }}
            />
            <BulletinMessageUsersModal
                isOpen={isOpen}
                handleClose={handleClose}
                setValue={setValue}
                triggerValidation={triggerValidation}
                preSelectedUsers={getValues('selectedUsers')}
            />
        </Stack>
    )
}

export default BulletinMessageUsers
