import { useEffect, useState } from 'react'
import { useHelpCameraProps } from '../types'

export const useHelpCamera = (): useHelpCameraProps => {
    const [cameraPermission, setCameraPermission] = useState<
        'granted' | 'prompt' | 'denied' | null
    >(null)

    const checkCameraPermission = async () => {
        try {
            await navigator.mediaDevices.getUserMedia({ video: true })
            setCameraPermission('granted')
        } catch (err) {
            console.log(err)
            setCameraPermission('denied')
        }
    }

    useEffect(() => {
        checkCameraPermission()
    }, [])

    return {
        cameraPermission,
        setCameraPermission,
        checkCameraPermission,
    }
}
