const toQueryString = (obj: { [key: string]: any }): string => {
    return Object.entries(obj)
        .flatMap(([key, value]) => {
            if (Array.isArray(value)) {
                return value.map(
                    (v) =>
                        `${encodeURIComponent(key)}=${encodeURIComponent(v)}`,
                )
            } else {
                return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
            }
        })
        .join('&')
}

export default toQueryString
