import { ConditionalInputHookProps, ConditionalInputProps } from '../types'
import moment from 'moment'
import { useCustomSnackbar } from '../../../hooks'

type Props = ConditionalInputHookProps

export const useConditionalInput = (props: Props): ConditionalInputProps => {
    const {
        visitForm,
        carePlanIndex,
        lastVoucherData,
        voucherIndex,
        inputControlName,
        inputTitle,
        copyNote,
    } = props

    const {
        formMethods: { setValue, getValues },
    } = visitForm

    const getSnackbar = useCustomSnackbar()

    const pasteFromLast = () => {
        if (!lastVoucherData) return

        const { lastInput, voucherCarePlansDate } = lastVoucherData

        const currentValue = getValues(
            `carePlans.${carePlanIndex}.voucherCarePlans.${voucherIndex}.${inputControlName}`,
        )

        const newValue = currentValue
            ? `${currentValue}\n\n${lastInput || ''}`
            : lastInput || ''

        setValue(
            `carePlans.${carePlanIndex}.voucherCarePlans.${voucherIndex}.${inputControlName}`,
            newValue,
        )

        getSnackbar(
            `Data byla vložena do pole "${inputTitle}" z poslední návštěvy ${
                voucherCarePlansDate
                    ? `${moment(voucherCarePlansDate).format('DD.MM.')}.`
                    : ''
            }`,
        )
    }

    const pastFromNote = () => {
        if (!copyNote) return

        const currentValue = getValues(
            `carePlans.${carePlanIndex}.voucherCarePlans.${voucherIndex}.${inputControlName}`,
        )

        const newValue = currentValue
            ? `${currentValue}\n\n${copyNote || ''}`
            : copyNote || ''

        setValue(
            `carePlans.${carePlanIndex}.voucherCarePlans.${voucherIndex}.${inputControlName}`,
            newValue,
        )

        getSnackbar(
            `Data byla vložena do pole "${inputTitle}" z poznámky k výkonu.`,
        )
    }

    return {
        pasteFromLast,
        pastFromNote,
        copyNote,
        ...props,
    }
}
