import { useMutation } from '@tanstack/react-query'
import { useContext } from 'react'
import { useCustomSnackbar } from '../../hooks'
import { AuthContext } from '../../providers'

import { postFaultReport } from './services'
import { HelpErrorForm } from '../../components/HelpDial/types'

export const useFaultReportMutation = () => {
    const { token } = useContext(AuthContext)
    const customSnackbar = useCustomSnackbar()

    const faultReportMutation = useMutation(
        async (data: HelpErrorForm) => await postFaultReport({ data, token }),
        {
            onSuccess: () => {
                customSnackbar(
                    'Děkujeme za nahlášení. Budeme trápit naše IT, dokud vše nespraví \u{1F609}.',
                    'success',
                    true,
                )
            },
            onError: () => {
                customSnackbar(
                    'Zpráva nebyla odeslána, prosím kontaktujte nás na info@e-sestricka.cz',
                    'error',
                    true,
                )
            },
        },
    )

    return faultReportMutation
}
