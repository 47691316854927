import { Box, Button, Skeleton, Typography } from '@mui/material'
import { BulletinCarousel } from '../../../components'
import { useNavigate } from 'react-router-dom'
import { UnreadNotificationsProps } from '../types'

export const UnreadNotifications = (props: UnreadNotificationsProps) => {
    const { unreadNotifications } = props
    const navigate = useNavigate()

    return (
        <Box className='pt-5'>
            <Typography className='pb-5 font-medium text-gray-700'>
                Nová oznámení
            </Typography>
            <BulletinCarousel
                bulletinList={unreadNotifications.bulletinList}
                isLoading={unreadNotifications.isLoading}
            />
            {unreadNotifications.isLoading ? (
                <Skeleton
                    height={34}
                    variant='rectangular'
                    className='mb-5 rounded'
                    animation='wave'
                />
            ) : (
                <Button
                    color='primary'
                    variant='outlined'
                    className='mb-5 bg-white-reduced'
                    size='small'
                    fullWidth
                    sx={{ textTransform: 'none' }}
                    onClick={() => {
                        navigate('/notifications')
                        window.scrollTo(0, 0)
                    }}
                >
                    Zobrazit nástěnku
                </Button>
            )}
        </Box>
    )
}
