import { BulletinFilterItems, BulletinFilterType } from '../types'
import { SelectItemsCzech } from '../../../types/components'

export const getBulletinsFilters = (isFnol: boolean): BulletinFilterItems[] => {
    const staticFilters: BulletinFilterItems[] = [
        {
            title: 'Stav zprávy',
            type: 'readability',
            items: [
                { active: false, value: false, name: 'Přečteno' },
                { active: false, value: true, name: 'Nepřečteno' },
            ],
        },
        {
            title: 'Zprávy e-Sestřičky',
            type: 'admin',
            items: [
                {
                    active: false,
                    value: false,
                    name: 'Zobrazit ES',
                },
                {
                    active: false,
                    value: true,
                    name: 'Nezobrazovat ES',
                },
            ],
        },
        {
            title: 'Archiv',
            type: 'archived',
            items: [
                {
                    active: true,
                    value: true,
                    name: 'Aktivní',
                },
                {
                    active: false,
                    value: false,
                    name: 'Archivní',
                },
            ],
        },
        {
            title: 'Tagy',
            type: 'tag',
            items: [
                {
                    active: false,
                    value: 'Important',
                    name: SelectItemsCzech['Important'],
                },
                {
                    active: false,
                    value: 'Unknown',
                    name: SelectItemsCzech['Unknown'],
                },
            ],
        },
    ]

    if (isFnol) {
        const tagsSection = staticFilters.find(
            (filter) => filter.type === 'tag',
        )
        if (tagsSection) {
            tagsSection.items.push({
                active: false,
                value: 'FNOL',
                name: SelectItemsCzech['Fnol'],
            })
        }
    }

    return staticFilters
}

export const singleSelectBulletinGroup: BulletinFilterType[] = [
    'readability',
    'admin',
    'archived',
]
