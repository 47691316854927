import axios from 'axios'
import { getRestApiConfig } from '../config'

export const pingPhoneUsage = async (token?: string) => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)
    const endpointUrl = new URL('/api-mob/stats/phone', baseUrl).href

    const response = await axios.head(endpointUrl, headerConfig)
    return response.data
}

export const pingNavigationUsage = async (token?: string) => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)
    const endpointUrl = new URL('/api-mob/stats/navigation', baseUrl).href

    const response = await axios.head(endpointUrl, headerConfig)
    return response.data
}
