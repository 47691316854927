import { Box, IconButton, SpeedDialAction, Typography } from '@mui/material'
import type { FC } from 'react'
import { HelpDial, Navigation, SortedVisit } from '../../components'
import type { OfficePageProps } from './types'
import { VisitLengthBar } from './components'
import { useLocation, useNavigate } from 'react-router-dom'
import {
    visitsInProgressActions,
    visitsReturnedActions,
} from '../../components/HelpDial/HelpDialActions'
import { HelpSpeedDialActions } from '../../components/HelpDial/types'
import { useUIDSeed } from 'react-uid'
import HomeIcon from '@mui/icons-material/Home'

export const OfficePageView: FC<OfficePageProps> = (props) => {
    const {
        isVisitLoading,
        hasEntries,
        sortedDateVisitCollections,
        orderAscending,
    } = props

    const location = useLocation()
    const pathName = location.pathname

    const uid = useUIDSeed()
    const navigate = useNavigate()
    return (
        <Box
            component='main'
            className='relative min-h-[calc(100vh-64px)] bg-orange-50 py-2'
        >
            <Navigation>
                <Box className='flex items-center'>
                    <IconButton
                        onClick={() => navigate('/home')}
                        className='mr-1'
                    >
                        <HomeIcon style={{ color: '#faa26b' }} />
                    </IconButton>
                    <Typography
                        variant='h1'
                        className='text-xl font-medium sm:text-2xl'
                    >
                        {pathName === '/visits-inprogress'
                            ? 'Ke zpracování'
                            : 'Vrácené návštěvy'}
                    </Typography>
                </Box>
            </Navigation>
            <Box className='container mx-auto lg:max-w-container'>
                <VisitLengthBar {...props} />
                <SortedVisit
                    isLoading={isVisitLoading}
                    hasEntries={hasEntries}
                    sortedDateVisitCollections={sortedDateVisitCollections}
                    shouldSetMainVisit
                    emptyText={
                        props.officeStateCzech === 'vrácených'
                            ? 'Zdá se, že nemáte žádné vrácené návštěvy k opravě nebo doplnění.'
                            : 'Zdá se, že nemáte žádné návštěvy ke zpracování.'
                    }
                    orderAscending={orderAscending}
                />
                <HelpDial>
                    {({ handleClose, handleActionClick }) => {
                        const arrayAction =
                            props.officeStateCzech === 'vrácených'
                                ? visitsReturnedActions
                                : visitsInProgressActions
                        return arrayAction.map(
                            ({
                                name,
                                icon,
                                modalName,
                            }: HelpSpeedDialActions) => (
                                <SpeedDialAction
                                    key={uid(modalName)}
                                    icon={icon}
                                    tooltipTitle={name}
                                    tooltipPlacement='right'
                                    tooltipOpen
                                    sx={{
                                        '& .MuiSpeedDialAction-staticTooltipLabel':
                                            {
                                                width: 'max-content',
                                            },
                                    }}
                                    onClick={handleActionClick(modalName)}
                                />
                            ),
                        )
                    }}
                </HelpDial>
            </Box>
        </Box>
    )
}
