import { getRestApiConfig } from './../config'
import type { LoginCredentials, LoginResponse } from './types'
import axios from 'axios'
import { AuthUser } from '../../providers/AuthProvider/types'
import { ProfilePhoto } from '../../types'

export const loginUser = async (
    authPayload: LoginCredentials,
): Promise<LoginResponse> => {
    const { baseUrl } = getRestApiConfig()
    const endpointUrl = new URL('/api-mob/authorization/token', baseUrl).href

    const response = await axios.post<LoginResponse>(endpointUrl, {
        ...authPayload,
    })
    return response.data
}

export const getRefreshToken = async (
    token: string,
): Promise<LoginResponse> => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)
    const endpointUrl = new URL('/api-mob/authorization/refresh', baseUrl).href

    const response = await axios.get<LoginResponse>(endpointUrl, headerConfig)
    return response.data
}

export const logoutUser = async (token: string): Promise<LoginResponse> => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)
    const endpointUrl = new URL('/api-mob/authorization/logout', baseUrl).href

    const response = await axios.get<LoginResponse>(endpointUrl, headerConfig)
    return response.data
}

export const getUserInfo = async (token?: string): Promise<AuthUser> => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)
    const endpointUrl = new URL('/api-mob/me', baseUrl).href

    const response = await axios.get<AuthUser>(endpointUrl, headerConfig)
    return response.data
}

export const getUserProfilePhoto = async (
    token?: string,
): Promise<ProfilePhoto> => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)
    const endpointUrl = new URL('/api-mob/ProfilePhoto', baseUrl).href

    const response = await axios.get<ProfilePhoto>(endpointUrl, headerConfig)
    return response.data
}

export const postUserProfilePhoto = async (data: string, token?: string) => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)
    const endpointUrl = new URL('/api-mob/ProfilePhoto', baseUrl).href
    const body = { data }

    return await axios.post(endpointUrl, body, headerConfig)
}

export const deleteUserProfilePhoto = async (token?: string) => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)
    const endpointUrl = new URL('/api-mob/ProfilePhoto', baseUrl).href

    return await axios.delete(endpointUrl, headerConfig)
}
