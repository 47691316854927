import type { FC } from 'react'
import { wrap } from '../../utils'
import type { UseVisitOrderHookProps } from './types'
import { VisitOrderView } from './VisitOrderView'
import { usePage } from './hooks'

export const VisitOrderPage: FC<UseVisitOrderHookProps> = wrap(
    VisitOrderView,
    usePage,
)
