// If you're using @svgr/webpack to import SVGs as React components
import { EPatientState } from '../pages/PatientsPage/types'
import { ReactComponent as PatientActiveIcon } from '../assets/icons/patientActiveState.svg'
import { ReactComponent as PatientDeadIcon } from '../assets/icons/patientDeadState.svg'
import { ReactComponent as PatientEndedIcon } from '../assets/icons/patientEndedState.svg'
import { ReactComponent as PatientHospitalizedIcon } from '../assets/icons/patientHospitalState.svg'
import { ReactComponent as PatientWaitingIcon } from '../assets/icons/patientWaitingState.svg'

export const PatientStateIcon = ({
    state,
}: {
    state: EPatientState
}): JSX.Element | null => {
    switch (state) {
        case 'Waiting':
            return <PatientWaitingIcon />
        case 'Active':
            return <PatientActiveIcon />
        case 'Hospitalized':
            return <PatientHospitalizedIcon />
        case 'Ended':
            return <PatientEndedIcon />
        case 'Dead':
            return <PatientDeadIcon />
        default:
            return null
    }
}
