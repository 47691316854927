import { Skeleton, Stack, Typography } from '@mui/material'
import { AdminPanelSettingsRounded, Error } from '@mui/icons-material'
import ApartmentIcon from '@mui/icons-material/Apartment'
import { SelectItemsCzech, type BulletinTagProps } from '../../types/components'
import { useMemo } from 'react'

export const BulletinTagItem = (props: BulletinTagProps) => {
    const { tag, isLoading, isAdmin } = props

    const badgeItem = useMemo(
        () => ({
            Unknown: {
                color: '#fcc19c',
                icon: null,
            },
            Important: {
                color: '#EE7777',
                text: SelectItemsCzech['Important'],
                icon: <Error className='text-[1.25rem] text-base' />,
            },
            Admin: {
                color: '#388df8',
                text: SelectItemsCzech['Admin'],
                icon: (
                    <AdminPanelSettingsRounded className='text-[1.25rem] text-base' />
                ),
            },
            Fnol: {
                color: '#336199',
                text: SelectItemsCzech['Fnol'],
                icon: <ApartmentIcon className='text-[1.25rem] text-base' />,
            },
        }),
        [],
    )

    return isLoading ? (
        <Skeleton
            height={22}
            width={72}
            variant='rounded'
        />
    ) : tag !== 'Unknown' || isAdmin ? (
        <Stack
            spacing={0.5}
            direction='row'
            className='px- items-center rounded py-[3px] pl-[6px] pr-[5px] text-white-reduced'
            alignSelf='flex-start'
            sx={{
                background: isAdmin
                    ? badgeItem.Admin.color
                    : badgeItem[tag].color,
            }}
        >
            <Typography className='text-xs font-bold leading-4 text-[inherit]'>
                {isAdmin ? badgeItem.Admin.text : SelectItemsCzech[tag]}
            </Typography>
            {isAdmin ? badgeItem.Admin.icon : badgeItem[tag].icon}
        </Stack>
    ) : null
}
