import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import * as yup from 'yup'
import type {
    CarePlan,
    UpdateVisitData,
    UpdateVoucherCarePlan,
    VisitNurseDetails,
} from '../../../types'
import type { VisitFormData, VisitFormProps } from '../types'
import { useParams } from 'react-router-dom'
import { useUpdateVisit, useStornoVisit, useSendVisit } from '../../../api'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { defaultValues } from '../constants'

export const useVisit = (visitData?: VisitNurseDetails): VisitFormProps => {
    const { visitId } = useParams()
    const updateVisitMutation = useUpdateVisit(Number(visitId))
    const stornoVisitMutation = useStornoVisit(Number(visitId))
    const sendVisitMutation = useSendVisit(Number(visitId))

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const acceptEmpty = yup.lazy((value) =>
        value === '' ? yup.string() : yup.number().nullable(),
    )

    const getRequiredNumberField = useCallback(
        (isRequired: boolean | undefined) =>
            !!isRequired ? yup.number().required() : acceptEmpty,
        [acceptEmpty],
    )

    const propName = (name: keyof VisitFormData): string => name
    const validationSchema = yup.object().shape({
        [propName('bloodPressureHigh')]: yup
            .number()
            .when(
                [propName('reasonNonDone'), propName('requiredFields')],
                (reasonNonDone, requiredFields) => {
                    if (
                        (!!reasonNonDone && reasonNonDone.length > 0) ||
                        !requiredFields
                    ) {
                        return yup.number().nullable()
                    } else {
                        return getRequiredNumberField(
                            visitData?.isBloodPressureHighRequired,
                        )
                    }
                },
            ),
        [propName('bloodPressureLow')]: yup
            .number()
            .when(
                [propName('reasonNonDone'), propName('requiredFields')],
                (reasonNonDone, requiredFields) => {
                    if (
                        (!!reasonNonDone && reasonNonDone.length > 0) ||
                        !requiredFields
                    ) {
                        return yup.number().nullable()
                    } else {
                        return getRequiredNumberField(
                            visitData?.isBloodPressureLowRequired,
                        )
                    }
                },
            ),
        [propName('pulse')]: yup
            .number()
            .when(
                [propName('reasonNonDone'), propName('requiredFields')],
                (reasonNonDone, requiredFields) => {
                    if (
                        (!!reasonNonDone && reasonNonDone.length > 0) ||
                        !requiredFields
                    ) {
                        return yup.number().nullable()
                    } else {
                        return getRequiredNumberField(
                            visitData?.isPulseRequired,
                        )
                    }
                },
            ),
        [propName('breathingFreq')]: yup
            .number()
            .when(
                [propName('reasonNonDone'), propName('requiredFields')],
                (reasonNonDone, requiredFields) => {
                    if (
                        (!!reasonNonDone && reasonNonDone.length > 0) ||
                        !requiredFields
                    ) {
                        return yup.number().nullable()
                    } else {
                        return getRequiredNumberField(
                            visitData?.isBreathingFreqRequired,
                        )
                    }
                },
            ),
        [propName('saturation')]: yup
            .number()
            .when(
                [propName('reasonNonDone'), propName('requiredFields')],
                (reasonNonDone, requiredFields) => {
                    if (
                        (!!reasonNonDone && reasonNonDone.length > 0) ||
                        !requiredFields
                    ) {
                        return yup.number().nullable()
                    } else {
                        return getRequiredNumberField(
                            visitData?.isSaturationRequired,
                        )
                    }
                },
            ),
        [propName('temperature')]: yup
            .number()
            .when(
                [propName('reasonNonDone'), propName('requiredFields')],
                (reasonNonDone, requiredFields) => {
                    if (
                        (!!reasonNonDone && reasonNonDone.length > 0) ||
                        !requiredFields
                    ) {
                        return yup.number().nullable()
                    } else {
                        return getRequiredNumberField(
                            visitData?.isTemperatureRequired,
                        )
                    }
                },
            ),
        [propName('glycemia')]: yup
            .number()
            .when(
                [propName('reasonNonDone'), propName('requiredFields')],
                (reasonNonDone, requiredFields) => {
                    if (
                        (!!reasonNonDone && reasonNonDone.length > 0) ||
                        !requiredFields
                    ) {
                        return yup.number().nullable()
                    } else {
                        return getRequiredNumberField(
                            visitData?.isGlycemiaRequired,
                        )
                    }
                },
            ),
        [propName('pain')]: yup
            .number()
            .when(
                [propName('reasonNonDone'), propName('requiredFields')],
                (reasonNonDone, requiredFields) => {
                    if (
                        (!!reasonNonDone && reasonNonDone.length > 0) ||
                        !requiredFields
                    ) {
                        return yup.number().nullable()
                    } else {
                        return getRequiredNumberField(visitData?.isPainRequired)
                    }
                },
            ),
        [propName('subjective')]: yup
            .string()
            .when(
                [propName('reasonNonDone'), propName('requiredFields')],
                (reasonNonDone, requiredFields) => {
                    if (
                        (!!reasonNonDone && reasonNonDone.length > 0) ||
                        !requiredFields
                    ) {
                        return yup.string().nullable()
                    } else {
                        return yup.string().required()
                    }
                },
            ),
        [propName('objective')]: yup
            .string()
            .when(
                [propName('reasonNonDone'), propName('requiredFields')],
                (reasonNonDone, requiredFields) => {
                    if (
                        (!!reasonNonDone && reasonNonDone.length > 0) ||
                        !requiredFields
                    ) {
                        return yup.string().nullable()
                    } else {
                        return yup.string().required()
                    }
                },
            ),
        [propName('aplicationDrugs')]: yup.string().nullable(),
        [propName('usedMaterials')]: yup.string().nullable(),
        [propName('visitNote')]: yup.string().nullable(),
        [propName('note')]: yup.string().nullable(),
        [propName('reasonNonDone')]: yup.string().nullable(),
        [propName('painLocalization')]: yup.string().nullable(),
        [propName('time')]: yup
            .string()
            .when(
                [propName('reasonNonDone'), propName('requiredFields')],
                (reasonNonDone, requiredFields) => {
                    if (
                        (!!reasonNonDone && reasonNonDone.length > 0) ||
                        !requiredFields
                    ) {
                        return yup.string().nullable()
                    } else {
                        return yup.string().required()
                    }
                },
            ),
        [propName('fixVisitTime')]: yup.boolean().nullable(),
        [propName('carePlans')]: yup.array().of(
            yup
                .object()
                .shape({
                    voucherCarePlans: yup
                        .array()
                        .of(
                            yup
                                .object()
                                .shape({
                                    id: yup.number().nullable(),
                                    done: yup.boolean().nullable(),
                                    reasonNonDone: yup
                                        .string()
                                        .when('done', (done) => {
                                            if (!done) {
                                                return yup.string().required()
                                            } else {
                                                return yup.string().nullable()
                                            }
                                        }),
                                    specialCodeID: yup.number().nullable(),
                                    fieldsRequired: yup
                                        .boolean()
                                        .default(false),
                                    isDescription: yup.boolean().nullable(),
                                    isDescriptionNotRequired: yup
                                        .boolean()
                                        .nullable(),
                                    description: yup
                                        .string()
                                        .when(
                                            [
                                                'isDescription',
                                                'isDescriptionNotRequired',
                                                'fieldsRequired',
                                                'done',
                                                'isTypeOf6311',
                                            ],
                                            {
                                                is: (
                                                    isDescription: boolean,
                                                    isDescriptionNotRequired: boolean,
                                                    fieldsRequired: boolean,
                                                    done: boolean,
                                                    isTypeOf6311: boolean,
                                                ) => {
                                                    if (done && isTypeOf6311) {
                                                        return false
                                                    }

                                                    return (
                                                        isDescriptionNotRequired ||
                                                        !isDescription ||
                                                        !fieldsRequired ||
                                                        !done
                                                    )
                                                },

                                                then: yup.string().nullable(),
                                                otherwise: yup
                                                    .string()
                                                    .required(),
                                            },
                                        ),
                                    isLocation: yup.boolean().nullable(),
                                    location: yup
                                        .string()
                                        .when(
                                            [
                                                'isLocation',
                                                'fieldsRequired',
                                                'done',
                                            ],
                                            {
                                                is: (
                                                    isLocation: boolean,
                                                    fieldsRequired: boolean,
                                                    done: boolean,
                                                ) =>
                                                    isLocation &&
                                                    fieldsRequired &&
                                                    done,
                                                then: yup.string().required(),
                                                otherwise: yup
                                                    .string()
                                                    .nullable(),
                                            },
                                        ),
                                    isDrugApplication: yup.boolean().nullable(),
                                    drugApplications: yup
                                        .string()
                                        .when(
                                            [
                                                'isDrugApplication',
                                                'fieldsRequired',
                                                'done',
                                            ],
                                            {
                                                is: (
                                                    isDrugApplication: boolean,
                                                    fieldsRequired: boolean,
                                                    done: boolean,
                                                ) =>
                                                    !isDrugApplication ||
                                                    !fieldsRequired ||
                                                    !done,
                                                then: yup.string().nullable(),
                                                otherwise: yup
                                                    .string()
                                                    .required(),
                                            },
                                        ),
                                    isTypeOf6311: yup.boolean().nullable(),
                                    typeOf6311: yup
                                        .string()
                                        .when(
                                            [
                                                'isTypeOf6311',
                                                propName('requiredFields'),
                                                'done',
                                            ],
                                            {
                                                is: (
                                                    isTypeOf6311: boolean,
                                                    requiredFields: boolean,
                                                    done: boolean,
                                                ) => {
                                                    if (isTypeOf6311 && done) {
                                                        return true
                                                    }
                                                    return (
                                                        isTypeOf6311 &&
                                                        requiredFields &&
                                                        done
                                                    )
                                                },
                                                then: yup.string().required(),
                                                otherwise: yup
                                                    .string()
                                                    .nullable(),
                                            },
                                        ),
                                    isTargetPoint: yup.boolean().nullable(),
                                    targetPointID: yup
                                        .number()
                                        .when(
                                            [
                                                'isTargetPoint',
                                                'fieldsRequired',
                                                'done',
                                            ],
                                            {
                                                is: (
                                                    isTargetPoint: boolean,
                                                    fieldsRequired: boolean,
                                                    done: boolean,
                                                ) =>
                                                    isTargetPoint &&
                                                    fieldsRequired &&
                                                    done,
                                                then: yup.number().required(),
                                                otherwise: yup
                                                    .number()
                                                    .nullable(),
                                            },
                                        ),
                                    diuresis: yup.string().nullable(),
                                })
                                .nullable(),
                        )
                        .nullable(),
                })
                .nullable(),
        ),
        [propName('requiredFields')]: yup.boolean(),
    })

    const formMethods = useForm<VisitFormData>({
        criteriaMode: 'all',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues,
    })

    const carePlanMethods = useFieldArray({
        control: formMethods.control,
        name: 'carePlans',
    })

    const reset = formMethods.reset

    useEffect(() => {
        if (visitData) {
            reset({
                time: visitData.realVisitTime
                    ? moment(visitData.realVisitTime).format('HH:mm')
                    : null,
                bloodPressureHigh:
                    visitData.bloodPressureHigh ?? visitData.bloodPressureHigh,
                bloodPressureLow:
                    visitData.bloodPressureLow ?? visitData.bloodPressureLow,
                pulse: visitData.pulse ?? visitData.pulse,
                breathingFreq:
                    visitData.breathingFreq ?? visitData.breathingFreq,
                saturation: visitData.saturation ?? visitData.saturation,
                temperature: visitData.temperature ?? visitData.temperature,
                glycemia: visitData.glycemia ?? visitData.glycemia,
                pain: visitData.pain ?? visitData.pain,
                subjective: visitData.subjective ?? visitData.subjective,
                objective: visitData.objective ?? visitData.objective,
                aplicationDrugs:
                    visitData.aplicationDrugs ?? visitData.aplicationDrugs,
                usedMaterials:
                    visitData.usedMaterials ?? visitData.usedMaterials,
                visitNote: visitData.visitNote ?? visitData.visitNote,
                note: visitData.note ?? visitData.note,
                reasonNonDone:
                    visitData.reasonNonDone ?? visitData.reasonNonDone,
                painLocalization:
                    visitData.painLocalization ?? visitData.painLocalization,
                requiredFields: false,
                carePlans: visitData?.carePlans?.map((item: CarePlan) => ({
                    ...item,
                })),
            })
        }
    }, [reset, visitData])

    const submitVisitData = useCallback(
        (submitFunction: (data: VisitFormData) => Promise<void>) => {
            if (Object.keys(formMethods.formState.errors).length !== 0) {
                Object.keys(formMethods.formState.errors).forEach((name) => {
                    const errorElement = document.querySelector(
                        `[name="${name}"]`,
                    )
                    if (errorElement) {
                        errorElement.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                            inline: 'nearest',
                        })
                    }
                })
            }

            return formMethods.handleSubmit((data) => submitFunction(data))
        },
        [formMethods],
    )

    const getFilteredData = useCallback((data: VisitFormData) => {
        const keysToFilter = [
            'bloodPressureHigh',
            'bloodPressureLow',
            'pulse',
            'breathingFreq',
            'saturation',
            'temperature',
            'glycemia',
            'pain',
            'subjective',
            'objective',
            'aplicationDrugs',
            'usedMaterials',
            'note',
            'visitNote',
            'nurseNote',
            'carePlans',
            'temperature',
            'time',
            'painLocalization',
            'reasonNonDone',
            'fixVisitTime',
        ]
        const filteredData = {
            ...Object.fromEntries(
                Object.entries(data).filter(([key, value]) =>
                    keysToFilter.includes(key),
                ),
            ),
            carePlans: data?.carePlans?.map((carePlan) => ({
                ...carePlan,
                diagnoseID: carePlan?.diagnose?.id,
                patientDiagnosisID: carePlan?.diagnose?.patientDiagnosisID,
                voucherCarePlans: carePlan?.voucherCarePlans?.map(
                    (voucherCarePlan) => {
                        const {
                            code,
                            name,
                            content,
                            visitTypeName,
                            visitTypeColor,
                            addedVisitLegth,
                            isDescription,
                            isDescriptionNotRequired,
                            descriptionText,
                            isLocation,
                            locationText,
                            isDrugApplication,
                            drugApplicationText,
                            isTypeOf6311,
                            typeOf6311Text,
                            isTargetPoint,
                            targetPointText,
                            targetPointName,
                            targetPoints,
                            fieldsRequired,
                            ...rest
                        } = voucherCarePlan

                        return rest as UpdateVoucherCarePlan
                    },
                ),
                plans: carePlan?.plans?.map((plan) => {
                    const { name, ...rest } = plan
                    return rest
                }),
            })),
        } as UpdateVisitData

        return filteredData
    }, [])

    const updateVisit = async (
        data: VisitFormData,
        showSavedSnackbar: boolean = true,
    ) => {
        const filteredData = getFilteredData(data)
        await updateVisitMutation.mutateAsync({
            data: filteredData,
            showSavedSnackbar,
        })
    }

    const stornoVisit = async ({ reasonNonDone }: VisitFormData) => {
        if (typeof reasonNonDone === 'string' && reasonNonDone?.length > 0)
            updateVisit(formMethods.getValues()).then(
                async () =>
                    await stornoVisitMutation.mutateAsync(reasonNonDone),
            )
    }

    const setRequiredVoucherFieldsValue = useCallback(
        (isRequired: boolean) => {
            formMethods
                .getValues('carePlans')
                ?.forEach((carePlan, carePlanIndex) => {
                    carePlan?.voucherCarePlans?.forEach(
                        (voucherPlan, voucherPlanIndex) =>
                            formMethods.setValue(
                                `carePlans.${carePlanIndex}.voucherCarePlans.${voucherPlanIndex}.fieldsRequired`,
                                isRequired,
                            ),
                    )
                })
        },
        [formMethods],
    )

    const sendVisit = async (data: VisitFormData) => {
        formMethods.setValue('requiredFields', true)
        setRequiredVoucherFieldsValue(true)
        const filteredData = getFilteredData(data)

        const triggerFields = formMethods.trigger([
            'subjective',
            'objective',
            'time',
            'carePlans',
        ])

        triggerFields
            .then(async (isValid) => {
                if (isValid) {
                    const updatedValues = formMethods.getValues()
                    return await updateVisit(updatedValues, false)
                } else {
                    formMethods.setValue('requiredFields', false)
                    setRequiredVoucherFieldsValue(false)
                    enqueueSnackbar(`Povinná pole nevyplněna.`, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        action: (key) => (
                            <IconButton onClick={() => closeSnackbar(key)}>
                                <Close color='secondary' />
                            </IconButton>
                        ),
                    })
                    throw new Error('Required fields are invalid.')
                }
            })
            .then(async () => {
                await (await sendVisitMutation).mutateAsync(filteredData)
                formMethods.setValue('requiredFields', false)
                setRequiredVoucherFieldsValue(false)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const handleUpdateVisit = submitVisitData(updateVisit)
    const handleStornoVisit = submitVisitData(stornoVisit)
    const handleSendVisit = submitVisitData(sendVisit)

    return {
        formMethods,
        handleUpdateVisit,
        handleStornoVisit,
        handleSendVisit,
        carePlanMethods,
    }
}
