import { useState } from 'react'
import { useOpenState } from '../../../hooks'
import { PatientsPageProps, UsePatientsHookProps } from '../types'

export const usePage = (props: UsePatientsHookProps): PatientsPageProps => {
    const newPatientsModalState = useOpenState()
    const [isMultiEditMode, setIsMultiEditMode] = useState(false)

    return { newPatientsModalState, isMultiEditMode, setIsMultiEditMode }
}
