import { Box, Stack, Typography } from '@mui/material'
import { SortedEmptyProps } from '../../types'

export const SortedEmpty = ({ emptyText }: SortedEmptyProps) => {
    return (
        <Stack
            justifyContent='center'
            direction='row'
            spacing={2}
            className='px-5'
        >
            <Typography className='pt-1 text-center text-sm'>
                {emptyText ||
                    `Zdá se, že za tento měsíc nemá pacient ještě žádnou historii
                návštěv`}
            </Typography>
            <Box className='relative w-[21px]'>
                <Box className='h-[2px] w-[100%] rounded bg-gray-300' />
                <Box className='absolute right-0 h-[16px] w-[2px] rounded bg-gray-300' />
            </Box>
        </Stack>
    )
}
