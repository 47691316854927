import { Box, Typography } from '@mui/material'
import { BulletinCarouselProps } from '../../types/components'
import { BulletinCard } from './BulletinCard'
import Carousel from 'react-material-ui-carousel'
import { useNavigate } from 'react-router-dom'
import useResizePage from '../../hooks/useResizePage'
import { useUIDSeed } from 'react-uid'

export const BulletinCarousel = (props: BulletinCarouselProps) => {
    const { isLoading, bulletinList } = props
    const navigate = useNavigate()

    const { isMobile } = useResizePage()

    const uid = useUIDSeed()

    return !isLoading && !bulletinList?.length ? (
        <Box
            onClick={() => navigate('/notifications')}
            className='mb-3 flex h-16 items-center justify-center rounded border border-solid border-gray-400 bg-orange-50'
        >
            <Typography className='text-sm'>
                Zdá se, že nemáte žádné nové notifikace.
            </Typography>
        </Box>
    ) : (
        <Carousel
            autoPlay
            interval={10000}
            navButtonsAlwaysInvisible={isMobile}
            navButtonsAlwaysVisible={false}
            animation='slide'
            sx={{
                '&:hover .MuiIconButton-root[aria-label="Next"], &:hover .MuiIconButton-root[aria-label="Previous"] ':
                    {
                        opacity: 0.6,
                    },
                "& .MuiIconButton-root[aria-label='Previous']:hover": {
                    opacity: '1 !important',
                },
                "& .MuiIconButton-root[aria-label='Next']:hover": {
                    opacity: '1 !important',
                },
            }}
            navButtonsWrapperProps={{
                style: {
                    height: 'auto',
                    top: '40%',
                    paddingBlock: '0.5rem',
                    transform: 'translateY(-50%)',
                },
            }}
            navButtonsProps={{
                style: {
                    color: '#f8f8f8',
                    background: '#f8833b',
                    marginLeft: '1.25rem',
                    marginRight: '1.25rem',
                },
            }}
            indicatorContainerProps={{
                style: {
                    paddingBottom: '12px',
                },
            }}
            indicatorIconButtonProps={{
                style: {
                    color: '#fcc19c',
                },
            }}
            activeIndicatorIconButtonProps={{
                style: {
                    color: '#f8833b',
                },
            }}
        >
            {(isLoading ? Array.from(new Array(3)) : bulletinList)?.map(
                (bulletin) => (
                    <BulletinCard
                        key={uid(bulletin?.id || Math.random())}
                        isLoading={isLoading}
                        className='h-[167px]'
                        notificationHidden
                        stopListInvalidate
                        hideReadButtonDetail
                        {...bulletin}
                    />
                ),
            )}
        </Carousel>
    )
}
