import { useContext } from 'react'
import { useVisitQuery } from '../../../api/visit/hooks'
import { usePageTitle } from '../../../hooks/usePageTitle'
import { AuthContext } from '../../../providers'
import type { OfficePageProps, UseOfficeHookProps } from '../types'
import { StateCzech } from '../../VisitDetailPage/types'
import { useOfficeState } from './useOfficeState'
import { useVisitMonthCollection } from '../../../hooks/useVisitMonthCollection'

export const usePage = (props: UseOfficeHookProps): OfficePageProps => {
    const { stateIn, orderAscending, listType, ...rest } = props
    const { token } = useContext(AuthContext)

    usePageTitle(`Kancelář - ${stateIn?.[0] && StateCzech[stateIn[0]]} `)

    const {
        data: visitData,
        isLoading: isVisitLoading,
        isError: isVisitError,
    } = useVisitQuery(
        { stateIn, orderAscending, ...rest, resultLimit: false },
        token,
        false,
        listType,
    )

    const { officeStateCzech } = useOfficeState({ stateIn })
    const visitCollection = useVisitMonthCollection({
        collectionData: visitData?.data,
        isVisitLoading,
        orderAscending,
    })

    return {
        visitData,
        isVisitLoading,
        isVisitError,
        officeStateCzech,
        ...visitCollection,
    }
}
