import { useQueryClient } from '@tanstack/react-query'
import { useOpenState } from '../../../hooks'
import {
    BulletinCardProps,
    UseBulletinCardHookProps,
} from '../../../types/components'
import { getNotification } from '../../../api/notifications/services'
import { useContext } from 'react'
import { AuthContext, BulletinFiltersContext } from '../../../providers'

export const useBulletinCard = (
    props: UseBulletinCardHookProps,
): BulletinCardProps => {
    const { id, isLoading, stopListInvalidate } = props
    const { token } = useContext(AuthContext)
    const { bulletinQueryFilters } = useContext(BulletinFiltersContext)

    const { isOpen, handleClose, handleOpen } = useOpenState()
    const queryClient = useQueryClient()

    const handleSetQueryMessageData = async () => {
        await queryClient.prefetchQuery(['notifications', id], () =>
            getNotification(token, String(id)),
        )
        queryClient.invalidateQueries(['notifications', 'count'])
        if (!stopListInvalidate) {
            queryClient.invalidateQueries(
                ['notifications', bulletinQueryFilters || {}],
                { exact: true },
            )
        }
    }

    const onCardClick = () => {
        if (!isLoading) {
            handleOpen()
            handleSetQueryMessageData()
        }
    }

    return {
        isOpen,
        handleClose,
        handleOpen,
        onCardClick,
        ...props,
    }
}
