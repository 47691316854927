import { FC, useCallback, useEffect } from 'react'
import { wrap } from '../../../utils'
import { useAddPatientAnamnesisFormStep12 } from '../hooks/useAddPatientAnamnesisFormStep12'
import {
    AddPatientsAnamnesisFormStep12Hook,
    IAddPatientAnamnesisFormStep12,
} from '../types'
import { Stack, InputLabel, CircularProgress, Box } from '@mui/material'
import { AnamnesisFormFooter } from './AnamnesisFormFooter'
import YesNoRadioGroup from '../../../components/YesNoRadioGroup'
import MultipleChoiceRadioGroup from '../../../components/MultipleChoicesRadioGroup'
import { ePainTypeOptions } from '../../../components/options'
import CheckboxStyled from '../../../components/CheckboxStyled'
import InputStyled from '../../../components/InputStyled'

const AddPatientAnamnesisFormStep12View = (
    props: IAddPatientAnamnesisFormStep12,
) => {
    const {
        submitForm,
        formMethods,
        setCurrentStep,
        currentStep,
        setSaveCurrentStepOnClose,
        isLoading,
        canEditCareAnamnesis,
    } = props

    const {
        control,
        handleSubmit,
        watch,
        getValues,
        formState: { errors, isSubmitting },
    } = formMethods

    const isPain = watch('IsPain')
    const isPainReliefAnalgesics = watch('IsPainReliefAnalgesics')
    const isPainReliefReliefOther = watch('IsPainReliefReliefOther')

    const handleSubmitWrapper = useCallback(
        async (isNext?: boolean) => {
            const formData = getValues()
            await submitForm(formData, isNext ? isNext : false)
        },
        [getValues, submitForm],
    )
    useEffect(() => {
        setSaveCurrentStepOnClose(() => handleSubmitWrapper)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSaveCurrentStepOnClose])

    if (isLoading) {
        return (
            <Box className='!mt-12 flex justify-center'>
                <CircularProgress />
            </Box>
        )
    }
    return (
        <form
            onSubmit={() => handleSubmitWrapper(true)}
            className='grid gap-3'
        >
            <YesNoRadioGroup
                control={control}
                name='IsPain'
                readOnly={!canEditCareAnamnesis}
                disabled={isSubmitting}
                label='Bolest'
            />

            {isPain && (
                <MultipleChoiceRadioGroup
                    control={control}
                    label=''
                    name='PainType'
                    readOnly={!canEditCareAnamnesis}
                    options={ePainTypeOptions}
                    isSubmitting={isSubmitting}
                />
            )}

            <Stack spacing={1}>
                <InputLabel className='text-sm font-semibold'>
                    Intenzita bolesti
                </InputLabel>

                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsPainIntensityTypeSteady'
                    label='stálá'
                />
                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='IsPainIntensityTypeUnsteady'
                    label='nestálá'
                />
                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='IsPainIntensityTypeBearable'
                    label='snesitelná'
                />
                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='IsPainIntensityTypeUnbearable'
                    label='nesnesitelná'
                />

                <div style={{ marginBlock: '0.5rem' }}></div>

                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsIntensityOfPainDisturbsSleep'
                    label='bolest ruší spánek'
                />
                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='IsIntensityOfPainPreventsRest'
                    label='brání v odpočinku'
                />
                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='IsIntensityOfPainOnlyWhenMoving'
                    label='jen při pohybu'
                />
                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='IsIntensityOfPainNotRestrict'
                    label='prakticky neomezuje'
                />
            </Stack>

            <InputStyled
                control={control}
                disabled={isSubmitting}
                name='IntensityOfPainValue'
                readOnly={!canEditCareAnamnesis}
                errors={errors}
                type='number'
                max={10}
                min={1}
                positiveOnly
                label='Stupeň bolesti od 1–10:'
                placeholder='stupeň bolesti'
            />

            <Stack spacing={1}>
                <InputLabel className='text-sm font-semibold'>
                    Charakteristika bolesti
                </InputLabel>
                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsCharacteristicsOfPainDull'
                    label='tupá'
                />
                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='IsCharacteristicsOfPainPrickly'
                    label='bodavá'
                />
                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='IsCharacteristicsOfPainCrampy'
                    label='křečovitá'
                />
                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='IsCharacteristicsOfPainFiring'
                    label='vystřelující'
                />
                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsCharacteristicsOfPainClutching'
                    label='svíravá'
                />
                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsCharacteristicsOfPainColicky'
                    label='kolikovitá'
                />
                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsCharacteristicsOfPainNeuralgic'
                    label='neuralgická'
                />
                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsCharacteristicsOfPainIndefinite'
                    label='neurčitá'
                />
            </Stack>

            <InputStyled
                control={control}
                disabled={isSubmitting}
                readOnly={!canEditCareAnamnesis}
                name='PainLocalization'
                errors={errors}
                label='Lokalizace bolesti:'
                placeholder='lokalizaci bolesti'
            />

            <Stack spacing={1}>
                <InputLabel className='text-sm font-semibold'>
                    Tišení bolesti
                </InputLabel>
                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsPainReliefAnalgesics'
                    label='analgetika'
                />
                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsPainReliefReliefPosition'
                    label='úlevová poloha'
                />
                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='IsPainReliefReliefRelaxationTechniques'
                    label='relaxační techniky'
                />
                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='IsPainReliefReliefPressureMassage'
                    label='tlakové masáže'
                />
                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsPainReliefReliefWarmCompress'
                    label='teplé obklady'
                />
                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='IsPainReliefReliefColdCompress'
                    label='studené obklady'
                />
                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsPainReliefReliefOther'
                    label='jiné'
                />
                {isPainReliefAnalgesics && (
                    <InputStyled
                        control={control}
                        disabled={isSubmitting}
                        readOnly={!canEditCareAnamnesis}
                        name='Analgesics'
                        errors={errors}
                        label='Dávka, způsob podání analgetik:'
                        placeholder='dávku, způsob podání analgetik'
                    />
                )}

                {isPainReliefReliefOther && (
                    <InputStyled
                        control={control}
                        disabled={isSubmitting}
                        readOnly={!canEditCareAnamnesis}
                        name='PainReliefOther'
                        errors={errors}
                        label='Jiné:'
                        placeholder='jiné'
                    />
                )}
            </Stack>

            <Stack spacing={2}>
                <AnamnesisFormFooter
                    currentStep={currentStep}
                    canEditCareAnamnesis={canEditCareAnamnesis}
                    disabled={isLoading || isSubmitting}
                    handleSubmitWrapper={handleSubmitWrapper}
                    setCurrentStep={setCurrentStep}
                />
            </Stack>
        </form>
    )
}

export const AddPatientAnamnesisFormStep12: FC<AddPatientsAnamnesisFormStep12Hook> =
    wrap(AddPatientAnamnesisFormStep12View, useAddPatientAnamnesisFormStep12)
