import { useAddPatientModalForm } from '../hooks/useAddPatientModalForm'
import Modal from '../../../components/Modal'
import { UseHelpModalProps } from '../../../components/HelpDial/types'
import { Button, Stack } from '@mui/material'

import DateInputStyled from '../../../components/DateInput'
import InputStyled from '../../../components/InputStyled'

export default function AddPatientModalForm(props: UseHelpModalProps) {
    const { formMethods, submitMessage } = useAddPatientModalForm({
        handleClose: props.handleClose,
    })

    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = formMethods

    return (
        <Modal
            {...props}
            title='Přidání pacienta'
        >
            <Stack>
                <form
                    onSubmit={handleSubmit(submitMessage)}
                    className='flex flex-col gap-2'
                >
                    <Stack>
                        <Stack spacing={2}>
                            <InputStyled
                                control={control}
                                disabled={isSubmitting}
                                name='firstname'
                                errors={errors}
                                empty='Zde zadejte jméno pacienta'
                                label='Jméno'
                                placeholder=''
                            />
                            <InputStyled
                                control={control}
                                disabled={isSubmitting}
                                name='surname'
                                errors={errors}
                                empty='Zde zadejte příjmení pacienta'
                                label='Příjmení'
                                placeholder=''
                            />

                            <DateInputStyled
                                control={control}
                                disabled={isSubmitting}
                                name='dateOfBirth'
                                errors={errors}
                                label='Datum narození'
                            />
                            <InputStyled
                                control={control}
                                disabled={isSubmitting}
                                name='insuranceNumber'
                                errors={errors}
                                empty='Zde zadejte rodné číslo'
                                label='Rodné číslo'
                                placeholder=''
                            />
                        </Stack>

                        <Button
                            color='primary'
                            variant='contained'
                            className='ml-auto mt-5 normal-case '
                            size='small'
                            disabled={isSubmitting}
                            type='submit'
                        >
                            Odeslat
                        </Button>
                    </Stack>
                </form>
            </Stack>
        </Modal>
    )
}
