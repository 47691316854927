import { RefObject, useEffect, useState } from 'react'

interface ObserverConfig {
    ref: RefObject<any>
    options?: IntersectionObserverInit
}

export const useIntersectionObserver = ({ ref, options }: ObserverConfig) => {
    const [isIntersecting, setIsIntersecting] = useState<boolean>(false)

    useEffect(() => {
        const element = ref.current

        if (!element) return

        if (element) {
            const observer = new IntersectionObserver(([entry]) => {
                setIsIntersecting(entry.isIntersecting)
            }, options)

            observer.observe(element)

            return () => observer.unobserve(element)
        }
    }, [ref, options])

    return isIntersecting
}
