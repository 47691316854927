import {
    Controller,
    FieldValues,
    Path,
    FieldErrors,
    Control,
} from 'react-hook-form'
import { InputLabel, Stack, TextField, Typography } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import csLocale from 'date-fns/locale/cs'
import { get } from 'lodash'
import { parse } from 'date-fns'

interface DateInputStyledProps<T extends FieldValues> {
    name: Path<T>
    control: Control<T>
    label: string
    disabled: boolean
    errors: FieldErrors<T>
    readOnly?: boolean
}

const DateInputStyled = <T extends FieldValues>({
    name,
    control,
    label,
    disabled,
    errors,
    readOnly,
}: DateInputStyledProps<T>) => {
    const errorMessage = get(errors, name)?.message
        ? String(get(errors, name)?.message)
        : ''

    return (
        <Stack spacing={1}>
            <InputLabel className='text-sm'>{label}</InputLabel>
            <Controller
                control={control}
                name={name}
                render={({ field: { onChange, value } }) => {
                    const dateValue =
                        typeof value === 'string'
                            ? parse(value, 'dd.MM.yyyy', new Date()) // Převod řetězce na objekt Date
                            : value
                    return (
                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={csLocale}
                        >
                            <DatePicker
                                value={dateValue}
                                onChange={onChange}
                                label={label}
                                readOnly={readOnly}
                                disabled={disabled}
                                inputFormat='dd.MM.yyyy'
                                mask='__.__.____' // Maska pro zadávání dat
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={''}
                                        size='small'
                                        error={!!get(errors, name)}
                                        sx={{
                                            backgroundColor: 'white',
                                            '.MuiOutlinedInput-root': {
                                                fontSize: '0.875rem',
                                            },
                                            '.MuiButtonBase-root': {
                                                padding: '0.25rem',
                                                marginRight: '0rem',
                                            },
                                            '.MuiSvgIcon-root': {
                                                fontSize: '1.25rem',
                                            },
                                            '.MuiPickersToolbar-root': {
                                                display: 'none',
                                            },
                                            marginBottom: 2,
                                        }}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    )
                }}
            />
            {errorMessage && (
                <Typography className='pt-1 text-sm text-error-2'>
                    {errorMessage}
                </Typography>
            )}
        </Stack>
    )
}

export default DateInputStyled
