import { Dispatch, SetStateAction, useContext, useState } from 'react'
import { usePageTitle } from '../../../hooks/usePageTitle'
import { AdminHookProps, AdminPageProps } from '../types'
import { AuthContext } from '../../../providers'
import {
    useOrganizationUsersQuery,
    useOrganizationsQuery,
    useReadonlyResponseQuery,
} from '../../../api/admin'
import { SelectChangeEvent } from '@mui/material/Select'

export const usePage = (props: AdminHookProps): AdminPageProps => {
    usePageTitle('Administrace')
    const [organizationId, setOrganizationId] = useState<number>()
    const [userId, setUserId] = useState<number>()

    const { token } = useContext(AuthContext)

    const { organizations, organizationsLoading, organizationsError } =
        useOrganizationsQuery(token)
    const { users, usersLoading, usersError } = useOrganizationUsersQuery(
        organizationId,
        token,
    )
    const { readonlyResponse, readonlyResponseLoading, readonlyResponseError } =
        useReadonlyResponseQuery(userId, token)

    const handleIdChange = (
        event: SelectChangeEvent,
        setFunction: Dispatch<SetStateAction<number | undefined>>,
    ) => {
        setFunction(Number(event.target.value as string))
    }

    const handleReadonlyLogin = () => {
        if (!readonlyResponse?.accessToken) {
            return
        }

        localStorage.setItem('token', readonlyResponse.accessToken)
        return (window.location.href = '/home')
    }

    return {
        organizationId,
        userId,
        setOrganizationId,
        setUserId,
        handleIdChange,
        organizations,
        organizationsLoading,
        organizationsError,
        users,
        usersLoading,
        usersError,
        readonlyResponse,
        readonlyResponseLoading,
        readonlyResponseError,
        handleReadonlyLogin,
    }
}
