import { CircularProgress, Stack, Typography } from '@mui/material'
import { ContentLoaderProps } from '../types'

export const ContentLoader = ({ loadingText }: ContentLoaderProps) => {
    return (
        <Stack
            py={6}
            textAlign='center'
            alignItems='center'
            spacing={3}
        >
            <Typography>{loadingText || 'Načítáme obsah'}</Typography>
            <CircularProgress sx={{ marginX: 'auto' }} />
        </Stack>
    )
}
