import { Avatar, Box, Skeleton, Stack, Typography, colors } from '@mui/material'
import {
    BulletinCardProps,
    UseBulletinCardHookProps,
} from '../../types/components'
import { FC, useMemo } from 'react'
import { calculateTime } from '../../helpers/date'
import { twMerge } from 'tailwind-merge'
import { BulletinDetailModal } from './BulletinDetailModal'
import { wrap } from '../../utils'
import { useBulletinCard } from './hooks'
import { BulletinTagItem } from './BulletinTagItem'
import ArchiveIcon from '@mui/icons-material/Archive'

export const BulletinCardView = (props: BulletinCardProps) => {
    const {
        id,
        user,
        dateCreated,
        title,
        message,
        tag,
        isReaded,
        isArchived,
        badgeColor = '#f8833b',
        notificationHidden = false,
        isLoading = false,
        className,
        onCardClick,
        isOpen,
        stopListInvalidate,
        isAdmin,
        hideReadButtonDetail,
        ...elementProps
    } = props

    const creationTime = useMemo(() => {
        return calculateTime(dateCreated)
    }, [dateCreated])

    const presetBadgeColor = useMemo(() => {
        switch (tag) {
            case 'Important':
                return colors.red[400]
            case 'Admin':
                return '#388df8'
            case 'Unknown':
                return '#fcc19c'
            default:
                return '#f8833b'
        }
    }, [tag])

    return (
        <>
            {isOpen && (
                <BulletinDetailModal
                    open={isOpen}
                    badgeColor={presetBadgeColor}
                    stopListInvalidate={stopListInvalidate}
                    hideReadButtonDetail={hideReadButtonDetail}
                    {...props}
                />
            )}
            <Stack
                className={twMerge(
                    'bg-white relative cursor-pointer gap-4 rounded border border-solid border-orange-400 p-3',
                    isLoading && 'cursor-not-allowed',
                    isAdmin && 'border-blue-300',
                    className,
                )}
                onClick={onCardClick}
                {...elementProps}
            >
                {!isReaded && !notificationHidden && !isLoading && (
                    <>
                        <span className='absolute -right-[0.375rem] -top-[0.375rem] h-3 w-3 animate-ping rounded-full bg-error-2 outline outline-1 outline-error-1'></span>
                        <span className='absolute -right-[0.375rem] -top-[0.375rem] h-3 w-3 rounded-full bg-error-2 outline outline-1 outline-error-1'></span>
                    </>
                )}
                <Stack
                    className='flex-wrap gap-2'
                    direction='row'
                    justifyContent='space-between'
                >
                    <Stack
                        direction='row'
                        spacing={1}
                    >
                        {isLoading ? (
                            <Skeleton
                                animation='wave'
                                variant='circular'
                                width={40}
                                height={40}
                            />
                        ) : (
                            <Avatar
                                className='bg-orange-300 text-base'
                                src={user.icon || ''}
                                alt={user.fullName || ''}
                            >
                                {!user?.icon?.trim() &&
                                    user?.fullName &&
                                    (user?.fullName.split(' ').length === 2
                                        ? `${
                                              user?.fullName.split(' ')[0]?.[0]
                                          }${user?.fullName.split(' ')[1]?.[0]}`
                                        : user?.fullName[0])}
                            </Avatar>
                        )}
                        <Stack>
                            {isLoading ? (
                                <Skeleton
                                    width={160}
                                    variant='text'
                                    sx={{ fontSize: '0.875rem' }}
                                />
                            ) : (
                                <Typography className='text-sm font-semibold leading-[auto]'>
                                    {user.fullName}
                                </Typography>
                            )}
                            {isLoading ? (
                                <Stack
                                    direction='row'
                                    gap={1}
                                >
                                    <Skeleton
                                        height={16}
                                        width={70}
                                    />
                                    <Skeleton
                                        height={16}
                                        width={50}
                                    />
                                </Stack>
                            ) : (
                                <Typography className='text-xs font-light text-gray-500'>
                                    <span className='pr-1'>{user.role}</span>·
                                    <span className='pl-1'>{creationTime}</span>
                                </Typography>
                            )}
                        </Stack>
                    </Stack>
                    <Box className='flex gap-2'>
                        <BulletinTagItem
                            tag={tag}
                            badgeColor={badgeColor}
                            isLoading={isLoading}
                            isAdmin={isAdmin}
                        />
                        {isArchived && (
                            <Stack
                                spacing={0.5}
                                direction='row'
                                className='px- items-center rounded py-[3px] pl-[6px] pr-[5px] text-white-reduced'
                                alignSelf='flex-start'
                                sx={{
                                    background: '#FFC107',
                                }}
                            >
                                <ArchiveIcon className='text-[1.25rem] text-base' />
                            </Stack>
                        )}
                    </Box>
                </Stack>
                <Stack className='gap-1'>
                    {isLoading ? (
                        <Skeleton
                            width={160}
                            variant='text'
                            sx={{ fontSize: '0.875rem', lineHeight: '1.25rem' }}
                        />
                    ) : (
                        <Typography
                            component='h3'
                            className='line-clamp-1 text-sm font-semibold'
                        >
                            {title}
                        </Typography>
                    )}
                    {isLoading ? (
                        <Skeleton
                            width={'100%'}
                            height={64}
                            variant='rounded'
                        />
                    ) : (
                        <Typography
                            className='line-clamp-3 whitespace-pre-line text-sm'
                            component='p'
                        >
                            {message}
                        </Typography>
                    )}
                </Stack>
            </Stack>
        </>
    )
}

export const BulletinCard: FC<UseBulletinCardHookProps> = wrap(
    BulletinCardView,
    useBulletinCard,
)
