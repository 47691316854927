import Modal from '../Modal'
import {
    Box,
    InputLabel,
    Button,
    OutlinedInput,
    Typography,
    Avatar,
    Chip,
    Stack,
} from '@mui/material'
import { BulletinPatientsModalProps } from './types'
import { useBulletinPatientSelect } from './hooks/hooks'
import { uid } from 'react-uid'

function BulletinPatientsModal({
    setValue,
    preselectedPatients,
    isOpen,
    handleClose,
    triggerValidation,
    isFnol,
}: BulletinPatientsModalProps) {
    const {
        selectedPatients,
        setSelectedPatients,
        patients,
        handleUpdateActualPatients,
        personalNumber,
        setName,
        isLoadingData,
        name,
        setPersonalNumber,
        handleChangePersonalNumber,
        handleAddNewPatient,
    } = useBulletinPatientSelect({
        preselectedPatients,
        isOpen,
        setValue,
        triggerValidation,
        handleClose,
    })

    return (
        <Modal
            handleClose={handleClose}
            open={isOpen}
            closeButton={false}
            title='Vyhledávání pacienta'
        >
            <Box className='flex h-full flex-col justify-between'>
                <Box className='mb-6 flex gap-2'>
                    <Box className='w-1/2'>
                        <InputLabel className='text-sm leading-relaxed'>
                            Jméno pacienta
                        </InputLabel>
                        <OutlinedInput
                            size='small'
                            className='w-full bg-white-reduced'
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            disabled={isFnol && selectedPatients.length > 0}
                        ></OutlinedInput>
                    </Box>
                    <Box className='w-1/2'>
                        <InputLabel className='text-sm leading-relaxed'>
                            Rodné číslo
                        </InputLabel>
                        <OutlinedInput
                            size='small'
                            className='w-full bg-white-reduced'
                            onChange={handleChangePersonalNumber}
                            value={personalNumber}
                            disabled={isFnol && selectedPatients.length > 0}
                            placeholder='000000/0000'
                        ></OutlinedInput>
                    </Box>
                </Box>

                {!isLoadingData &&
                    patients.length === 0 &&
                    (name.length > 0 || personalNumber.length > 0) && (
                        <Typography className='text-center text-sm text-[#333]'>
                            Nenalezeni žádní pacienti
                        </Typography>
                    )}

                {(name.length > 0 || personalNumber.length > 0) &&
                    (patients && patients.length) > 0 && (
                        <Box>
                            <Typography className='mb-[0.625rem] text-[12px]'>
                                Výsledky vyhledávání
                            </Typography>
                            <Box className='flex flex-col gap-2'>
                                {patients.map((patient) => {
                                    return (
                                        <Stack
                                            key={uid(patient.id)}
                                            className='flex flex-row flex-wrap gap-1'
                                        >
                                            <Chip
                                                disabled={
                                                    selectedPatients.some(
                                                        (selectedPatient) =>
                                                            selectedPatient.id ===
                                                            patient.id,
                                                    ) ||
                                                    (isFnol &&
                                                        selectedPatients.length >
                                                            0)
                                                }
                                                sx={{
                                                    '&:hover': {
                                                        backgroundColor:
                                                            '#33333314',
                                                    },
                                                }}
                                                label={`${patient.fullName} | ${patient.personalNumber}`}
                                                onClick={() => {
                                                    setSelectedPatients(
                                                        (prev) => [
                                                            ...prev,
                                                            patient,
                                                        ],
                                                    )

                                                    setPersonalNumber('')
                                                }}
                                                color='primary'
                                                style={{}}
                                                className={
                                                    'text-white bg-[#33333314] text-[#333333de]'
                                                }
                                                avatar={
                                                    <Avatar
                                                        style={{
                                                            backgroundColor:
                                                                '#BDBDBD',
                                                        }}
                                                        className='text-base'
                                                    >
                                                        {
                                                            patient.fullName?.split(
                                                                ' ',
                                                            )[0][0]
                                                        }
                                                        {
                                                            patient.fullName?.split(
                                                                ' ',
                                                            )[1][0]
                                                        }
                                                    </Avatar>
                                                }
                                            />
                                        </Stack>
                                    )
                                })}
                            </Box>
                        </Box>
                    )}

                {/* DOČASNĚ PRÝ NEPOTŘEBUJEME, AVŠAK PŘIPRAVENO K NASAZENO */}
                {/*
                
                 {(name.length > 0 || personalNumber.length > 0) && !isFnol && (
                    <Box className='mt-6 flex flex-col justify-center gap-1'>
                        <Typography className='text-center text-sm'>
                            nenašli jste pacienta v kartotéce?
                        </Typography>
                        <Button
                            className='text-t mx-auto text-[13px] lowercase'
                            onClick={handleAddNewPatient}
                        >
                            přidat pacienta mimo kartotéku
                        </Button>
                    </Box>
                )}
                
                */}
                <InputLabel className='mb-[10px] mt-[1.56rem] text-sm font-normal'>
                    aktuální výběr
                </InputLabel>
                <Box className='flex max-h-[150px] min-h-[60px] flex-row flex-wrap gap-2 overflow-y-auto rounded-[6px] border border-solid border-[#bfbfbf] bg-white-reduced p-[6px] text-sm'>
                    {selectedPatients.length > 0 &&
                        selectedPatients.map((item) => {
                            return (
                                <Chip
                                    key={uid(
                                        `${item.id}${item.fullName}${item.personalNumber}`,
                                    )}
                                    label={
                                        item.id === 0
                                            ? `${item.fullName} | ${item.personalNumber}`
                                            : item.fullName
                                    }
                                    color='primary'
                                    sx={{
                                        '& .MuiChip-deleteIcon': {
                                            color: '#BDBDBD',
                                        },
                                        '&:hover': {
                                            backgroundColor: '#33333314',
                                        },
                                    }}
                                    className={
                                        'bg-primary text-white  bg-[#33333314] text-[#333333de]'
                                    }
                                    avatar={
                                        item.fullName?.split(' ').length > 1 &&
                                        item.id !== 0 ? (
                                            <Avatar
                                                style={{
                                                    backgroundColor: '#BDBDBD',
                                                }}
                                                className='text-base'
                                                src={''}
                                                alt={item.fullName || ''}
                                            >
                                                {
                                                    item.fullName?.split(
                                                        ' ',
                                                    )[0][0]
                                                }
                                                {
                                                    item.fullName?.split(
                                                        ' ',
                                                    )[1][0]
                                                }
                                            </Avatar>
                                        ) : undefined
                                    }
                                    onClick={() =>
                                        setSelectedPatients((prev) =>
                                            prev.filter(
                                                (user) => user.id !== item.id,
                                            ),
                                        )
                                    }
                                    onDelete={() => {
                                        setSelectedPatients((prev) =>
                                            prev.filter(
                                                (user) => user.id !== item.id,
                                            ),
                                        )
                                    }}
                                />
                            )
                        })}
                </Box>
                <Box className='mt-20 flex justify-end gap-2'>
                    <Button
                        type='button'
                        variant='outlined'
                        size='small'
                        className='bg-white-reduced text-sm font-bold normal-case shadow-none'
                        onClick={handleClose}
                    >
                        zrušit
                    </Button>
                    <Button
                        variant='contained'
                        size='small'
                        type='button'
                        className='text-sm font-bold normal-case shadow-none'
                        onClick={handleUpdateActualPatients}
                    >
                        uložit a zavřít
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default BulletinPatientsModal
