import type { ChangeEvent } from 'react'
import {
    useUserPhotoDeleteMutation,
    useUserPhotoPostMutation,
} from '../../../api/user/hooks'
import type { ProfilePhotoHandles } from '../types'

export const usePhotoMutation = (): ProfilePhotoHandles => {
    const { mutateAsync: uploadMutation, isLoading: photoUploadLoading } =
        useUserPhotoPostMutation()

    const { mutateAsync: deleteMutation, isLoading: photoDeleteLoading } =
        useUserPhotoDeleteMutation()

    const handlePhotoUpload = async (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0]
        if (!file) return

        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = async () => {
            await uploadMutation(reader.result as string)
        }
    }

    const handlePhotoDelete = async () => {
        await deleteMutation()
    }

    return {
        handlePhotoUpload,
        photoUploadLoading,
        handlePhotoDelete,
        photoDeleteLoading,
    }
}
