import { MutableRefObject, useCallback, useEffect, useRef } from 'react'
import type { NearestElement, NearestElementHookProps } from '../types'
import moment from 'moment'

export const useNearestElement = (
    props: NearestElementHookProps,
): NearestElement => {
    const { sortedDateVisitCollections, isAvailable = false } = props

    const nearestDateElement = useRef(
        null,
    ) as MutableRefObject<HTMLDivElement | null>

    const currentDate = moment().format('MM/DD/YYYY')

    const findNearestDateElements = useCallback(() => {
        const dates = Object.keys(sortedDateVisitCollections)
        let nearestDates = [dates[0]]
        let minDiff = Math.abs(
            moment(currentDate, 'MM/DD/YYYY').diff(
                moment(nearestDates[0], 'MM/DD/YYYY'),
                'days',
            ),
        )

        dates.forEach((date) => {
            const diff = Math.abs(
                moment(currentDate, 'MM/DD/YYYY').diff(
                    moment(date, 'MM/DD/YYYY'),
                    'days',
                ),
            )
            if (diff < minDiff) {
                minDiff = diff
                nearestDates = [date]
            } else if (diff === minDiff) {
                nearestDates.push(date)
            }
        })

        return nearestDates
    }, [currentDate, sortedDateVisitCollections])

    const nearestDates = findNearestDateElements()

    useEffect(() => {
        if (nearestDateElement.current && isAvailable) {
            nearestDateElement.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            })
        }
    }, [isAvailable, nearestDates])

    return {
        nearestDateElement,
        nearestDates,
    }
}
