import React from 'react'
import { Controller, Control, FieldValues, Path } from 'react-hook-form'
import { Checkbox, FormControlLabel } from '@mui/material'

// Define the types for the component props with generics
interface CheckboxStyledProps<T extends FieldValues> {
    name: Path<T> // Ensures the name is a valid key of the form data
    control: Control<T> // Generic form control tied to the form data type
    label: string
    readOnly?: boolean
    disabled?: boolean
}

const CheckboxStyled = <T extends FieldValues>({
    name,
    control,
    label,
    readOnly,
    disabled,
}: CheckboxStyledProps<T>) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <FormControlLabel
                    control={
                        <Checkbox
                            {...field}
                            disabled={disabled || readOnly}
                            sx={{
                                padding: '2px',
                                transform: 'scale(0.8)', // Make checkbox smaller
                            }}
                            checked={field.value}
                        />
                    }
                    label={<span style={{ fontSize: '1rem' }}>{label}</span>} // Dynamic label
                    sx={{
                        width: 'fit-content',
                        margin: '0',
                    }}
                />
            )}
        />
    )
}

export default CheckboxStyled
