import moment from 'moment'
import { useState } from 'react'

export const useMonthHandle = () => {
    const [activeMonthDate, setActiveMonthDate] = useState<Date | null>(
        new Date(),
    )

    const handleDateChange = (newValue: Date | null) =>
        setActiveMonthDate(newValue)

    const isntFuture = (date: Date) => moment(date).isSame(moment(), 'month')

    const handleSubtractMonth = () => {
        setActiveMonthDate((prevValue) =>
            moment(prevValue).subtract(1, 'month').toDate(),
        )
    }

    const handleAddMonth = ({ disableFuture = true }) => {
        setActiveMonthDate((prevValue) =>
            isntFuture(moment(prevValue).toDate()) && !!disableFuture
                ? prevValue
                : moment(prevValue).add(1, 'month').toDate(),
        )
    }

    const firstDayOfMonth = (date: Date) =>
        moment(date).startOf('month').format('YYYY-MM-DD')
    const lastDayOfMonth = (date: Date, disableFuture = true) =>
        disableFuture
            ? isntFuture(date)
                ? moment(date).format('YYYY-MM-DD')
                : moment(date).endOf('month').format('YYYY-MM-DD')
            : moment(date).endOf('month').format('YYYY-MM-DD')

    return {
        activeMonthDate,
        handleDateChange,
        handleSubtractMonth,
        handleAddMonth,
        firstDayOfMonth,
        lastDayOfMonth,
    }
}
