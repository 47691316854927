import { FC, useCallback, useEffect } from 'react'
import { wrap } from '../../../utils'
import { useAddPatientAnamnesisFormStep4 } from '../hooks/useAddPatientAnamnesisFormStep4'
import {
    AddPatientsAnamnesisFormStep4Hook,
    IAddPatientAnamnesisFormStep4,
} from '../types'
import { Stack, InputLabel, CircularProgress, Box } from '@mui/material'
import { AnamnesisFormFooter } from './AnamnesisFormFooter'
import YesNoRadioGroup from '../../../components/YesNoRadioGroup'
import InputStyled from '../../../components/InputStyled'
import CheckboxStyled from '../../../components/CheckboxStyled'

const AddPatientAnamnesisFormStep4View = (
    props: IAddPatientAnamnesisFormStep4,
) => {
    const {
        submitForm,
        formMethods,
        setCurrentStep,
        currentStep,
        isLoading,
        setSaveCurrentStepOnClose,
        canEditCareAnamnesis,
    } = props

    const {
        control,
        handleSubmit,
        watch,
        getValues,
        formState: { errors, isSubmitting },
    } = formMethods

    const isHypnotics = watch('IsHypnotics')
    const isMotionModeOther = watch('IsMotionModeOther')
    const isMissingBodyPart = watch('IsMissingBodyPart')
    const isCompensatoryAids = watch('IsCompensatoryAids')

    const handleSubmitWrapper = useCallback(
        async (isNext?: boolean) => {
            const formData = getValues()
            await submitForm(formData, isNext ? isNext : false)
        },
        [getValues, submitForm],
    )
    useEffect(() => {
        setSaveCurrentStepOnClose(() => handleSubmitWrapper)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSaveCurrentStepOnClose])

    if (isLoading) {
        return (
            <Box className='!mt-12 flex justify-center'>
                <CircularProgress />
            </Box>
        )
    }
    return (
        <form
            onSubmit={() => handleSubmitWrapper(true)}
            className='grid gap-3'
        >
            <YesNoRadioGroup
                control={control}
                name='IsSleepDisorders'
                readOnly={!canEditCareAnamnesis}
                disabled={isSubmitting}
                label='Poruchy spánku'
            />

            <YesNoRadioGroup
                control={control}
                name='IsHypnotics'
                readOnly={!canEditCareAnamnesis}
                disabled={isSubmitting}
                label='Hypnotika'
            />
            {isHypnotics && (
                <InputStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='Hypnotics'
                    errors={errors}
                    label='Jaká:'
                    placeholder='jaká'
                />
            )}

            <Stack spacing={1}>
                <InputLabel className='text-sm font-semibold'>
                    Pohybový režim
                </InputLabel>

                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='IsMotionModeWalksAlone'
                    label='chodí sám'
                />

                <CheckboxStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='IsMotionModeWalksWith'
                    label='chodí s pomocí'
                />

                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsMotionModeIsPlanted'
                    label='posazuje se'
                />

                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsMotionModeLies'
                    label='leží'
                />

                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsMotionModeWheelchairBound'
                    label='upoután na vozík'
                />

                <CheckboxStyled
                    control={control}
                    readOnly={!canEditCareAnamnesis}
                    disabled={isSubmitting}
                    name='IsMotionModeOther'
                    label='jiný'
                />

                {isMotionModeOther && (
                    <InputStyled
                        className='mt-2'
                        control={control}
                        readOnly={!canEditCareAnamnesis}
                        disabled={isSubmitting}
                        name='MotionModeOther'
                        errors={errors}
                        label='Jaký:'
                        placeholder='jaký'
                    />
                )}
            </Stack>

            <YesNoRadioGroup
                control={control}
                name='IsMissingBodyPart'
                readOnly={!canEditCareAnamnesis}
                disabled={isSubmitting}
                label='Chybění části těla'
            />
            {isMissingBodyPart && (
                <InputStyled
                    control={control}
                    disabled={isSubmitting}
                    readOnly={!canEditCareAnamnesis}
                    name='MissingBodyPart'
                    errors={errors}
                    label='Jaké:'
                    placeholder='jaké'
                />
            )}

            <YesNoRadioGroup
                control={control}
                name='IsCompensatoryAids'
                disabled={isSubmitting}
                readOnly={!canEditCareAnamnesis}
                label='Kompenzační pomůcky'
            />
            {isCompensatoryAids && (
                <InputStyled
                    control={control}
                    disabled={isSubmitting}
                    name='CompensatoryAids'
                    readOnly={!canEditCareAnamnesis}
                    errors={errors}
                    label='Jaké:'
                    placeholder='jaké'
                />
            )}

            <Stack spacing={2}>
                <AnamnesisFormFooter
                    currentStep={currentStep}
                    canEditCareAnamnesis={canEditCareAnamnesis}
                    handleSubmitWrapper={handleSubmitWrapper}
                    disabled={isLoading || isSubmitting}
                    setCurrentStep={setCurrentStep}
                />
            </Stack>
        </form>
    )
}

export const AddPatientAnamnesisFormStep4: FC<AddPatientsAnamnesisFormStep4Hook> =
    wrap(AddPatientAnamnesisFormStep4View, useAddPatientAnamnesisFormStep4)
