import { useLoginMutation } from './useLoginMutation'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { LoginFormData, UseLoginFormProps } from '../types'
import { useState } from 'react'
import { ErrorResponse, LoginResponse } from '../../../api/user/types'
import { useSnackbar } from 'notistack'
import { AxiosError } from 'axios'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { AuthUser } from '../../../providers/AuthProvider/types'

export const PAGE_LOGIN_REDIRECT = 'PAGE_LOGIN_REDIRECT'
export const VALIDATIONPATTERN_EMAIL =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const useLogin = (): UseLoginFormProps => {
    const [smsCodeNeeded, setSmsCodeNeeded] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const loginMutation = useLoginMutation()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const getUserLoginPath = ({ organizationID }: AuthUser) => {
        const pageRedirect = sessionStorage.getItem(PAGE_LOGIN_REDIRECT)
        if (pageRedirect) {
            return pageRedirect
        }
        return organizationID === 0 ? '/admin' : '/home'
    }

    const relocateLoggedUser = (loggedUser: AuthUser) =>
        (window.location.href = getUserLoginPath(loggedUser))

    const handleLogin = (res: LoginResponse) => {
        localStorage.setItem('token', res.accessToken)
        return relocateLoggedUser(res.loggedUser)
    }

    const validationSchema = yup.object().shape({
        username: yup
            .string()
            .required('Toto pole je povinné')
            .email('Neplatný formát emailové adresy')
            .matches(
                VALIDATIONPATTERN_EMAIL,
                'Neplatný formát emailové adresy',
            ),
        password: yup.string().required('Toto pole je povinné'),
        smsCode: smsCodeNeeded
            ? yup.string().required('Toto pole je povinné')
            : yup.string(),
    })

    const formMethods = useForm<LoginFormData>({
        criteriaMode: 'all',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
    })

    const submitLogin = async (data: LoginFormData) => {
        setIsLoading(true)
        await loginMutation.mutateAsync(data, {
            onSuccess: (res) => {
                setIsLoading(false)
                res.status === 202 ? setSmsCodeNeeded(true) : handleLogin(res)
            },
            onError: (res) => {
                setIsLoading(false)
                const error = (res as AxiosError).response
                    ?.data as ErrorResponse
                enqueueSnackbar(error?.title + '.', {
                    variant: 'error',
                    action: (key) => (
                        <IconButton onClick={() => closeSnackbar(key)}>
                            <Close color='secondary' />
                        </IconButton>
                    ),
                })
            },
        })
    }

    return { formMethods, submitLogin, smsCodeNeeded, isLoading }
}
