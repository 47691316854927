import React from 'react'

import { BulletinMessageAttachments } from '../../types/components'
import { BulletinDocument } from './BulletinDocument'

const BulletinAttachment: React.FC<BulletinMessageAttachments> = (props) => {
    return <BulletinDocument {...props} />
}

export default BulletinAttachment
