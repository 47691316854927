import { useOpenState } from '../../../hooks'
import type { AsideHookProps, AsideProps } from '../types'
import { useNewNoteForm } from './useNewNoteForm'

export const useAside = (props: AsideHookProps): AsideProps => {
    const { patientLoading, patientSex, patientNotesQuery } = props
    const noteModal = useOpenState()
    const newNoteModal = useOpenState()
    const newNoteForm = useNewNoteForm(newNoteModal.handleClose)

    return {
        patientLoading,
        patientSex,
        noteModal,
        newNoteModal,
        newNoteForm,
        patientNotesQuery,
    }
}
