import { useCallback, useEffect, useRef, useState } from 'react'

// @ts-ignore
import { useScreenshot } from 'use-react-screenshot'
import { HelpDialModalTypes } from '../types'

export const useHelpDial = () => {
    const [selectedModal, setSelectedModal] = useState<HelpDialModalTypes>('')

    const containerRef = useRef(document.body)
    const [image, takeScreenshot] = useScreenshot()

    const getImage = useCallback(() => {
        if (containerRef.current) {
            takeScreenshot(containerRef.current)
        }
    }, [containerRef, takeScreenshot])

    useEffect(() => {
        let modal: HTMLElement | null = null

        if (selectedModal && containerRef.current) {
            if (selectedModal === 'error') {
                modal = document.querySelector(
                    '.MuiBackdrop-root',
                ) as HTMLElement | null

                if (modal) {
                    modal.style.display = 'none'
                    getImage()
                }
            }
        }

        return () => {
            if (modal) {
                modal.style.display = 'flex'
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedModal, containerRef])

    const handleActionClick = useCallback(
        (modalName: HelpDialModalTypes) => (e: any) => {
            e.stopPropagation()
            setSelectedModal(modalName)
        },
        [],
    )

    const closeModal = useCallback(() => {
        setSelectedModal('')
    }, [])

    return {
        selectedModal,
        containerRef,
        image,
        handleActionClick,
        closeModal,
    }
}
