import {
    Control,
    Controller,
    FieldErrors,
    FieldValues,
    get,
    Path,
} from 'react-hook-form'
import { useMapyCZHook } from '../hooks/useMapyCZHook'
import {
    Autocomplete,
    FormControl,
    InputLabel,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import { useUIDSeed } from 'react-uid'
import MapyCZLogo from '../assets/Mapy.cz/mapy-cz-logo-mapovy-podklad/mapy-cz-logo-mapovy-podklad-rgb.svg'
import { MapyCZAdress } from '../api/mapyCZ'
import { forwardRef } from 'react'

interface MapyCZAutocompleteProps<T extends FieldValues> {
    name: Path<T>
    control: Control<T>
    label: string
    disabled: boolean
    placeholder?: string
    errors: FieldErrors<T>
    readOnly?: boolean
}

const ListboxComponent = forwardRef<
    HTMLDivElement,
    React.HTMLProps<HTMLDivElement>
>(function ListboxComponent(props, ref) {
    return (
        <div
            ref={ref}
            {...props}
            className='pb-0 pt-3'
        >
            {props.children}
            <div
                style={{
                    textAlign: 'right',
                    height: '20px !important',
                    paddingInline: '1rem',
                    borderTop: '1px solid #e0e0e0',
                }}
            >
                <div
                    style={{
                        fontSize: '10px',
                        color: '#666',
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'center',
                    }}
                >
                    <h3
                        style={{
                            marginRight: '4px',
                            fontWeight: 'normal',
                        }}
                    >
                        Hledají
                    </h3>
                    <img
                        width={65}
                        height={'auto'}
                        src={MapyCZLogo}
                        alt='Mapy.cz logo'
                    />
                </div>
            </div>
        </div>
    )
})
const MapyCZAutocomplete = <T extends FieldValues>({
    name,
    control,
    label,
    disabled,
    placeholder = '',
    readOnly,
    errors,
}: MapyCZAutocompleteProps<T>) => {
    const { suggestions, setInputValue, isLoading, inputValue, isFetched } =
        useMapyCZHook()
    const uid = useUIDSeed()

    const errorMessage = get(errors, name)?.message
        ? String(get(errors, name)?.message)
        : ''

    return (
        <Stack spacing={1}>
            <InputLabel className='text-sm'>{label}</InputLabel>
            <FormControl fullWidth>
                <Controller
                    control={control}
                    name={name}
                    render={({ field: { onChange, value, ref, ...field } }) => (
                        <Autocomplete
                            {...field}
                            readOnly={readOnly}
                            loading={isLoading}
                            loadingText={
                                <div
                                    style={{
                                        fontSize: '14px',
                                        padding: '4px !important',
                                    }}
                                >
                                    Načítání...
                                </div>
                            }
                            noOptionsText={
                                !isLoading && inputValue.length < 3 ? (
                                    <div
                                        style={{
                                            fontSize: '14px',
                                            padding: '4px !important',
                                        }}
                                    >
                                        Zadejte alespoň 3 znaky pro hledání
                                    </div>
                                ) : !isLoading && isFetched ? (
                                    <div
                                        style={{
                                            fontSize: '14px',
                                            padding: '4px !important',
                                        }}
                                    >
                                        Žádné výsledky nenalezeny
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            fontSize: '14px',
                                            padding: '4px !important',
                                        }}
                                    >
                                        Načítání...
                                    </div>
                                )
                            }
                            value={value || null}
                            filterOptions={(options) => options}
                            options={suggestions as MapyCZAdress[]}
                            isOptionEqualToValue={(option, value) =>
                                option.name === value.name
                            }
                            getOptionLabel={(option) => option.name || ''}
                            disabled={disabled}
                            onInputChange={(_, newValue) => {
                                setInputValue(newValue)
                            }}
                            onChange={(_, selectedValue) => {
                                onChange(selectedValue || null)
                            }}
                            renderOption={(props, option, { selected }) => {
                                const baseClass =
                                    'cursor-pointer px-4 py-2 text-sm transition'
                                const selectedClass = selected
                                    ? 'bg-[#f5b895]'
                                    : 'bg-[#fff]'
                                const locationColor = selected
                                    ? 'text-gray-700'
                                    : 'text-gray-500'
                                return (
                                    <li
                                        {...props}
                                        className={`${baseClass} ${selectedClass} list-none hover:bg-[#f3f3f3]`}
                                        key={uid(option.name)}
                                    >
                                        <div className='flex flex-col'>
                                            <span className='text-black font-medium'>
                                                {option.name}
                                            </span>
                                            <span
                                                className={`text-xs ${locationColor}`}
                                            >
                                                {option.location}
                                            </span>
                                        </div>
                                    </li>
                                )
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder={
                                        placeholder
                                            ? `Uveďte ${placeholder} zde...`
                                            : ''
                                    }
                                    error={!!errors[name]}
                                    variant='outlined'
                                    size='small'
                                    InputProps={{
                                        ...params.InputProps,
                                        sx: {
                                            backgroundColor: '#f8f8f8',
                                            fontSize: '14px !important',
                                        },
                                    }}
                                />
                            )}
                            ListboxComponent={ListboxComponent}
                        />
                    )}
                />
            </FormControl>
            {errorMessage && (
                <Typography className='pt-1 text-sm text-error-2'>
                    {errorMessage}
                </Typography>
            )}
        </Stack>
    )
}

export default MapyCZAutocomplete
