import { DragIndicator } from '@mui/icons-material'
import { Divider, Grid, Stack, Typography } from '@mui/material'
import { Draggable } from 'react-beautiful-dnd'
import { VisitCard } from '../../../components'
import type { ReorderItemProps } from '../types'

export const ReorderItem = (props: ReorderItemProps) => {
    const { index, id, date } = props

    return (
        <Draggable
            draggableId={String(id)}
            index={index}
        >
            {({ draggableProps, dragHandleProps, innerRef }) => (
                <div
                    ref={innerRef}
                    {...draggableProps}
                >
                    {index !== 0 && (
                        <Divider className='mb-2 border-gray-300' />
                    )}
                    <Grid
                        container
                        alignItems='center'
                        spacing={2}
                        className='mb-2'
                    >
                        <Grid
                            item
                            xs={10}
                        >
                            <VisitCard
                                mainVisitData={{
                                    id,
                                    date,
                                }}
                                showLessCodes
                                {...props}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={2}
                        >
                            <Stack
                                direction='row'
                                className='text-gray-500'
                                {...dragHandleProps}
                            >
                                <Typography className='text-[inherit]'>
                                    {index + 1}.
                                </Typography>
                                <DragIndicator />
                            </Stack>
                        </Grid>
                    </Grid>
                </div>
            )}
        </Draggable>
    )
}
