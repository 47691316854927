import { useMedicationQuery } from '../../../api'
import { MedicationHookProps, MedicationProps } from '../types'
import { useContext } from 'react'
import { PatientContext } from '../../../providers'
import moment from 'moment'
import { useOpenState } from '../../../hooks'

export const useMedication = ({
    dateAt,
    visitId,
}: MedicationHookProps): MedicationProps => {
    const { activePatient } = useContext(PatientContext)
    const { isOpen: showOnlyCurrent, stateSwitch: handleCurrentChange } =
        useOpenState()

    const {
        data: medicationData,
        isLoading: isMedicationLoading,
        isError: isMedicationError,
    } = useMedicationQuery(
        {
            patientId: Number(activePatient?.id),
            visitId: Number(visitId),
            dateAt: showOnlyCurrent ? moment(dateAt).format('YYYY-MM-DD') : '',
        },
        showOnlyCurrent,
    )

    return {
        medicationData,
        isMedicationLoading,
        isMedicationError,
        showOnlyCurrent,
        handleCurrentChange,
    }
}
