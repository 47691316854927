import { VisitCarouselProps } from '../types'
import { CarouselItem } from './CarouselItem'
import Carousel from 'react-material-ui-carousel'
import { useUIDSeed } from 'react-uid'
import { Grid } from '@mui/material'

export const VisitCarousel = (props: VisitCarouselProps) => {
    const {
        isLoading,
        location,
        carouselState: {
            slidesPerPage,
            isMobile,
            slides,
            carouselHeight,
            setCarouselHeight,
        },
    } = props

    const uid = useUIDSeed()

    return (
        <Carousel
            key={slidesPerPage}
            autoPlay={false}
            animation='slide'
            navButtonsAlwaysInvisible={isMobile}
            navButtonsAlwaysVisible={false}
            height={carouselHeight}
            sx={{
                '&:hover .MuiIconButton-root[aria-label="Next"], &:hover .MuiIconButton-root[aria-label="Previous"] ':
                    {
                        opacity: 0.6,
                    },
                "& .MuiIconButton-root[aria-label='Previous']:hover": {
                    opacity: '1 !important',
                },
                "& .MuiIconButton-root[aria-label='Next']:hover": {
                    opacity: '1 !important',
                },
            }}
            onChange={() => {
                setCarouselHeight(undefined)
            }}
            navButtonsWrapperProps={{
                style: {
                    height: 'auto',
                    top: '40%',
                    transform: 'translateY(-50%)',
                },
            }}
            navButtonsProps={{
                style: {
                    color: '#f8f8f8',
                    background: '#f8833b',
                    marginLeft: '1.25rem',
                    marginRight: '1.25rem',
                },
            }}
            indicatorContainerProps={{
                style: {
                    marginTop: '-0.2rem',
                    paddingBottom: '12px',
                },
            }}
            indicatorIconButtonProps={{
                style: {
                    color: '#fcc19c',
                },
            }}
            activeIndicatorIconButtonProps={{
                style: {
                    color: '#f8833b',
                },
            }}
            className='-mx-2'
        >
            {isLoading
                ? Array.from(new Array(3)).map((_, index) => (
                      <Grid
                          spacing={2}
                          container
                          className='px-2'
                          key={index}
                      >
                          {Array.from(new Array(slidesPerPage)).map((visit) => (
                              <CarouselItem
                                  key={uid(Math.random())}
                                  latLng={location?.latLng}
                                  isLoading={isLoading}
                                  slidesPerPage={slidesPerPage}
                                  {...visit}
                              />
                          ))}
                      </Grid>
                  ))
                : slides.length > 0 &&
                  slides.map((slide, index) => (
                      <Grid
                          spacing={2}
                          container
                          className='px-2'
                          key={index}
                      >
                          {slide.map((visit) => (
                              <CarouselItem
                                  key={uid(
                                      visit?.id ? visit.id : Math.random(),
                                  )}
                                  latLng={location?.latLng}
                                  isLoading={isLoading}
                                  slidesPerPage={slidesPerPage}
                                  {...visit}
                              />
                          ))}
                      </Grid>
                  ))}
        </Carousel>
    )
}
