import { FC } from 'react'
import { TreatmentItemView } from './TreatmentItemView'
import { wrap } from '../../../../utils'
import type { TreatmentHookProps } from '../../types'
import { useTreatmentItem } from '../../hooks'

export const TreatmentItem: FC<TreatmentHookProps> = wrap(
    TreatmentItemView,
    useTreatmentItem,
)
