import {
    Box,
    InputLabel,
    OutlinedInput,
    Stack,
    ToggleButton,
} from '@mui/material'
import { Controller } from 'react-hook-form'
import { Modal } from '../../../../components'
import type { TreatmentStep } from '../../types'
import { TreatmentItem } from './TreatmentItem'
import StackGrid from 'react-stack-grid'
import { VoucherPlan } from './VoucherPlan'
import type { FC } from 'react'
import { useUIDSeed } from 'react-uid'
import classNames from 'classnames'

export const TreatmentStepView: FC<TreatmentStep> = (props) => {
    const {
        visitForm,
        diagnose,
        voucherCarePlans,
        plans,
        canSave,
        carePlanIndex,
        lastVisit,
        isOpen,
        handleOpen,
        handleModalClose,
    } = props

    const {
        formMethods: {
            control,
            setValue,
            watch,
            formState: { errors },
        },
    } = visitForm

    const { carePlans: carePlansErrors } = errors

    const uid = useUIDSeed()

    return (
        <Box className='m-0 [&:not(:first-of-type)]:pt-2 [&:not(:last-of-type)]:pb-2'>
            <TreatmentItem
                onClick={handleOpen}
                hasBackground
                hideSelectAll
                hasAllCodes
                isError={
                    !!carePlansErrors?.[carePlanIndex]?.plans ||
                    !!carePlansErrors?.[carePlanIndex]?.voucherCarePlans
                }
                {...props}
            />
            <Modal
                open={isOpen}
                handleClose={handleModalClose}
                onClick={handleModalClose}
                sx={{
                    marginX: '-8px',
                }}
            >
                <Stack>
                    <Stack
                        direction='row'
                        paddingBottom={2}
                    >
                        <TreatmentItem
                            isThinner
                            hideSelectAll={false}
                            {...props}
                        />
                    </Stack>
                    {voucherCarePlans?.map((voucher, voucherIndex) => (
                        <VoucherPlan
                            canSave={canSave}
                            carePlanIndex={carePlanIndex}
                            visitForm={visitForm}
                            diagnose={diagnose}
                            key={uid(
                                `voucher-${voucherIndex}-${carePlanIndex}`,
                            )}
                            voucherIndex={voucherIndex}
                            canEdit={canSave}
                            lastVisit={lastVisit}
                            {...voucher}
                        />
                    ))}
                    <InputLabel
                        sx={{
                            paddingBottom: '8px',
                            fontSize: '14px',
                            fontWeight: '700',
                        }}
                    >
                        Doplnění
                    </InputLabel>
                    <Controller
                        control={control}
                        name={`carePlans.${carePlanIndex}.addition`}
                        render={({ field }) => (
                            <OutlinedInput
                                {...field}
                                value={field.value || ''}
                                placeholder='zde zadejte doplňující informace'
                                multiline
                                readOnly={!canSave}
                                className={classNames(
                                    'rounded text-sm font-medium',
                                    {
                                        'bg-orange-100': canSave === false,
                                        'bg-white-reduced': canSave,
                                    },
                                )}
                                sx={{
                                    width: '100%',
                                    bgcolor: '#F8F8F8',
                                    fontSize: '14px',
                                    color: '#4B5563',
                                    paddingY: '8px',
                                    marginBottom: '16px',
                                }}
                            />
                        )}
                    />
                    {!!plans?.length && (
                        <>
                            <InputLabel
                                sx={{
                                    paddingBottom: '8px',
                                    fontSize: '14px',
                                    fontWeight: '700',
                                }}
                            >
                                Kroky
                            </InputLabel>
                            <StackGrid
                                columnWidth={'50%'}
                                gutterHeight={8}
                                gutterWidth={8}
                                horizontal
                            >
                                {plans?.map((plan, planIndex) => (
                                    <ToggleButton
                                        selected={watch(
                                            `carePlans.${carePlanIndex}.plans.${planIndex}.done`,
                                        )}
                                        value='check'
                                        size='small'
                                        key={uid(planIndex)}
                                        disabled={!canSave}
                                        sx={{
                                            textAlign: 'left',
                                            textTransform: 'none',
                                            fontSize: '12px',
                                            fontWeight: 'bold',
                                            padding: '4px 8px',
                                            bgcolor: '#F8F8F8',
                                            color: '#4B5563',
                                            borderColor: '#f8833b',
                                            '&.Mui-selected': {
                                                bgcolor: '#f8833b',
                                                color: '#f8f8f8',
                                            },
                                            '&.Mui-selected:focus': {
                                                bgcolor: '#f8833b',
                                            },
                                        }}
                                        onClick={() => {
                                            const stepDone = watch(
                                                `carePlans.${carePlanIndex}.plans.${planIndex}.done`,
                                            )
                                            setValue(
                                                `carePlans.${carePlanIndex}.plans.${planIndex}.done`,
                                                !stepDone,
                                            )
                                        }}
                                    >
                                        {plan.name}
                                    </ToggleButton>
                                ))}
                            </StackGrid>
                        </>
                    )}
                </Stack>
            </Modal>
        </Box>
    )
}
