import { FC } from 'react'
import { wrap } from '../../../../utils'
import { useMedication } from '../../hooks'
import type { MedicationHookProps, MedicationProps } from '../../types'
import {
    FormControlLabel,
    SpeedDialAction,
    Stack,
    Switch,
    Typography,
} from '@mui/material'
import { MedicationCard } from './MedicationCard'
import { ContentError, ContentLoader, HelpDial } from '../../../../components'
import { MedicationInput } from './MedicationInput'
import { visitDetailMedicationActions } from '../../../../components/HelpDial/HelpDialActions'
import { HelpSpeedDialActions } from '../../../../components/HelpDial/types'
import { useUIDSeed } from 'react-uid'

const MedicationView: FC<MedicationProps> = (props) => {
    const {
        isMedicationLoading,
        isMedicationError,
        medicationData,
        showOnlyCurrent,
        handleCurrentChange,
    } = props

    const uid = useUIDSeed()

    return (
        <section className='pb-8'>
            <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
            >
                <h2 className='text-[1rem] font-medium'>Medikace</h2>
                <FormControlLabel
                    className='mr-0'
                    control={
                        <Switch
                            checked={showOnlyCurrent}
                            onChange={handleCurrentChange}
                            disabled={
                                isMedicationLoading ||
                                isMedicationError ||
                                !medicationData?.data?.length
                            }
                            defaultChecked
                            color='primary'
                            size='small'
                        />
                    }
                    slotProps={{
                        typography: {
                            fontSize: '0.8125rem',
                        },
                    }}
                    labelPlacement='start'
                    label='Pouze aktuální'
                />
            </Stack>
            {isMedicationLoading ? (
                <ContentLoader loadingText='Načítáme medikace' />
            ) : isMedicationError ? (
                <ContentError />
            ) : (
                <Stack
                    gap='1rem'
                    className='text-sm'
                >
                    {medicationData?.data && medicationData.data?.length > 0 ? (
                        medicationData?.data?.map((medicationProps) => (
                            <MedicationCard
                                key={uid(medicationProps.id)}
                                {...medicationProps}
                            />
                        ))
                    ) : (
                        <Typography fontSize='medium'>
                            Pro pacienta nejsou aktuálně zapsány žádné medikace.
                        </Typography>
                    )}
                    <MedicationInput
                        readOnly
                        multiline
                        size='small'
                        labelText='Poznámka k medikaci'
                        className='bg-orange-100'
                        inputData={medicationData?.note || 'neuvedeno'}
                    />
                    <MedicationInput
                        readOnly
                        multiline
                        size='small'
                        labelText='Významné údaje o současné medikaci'
                        className='bg-orange-100'
                        inputData={medicationData?.importantNote || 'neuvedeno'}
                    />
                </Stack>
            )}
            <HelpDial>
                {({ handleClose, handleActionClick }) => {
                    return visitDetailMedicationActions.map(
                        ({ name, icon, modalName }: HelpSpeedDialActions) => (
                            <SpeedDialAction
                                key={uid(modalName)}
                                icon={icon}
                                tooltipTitle={name}
                                tooltipPlacement='right'
                                tooltipOpen
                                sx={{
                                    '& .MuiSpeedDialAction-staticTooltipLabel':
                                        {
                                            width: 'max-content',
                                        },
                                }}
                                onClick={handleActionClick(modalName)}
                            />
                        ),
                    )
                }}
            </HelpDial>
        </section>
    )
}

export const Medication: FC<MedicationHookProps> = wrap(
    MedicationView,
    useMedication,
)
