import { useState, type MouseEvent } from 'react'
import { DropdownAnchor } from '../types'

export const useDropdownAnchor = (): DropdownAnchor => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

    return { open, handleOpenMenu, handleCloseMenu, anchorEl }
}
