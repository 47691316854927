export const getAuthorizationHeader = (token?: string | null) => {
    token = token || getToken()
    if (!token) return undefined
    return { authorization: `Bearer ${token}` }
}

export const getToken = (): string | null => {
    return localStorage.getItem('token')
}

export const getConfigHeaders = (token?: string) => ({
    'Content-Type': 'application/json',
    ...getAuthorizationHeader(token),
})

export const getRestApiConfig = (token?: string) => {
    return {
        baseUrl: process.env.REACT_APP_API_HOST,
        headerConfig: {
            headers: getConfigHeaders(token),
            data: {},
        },
    }
}
