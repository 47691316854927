import axios from 'axios'
import { SimpleEnum } from '../../types'
import { getRestApiConfig } from '../config'
import { LoginResponse } from '../user/types'

export const getOrganizations = async (
    token?: string,
): Promise<SimpleEnum[]> => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)
    const endpointUrl = new URL('/api-mob/organizations', baseUrl).href

    const response = await axios.get<SimpleEnum[]>(endpointUrl, headerConfig)
    return response.data
}

export const getOrganizationUsers = async (
    organizationId?: number,
    token?: string,
): Promise<SimpleEnum[]> => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)
    const endpointUrl = new URL(`/api-mob/users/${organizationId}`, baseUrl)
        .href

    const response = await axios.get<SimpleEnum[]>(endpointUrl, headerConfig)
    return response.data
}

export const getReadonlyUserResponse = async (
    userId?: number,
    token?: string,
): Promise<LoginResponse> => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)
    const endpointUrl = new URL(`/api-mob/getTokenForUser/${userId}`, baseUrl)
        .href

    const response = await axios.get<LoginResponse>(endpointUrl, headerConfig)
    return response.data
}
